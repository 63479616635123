import { fetchTags } from "./queries";
import { Action } from "../../types";
import { convertStringDatesToActualDates } from "../utils";

//@ts-ignore
export const initialize: Action = ([set]) => {
  fetchTags().catch((error) => {
    set((state) => {
      state.isTagsLoading = false;
    });
  }).then((tags) => {
    if (!tags) return;
    set((state) => {
      state.tags = convertStringDatesToActualDates(tags);
      state.isTagsLoading = false;
    });
  });
};

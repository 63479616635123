import { MergeCellsIcon, SplitCellsIcon } from "@/components/icons/hollow";
import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap";

export const MergeOrSplit = () => {
  const iconClassName = "w-4 h-4 text-neutral-800";
  const { editor } = useRichTextEditorContext();
  const canMerge = editor?.can().mergeCells();
  const Icon = canMerge ? MergeCellsIcon : SplitCellsIcon;
  const title = canMerge ? "Merge cells" : "Split cell";

  return (
    <RichTextEditor.Control
      title={title}
      aria-label={title}
      onClick={() => editor?.commands.mergeOrSplit()}
    >
      {<Icon className={iconClassName} />}
    </RichTextEditor.Control>
  );
};

import { calculateMinutesForQuestions } from "@/services/test";
import { Test } from "../test-model-types";
import { DateTime } from "luxon";
import { TestSlot } from "@/models/testslot-model";

const reconstructTestMode = (
  { tutor, timed }: { tutor?: boolean; timed?: boolean },
) => {
  return [tutor ? "tutor" : "", timed ? "timed" : ""].join(",");
};

const calculateTestDueDate = (
  { unsubmittedSlotCount, mode, minutesPerQuestion = 1.5 }: {
    unsubmittedSlotCount: number;
    mode: string;
    minutesPerQuestion?: number;
  },
): Date | null => {
  const isTimed = mode.includes("timed");
  if (!isTimed) return null;
  return DateTime.fromJSDate(new Date()).plus({
    minutes: calculateMinutesForQuestions(
      unsubmittedSlotCount,
      minutesPerQuestion,
    ),
  }).toJSDate();
};

const setTestModeManipulator = (
  { test, mode, testSlots }: {
    test: Pick<Test, "mode" | "endsAt">;
    testSlots: Pick<TestSlot, "submitted">[];
    mode: { tutor?: boolean; timed?: boolean };
  },
): Pick<Test, "mode" | "endsAt"> => {
  const { tutor, timed } = mode;
  const wasAlreadyTutor = test.mode.includes("tutor");
  const wasAlreadyTimed = test.mode.includes("timed");

  const newTestMode = reconstructTestMode({
    tutor: tutor ?? wasAlreadyTutor,
    timed: timed ?? wasAlreadyTimed,
  });

  const newTestEndsAt = calculateTestDueDate({
    mode: newTestMode,
    unsubmittedSlotCount: testSlots.filter((s) => s.submitted === false).length,
  });

  return {
    mode: newTestMode,
    endsAt: wasAlreadyTimed ? test.endsAt : newTestEndsAt,
  };
};

export default setTestModeManipulator;

import { createFileRoute } from "@tanstack/react-router";

import { Header, Table } from "./-components";
export const Route = createFileRoute("/admin/users")({
  component: AdminUsersPage,
});

export default function AdminUsersPage() {
  return (
    <div className="flex flex-col justify-start items-center w-full gap-3">
      <Header />
      <Table />
    </div>
  );
}

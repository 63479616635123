import { useAppStore } from "@/store";
import { NoteAction } from "../../types";
import { findTarget } from "@/store/utils";
import { renameNote as renameNoteOnServer } from "./mutations";
import { toast } from "sonner";

export const renameNote: NoteAction =
  ([set]) => ({ id, newTitle }: { id: string; newTitle: string }) => {
    const appState = useAppStore.getState();
    const { notes: oldNotes } = appState;
    const oldNote = findTarget(id, oldNotes);
    if (!oldNote) {
      toast.error("Trying to rename a note that doesn't exist");
      return;
    }
    set((state) => {
      findTarget(id, state.notes)!.title = newTitle;
    });

    renameNoteOnServer({ id, newTitle }).catch((error) => {
      toast.error(`Could not rename note, an error occurred: ${error.message}`);
      set((state) => {
        findTarget(id, state.notes)!.title = oldNote.title;
      });
    }).then((updatedNote) => {
      toast.success(`Successfully renamed note to: ${updatedNote!.title}`);
    });
  };

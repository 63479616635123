import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface InputGroupProps extends PropsWithChildren {
  className?: string;
  error?: string;
  required?: string;
}

export const InputGroup = (
  { children, className, error, required }: InputGroupProps,
) => {
  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child as React.ReactElement, {
        error: error,
      });
    });
  };

  return (
    <div
      className={twMerge(
        "flex flex-col justify-center items-start w-full gap-1",
        className,
      )}
    >
      {renderChildren()}
      {error && (
        <p className="text-red-500 self-end text-right text-xs lg:text-sm">
          {error}
        </p>
      )}
    </div>
  );
};

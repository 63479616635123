import React from "react";
import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { toast } from "sonner";
import { useNbmeNotebookVisible } from "@/utils/stores/nbmeLayoutStore";

export const NbmeNotebookButton = (props: {}) => {
  const [isVisible, setIsVisible] = useNbmeNotebookVisible();
  return (
    <NbmeIconNavbarButton
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faBook} className="w-6 h-6" />}
      label="My Notebook"
      onClick={handleClick}
    />
  );

  function handleClick() {
    setIsVisible(!isVisible);
  }
};

import { NbmeSettingsConfirmAnswerOmissionControl } from "./NbmeSettingsConfirmAnswerOmissionControl";
import { NbmeSettingsBarSection } from "./NbmeSettingsBarSection";
import { NbmeSettingsPercentageAnsweredControl } from "./NbmeSettingsPercentageAnsweredControl";
import { NbmeSettingsSegment } from "./NbmeSettingsSegment";

export const NbmeSettingsFeaturesSection = () => {
  return (
    <NbmeSettingsBarSection
      title={"Features"}
    >
      <NbmeSettingsSegment>
        <p className="text-xs">Confirm Answer Omission</p>
        <NbmeSettingsConfirmAnswerOmissionControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment className="">
        <p className="text-xs">Hide % Answered Correctly</p>
        <NbmeSettingsPercentageAnsweredControl />
      </NbmeSettingsSegment>
    </NbmeSettingsBarSection>
  );
};

import { DashboardPerformanceSelect } from "@/routes/qbanks/$qbankEndpoint/dashboard/-components";
import { useReportOptions } from "../../-hooks";
import { DashboardPerformanceSelectItemTypeWithoutMarked } from "@/routes/qbanks/$qbankEndpoint/dashboard/-components/DashboardPerformanceSelect";

export const PerformanceTestAnalysisPageSelect = () => {
  const { options, setOptions } = useReportOptions();
  return (
    <DashboardPerformanceSelect
      showTypeSelect={false}
      withMarked={false}
      type="Score"
      onItemsChange={setOptions}
      items={options.filter((option) =>
        option !== "Marked"
      ) as DashboardPerformanceSelectItemTypeWithoutMarked[]}
    />
  );
};

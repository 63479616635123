import { useNbmeLayoutStore, useTestStore } from "@/utils/stores";
import { NbmeHighlightMarker } from "./NbmeHighlightMarker";
import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "@/assets/framer";
import { useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "@/utils/hooks";
import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { NbmeHighlightMarkerMenuButton } from "./NbmeHighlightMarkersMenuButton";
import useTestPageClearHighlights from "@/routes/tests/$testId/-test-page-hooks/use-test-page-clear-highlights";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { HighlightMarkerForm } from "@/ncomponents/highlights/highlight-marker-form";
import { DashboardScrollArea } from "./DashboardScrollArea";

export const NbmeHighlightMarkersMenu = ({}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const clearHighlights = useTestPageClearHighlights();

  const highlightMarkersQuery = useHighlightMarkers();
  const { data: highlightMarkersArrayMap } = highlightMarkersQuery;
  const highlightMarkers = useMemo(
    () => ArrayMapUtils.toArray(highlightMarkersArrayMap),
    [highlightMarkersArrayMap],
  );

  const { setSelectedHighlightMarker } = useTestStore((state) => ({
    setSelectedHighlightMarker: state.setSelectedHighlightMarker,
  }));
  const { visible, setVisible, setShortcutsEnabled } = useNbmeLayoutStore((
    state,
  ) => ({
    visible: state.highlightMarkersVisible,
    setVisible: state.setHighlightMarkersVisible,
    isWarnedAboutHighlights: state.isUserWarnedAboutHighlights,
    setShortcutsEnabled: state.setShortcutsEnabled,
  }));

  const [isHighlightCreatorVisible, setIsHighlightCreatorVisible] = useState<
    boolean
  >(false);

  useEffect(() => {
    setShortcutsEnabled(!isHighlightCreatorVisible);
  }, [isHighlightCreatorVisible]);

  const handleNoneClicked = () => {
    setVisible(false);
  };

  return (
    <AnimatePresence mode="wait">
      {visible &&
        (
          <motion.div
            ref={menuRef}
            {...fadeAnimation}
            className="absolute flex flex-col justify-start items-start text-white font-semibold top-0 md:top-auto md:bottom-0 left-0 -translate-x-[110%] md:translate-x-[-25%] md:translate-y-[105%] border border-transparent dark:border-nbme-primary-dark-600 rounded-t-primary rounded-b-primary w-48"
          >
            <NbmeHighlightMarker
              name="None"
              id=""
              color="white"
              className="rounded-t-primary dark:rounded-t-primary"
              userId={""}
              onClick={handleNoneClicked}
            />
            <DashboardScrollArea
              className="max-h-40"
              viewportClassName="overflow-x-hidden"
              thumbClassName="bg-nbme-primary-900"
              scrollbarClassName="bg-transparent hover:bg-nbme-primary-800 warm:hover:bg-brown-900"
            >
              <>
                {highlightMarkers.map((hm) => (
                  <NbmeHighlightMarker
                    key={hm.id}
                    {...hm}
                    onClick={() => setVisible(false)}
                  />
                ))}
              </>
            </DashboardScrollArea>
            {
              /*
            <div className="flex flex-row justify-between items-center w-full text-sm">
              <NbmeHighlightMarkerMenuButton onClick={undoHighlight}>
                Undo
              </NbmeHighlightMarkerMenuButton>

              <div className="h-9 w-0.5 bg-primary-700 dark:bg-nbme-primary-dark-500">
              </div>



              <NbmeHighlightMarkerMenuButton onClick={redoHighlight}>
                Redo
              </NbmeHighlightMarkerMenuButton>

            </div>*/
            }
            <Popover
              open={isHighlightCreatorVisible}
              onOpenChange={setIsHighlightCreatorVisible}
            >
              <PopoverTrigger className="!text-center flex flex-row justify-center items-center !w-full bg-nbme-primary-700 py-1.5 hover:bg-nbme-primary-800 dark:bg-neutral-800 dark:hover:bg-nbme-primary-dark-700 transition-colors warm:bg-brown-700 warm:hover:bg-brown-800">
                Create
              </PopoverTrigger>
              <PopoverContent className="w-full z-[999] bg-transparent dark:bg-transparent shadow-none border-0">
                <HighlightMarkerForm
                  onCreate={(newHighlightMarker) => {
                    setIsHighlightCreatorVisible(false);
                    setVisible(false);
                    setSelectedHighlightMarker(newHighlightMarker);
                  }}
                  onCancel={() => {
                    setIsHighlightCreatorVisible(false);
                  }}
                />
              </PopoverContent>
            </Popover>
            <NbmeHighlightMarkerMenuButton
              className="rounded-b-primary"
              onClick={() => {
                setVisible(false);
                clearHighlights();
              }}
            >
              <p className="text-center flex flex-row justify-center items-center w-full">
                Clear All
              </p>
            </NbmeHighlightMarkerMenuButton>
          </motion.div>
        )}
    </AnimatePresence>
  );
};

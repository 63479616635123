import WindowTitlebarIndicator from "./window-titlebar-indicator";
import ResizableIcon from "@/components/icons/resizable-icon";

const WindowResizableIndicator = () => {
  return (
    <WindowTitlebarIndicator tooltip="This Window Can Be Resized">
      <ResizableIcon />
    </WindowTitlebarIndicator>
  );
};

export default WindowResizableIndicator;

import useNbmeSidebarVisible from "./use-nbme-sidebar-visible";
import { useEffect } from "react";
import { useOnMobile } from "@/hooks";
import { useNbmeSettingsAutoShowSidebar } from "@/utils/stores/nbmeLayoutStore";
import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";

const useNbmeSidebarActivity = () => {
  const onMobile = useOnMobile();
  const { data: test } = useTestPageTest();
  const slot = test?.selectedSlot;
  const [autoShow] = useNbmeSettingsAutoShowSidebar();
  const [_, setSidebarVisible] = useNbmeSidebarVisible();
  useEffect(() => {
    if (!autoShow) return;
    if (onMobile) return;
    setSidebarVisible(true);
  }, [slot]);
};

export default useNbmeSidebarActivity;

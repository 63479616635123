import { Test } from "@/models/test-model";
import useTest from "./use-test";

const useTestSettings = ({ testId }: { testId: Test["id"] }) => {
  const testQuery = useTest({ testId });
  const { data: test } = testQuery;

  const settings = test
    ? {
      mode: test.mode.split(","),
      pool: test.pool.split(","),
    }
    : undefined;
  return { ...testQuery, data: settings };
};
export default useTestSettings;

import { immer } from "zustand/middleware/immer";
import { createJSONStorage, persist } from "zustand/middleware";
import * as slices from "./slices";
import { create } from "zustand";
import { mountSlices } from "./utils";
import { Store } from "./types";

export const useAppStore = create<Store>()(persist(
  immer((...a) => ({
    ...mountSlices(slices, a),
  })),
  {
    name: "coursology-app-store",
    version: 5,
    partialize: (state) => ({
      theme: state.theme,
      currentUser: state.currentUser,
    }),
    storage: createJSONStorage(() => localStorage),
  },
));

import { Notebook } from "@/models";
import { Setter } from "@/types";
import { StateCreator } from "zustand";
import { createPageSlice } from "./slices";
import { fetchNotebook } from "./queries";
import { fixDates } from "./utils";
import { toast } from "sonner";

import { state } from "./state";

type PageSlice = ReturnType<typeof createPageSlice>;

export interface NotebookSlice extends PageSlice {
  notebookDraft: string;
  notebook?: Notebook;
  isLoading: boolean;
  selectedNoteId: string;
  setNotebookDraft: Setter<string>;
  setSelectedNoteId: Setter<this["selectedNoteId"]>;
}

export const createNotebookSlice: StateCreator<
  NotebookSlice,
  [["zustand/immer", never]],
  [],
  NotebookSlice
> = (...args) => {
  const set = args[0];
  fetchNotebook().then((notebook) =>
    set((state) => {
      state.isLoading = false;
      state.notebook = { id: notebook.id, creatorId: notebook.creatorId };
      state.pages = fixDates(notebook.pages) as typeof state.pages;
      state.notes = fixDates(notebook.notes) as typeof state.notes;
    })
  );

  return ({
    ...state,
    setSelectedNoteId: (newId) => set(() => ({ selectedNoteId: newId })),
    setNotebookDraft: (newDraft) => set(() => ({ notebookDraft: newDraft })),
    ...createPageSlice(...args),
  });
};

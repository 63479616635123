import { Test } from "@/api/types";
import useTest from "./use-test";

const useTestScore = ({ testId }: { testId: Test["id"] }) => {
  const testQuery = useTest({ testId });

  return {
    ...testQuery,
    data: testQuery?.data?.score,
  };
};
export default useTestScore;

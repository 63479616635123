import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";

interface TagIconProps {
  disabled?: boolean;
}
export const TagIcon = ({ disabled }: TagIconProps) => {
  return (
    <FontAwesomeIcon
      className={twMerge(
        "text-gray-400 warm:text-brown-950/50",
        disabled && "text-gray-200",
      )}
      icon={faTag}
    />
  );
};

import { useState } from "react";
import { NbmeFloatingWindow } from ".";
import LabeledCheckbox from "@/components/LabeledCheckbox";
import { Button } from "@/components/Button";

interface NbmeFeedbackWindowProps {
  isVisible: boolean;
  onIsVisibleChange: (v: boolean) => void;
  onIsSelectionEnabledChange: (v: boolean) => void;
  onIsShortcutsEnabledChange: (v: boolean) => void;
  onFeedbackSubmitted: (
    feedback: {
      text: string;
      technical: boolean;
    },
  ) => void;
}

export const NbmeFeedbackWindow = (
  {
    isVisible,
    onIsVisibleChange,
    onIsSelectionEnabledChange,
    onFeedbackSubmitted,
    onIsShortcutsEnabledChange,
  }: NbmeFeedbackWindowProps,
) => {
  const [draft, setDraft] = useState<string>("");
  const [technical, setTechnical] = useState<boolean>(false);

  return (
    <NbmeFloatingWindow
      onIsSelectionEnabledChange={onIsSelectionEnabledChange}
      isVisible={isVisible}
      title={"Submit Feedback"}
      className="lg:min-w-[30rem] md:min-w-[25rem] min-w-[20rem]"
      viewportClassName="dark:bg-nbme-primary-dark-800"
      onCloseClicked={() => {
        onIsVisibleChange(false);
        setDraft("");
      }}
    >
      <form
        onSubmit={() => {
          if (!draft) {
            onIsVisibleChange(false);
            return;
          }
          onFeedbackSubmitted({ text: draft, technical });
          onIsVisibleChange(false);
        }}
        className="flex flex-col justify-start items-center w-full p-3 gap-3"
      >
        <textarea
          value={draft}
          onChange={(e) => setDraft(e.target.value)}
          onFocus={() => onIsShortcutsEnabledChange(false)}
          onBlur={() => onIsShortcutsEnabledChange(true)}
          placeholder="Your feedback matters to us! Make sure to avoid simple feedback as 'good question', or 'bad question'."
          className="w-full border border-gray-300 rounded-primary px-2 py-1 min-h-[10rem] placeholder:text-sm lg:placeholder:text-base dark:bg-nbme-primary-dark-600 dark:text-white dark:placeholder:text-nbme-primary-dark-300 dark:border-nbme-primary-dark-600 dark:outline-nbme-primary-dark-600"
        >
        </textarea>
        <div className="flex flex-row justify-between items-center w-full gap-5">
          <LabeledCheckbox
            className="w-full"
            checkBoxClassName="dark:bg-nbme-primary-dark-600 dark:border-nbme-primary-dark-600"
            labelClassName="text-xs w-full"
            id="technical-checkbox"
            label="Check here if you are submitting feedback regarding a technical issue."
            checked={technical}
            onClick={() => setTechnical(!technical)}
          />
          <Button
            type="submit"
            className="p-1 text-sm md:text-sm lg:text-sm bg-nbme-primary-600 hover:bg-nbme-primary-700 focus:bg-nbme-primary-700 w-[20%] dark:bg-nbme-primary-dark-700 dark:hover:bg-nbme-primary-dark-600"
          >
            Submit
          </Button>
        </div>
      </form>
    </NbmeFloatingWindow>
  );
};

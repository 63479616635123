import { ArgumentTypes } from "@/types";
import createResizableBorder from "./utils/create-resizable-border";
import {
  ResizableLeftBorder,
  ResizableLowerBorder,
  ResizableRightBorder,
  ResizableUpperBorder,
} from ".";

const ResizableBorders = (
  { ...props }: ArgumentTypes<ReturnType<typeof createResizableBorder>>[0],
) => {
  return (
    <>
      <ResizableLeftBorder {...props} />
      <ResizableRightBorder {...props} />
      <ResizableUpperBorder {...props} />
      <ResizableLowerBorder {...props} />
    </>
  );
};

export default ResizableBorders;

import useTestPageConstructedTest from "./use-test-page-constructed-test";

const useTestPageHints = () => {
  const constructedTest = useTestPageConstructedTest();
  return {
    ...constructedTest,
    data: constructedTest.data?.slots.flatMap((
      s,
    ) => [...s.question.explanation.hints, ...s.question.hints]),
  };
};
export default useTestPageHints;

import React, { PropsWithChildren } from "react";
import { Card } from "./Card";

export const AuthCard = ({ children }: PropsWithChildren) => {
  return (
    <Card className="py-10 px-8 lg:py-12 lg:px-10 min-w-[50%] max-w-lg lg:max-w-md w-full">
      {children}
    </Card>
  );
};

import type { PartializedStore } from "@/store/types";

const getCurrentUser = () => {
  const unparsedStore = localStorage.getItem("coursology-app-store");
  if (!unparsedStore) return undefined;
  const { state: store } = JSON.parse(unparsedStore) as {
    state: PartializedStore;
  };
  return store.currentUser;
};

export default getCurrentUser;

import { useCreateTestPageStore } from "@/utils/stores";
import { useEffect } from "react";

const useQuestionMode = (qMode: "standard" | "custom" | "ordered") => {
  const setQmode = useCreateTestPageStore((state) => state.setQmode);
  useEffect(() => {
    setQmode(qMode);
  }, [qMode]);
};

export default useQuestionMode;

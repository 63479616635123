import React from "react";
import Dialog from "./Dialog";
import {
  useNbmeLayoutStore,
  useNbmeTheme,
} from "@/utils/stores/nbmeLayoutStore";
import { Button } from "./Button";
import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";
import useTestPageSuspendTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-suspend-test";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import { useNavigate, useRouterState } from "@tanstack/react-router";

export const NbmeSuspendTestDialog = () => {
  const [theme] = useNbmeTheme();
  const { dialogVisible, setDialogVisible } = useNbmeLayoutStore((state) => ({
    dialogVisible: state.suspendTestDialogVisible,
    setDialogVisible: state.setSuspendTestDialogVisible,
  }));
  const pathname = useRouterState().location.pathname;
  const navigate = useNavigate();
  const { data: test } = useTestPageTest();
  const { data: questionBank } = useQuestionBank({
    questionBankId: test?.questionBankId ?? "",
  });
  const suspendTest = useTestPageSuspendTest();

  if (test?.submitted) return <></>;

  return (
    <Dialog
      dark={theme === "dark"}
      title={"Suspend Test?"}
      description={'Time will freeze until you come back again to the test, you can find it at any time in the "Previous Tests" page in your dashboard.'}
      open={dialogVisible}
      onCloseClicked={() => setDialogVisible(false)}
      className="dark:bg-nbme-primary-dark-900 dark:border-nbme-primary-dark-600"
      onOpenChange={setDialogVisible}
    >
      <div className="flex flex-row justify-end items-center mt-4">
        <div className="flex flex-row justify-start items-center gap-3">
          <Button
            className="p-1 px-2"
            variant="dashboard"
            onClick={() => setDialogVisible(false)}
          >
            Cancel
          </Button>
          <Button
            className="p-1 px-2"
            error
            onClick={() => {
              setDialogVisible(false);
              suspendTest();
              questionBank?.endpoint && navigate(
                {
                  from: "/tests/$testId",
                  to: "/qbanks/$qbankEndpoint/dashboard",
                  params: { qbankEndpoint: questionBank?.endpoint },
                },
              );
            }}
          >
            Suspend
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

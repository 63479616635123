import { RichTextEditor } from "@mantine/tiptap";
import {
  AlignmentControls,
  BubbleMenu,
  ColorControls,
  DirectionControls,
  FormatControls,
  HeadingControls,
  ImageControls,
  LinkControls,
  ParagraphTypeControls,
  TableControls,
  UndoRedoControls,
} from "./components";
import { Editor } from "@tiptap/react";

export const Toolbar = (
  { editor }: { editor: Editor | null },
) => {
  return (
    <RichTextEditor.Toolbar
      sticky
    >
      <ColorControls />
      <FormatControls />
      <BubbleMenu editor={editor} />
      <HeadingControls />
      <ParagraphTypeControls />
      <LinkControls />
      <AlignmentControls />
      <DirectionControls />
      <TableControls />
      <ImageControls />
      <UndoRedoControls />
    </RichTextEditor.Toolbar>
  );
};

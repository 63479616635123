import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";
import { useNbmeTimerVisible } from "@/utils/stores/nbmeLayoutStore";

export const NbmeSettingsTimerControl = () => {
  const { timerVisible, setTimerVisible } = useNbmeTimerVisible();
  return (
    <NbmeSettingsSwitch
      checked={timerVisible}
      onCheckedChange={setTimerVisible}
    />
  );
};

import QuestionCountBadge from "./QuestionCountBadge";
import LabeledCheckbox from "./LabeledCheckbox";
import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import { Subject } from "@/api/types";

interface SubjectCheckBoxProps {
  subject: Subject;
  questionCount: number;
  className?: string;
  loading?: boolean;
}

const SubjectCheckBox = (
  { subject, questionCount, className, loading }: SubjectCheckBoxProps,
) => {
  const disabled = questionCount === 0;
  const { toggleSelection, selectedSubjects } = useCreateTestPageStore((
    state,
  ) => ({
    toggleSelection: state.toggleSelection,
    selectedSubjects: state.selectedSubjects,
  }));

  const checked = !!(selectedSubjects.find((s) => s.id === subject.id));

  return (
    <div className="flex justify-between items-center gap-2 w-full lg:max-w-xs">
      <LabeledCheckbox
        className={className}
        id={`${subject.name}-subject-checkbox`}
        checked={checked && questionCount !== 0}
        onCheckedChange={(checked) => {
          toggleSelection("selectedSubjects", checked, subject);
        }}
        disabled={disabled}
        label={subject.name.at(0)!.toUpperCase() + subject.name.slice(1)}
      />
      <QuestionCountBadge count={questionCount} loading={loading} />
    </div>
  );
};

export default SubjectCheckBox;

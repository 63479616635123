import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithClassName } from "@/types";
import { FontSizes } from "@/types";
import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import { useNbmeFontSizeFactor } from "@/utils/stores/nbmeLayoutStore";
import React from "react";
import { twMerge } from "tailwind-merge";

interface NbmeClickToRevealButtonProps extends PropsWithClassName {
  onClick: () => void;
}

export const NbmeClickToRevealButton = (
  { className, onClick }: NbmeClickToRevealButtonProps,
) => {
  const { fontSizeFactor } = useNbmeFontSizeFactor();

  return (
    <button
      className={twMerge(
        "text-gray-500 dark:text-nbme-primary-dark-600 text-xs",
        className,
      )}
      onClick={onClick}
      style={{
        fontSize: scaleUnitInRem(
          FontSizes.ExtraSmall,
          fontSizeFactor,
        ).resultInRemAsCss,
      }}
    >
      <FontAwesomeIcon
        className="text-xs"
        icon={faEyeSlash}
      />{" "}
      Hidden (click to reveal)
    </button>
  );
};

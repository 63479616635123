import { createFileRoute } from "@tanstack/react-router";
import CreateTestCustomSection from "@/components/CreateTestCustomSection";
import useQuestionMode from "../-hooks/use-question-mode";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/create-test/_create-test/custom",
)({
  component: CreateCustomTestPage,
});

function CreateCustomTestPage() {
  useQuestionMode("custom");
  return (
    <>
      <CreateTestCustomSection />
    </>
  );
}

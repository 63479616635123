import { RichTextEditor } from "@mantine/tiptap";

export const FormatControls = () => {
  return (
    <RichTextEditor.ControlsGroup>
      <RichTextEditor.Bold />
      <RichTextEditor.Italic />
      <RichTextEditor.Underline />
      <RichTextEditor.Strikethrough />
      <RichTextEditor.ClearFormatting />
      <RichTextEditor.Highlight />
    </RichTextEditor.ControlsGroup>
  );
};

import { QuestionBankIdentifier } from "@/types/question-bank-types";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestionBankTopics from "@/hooks/topic-hooks/use-question-bank-topics";
import useQuestionBankSystems from "./use-question-bank-systems";
import mergeFlags from "@/utils/common/merge-flags";

const useGetQuestionBankTopicSystem = (args: QuestionBankIdentifier) => {
  const systemsQuery = useQuestionBankSystems(args);
  const { data: systems } = systemsQuery;
  const topicsQuery = useQuestionBankTopics(args);
  const { data: topics } = topicsQuery;

  const getQuestionBankTopicSystem = (topicId: string) => {
    const topic = ArrayMapUtils.find(topics, topicId);
    if (!topic) return undefined;
    return ArrayMapUtils.find(systems, topic.systemId);
  };

  return {
    ...mergeFlags({ source: topicsQuery, destinations: [systemsQuery] }),
    data: getQuestionBankTopicSystem,
  };
};
export default useGetQuestionBankTopicSystem;

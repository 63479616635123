import useQuestionBankQuestionTopic from "@/hooks/topic-hooks/use-questionbank-question-topic";
import useTestPageTest from "./use-test-page-test";
import useTestPageTestSelectedTestSlotQuestion from "./use-test-page-test-selected-test-slot-questiont";
import mergeFlags from "@/utils/common/merge-flags";

const useTestPageTestSelectedTestSlotQuestionTopic = () => {
  const test = useTestPageTest();
  const question = useTestPageTestSelectedTestSlotQuestion();
  const topic = useQuestionBankQuestionTopic({
    questionBankId: test.data?.questionBankId ?? "",
    questionId: question.data?.id ?? 0,
  });
  return {
    ...mergeFlags({ source: test, destinations: [question, topic] }),
    data: topic.data,
  };
};

export default useTestPageTestSelectedTestSlotQuestionTopic;

import { PropsWithClassName } from "@/types";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface ToolTipProps extends PropsWithClassName, PropsWithChildren {
  visible?: boolean;
}

export const ToolTip = (
  { className, children, visible = true }: ToolTipProps,
) => {
  return (
    <div
      className={twMerge(
        "absolute rounded-primary bg-gray-500 warm:bg-brown-800 dark:bg-neutral-700 border border-transparent dark:border-neutral-600 text-gray-50 text-sm top-0 left-[50%] translate-x-[-50%] -translate-y-full w-max",
        className,
        !visible && "opacity-0",
      )}
    >
      <div className="relative px-2 py-1 w-full h-full">
        {children}
      </div>
    </div>
  );
};

import useTestPageTestSelectedTestSlotQuestion from "./use-test-page-test-selected-test-slot-questiont";

const useTestPageSelectedTestSlotQuestionNote = () => {
  const selectedTestSlotQuestion = useTestPageTestSelectedTestSlotQuestion();
  return {
    ...selectedTestSlotQuestion,
    data: selectedTestSlotQuestion.data?.note,
  };
};

export default useTestPageSelectedTestSlotQuestionNote;

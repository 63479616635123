"use client";
import useClampedState from "@/hooks/use-clamped-state";
import { Dimension, Point } from "@/types";
import { clamp } from "@/utils/common/clamp";
import { useViewportDimensions } from "@/utils/hooks";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useResizeObserver from "use-resize-observer";

const useDraggableState = (opts?: {
  position?: Point;
  width?: number;
  height?: number;
  allowOffScreen?: boolean;
  isDraggable?: boolean;
  onIsDraggableChanged?: Dispatch<SetStateAction<boolean>>;
  events?: {
    onDragStart?: () => void;
    onDragEnd?: () => void;
    onDrag?: () => void;
  };
}) => {
  const [isDraggable, setIsDraggable] = useState<boolean>(true);

  const [neverDraggedBefore, setNeverDraggedBefore] = useState<boolean>(true);

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const bodyRef = useRef<HTMLDivElement>(null);

  const { width = opts?.width ?? 500, height = opts?.height ?? 500 } =
    useResizeObserver({ ref: bodyRef });

  const { width: viewportWidth, height: viewportHeight } =
    useViewportDimensions();

  const clampPositionToViewport = useCallback(
    (position: Point | undefined): Point | undefined => {
      if (!position) return undefined;
      /** Prevents windows from being completely lost
       * outside the viewport */
      const viewportGaurdRails = { x: 20, y: 20 };

      const dragMax = {
        x: opts?.allowOffScreen
          ? viewportWidth - viewportGaurdRails.x
          : viewportWidth - width,
        y: opts?.allowOffScreen
          ? viewportHeight - viewportGaurdRails.y
          : viewportHeight - height,
      };

      const dragMin = {
        x: opts?.allowOffScreen ? -width + viewportGaurdRails.x : 0,
        y: opts?.allowOffScreen ? -height + viewportGaurdRails.y : 0,
      };

      return {
        x: clamp({
          num: position.x,
          min: dragMin.x,
          max: dragMax.x,
        }),
        y: clamp({
          num: position.y,
          min: dragMin.y,
          max: dragMax.y,
        }),
      };
    },
    [viewportWidth, viewportHeight, width, height, opts],
  );

  const { height: vH, width: vW } = useViewportDimensions();

  const calculateCenter = (dimensions: Dimension) => {
    return {
      x: vW / 2 - dimensions.width / 2,
      y: vH / 2 - dimensions.height / 2,
    };
  };

  const recenter = () => {
    if (!bodyRef) return;
    if (!bodyRef.current) return;
    const center = calculateCenter({
      width: bodyRef.current.offsetWidth,
      height: bodyRef.current.offsetHeight,
    });
    setPosition(center);
  };

  useEffect(() => {
    recenter();
  }, []);

  const [position, setPosition] = useClampedState<Point | undefined>(
    opts?.position ?? undefined,
    clampPositionToViewport,
  );

  return {
    position,
    setPosition,
    isDragging,
    setIsDragging,
    neverDraggedBefore,
    setNeverDraggedBefore,
    recenter,
    bodyRef,
    events: opts?.events,
    isDraggable: opts?.isDraggable ?? isDraggable,
    setIsDraggable: opts?.onIsDraggableChanged ?? setIsDraggable,
  };
};

export type DraggableState = ReturnType<typeof useDraggableState>;

export default useDraggableState;

import { clamp } from "@/utils/common/clamp";

const formatUnits = (units: number): string => {
  if (units < 10) {
    return `0${units}`;
  } else return `${units}`;
};

const clampHours = (hours: number) => clamp({ min: 0, max: 12, num: hours });

const clampMinutes = (minutes: number) =>
  clamp({ min: 0, max: 59, num: minutes });

export interface NbmeSettingsSidebarNightModeTimeControlProps {
  isNightMode: boolean;
  time: {
    hours: number;
    minutes: number;
    isAm: boolean;
  };
  onTimeChange: (time: this["time"]) => void;
}

export const NbmeSettingsSidebarNightModeTimeControl = (
  {
    isNightMode,
    time,
    onTimeChange,
  }: NbmeSettingsSidebarNightModeTimeControlProps,
) => {
  const { hours, minutes, isAm } = time;

  const clampedHours = clampHours(hours);
  const clampedMinutes = clampMinutes(minutes);

  return (
    <div className="flex flex-row justify-center items-center text-gray-900 text-xs">
      <input
        type="number"
        value={formatUnits(clampedHours)}
        onChange={(e) =>
          onTimeChange({ ...time, hours: clampHours(Number(e.target.value)) })}
        size={2}
        max={24}
        min={0}
        disabled={!isNightMode}
        className="w-5 outline-transparent"
      />
      <span className="font-bold">:</span>
      <input
        size={2}
        value={formatUnits(clampedMinutes)}
        onChange={(e) =>
          onTimeChange({
            ...time,
            minutes: clampMinutes(Number(e.target.value)),
          })}
        type="number"
        max={60}
        min={0}
        disabled={!isNightMode}
        className="w-5 outline-transparent"
      />
      <button
        disabled={!isNightMode}
        onClick={() => onTimeChange({ ...time, isAm: !isAm })}
      >
        {isAm ? "AM" : "PM"}
      </button>
    </div>
  );
};

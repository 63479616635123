import { Setter } from "@/types";
import { State } from "./types";

export const actions = (
  set: (updater: (state: State) => void) => void,
) => {
  return {
    setIsUnsavedChangesDialogVisible: (n: boolean) =>
      set((state) => {
        state.isUnsavedChangesDialogVisible = n;
      }),
    setOnUnsavedChangesDialogConfirm: (n: () => void) =>
      set((state) => {
        state.onUnsavedChangesDialogConfirm = n;
      }),
    setOnUnsavedChangesDialogCancel: (n: () => void) =>
      set((state) => {
        state.onUnsavedChangesDialogCancel = n;
      }),
    setIsConfirmDeleteDialogVisible: (n: boolean) =>
      set((state) => {
        state.isConfirmDeleteDialogVisible = n;
      }),
    setConfirmDeleteDialogTarget: (n: State["confirmDeleteDialogTarget"]) =>
      set((state) => {
        state.confirmDeleteDialogTarget = n;
      }),
    setOnConfirmDeleteDialogConfirm: (n: () => void) =>
      set((state) => {
        state.onConfirmDeleteDialogConfirm = n;
      }),
    setOnConfirmDeleteDialogCancel: (n: () => void) =>
      set((state) => {
        state.onConfirmDeleteDialogCancel = n;
      }),
    setVariant: (n: State["variant"]) =>
      set((state) => {
        state.variant = n;
      }),
    setIsSidebarOpen: (n: boolean) =>
      set((state) => {
        state.isSidebarOpen = n;
      }),
    setSearch: (n: string) =>
      set((state) => {
        state.search = n;
      }),
    setCollapsedItems: (n: State["collapsedItems"]) =>
      set((state) => {
        state.collapsedItems = n;
      }),
    setTagInpuValue: (n: State["tagInputValue"]) =>
      set((state) => {
        state.tagInputValue = n;
      }),
    collapseItem: (itemId: string) =>
      set((state) => {
        const itemIsAlreadyCollapsed = state.collapsedItems.includes(
          itemId,
        );
        if (itemIsAlreadyCollapsed) return;

        state.collapsedItems.push(itemId);
      }),
    retractItem: (itemId: string) =>
      set((state) => {
        const itemIsAlreadyRetracted = !state.collapsedItems.includes(
          itemId,
        );
        if (itemIsAlreadyRetracted) return;

        state.collapsedItems = state.collapsedItems.filter((id) =>
          id !== itemId
        );
      }),
  };
};

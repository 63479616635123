import { DashboardPerformanceSelect } from "@/routes/qbanks/$qbankEndpoint/dashboard/-components";
import { useReportOptions } from "../../-hooks";

export const PerformanceTestResultsPageSelect = () => {
  const { options, setOptions } = useReportOptions();
  return (
    <DashboardPerformanceSelect
      showTypeSelect={false}
      type="Score"
      items={options}
      onItemsChange={setOptions}
      withMarked
    />
  );
};

import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { HighlightSliceCreator, highlightSliceState } from ".";
import gen from "@/lib/gen";
import { trpcProxyClient } from "@/utils/trpc";
import handleTRPCMutationError from "@/utils/trpc/handle-trpc-mutation-error";

const createHighlightSlice: HighlightSliceCreator = (set, get) => {
  return {
    ...highlightSliceState,
    addHighlight: (highlight) =>
      set((state) => {
        ArrayMapUtils.push(
          state.highlights,
          [highlight],
        );
      }),
    addHighlights: (highlights) =>
      set((state) => {
        ArrayMapUtils.push(
          state.highlights,
          highlights,
        );
      }),
    createHighlight: ({ highlight }) => {
      const state = get();
      const newHighlight = { ...highlight, id: gen.cuid() };
      if (!newHighlight.questionId && !newHighlight.questionExplanationId) {
        return;
      }
      const highlightMarker = ArrayMapUtils.find(
        state.highlightMarkers,
        newHighlight.markerId,
      );
      if (!highlightMarker) return;

      set((state) => {
        if (!state.currentUser?.id) return;
        ArrayMapUtils.create(state.highlights, {
          ...newHighlight,
          userId: state.currentUser.id,
          isOnExplanation: newHighlight.questionExplanationId ? true : false,
          questionVersionId: null,
        });
      });
      trpcProxyClient.highlight.createHighlight.mutate({
        highlight: newHighlight,
      }).catch(
        handleTRPCMutationError(
          { mutationDescription: "create highlight" },
          () => {
            set((state) => {
              ArrayMapUtils.delete(state.highlights, newHighlight.id);
            });
          },
        ),
      );
    },
    deleteHighlight: ({ highlightId }, opts) => {
      const oldState = get();
      const oldHighlight = ArrayMapUtils.find(oldState.highlights, highlightId);
      if (!oldHighlight) return;
      set((state) => {
        ArrayMapUtils.delete(state.highlights, highlightId);
        ArrayMapUtils.push(state.deletedHighlights, [oldHighlight]);
      });
      if (opts?.local) return;
      trpcProxyClient.highlight.deleteHighlight.mutate({ highlightId }).catch(
        handleTRPCMutationError(
          { mutationDescription: "delete highlight" },
          () => {
            set((state) => {
              ArrayMapUtils.delete(state.deletedHighlights, highlightId);
              ArrayMapUtils.push(state.highlights, [oldHighlight]);
            });
          },
        ),
      );
    },
    deleteHighlights: ({ highlightIds }) => {
      set((state) => {
        for (const id of highlightIds) {
          const oldHighlight = ArrayMapUtils.find(state.highlights, id);
          if (!oldHighlight) return;
          ArrayMapUtils.push(state.deletedHighlights, [oldHighlight]);
          ArrayMapUtils.delete(state.highlights, id);
        }
      });
    },
    resetHighlights: ({ highlightIds }) => {
      set((state) => {
        ArrayMapUtils.forEach(
          ArrayMapUtils.filter(
            state.highlights,
            (t) => highlightIds.includes(t.id),
          ),
          (highlight) => {
            ArrayMapUtils.delete(state.highlights, highlight.id);
          },
        );
      });
    },
    changeHighlightHighlightMarker: (
      { highlightMarkerId, highlightId },
      opts,
    ) => {
      const oldState = get();
      const oldHighlight = ArrayMapUtils.find(oldState.highlights, highlightId);
      if (!oldHighlight) return;
      set((state) => {
        ArrayMapUtils.update(state.highlights, {
          ids: [oldHighlight.id],
          updater: (highlight) => ({
            ...highlight,
            markerId: highlightMarkerId,
          }),
        });
      });
      if (opts?.local) return;
      trpcProxyClient.highlight.changeMarker.mutate({
        highlightId,
        highlightMarkerId,
      }).catch(
        handleTRPCMutationError({
          "mutationDescription": "change highlight color",
        }, () => {
          const state = get();
          state.changeHighlightHighlightMarker({
            highlightId,
            highlightMarkerId: oldHighlight.markerId,
          }, { local: true });
        }),
      );
    },
    clearQuestionHighlights: ({ questionId }) => {
      const state = get();
      const question = ArrayMapUtils.find(state.questions, questionId);
      if (!question) return;
      const targetHighlights = ArrayMapUtils.filter(
        state.highlights,
        (h) =>
          h.questionId === question.id ||
          h.questionExplanationId === question.explanationId,
      );
      ArrayMapUtils.forEach(targetHighlights, (h) => {
        state.deleteHighlight({ highlightId: h.id }, { local: true });
      });
      trpcProxyClient.highlight.clearQuestionHighlights.mutate({
        questionId: questionId,
      }).catch(
        handleTRPCMutationError(
          { mutationDescription: "clear highlights" },
          () => {
            state.addHighlights(ArrayMapUtils.toArray(targetHighlights));
          },
        ),
      );
    },
  };
};

export default createHighlightSlice;

import useCurrentUser from "@/hooks/auth-hooks/use-current-user";
import { useAppStore } from "@/store";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useDeletedQuestionNotes from "./use-deleted-question-notes";
import { useMemo } from "react";

const useQuestionNotes = () => {
  const user = useCurrentUser();
  const questionNotes = useAppStore((state) => state.questionNotes);
  const deletedQuestionNotes = useDeletedQuestionNotes();
  const userQuestionNotes = useMemo(() =>
    ArrayMapUtils.filter(
      questionNotes,
      (qn) =>
        qn.userId === user.id &&
        !ArrayMapUtils.includes(deletedQuestionNotes, qn),
    ), [questionNotes, user, deletedQuestionNotes]);

  return userQuestionNotes;
};
export default useQuestionNotes;

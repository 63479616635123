import { AuthCard } from "./AuthCard";
import { SigninForm } from "./SignInForm";

const SigninCard = () => {
  return (
    <AuthCard>
      <SigninForm />
    </AuthCard>
  );
};

export default SigninCard;

import { useNotebookUi } from ".";

export const useUnsavedChangesDialog = () => {
  const {
    isVisible,
    setIsVisible,
    onConfirm,
    setOnConfirm,
    onCancel,
    setOnCancel,
  } = useNotebookUi((
    state,
  ) => ({
    isVisible: state.isUnsavedChangesDialogVisible,
    setIsVisible: state.setIsUnsavedChangesDialogVisible,
    onConfirm: state.onUnsavedChangesDialogConfirm,
    setOnConfirm: state.setOnUnsavedChangesDialogConfirm,
    onCancel: state.onUnsavedChangesDialogCancel,
    setOnCancel: state.setOnUnsavedChangesDialogCancel,
  }));
  return {
    isVisible,
    setIsVisible,
    onConfirm,
    setOnConfirm,
    onCancel,
    setOnCancel,
  };
};

import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

type WindowTitlebarProps = PropsWithClassName & PropsWithChildren;

const WindowTitlebar = ({ children, className }: WindowTitlebarProps) => {
  return (
    <div
      className={cn(
        "relative flex flex-row justify-center items-center w-full h-12 bg-nbme-primary-600 warm:bg-brown-800 dark:bg-neutral-800 rounded-t-xl text-white",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default WindowTitlebar;

import { Paginator } from "@/components/Paginator";
import useNotesPagePagination from "../-notes-page-hooks/use-notes-page-pagination";
import useNotesPageNotes from "../-notes-page-hooks/use-notes-page-notes";

const NotesPagePaginator = () => {
  const { data: notes } = useNotesPageNotes();
  const { currentPage: currentPageState, notesPerPage: notesPerPageState } =
    useNotesPagePagination();
  const [currentPage, setCurrentPage] = currentPageState;
  const [notesPerPage, setNotesPerPage] = notesPerPageState;

  return (
    <Paginator
      itemSingularName="note"
      currentPage={currentPage}
      onCurrentPageChange={setCurrentPage}
      onItemsPerPageChange={setNotesPerPage}
      itemsPerPage={notesPerPage}
      numberOfItems={notes.length}
    />
  );
};

export default NotesPagePaginator;

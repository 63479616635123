import {
  useNbmeFontSizeFactor,
  useNbmeLayoutStore,
} from "@/utils/stores/nbmeLayoutStore";
import { FontSizeControl } from "./FontSizeControl";

export const NbmeSettingsFontSizeControl = () => {
  const { fontSizeFactor, setFontSizeFactor } = useNbmeFontSizeFactor();

  return (
    <FontSizeControl
      step={0.05}
      factor={fontSizeFactor}
      onChange={setFontSizeFactor}
    />
  );
};

import { ChangeEventHandler, useEffect, useState } from "react";
import { useSelectedNoteTitle } from "./hooks";
import { useDraft } from "@/hooks";
import { twMerge } from "tailwind-merge";

interface EditableTitleProps {
  isEditMode?: boolean;
}

export const EditableTitle = (
  { isEditMode = false }: EditableTitleProps,
) => {
  const [title, setTitle] = useSelectedNoteTitle();
  const [draft, setDraft] = useDraft(title);

  useEffect(() => {
    setDraft(title);
  }, [title]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDraft(e.target.value);
  };

  return (
    <input
      className={twMerge(
        "bg-transparent w-full text-3xl capitalize outline-none font-semibold warm:text-brown-950/80 dark:text-neutral-300",
        !draft && "text-gray-300 warm:text-brown-950/30",
      )}
      value={draft ?? "Please select a note to view/edit."}
      onChange={handleChange}
      readOnly={!isEditMode}
      disabled={!isEditMode}
    />
  );
};

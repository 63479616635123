import { trpcProxyClient } from "@/utils/trpc";

interface NewPage {
  title: string;
  notebookId: string;
}

export const postPage = (args: NewPage) => {
  return trpcProxyClient.notebook.page.addOne.mutate({
    title: args.title,
    notebookId: args.notebookId,
  });
};

const mergeFunctions = <Z, Y, T extends (args: Y) => Z>(
  original: T,
  functions: ((args: Y) => void | any)[],
) => {
  return (args: Y) => {
    for (const fn of functions) {
      fn(args);
    }
    return original(args);
  };
};

export default mergeFunctions;

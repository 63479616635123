import { HexColorPicker } from "react-colorful";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import color from "color-string";
import useCreateHighlightMarker from "@/hooks/highlightmarker-hooks/use-create-highlightmarker";
import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { useEffect } from "react";
import useEditHighlightMarker from "@/hooks/highlightmarker-hooks/use-edit-highlightmarker";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import useDeleteHighlightMarker from "@/hooks/highlightmarker-hooks/use-delete-highlightmarker";
import { HighlightMarker } from "@/models";

const colorValidator = z.string().refine((value: string) => {
  if (!value) return false;
  try {
    return color.get(value) != null;
  } catch {
    return false;
  }
});

type HighlightMarkerCreatorProps = {
  onCreate?: (newHighlightMarker: HighlightMarker) => void;
  onCancel?: () => void;
  onEdit?: undefined;
  id?: undefined;
} | {
  onCreate?: undefined;
  onEdit?: () => void;
  id: string;
  onCancel?: () => void;
};

export const HighlightMarkerForm = (
  { onCreate, onCancel, id, onEdit }: HighlightMarkerCreatorProps,
) => {
  const createHighlightMarker = useCreateHighlightMarker();
  const editHighlightMarker = useEditHighlightMarker();
  const deleteHighlightMarker = useDeleteHighlightMarker();

  const form = useForm({
    defaultValues: {
      name: "",
      color: "",
    },
    onSubmit: (submission) => {
      if (id) {
        onEdit?.();
        editHighlightMarker({ id, ...submission.value });
      } else {
        const newHighlight = createHighlightMarker(submission.value);
        if (newHighlight) {
          onCreate?.(
            newHighlight,
          );
        }
      }
    },
  });
  const { data: highlightMarkers } = useHighlightMarkers();
  const markerToEdit = id
    ? ArrayMapUtils.find(highlightMarkers, id)
    : undefined;

  useEffect(() => {
    if (markerToEdit) {
      form.setFieldValue("name", markerToEdit.name);
      form.setFieldValue("color", markerToEdit.color);
    }
  }, [markerToEdit]);

  return (
    <div className="bg-white dark:bg-neutral-800 dark:border dark:border-neutral-700 warm:bg-egg-sour-50 warm:border warm:border-brown-200 flex flex-row justify-start items-center p-3 rounded-xl drop-shadow-lg gap-4 max-w-md">
      <form.Field
        name="color"
        children={(field) => (
          <HexColorPicker
            onChange={field.handleChange}
            color={field.state.value}
          />
        )}
      >
      </form.Field>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        className="flex flex-col justify-start items-start gap-3"
      >
        <form.Field
          name="name"
          validators={{
            onChange: z.string().min(1, "Name can't be empty"),
          }}
          validatorAdapter={zodValidator()}
          children={(field) => (
            <div className="flex flex-col justify-start items-start gap-2">
              <Label className="dark:text-neutral-200" htmlFor="name">
                Name
              </Label>
              <Input
                id="name"
                placeholder="e.g. Important"
                className="dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-100"
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            </div>
          )}
        >
        </form.Field>
        <form.Field
          name="color"
          validators={{
            onChange: colorValidator,
          }}
          validatorAdapter={zodValidator()}
          children={(field) => (
            <div className="flex flex-col justify-start items-start gap-2">
              <Label className="dark:text-neutral-200" htmlFor="color">
                Color
              </Label>
              <Input
                id="color"
                placeholder="#fad021"
                className="dark:bg-neutral-700 dark:border-neutral-600 dark:text-neutral-100"
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            </div>
          )}
        >
        </form.Field>
        <div className="flex flex-row justify-start items-center gap-3">
          <form.Subscribe
            selector={(
              state,
            ) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => {
              return (
                <Button
                  type="submit"
                  className="dark:bg-neutral-700 dark:hover:bg-neutral-600 warm:bg-brown-700 warm:hover:bg-brown-800"
                  disabled={!canSubmit}
                >
                  {isSubmitting ? "..." : id ? "Edit" : "Create"}
                </Button>
              );
            }}
          />
          {id &&
            (
              <AlertDialog>
                <AlertDialogTrigger>
                  <Button
                    type="button"
                    variant={"destructive"}
                  >
                    Delete
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle className="text-gray-800 dark:text-neutral-200">
                      Are you absolutely sure you want to delete this marker
                      ({form.getFieldValue("name")})?
                    </AlertDialogTitle>
                    <AlertDialogDescription className="text-red-500">
                      This will permanently delete all highlights that have been
                      made with this marker, are you sure you want to continue?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogAction asChild>
                      <Button
                        onClick={() =>
                          deleteHighlightMarker({ highlightMarkerId: id })}
                        variant={"destructive"}
                        className="bg-red-500 hover:bg-red-400 warm:bg-red-500 warm:hover:bg-red-400 dark:text-white dark:bg-red-500 dark:hover:bg-red-600"
                      >
                        Delete
                      </Button>
                    </AlertDialogAction>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          <Button
            type="button"
            variant={"ghost"}
            className="text-gray-800"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

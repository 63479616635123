import { twMerge } from "tailwind-merge";
import {
  CancelButton,
  Layout,
  OpenNotesButton,
  SaveButton,
  TagInput,
} from "./components";
interface FooterProps {
  selectedNote?: { id: string };
  visible: boolean;
  isEditMode: boolean;
  onIsEditModeChange: (v: boolean) => void;
  onEditSave: () => void;
  onEditCancel: () => void;
}

export const Footer = (
  {
    visible,
    isEditMode,
    onIsEditModeChange,
    onEditSave,
    selectedNote,
    onEditCancel,
  }: FooterProps,
) => {
  return (
    <Layout>
      <div
        className={twMerge(
          "flex flex-row justify-star items-center w-full opacity-0",
          visible && "opacity-100",
        )}
      >
        {selectedNote && (
          <>
            <TagInput selectedNote={selectedNote} />
            <div
              className={twMerge(
                "flex-row justify-start items-center opacity-0 transition-opacity hidden",
                visible && "opacity-100 flex",
              )}
            >
              <div className="flex flex-row justify-start items-center gap-3">
                <CancelButton
                  isEditMode={isEditMode}
                  onIsEditModeChange={onIsEditModeChange}
                  onEditCancel={onEditCancel}
                />
                <SaveButton
                  isEditMode={isEditMode}
                  onIsEditModeChange={onIsEditModeChange}
                  onEditSave={onEditSave}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <OpenNotesButton />
    </Layout>
  );
};

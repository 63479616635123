import React from "react";
import QuestionCountBadge from "./QuestionCountBadge";
import { Separator } from "./Separator";
import { twMerge } from "tailwind-merge";

interface DashboardStatsSectionProps {
  title: string;
  items: { text: string | JSX.Element; count: number }[];
  className?: string;
  isLoading?: boolean;
}

const DashboardStatsSection = (
  { title, items, className, isLoading }: DashboardStatsSectionProps,
) => {
  return (
    <div
      className={twMerge(
        "flex flex-col justify-start items-start w-full gap-4",
        className,
      )}
    >
      <h1 className="font-medium text-base">{title}</h1>
      <div className="flex flex-col justify-start items-start w-full gap-2">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col justify-start items-start w-full gap-1"
          >
            <div className="flex flex-row justify-between items-center w-full">
              <p className="text-gray-500 dark:text-neutral-200 transition-colors text-sm">
                {item.text}
              </p>
              <QuestionCountBadge
                count={item.count}
                className="text-gray-600 bg-gray-200"
                loading={isLoading}
                spinnerClassName="fill-gray-500"
              />
            </div>
            <Separator
              orientation="horizontal"
              className="bg-gray-200 h-[1px]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardStatsSection;

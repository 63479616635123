import { ChangeEvent, useEffect, useState } from "react";
import { SearchInput, SearchInputProps } from "./SearchInput";
import { useActivity } from "@/utils/hooks";

interface SearchInputWithActivityProps extends SearchInputProps {
  onChangeEnd?: (newValue: string) => void;
  activityDurationInMilliSeconds?: number;
}

export const SearchInputWithActivity = (
  {
    isLoading,
    onChangeEnd,
    onChange,
    activityDurationInMilliSeconds = 500,
    value,
    ...props
  }: SearchInputWithActivityProps,
) => {
  const [draft, setDraft] = useState<string>(String(value));

  const { triggerActivity } = useActivity({
    activityDurationInMilliSeconds: activityDurationInMilliSeconds,
    onActivityStopped: () => onChangeEnd?.(draft),
  });

  useEffect(() => {
    setDraft(String(value));
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    triggerActivity();
    setDraft(e.target.value);
    onChange(e);
  };

  return (
    <SearchInput
      {...props}
      isLoading={isLoading}
      value={draft}
      onChange={handleChange}
    />
  );
};

import { PropsWithChildren, useRef } from "react";
import ContextMenuProvider from "./context-menu-provider";
import useContextMenuState from "./hooks/use-context-menu-state";
import { TriggerableRoot } from "@/components/triggerable";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";

type ContextMenuRootProps = PropsWithChildren & PropsWithClassName;

const ContextMenuRoot = ({ children, className }: ContextMenuRootProps) => {
  const internalState = useContextMenuState();
  const state = internalState;

  return (
    <span ref={state.rootRef} className={cn("relative", className)}>
      <TriggerableRoot as={"span"} state={state}>
        <ContextMenuProvider value={state}>
          {children}
        </ContextMenuProvider>
      </TriggerableRoot>
    </span>
  );
};

export default ContextMenuRoot;

import { FilterInterface } from "interweave";

export const imageFilter = (
  imageDataUrl?: string,
  width?: number,
  height?: number,
): FilterInterface => ({
  node(name, node) {
    if (name === "img") {
      node.setAttribute(
        "style",
        `${width && `min-width: ${width};`} ${
          height && `min-height: ${height};`
        }`,
      );
      node.setAttribute(
        "class",
        "lg:w-[60%] md:w-[80%] w-full my-5 rounded-lg",
      );
      imageDataUrl && node.setAttribute("src", imageDataUrl);
    }
    return node;
  },
});

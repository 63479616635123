import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { QuestionNoteSliceCreator, questionNoteSliceState } from ".";
import { QuestionNote } from "@/models";

const createQuestionNoteSlice: QuestionNoteSliceCreator = (set, get) => {
  return {
    ...questionNoteSliceState,
    addQuestionNote: (questionNote) =>
      set((state) => {
        const isDeleted = ArrayMapUtils.includes(
          state.deletedQuestionNotes,
          questionNote,
        );

        if (isDeleted) return;

        ArrayMapUtils.push(
          state.questionNotes,
          [questionNote],
        );
      }),
    addQuestionNotes: (questionNotes) =>
      set((state) => {
        const isNotDeleted = (questionNote: QuestionNote) =>
          !ArrayMapUtils.includes(state.deletedQuestionNotes, questionNote);

        ArrayMapUtils.push(
          state.questionNotes,
          questionNotes.filter(isNotDeleted),
        );
      }),
    editQuestionNote: ({ questionNoteId, text }) => {
      set((state) => {
        ArrayMapUtils.update(state.questionNotes, {
          ids: [questionNoteId],
          updater: (questionNote) => ({
            ...questionNote,
            text,
          }),
        });
      });
    },
    deleteQuestionNote: ({ questionNoteId }) => {
      set((state) => {
        const questionNote = ArrayMapUtils.find(
          state.questionNotes,
          questionNoteId,
        );
        if (!questionNote) return;
        ArrayMapUtils.push(
          state.deletedQuestionNotes,
          [questionNote],
        )!;
        ArrayMapUtils.delete(state.questionNotes, questionNoteId);
      });
    },
    createQuestionNote: ({ questionId, text, id }) => {
      const state = get();
      if (!state.currentUser) return;
      state.addQuestionNote({
        id,
        text,
        questionId,
        userId: state.currentUser.id,
      });
    },
    resetQuestionNotes: ({ questionNoteIds }) => {
      set((state) => {
        ArrayMapUtils.forEach(
          ArrayMapUtils.filter(
            state.questionNotes,
            (qn) => questionNoteIds.includes(qn.id),
          ),
          (test) => {
            ArrayMapUtils.delete(state.questionNotes, test.id);
          },
        );
      });
    },
  };
};

export default createQuestionNoteSlice;

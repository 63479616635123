import React from "react";
import { NbmeSettingsBarSection } from "./NbmeSettingsBarSection";
import { NbmeSettingsSegment } from "./NbmeSettingsSegment";
import { NbmeSettingsNightModeControl } from "./NbmeSettingsNightModeControl";
import { NbmeSettingsNightModeTimeControl } from "./NbmeSettingsNightModeTimeControl";
import { useNbmeNightMode } from "@/utils/stores/nbmeLayoutStore";

export const NbmeSettingsNightModeSection = (props: {}) => {
  const { nightMode, setNightMode } = useNbmeNightMode();
  const { on, startTime, endTime } = nightMode;

  const setHours = (newHours: number, key: keyof typeof nightMode) =>
    setNightMode(key, {
      ...nightMode[key] as (typeof nightMode)["startTime" | "endTime"],
      hours: newHours,
    });

  const setMinutes = (newMinutes: number, key: keyof typeof nightMode) =>
    setNightMode(key, {
      ...nightMode[key] as (typeof nightMode)["startTime" | "endTime"],
      minutes: newMinutes,
    });

  return (
    <NbmeSettingsBarSection
      title={"Night Mode"}
      note={"If on, auto changes to dark mode automatically based on scheduled time"}
    >
      <NbmeSettingsSegment>
        <p className="text-xs">Automatic</p>
        <NbmeSettingsNightModeControl />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment disabled={!on}>
        <p className="text-xs">Start</p>
        <NbmeSettingsNightModeTimeControl controls="startTime" />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment disabled={!on}>
        <p className="text-xs">End</p>
        <NbmeSettingsNightModeTimeControl controls="endTime" />
      </NbmeSettingsSegment>
    </NbmeSettingsBarSection>
  );
};

import { findTarget } from "@/store/utils";
import { Action } from "../../types";
import { mutation } from "./mutation";
import { toast } from "sonner";
import { tagIsSavedOnServer } from "../../utils";

export const disconnectTagFromNote: Action =
  ([set, get]) => ({ noteId, tagId }: { noteId: string; tagId: string }) => {
    const { tags: oldTags } = get();
    const targetTag = findTarget(tagId, oldTags);
    if (!targetTag) return;
    const hasNoNotesConnected = (tag: { notes: any[] }) =>
      tag.notes.length === 0;

    set((state) => {
      const targetTagDraft = findTarget(tagId, state.tags);
      if (!targetTagDraft) return;
      targetTagDraft.notes = targetTagDraft.notes.filter((t) =>
        t.id !== noteId
      );
      if (hasNoNotesConnected(targetTagDraft)) {
        //This is supposed to be the same thing done by removeTag
        //but for some reason (probably because of nesting two immer drafts
        //inside each other) this is not working so we have to call this again.
        state.tags = state.tags.filter((t) => t.id !== targetTag.id);
        tagIsSavedOnServer(targetTag) && state.removeTag(targetTag.id);
      }
    });

    tagIsSavedOnServer(targetTag) &&
      mutation({ noteId, tagId }).catch((error) => {
        toast.error(
          `Could not remove tag from note, an error occurred: ${error.message}`,
        );
        set((state) => {
          state.tags = oldTags;
        });
      });
  };

import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

type WindowTitlebarGroupProps = PropsWithClassName & PropsWithChildren;

const WindowTitlebarGroup = (
  { children, className }: WindowTitlebarGroupProps,
) => {
  return (
    <div
      className={cn(
        "absolute h-full flex flex-row justify-center items-center font-semibold px-3.5 gap-2",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default WindowTitlebarGroup;

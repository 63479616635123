import { ResizableHandle, ResizablePanel } from "@/components/ui/resizable";
import { ScrollArea } from "../../../../components";
import { PropsWithChildren } from "react";
import { useNotebookUiVariant } from "@/hooks/notebook";
import { cn } from "@/lib/utils";

export const Layout = ({ children }: PropsWithChildren) => {
  const [variant] = useNotebookUiVariant();
  const onDesktop = variant === "desktop";
  return (
    <>
      <ResizableHandle withHandle />
      <ResizablePanel
        order={2}
        defaultSize={70}
        className="h-full max-h-screen"
      >
        <ScrollArea>
          {children}
        </ScrollArea>
      </ResizablePanel>
    </>
  );
};

import { createFileRoute } from "@tanstack/react-router";
import DashboardLoading from "../-loading";
import { DashboardComingSoonPage } from "@/components/DashboardComingSoonPage";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/flashcards",
)({
  component: FlashcardPage,
  pendingComponent: DashboardLoading,
});

function FlashcardPage() {
  return <DashboardComingSoonPage />;
  // redirect("flashcards/browse");
}

import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";
import { useNbmeConfirmAnswerOmission } from "@/utils/stores/nbmeLayoutStore";

export const NbmeSettingsConfirmAnswerOmissionControl = () => {
  const { confirmAnswerOmission, setConfirmAnswerOmission } =
    useNbmeConfirmAnswerOmission();

  return (
    <NbmeSettingsSwitch
      checked={confirmAnswerOmission}
      onCheckedChange={(newValue) => setConfirmAnswerOmission(newValue)}
    />
  );
};

import { Button } from ".";

interface CancelButtonProps {
  isEditMode: boolean;
  onIsEditModeChange: (newValue: boolean) => void;
  onEditCancel: () => void;
}

export const CancelButton = (
  { isEditMode, onIsEditModeChange, onEditCancel }: CancelButtonProps,
) => {
  return (
    <Button
      variant="dashboard"
      className="lg:text-base w-[50%]"
      onClick={() => {
        onIsEditModeChange(false);
        onEditCancel();
      }}
      disabled={!isEditMode}
    >
      {"Cancel"}
    </Button>
  );
};

import { PropsWithClassName } from "@/types";
import { ComponentProps, PropsWithChildren } from "react";

type ContextMenuItemProps =
  & PropsWithChildren
  & PropsWithClassName
  & ComponentProps<"div">;
const ContextMenuItem = (
  { className, children, ...props }: ContextMenuItemProps,
) => {
  return <div role="button" className={className} {...props}>{children}</div>;
};

export default ContextMenuItem;

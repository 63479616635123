import { useEffect } from "react";
import { useNavigate } from "@tanstack/react-router";
import useAuthSlice from "@/hooks/auth-hooks/use-auth-slice";

export const useRequiresUser = () => {
  const currentUser = useAuthSlice().currentUser;
  const navigate = useNavigate();
  useEffect(() => {
    if (!currentUser) {
      navigate({ to: "/auth/signin" });
    }
  }, [currentUser]);
  return currentUser;
};

export const useRequiresNoUser = () => {
  const currentUser = useAuthSlice().currentUser;
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser) {
      if (currentUser.role === "ADMIN") {
        navigate({ to: "/admin/users" });
      }
      navigate({ to: "/home" });
    }
  }, [currentUser]);
};

const fixDatesForOne = <
  T extends { createdAt: string; lastUpdatedAt: string },
>(one: T) => {
  const result = {
    ...one,
    createdAt: new Date(one.createdAt),
    lastUpdatedAt: new Date(one.lastUpdatedAt),
  };

  return result;
};

export const fixDates = <
  T extends { createdAt: string; lastUpdatedAt: string },
>(
  array: T[] | T,
) => {
  const isArray = Array.isArray(array);

  return isArray ? array.map((i) => fixDatesForOne(i)) : fixDatesForOne(array);
};

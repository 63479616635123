import { stringToRegex } from "@/utils/common";

/** Gets which nth ocurrence is the pattern inside the
 * input string, which was matched at the given index
 * example:
 * @example
 * const ocurrence = getOcurrence("of", 29,"this example has two offs,
 *                                      one of here and one of there.")
 * console.log(occurrence) //<- This will print '2'
 */
export const getOcurrence = (pattern: string, index: number, input: string) => {
  if (pattern === "" || input === "") return 0;
  const beforePattern = input.slice(0, index);
  const matches = Array.from(
    beforePattern.matchAll(stringToRegex(pattern, "g")),
  );

  return matches.length + 1;
};

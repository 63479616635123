import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import { createContext } from "react";

export type DashboardContext = {
  questionBank: ReturnType<typeof useQuestionBank>;
  questionHeaders: ReturnType<typeof useQuestionBankQuestionHeaders>;
};
const dashboardContext = createContext<DashboardContext | undefined>(undefined);
export default dashboardContext;

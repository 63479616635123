import { CircularProgressBar } from "@/components/CircularProgressBar";
import { AnimatePresence, motion } from "framer-motion";
import { getCenterText, getPercentages, getStrokeClassnames } from "./utils";
import { useState } from "react";
import { fadeAnimation } from "@/assets/framer";
import { LoadingSpinner } from "@/components/LoadingSpinner";

interface DashboardUsageStatsCircleProps {
  used: number;
  total: number;
  isLoading?: boolean;
}

export const DashboardUsageStatsCircle = (
  { used, total, isLoading }: DashboardUsageStatsCircleProps,
) => {
  const [activePercentage, setActivePercentage] = useState<number>(0);
  const percentages = getPercentages(used, total);
  const centerText = getCenterText(activePercentage);

  return (
    <CircularProgressBar
      clickable
      percentages={percentages}
      strokeClassNames={getStrokeClassnames(activePercentage)}
      strokeOnClick={setActivePercentage}
    >
      <motion.div
        key={isLoading ? "loading" : centerText}
        {...fadeAnimation}
        transition={{ ease: "backOut", duration: 0.9 }}
        className="flex flex-col justify-center items-center select-none"
      >
        {isLoading ? <LoadingSpinner className="w-12 h-12" /> : (
          <>
            <p className="text-xl text-gray-700 dark:text-neutral-200 transition-colors">
              {percentages[activePercentage]!.toFixed(1)}%
            </p>
            <p className="text-gray-500 font-light dark:text-neutral-300 transition-colors">
              {centerText}
            </p>
          </>
        )}
      </motion.div>
    </CircularProgressBar>
  );
};

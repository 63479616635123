import { useNbmePadding } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSlider } from "./NbmeSettingsSlider";

export const NbmeSettingsQuestionPaddingControl = () => {
  const [padding, setPadding] = useNbmePadding();

  return (
    <NbmeSettingsSlider
      value={padding.question}
      defaultValue={padding.question}
      onValueChange={(newValue) =>
        setPadding({ question: newValue, explanation: padding.explanation })}
      max={100}
      min={0}
    />
  );
};

import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { HighlightMarkerSliceCreator, highlightMarkerSliceState } from ".";
import { HighlightMarker } from "@/models";
import gen from "@/lib/gen";
import { toast } from "sonner";
import { trpcProxyClient } from "@/utils/trpc";
import handleTRPCMutationError from "@/utils/trpc/handle-trpc-mutation-error";
import { useTestStore } from "@/utils/stores";

const createHighlightMarkerSlice: HighlightMarkerSliceCreator = (set, get) => {
  return {
    ...highlightMarkerSliceState,
    addHighlightMarker: (highlightMarker) =>
      set((state) => {
        ArrayMapUtils.push(
          state.highlightMarkers,
          [highlightMarker],
        );
      }),
    addHighlightMarkers: (highlightMarkers) =>
      set((state) => {
        ArrayMapUtils.push(
          state.highlightMarkers,
          highlightMarkers,
        );
      }),
    editHighlightMarker: (input) => {
      const state = get();
      const markerToEdit = ArrayMapUtils.find(state.highlightMarkers, input.id);
      if (!markerToEdit) return;
      set((state) => {
        ArrayMapUtils.update(state.highlightMarkers, {
          ids: [input.id],
          updater: (hm) => ({ ...hm, ...input }),
        });
      });
      trpcProxyClient.highlightMarker.editHighlightMarker.mutate(input).catch(
        handleTRPCMutationError({
          mutationDescription: "Update Highlight Marker",
        }, () => {
          set((state) => {
            ArrayMapUtils.update(state.highlightMarkers, {
              updater: () => markerToEdit,
              ids: [markerToEdit.id],
            });
          });
        }),
      );
    },
    createHighlightMarker: (input) => {
      const state = get();
      const currentUser = state.currentUser;
      if (!currentUser) {
        toast.error(
          "You have to be logged in to create a new highlight marker.",
        );
        return;
      }
      const newHighlightMarker: HighlightMarker = {
        ...input,
        id: gen.cuid(),
        userId: currentUser.id,
      };
      state.addHighlightMarker(newHighlightMarker);
      trpcProxyClient.highlightMarker.createHighlightMarker.mutate(
        newHighlightMarker,
      )
        .catch(
          handleTRPCMutationError({
            mutationDescription: "Create Highlight Marker",
          }, () => {
            state.deleteHighlightMarker(
              { highlightMarkerId: newHighlightMarker.id },
              { local: true },
            );
          }),
        );
      return newHighlightMarker;
    },
    deleteHighlightMarker: (input, opts) => {
      const state = get();
      const highlightMarkerToDelete = ArrayMapUtils.find(
        state.highlightMarkers,
        input.highlightMarkerId,
      );
      if (!highlightMarkerToDelete) return;
      const highlightsToDelete = ArrayMapUtils.filter(
        state.highlights,
        (h) =>
          h.userId === state.currentUser?.id &&
          h.markerId === highlightMarkerToDelete.id,
      );
      state.deleteHighlights({
        highlightIds: ArrayMapUtils.toArray(highlightsToDelete).map(
          (h) => (h.id),
        ),
      });

      set((state) => {
        ArrayMapUtils.delete(state.highlightMarkers, input.highlightMarkerId);
      });
      useTestStore.getState().setSelectedHighlightMarker(undefined);

      if (opts?.local) return;
      trpcProxyClient.highlightMarker.deleteHighlightMarker.mutate(input).catch(
        handleTRPCMutationError({
          mutationDescription: "Delete Highlight Marker",
        }, () => {
          state.addHighlightMarker(highlightMarkerToDelete);
          state.addHighlights(ArrayMapUtils.toArray(highlightsToDelete));
        }),
      );
    },
    resetHighlightMarkers: ({ highlightMarkerIds }) => {
      set((state) => {
        ArrayMapUtils.forEach(
          ArrayMapUtils.filter(
            state.highlightMarkers,
            (t) => highlightMarkerIds.includes(t.id),
          ),
          (highlightMarker) => {
            ArrayMapUtils.delete(state.highlightMarkers, highlightMarker.id);
          },
        );
      });
    },
  };
};

export default createHighlightMarkerSlice;

import { useAppStore } from "@/store";
import { trpc } from "@/utils/trpc";
import { ArgumentTypes } from "@/types";
import gen from "@/lib/gen";

const useCreateQuestionNote = () => {
  const createQuestionNote = useAppStore((state) => state.createQuestionNote);
  const mutation = trpc.questionNote.createQuestionNote.useMutation();
  const mutate = (
    args: Omit<ArgumentTypes<typeof mutation.mutate>[0], "id">,
  ) => {
    const newQuestionNote = {
      id: gen.cuid(),
      text: args.text,
      questionId: args.questionId,
    };
    createQuestionNote(newQuestionNote);
    mutation.mutate(newQuestionNote);
  };
  const mutateAsync = (args: ArgumentTypes<typeof createQuestionNote>[0]) => {
    const newQuestionNote = {
      id: gen.cuid(),
      text: args.text,
      questionId: args.questionId,
    };
    createQuestionNote(newQuestionNote);
    return mutation.mutateAsync(newQuestionNote);
  };

  return { ...mutation, mutate, mutateAsync };
};
export default useCreateQuestionNote;

import { useEffect, useState } from "react";
import {
  BreadCrumb,
  Footer,
  Header,
  HtmlEditor,
  Layout,
  placeholder,
} from "./components";
import { useSelectedNoteContent } from "./hooks";

interface BodyProps {
}

export const Body = ({}: BodyProps) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { content, setContent, draft, setDraft, resetDraft, selectedNote } =
    useSelectedNoteContent();

  return (
    <Layout>
      <BreadCrumb />
      <Header />
      <HtmlEditor
        editable={isEditMode}
        onChange={setDraft}
        content={selectedNote ? content ?? "" : placeholder}
      />

      <Footer
        selectedNote={selectedNote
          ? {
            id: selectedNote.id,
          }
          : undefined}
        visible={!!selectedNote}
        isEditMode={isEditMode}
        onIsEditModeChange={setIsEditMode}
        onEditSave={() => {
          if (selectedNote) setContent(draft);
          setIsEditMode(false);
        }}
        onEditCancel={() => {
          resetDraft();
        }}
      />
    </Layout>
  );
};

import useMarkQuestion from "@/hooks/question-hooks/use-mark-question";
import useTestPageTestSelectedTestSlotQuestion from "./use-test-page-test-selected-test-slot-questiont";
import { useCallback } from "react";

const useTestPageMarkSelectedTestSlotQuestion = () => {
  const questionQuery = useTestPageTestSelectedTestSlotQuestion();
  const { data: selectedQuestion } = questionQuery;
  const markQuestion = useMarkQuestion();
  return useCallback(() => {
    selectedQuestion &&
      markQuestion({
        questionId: selectedQuestion.id,
        marked: !selectedQuestion.marked,
      });
  }, [markQuestion, selectedQuestion]);
};

export default useTestPageMarkSelectedTestSlotQuestion;

import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankTopics from "./use-question-bank-topics";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const useGetSystemTopics = (args: QuestionBankIdentifier) => {
  const topicsQuery = useQuestionBankTopics(args);
  const { data: topics } = topicsQuery;
  const getSystemTopics = (systemId: string) => {
    return ArrayMapUtils.filter(topics, (t) => t.systemId === systemId);
  };
  return {
    ...topicsQuery,
    data: getSystemTopics,
  };
};
export default useGetSystemTopics;

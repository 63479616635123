import { TestSlot } from "../testslot-model-types";

const updateTestSlotLastSelectedTimeManipulator = (): Pick<
  TestSlot,
  "lastSelected"
> => {
  return { lastSelected: new Date() };
};

export default updateTestSlotLastSelectedTimeManipulator;

import { PropsWithClassName } from "@/types";
import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface NbmeSettingsSegmentProps
  extends PropsWithClassName, PropsWithChildren {
  disabled?: boolean;
}

export const NbmeSettingsSegment = (
  { children, className, disabled }: NbmeSettingsSegmentProps,
) => {
  return (
    <div
      className={twMerge(
        "flex flex-row justify-between items-center w-full border-b border-gray-300 dark:border-nbme-primary-dark-300 dark:text-neutral-700 pb-3 transition-opacity last:border-b-0 last:pb-0",
        className,
        disabled && "opacity-30 pointer-events-none",
      )}
    >
      {children}
    </div>
  );
};

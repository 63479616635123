import { trpcProxyClient } from "@/utils/trpc";

export const postNote = (
  { title, pageId, parentId }: {
    title: string;
    pageId: string;
    parentId?: string;
  },
) => {
  return trpcProxyClient.notebook.note.addOne.mutate({
    title,
    pageId,
    parentId,
  });
};

import { useAppStore } from "@/store";
import { useSelectedNote } from "@/hooks/notebook";

export const useSelectedNoteTitle = () => {
  const selectedNote = useSelectedNote();
  const rename = useAppStore((state) => state.renameNote);
  const setSelectedNoteTitle = (newTitle: string) => {
    if (selectedNote) {
      rename({ id: selectedNote.id, newTitle });
    }
  };
  return [selectedNote?.title, setSelectedNoteTitle] as const;
};

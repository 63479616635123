import { useEffect, useState } from "react";

export const useActivity = (
  { activityDurationInMilliSeconds, onActivityStarted, onActivityStopped }: {
    onActivityStarted?: () => void;
    onActivityStopped?: () => void;
    activityDurationInMilliSeconds: number;
  },
) => {
  const [isCurrentActivity, setIsCurrentActivity] = useState<boolean>(false);

  const [currentActivityTimer, setCurrentActivityTimer] = useState<
    ReturnType<typeof setTimeout> | null
  >(null);

  const triggerActivity = () => {
    onActivityStarted?.();
    if (!isCurrentActivity) setIsCurrentActivity(true);
  };

  useEffect(() => {
    if (!isCurrentActivity) return;

    if (currentActivityTimer !== null) {
      clearTimeout(currentActivityTimer);
    }

    setCurrentActivityTimer(
      setTimeout(() => {
        onActivityStopped?.();
        setCurrentActivityTimer(null);
      }, activityDurationInMilliSeconds),
    );

    setIsCurrentActivity(false);

    return () => {
      if (currentActivityTimer) clearTimeout(currentActivityTimer);
    };
  }, [isCurrentActivity]);

  return { triggerActivity };
};

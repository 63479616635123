import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { Point } from "@/types";
import {
  NbmeCalculatorButton,
  NbmeCalculatorPrimaryButton,
  NbmeCalculatorSecondaryButton,
  NbmeCalculatorTertiaryButton,
  NbmeFloatingWindow,
} from ".";
import { Draft } from "immer";
import { isNaN } from "underscore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "@/components/Input";
import {
  faDeleteLeft,
  faDivide,
  faEquals,
  faMinus,
  faMultiply,
  faPlus,
  faPlusMinus,
  faSquareRootVariable,
} from "@fortawesome/free-solid-svg-icons";

interface NbmeCalculatorStore {
  tokens: string[];
  result?: number;
  lastResult?: number;
  addToken: (t: string) => void;
  setTokens: (tokens: string[]) => void;
}

export const useNbmeCalculatorStore = create(
  immer<NbmeCalculatorStore>((set) => {
    const evaluate = (state: Draft<NbmeCalculatorStore>) => {
      const result = eval(
        state.tokens.join("").replace(
          /ANS/,
          state.lastResult?.toString() ?? "",
        ),
      );
      state.result = result;
      state.tokens = [result];
      state.lastResult = result;
      return result;
    };

    return ({
      tokens: [],
      result: undefined,
      lastResult: undefined,
      setTokens: (tokens: string[]) =>
        set((state) => {
          state.tokens = tokens;
        }),
      addToken: (t) =>
        set((state) => {
          try {
            if (state.tokens[0] === "ERROR") state.tokens = [];
            if (t === "=") {
              evaluate(state);
              return;
            }
            if (t === "sqrt") {
              const result = evaluate(state);
              const sqrtResult = eval(`Math.sqrt(${result})`);
              state.result = sqrtResult;
              state.tokens = [sqrtResult];
              return;
            }
            if (t === "1/x") {
              state.tokens = ["1", "/", "(", ...state.tokens, ")"];
              return;
            }
            if (t === "+-") {
              state.tokens = [
                state.tokens[0] === "-" ? "" : "-",
                ...state.tokens,
              ];
              return;
            }
            if (t === "clear") {
              state.tokens = [];
              state.result = undefined;
              return;
            }
            if (t === "<") {
              state.tokens.pop();
              return;
            }

            if (
              state.tokens.length === 1 && state.result !== undefined &&
              (!isNaN(Number(t)) || t === "ans")
            ) {
              state.tokens = [];
              state.result = undefined;
            }

            if (t === "ans") {
              if (!isNaN(Number(state.tokens.at(-1)))) {
                state.tokens = ["ERROR"];
                return;
              }
              state.tokens.push("ANS");
              return;
            }

            state.tokens.push(t);
          } catch (exc) {
            state.tokens = ["ERROR"];
          }
        }),
    });
  }),
);

interface NbmeCalculatorWindowProps {
  isVisible: boolean;
  onIsVisibleChange: (v: boolean) => void;
  position: Point;
  onPositionChange: (p: Point) => void;
  onIsSelectionEnabledChange: (v: boolean) => void;
}

export const NbmeCalculatorWindow = (
  {
    isVisible,
    onIsVisibleChange,
    position,
    onPositionChange,
    onIsSelectionEnabledChange,
  }: NbmeCalculatorWindowProps,
) => {
  const { tokens, addToken, result, setTokens } = useNbmeCalculatorStore();

  return (
    <NbmeFloatingWindow
      isVisible={isVisible}
      key={"calc"}
      title={"Calculator"}
      position={position}
      onPositionChanged={onPositionChange}
      viewportClassName="dark:bg-nbme-primary-dark-800"
      onCloseClicked={() => onIsVisibleChange(false)}
      onIsSelectionEnabledChange={onIsSelectionEnabledChange}
      className="min-w-[15rem] md:max-w-[18rem] lg:max-w-[20rem]"
    >
      <div className="flex flex-col justify-start items-center px-2 py-3 w-full md:w-auto">
        <div className="grid grid-cols-4 grid-rows-7 gap-2 w-full md:w-auto">
          <NbmeCalculatorButton
            onClick={() => addToken("<")}
            className="inline-flex justify-center items-center col-span-1"
          >
            <FontAwesomeIcon icon={faDeleteLeft} />
          </NbmeCalculatorButton>
          <Input
            className="py-1 text-end col-span-3 rounded-full md:rounded-full lg:rounded-full dark:bg-nbme-primary-dark-600"
            value={tokens.join("").replace(/[\*]/g, "x")}
            onChange={(e) => {
              setTokens(e.target.value.replace(/[x]/g, "*").split(""));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addToken("=");
              }
            }}
          />
          <NbmeCalculatorSecondaryButton onClick={() => addToken("(")}>
            (
          </NbmeCalculatorSecondaryButton>
          <NbmeCalculatorSecondaryButton onClick={() => addToken(")")}>
            )
          </NbmeCalculatorSecondaryButton>
          <NbmeCalculatorSecondaryButton onClick={() => addToken("ans")}>
            ANS
          </NbmeCalculatorSecondaryButton>
          <NbmeCalculatorPrimaryButton onClick={() => addToken("clear")}>
            C
          </NbmeCalculatorPrimaryButton>
          <NbmeCalculatorTertiaryButton onClick={() => addToken("+-")}>
            <FontAwesomeIcon icon={faPlusMinus} />
          </NbmeCalculatorTertiaryButton>
          <NbmeCalculatorTertiaryButton onClick={() => addToken("sqrt")}>
            <FontAwesomeIcon icon={faSquareRootVariable} />
          </NbmeCalculatorTertiaryButton>
          <NbmeCalculatorTertiaryButton
            onClick={() => addToken("1/x")}
            className="font-bold"
          >
            1/x
          </NbmeCalculatorTertiaryButton>
          <NbmeCalculatorTertiaryButton
            onClick={() => addToken("/")}
            className="font-bold"
          >
            <FontAwesomeIcon icon={faDivide} />
          </NbmeCalculatorTertiaryButton>
          <NbmeCalculatorButton onClick={() => addToken("7")}>
            7
          </NbmeCalculatorButton>
          <NbmeCalculatorButton onClick={() => addToken("8")}>
            8
          </NbmeCalculatorButton>
          <NbmeCalculatorButton onClick={() => addToken("9")}>
            9
          </NbmeCalculatorButton>
          <NbmeCalculatorTertiaryButton
            onClick={() => addToken("*")}
            className="font-bold"
          >
            <FontAwesomeIcon icon={faMultiply} />
          </NbmeCalculatorTertiaryButton>
          <NbmeCalculatorButton onClick={() => addToken("4")}>
            4
          </NbmeCalculatorButton>
          <NbmeCalculatorButton onClick={() => addToken("5")}>
            5
          </NbmeCalculatorButton>
          <NbmeCalculatorButton onClick={() => addToken("6")}>
            6
          </NbmeCalculatorButton>
          <NbmeCalculatorTertiaryButton
            onClick={() => addToken("-")}
            className="font-bold"
          >
            <FontAwesomeIcon icon={faMinus} />
          </NbmeCalculatorTertiaryButton>
          <NbmeCalculatorButton onClick={() => addToken("1")}>
            1
          </NbmeCalculatorButton>
          <NbmeCalculatorButton onClick={() => addToken("2")}>
            2
          </NbmeCalculatorButton>
          <NbmeCalculatorButton onClick={() => addToken("3")}>
            3
          </NbmeCalculatorButton>
          <NbmeCalculatorTertiaryButton
            onClick={() => addToken("+")}
            className="font-bold"
          >
            <FontAwesomeIcon icon={faPlus} />
          </NbmeCalculatorTertiaryButton>
          <NbmeCalculatorButton onClick={() => addToken("0")}>
            0
          </NbmeCalculatorButton>
          <NbmeCalculatorButton onClick={() => addToken(".")}>
            .
          </NbmeCalculatorButton>
          <NbmeCalculatorPrimaryButton
            onClick={() => addToken("=")}
            className="col-span-2"
          >
            <FontAwesomeIcon icon={faEquals} />
          </NbmeCalculatorPrimaryButton>
        </div>
      </div>
    </NbmeFloatingWindow>
  );
};

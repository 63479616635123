export const getCenterText = (activePercentage: number): string => {
  switch (activePercentage) {
    case 0:
      return `Correct`;
    case 1:
      return `Incorrect`;
    case 2:
      return `Omitted`;
    default:
      return "ERROR";
  }
};

"use client";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

interface NbmeChoiceProps {
  text: string;
  crossed: boolean;
}

export const NbmeChoice = ({ text, crossed }: NbmeChoiceProps) => {
  return (
    <p
      className={twMerge("cursor-default", crossed && "line-through")}
    >
      {text}
    </p>
  );
};

import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

type WindowBodyProps = PropsWithChildren & PropsWithClassName;
const WindowBody = ({ children, className }: WindowBodyProps) => {
  return (
    <div
      className={cn(
        "flex flex-col justify-start items-center w-full h-full border border-gray-300 dark:border-neutral-700 rounded-xl overflow-hidden",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default WindowBody;

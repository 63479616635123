import useTestPageTestSelectedTestSlotQuestion from "./use-test-page-test-selected-test-slot-questiont";

const useTestPageTestSelectedTestSlotQuestionExplanationHighlights = () => {
  const selectedQuestion = useTestPageTestSelectedTestSlotQuestion();

  return {
    ...selectedQuestion,
    data: selectedQuestion.data?.explanation.highlights,
  };
};

export default useTestPageTestSelectedTestSlotQuestionExplanationHighlights;

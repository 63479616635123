import { DashboardTable } from "@/components/DashboardTable";
import {
  PerformanceReportsPageNoResultsCenterPiece,
  PerformanceReportsPageProgressBar,
} from ".";
import usePerformanceReportsPageStore from "../-store";
import { ItemPerformanceReport } from "../-types";
import { searchReports } from "../-utils";
import { percent } from "@/utils/common/percent";
import { Paginator } from "@/components/Paginator";
import { motion } from "framer-motion";
import { fadeAnimation } from "@/assets/framer";
import { twMerge } from "tailwind-merge";
import { Badge } from "@/components/Badge";
import { TableRowContentMapper } from "@/types";
import { row } from "../-table";
import usePerformanceReportsPageReports from "../-hooks/use-performance-reports-page-reports";

export function PerformanceReportsPageTable() {
  const {
    reportOptions,
    currentTab,
    query,
    currentPage,
    setCurrentPage,
    setReportsPerPage,
    reportsPerPage,
  } = usePerformanceReportsPageStore((state) => ({
    reportOptions: state.reportOptions,
    currentTab: state.currentTab,
    query: state.query,
    reportsPerPage: state.reportsPerPage,
    currentPage: state.currentPage,
    setCurrentPage: state.setCurrentPage,
    setReportsPerPage: state.setReportsPerPage,
  }));

  const { data: reports, isLoading } = usePerformanceReportsPageReports();
  const searchedArray = searchReports(reports, query);

  if (searchedArray.length === 0 && !isLoading) {
    return (
      <div className="relative min-h-[50vh] min-w-full flex flex-col justify-center items-center">
        <div className="absolute left-[0.5] top-[0.5] w-full">
          <PerformanceReportsPageNoResultsCenterPiece />
        </div>
      </div>
    );
  }

  const contentMapper = (
    variant: "child" | "parent",
  ): TableRowContentMapper<ItemPerformanceReport> =>
  (
    column,
    content: ItemPerformanceReport,
  ) => {
    if (column.name === "Name") {
      return (
        <div
          className={twMerge(
            "flex flex-col justify-start items-start gap-1.5 md:w-[20rem] w-[15rem] md:py-0 py-2",
          )}
        >
          {variant === "parent"
            ? (
              <div className="flex flex-r2w justify-start items-center gap-2">
                <p>{content.name}</p>
                {percent(content.used, content.total) === 100 &&
                  (
                    <Badge className="px-2 py-0.5 text-xs font-bold text-white bg-lime-500 w-max">
                      <p>
                        100% Used
                      </p>
                    </Badge>
                  )}
              </div>
            )
            : <p>{content.name}</p>}
          {variant === "parent" &&
            (
              <PerformanceReportsPageProgressBar
                key={content.name}
                itemReport={content}
                reportOptions={reportOptions}
              />
            )}
        </div>
      );
    }
    return content;
  };

  return (
    <motion.div
      {...fadeAnimation}
      className="flex flex-col justify-start items-start"
    >
      <DashboardTable
        columns={{ ...row, collapsible: currentTab !== "topics" }}
        items={searchedArray}
        itemsPerPage={reportsPerPage}
        currentPage={currentPage}
        collapsedItems={(report) => report.children}
        columnContentMapper={contentMapper("parent")}
        collapsedColumnContentMapper={contentMapper("child")}
      />
      <div className="flex flex-row justify-end items-center w-full p-3">
        <Paginator
          currentPage={currentPage}
          itemsPerPage={reportsPerPage}
          numberOfItems={searchedArray.length}
          itemSingularName="report"
          onCurrentPageChange={setCurrentPage}
          onItemsPerPageChange={setReportsPerPage}
        />
      </div>
    </motion.div>
  );
}

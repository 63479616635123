import { useAppStore } from "@/store";
import infuseFunctionIntoMutation from "@/utils/common/infuse-function-into-mutation";
import { trpc } from "@/utils/trpc";

const useRenameTest = () => {
  const renameTest = useAppStore((state) => state.renameTest);
  const mutation = trpc.test.renameTest.useMutation();
  return { ...infuseFunctionIntoMutation({ func: renameTest, mutation }) };
};
export default useRenameTest;

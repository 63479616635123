import { NbmeNavbarButtonWithIcon } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faClipboard as faSolidClipboard } from "@fortawesome/free-solid-svg-icons";

interface NbmeQuestionNoteButtonProps {
  hasNote: boolean;
  isNoteVisible: boolean;
  onIsNoteVisibleChange: (newIsNoteVisible: boolean) => void;
}

export const NbmeQuestionNoteButton = (
  { hasNote, isNoteVisible, onIsNoteVisibleChange }:
    NbmeQuestionNoteButtonProps,
) => {
  return (
    <NbmeNavbarButtonWithIcon
      className="w-full md:w-auto"
      icon={
        <FontAwesomeIcon
          icon={hasNote ? faSolidClipboard : faClipboard}
          className="w-8 h-8"
        />
      }
      label="Notes"
      onClick={() => {
        onIsNoteVisibleChange(!isNoteVisible);
      }}
    />
  );
};

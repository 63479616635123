import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import DashboardAccordionSection from "./DashboardAccordionSection";
import { InputGroup } from "./InputGroup";
import { Input } from "./Input";

const CreateTestNameSection = () => {
  const {
    name,
    setName,
    toggleSection,
    toggledSections,
  } = useCreateTestPageStore((state) => ({
    name: state.name,
    setName: state.setName,
    toggledSections: state.toggledSections,
    toggleSection: state.toggleSection,
  }));

  return (
    <DashboardAccordionSection
      title={
        <div className="text-gray-800 warm:text-brown-900 dark:text-neutral-100 font-semibold">
          Test Name{"  "}
          <span className="text-gray-400 warm:text-brown-400 dark:text-neutral-500 font-normal">
            - Optional
          </span>
        </div>
      }
      value="name"
      className="border-b"
      onToggle={() => toggleSection("name")}
      toggled={toggledSections.includes("name")}
    >
      <div className="flex flex-row justify-start items-center gap-5 mt-3">
        <InputGroup className="flex flex-row justify-center items-center gap-2 ">
          <Input
            id="name-input"
            placeholder="Important Test"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputGroup>
      </div>
    </DashboardAccordionSection>
  );
};

export default CreateTestNameSection;

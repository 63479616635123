import { Button } from "@/components/Button";
import { twMerge } from "tailwind-merge";

interface ItemProps {
  title: string;
  isSelected: boolean;
  matcher: string;
  onClick: () => void;
}
export const Item = ({ matcher, title, isSelected, onClick }: ItemProps) => {
  return (
    <Button
      onClick={onClick}
      variant="dashboard"
      className={twMerge(
        "p-1 text-sm md:text-base lg:text-base text-gray-500 font-normal",
        isSelected && "bg-gray-100",
      )}
    >
      {Array.from(title).map((char, index) => {
        return (
          <span
            className={twMerge(matcher.includes(char) && "font-semibold")}
            key={char + index}
          >
            {char}
          </span>
        );
      })}
    </Button>
  );
};

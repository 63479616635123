import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  NbmeCalculatorNavbarButton,
  NbmeFullscreenButton,
  NbmeLabValuesButton,
  NbmeMarkQuestionButton,
  NbmeNavbarBase,
  NbmeNextPreviousButton,
  NbmeQuestionNoteButton,
  NbmeSettingsNavbarButton,
} from ".";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { clamp } from "@/utils/common/clamp";
import { twMerge } from "tailwind-merge";
import { useState } from "react";
import { useBreakPoint } from "@/utils/hooks";
import { BreakPoints } from "@/types";

export interface NbmeTopNavbarProps<T = "test" | "question"> {
  variant: T;
  questionId: number;
  isQuestionMarked: boolean;
  onQuestionMarkedChange: (questionMarked: this["isQuestionMarked"]) => void;
  isLabValuesVisible: boolean;
  onIsLabValuesVisibleChange: (labValues: boolean) => void;
  isQuestionNoteVisible: boolean;
  onIsQuestionNoteVisibleChange: (newIsNoteVisible: boolean) => void;
  isQuestionNotedOn: boolean;
  isCalculatorVisible: boolean;
  onIsCalculatorVisibleChange: (v: boolean) => void;
  isSettingsVisible: boolean;
  onIsSettingsVisibleChange: (v: boolean) => void;

  isSidebarVisible?: T extends "test" ? boolean : undefined;
  onIsSidebarVisibleChange?: T extends "test" ? (v: boolean) => void
    : undefined;
  currentSlot?: T extends "test" ? number : undefined;
  onCurrentSlotChange?: T extends "test" ? (newSlot: number) => void
    : undefined;
  totalSlots?: T extends "test" ? number : undefined;
}

export const NbmeTopNavbar = <T = "test" | "question">(
  {
    variant,
    currentSlot,
    totalSlots,
    questionId,
    isQuestionMarked,
    onQuestionMarkedChange,
    onCurrentSlotChange,
    isLabValuesVisible,
    onIsLabValuesVisibleChange,
    isQuestionNotedOn,
    isQuestionNoteVisible,
    onIsQuestionNoteVisibleChange,
    isCalculatorVisible,
    onIsCalculatorVisibleChange,
    isSettingsVisible,
    onIsSettingsVisibleChange,
    isSidebarVisible,
    onIsSidebarVisibleChange,
  }: NbmeTopNavbarProps<T>,
) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);
  const breakPoint = useBreakPoint();
  const isScreenSmall = breakPoint < BreakPoints.Medium;

  return (
    <NbmeNavbarBase className="sticky top-0 z-[900] min-h-[60px]">
      <div className="flex flex-row justify-center items-center gap-4 ">
        {variant === "test" &&
          (
            <>
              <button
                className=""
                type="button"
                onClick={() => onIsSidebarVisibleChange?.(!isSidebarVisible)}
              >
                <FontAwesomeIcon icon={faBars} className="w-8 h-8 text-white" />
              </button>
              <div className="md:flex flex-col gap-1 justify-center items-start text-white md:text-sm text-xs font-bold">
                <p>Item {currentSlot} of {totalSlots}</p>
                <p className="text-xs">
                  Question Id: <br className="block md:hidden" />
                  {questionId}
                </p>
              </div>
            </>
          )}
        {variant === "question" && (
          <>
            <div className="md:flex flex-col gap-1 justify-center items-start text-white md:text-sm font-bold">
              <p className="">
                Question Id: <br className="block md:hidden" />
                {questionId}
              </p>
            </div>
          </>
        )}
        <div className="ml-2 hidden md:block">
          <NbmeMarkQuestionButton
            isMarked={isQuestionMarked}
            onMarkedChange={onQuestionMarkedChange}
          />
        </div>
      </div>
      {variant === "test" &&
        (
          <div className="flex flex-row justify-center items-center gap-2 mx-auto absolute left-[50%] translate-x-[-50%] md:translate-x-0 md:relative md:left-auto">
            <NbmeNextPreviousButton
              direction="previous"
              onClick={() =>
                onCurrentSlotChange?.(
                  clamp({
                    min: 1,
                    max: totalSlots!,
                    num: currentSlot! - 1,
                  }),
                )}
            />
            <NbmeNextPreviousButton
              direction="next"
              onClick={() =>
                onCurrentSlotChange?.(
                  clamp({ min: 1, max: totalSlots!, num: currentSlot! + 1 }),
                )}
            />
          </div>
        )}
      <div
        className={twMerge(
          "flex flex-col md:flex-row justify-start md:justify-center items-center gap-2 md:relative md:right-auto md:top-auto fixed right-0 top-3.5 rounded-b-primary",
          mobileMenuVisible && "shadow-lg",
        )}
      >
        <button
          type="button"
          className="text-nbme-primary-500 dark:text-nbme-primary-dark-900  bg-white px-2 py-1 rounded-full md:hidden"
          onClick={() => setMobileMenuVisible((prev) => !prev)}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            className={twMerge(
              "transition-transform",
              mobileMenuVisible && "rotate-180",
            )}
          />
        </button>
        <div
          className={twMerge(
            "flex flex-col md:flex-row justify-start md:justify-center items-center gap-2 bg-nbme-primary-700 dark:bg-nbme-primary-dark-800 rounded-b-primary overflow-hidden transition-colors",
            !mobileMenuVisible && isScreenSmall &&
              "invisible  pointer-events-none bg-transparent",
          )}
        >
          <NbmeMarkQuestionButton
            isMarked={isQuestionMarked}
            onMarkedChange={onQuestionMarkedChange}
            className="md:hidden w-full md:w-auto"
          />
          <NbmeFullscreenButton />
          {/*<NbmeTutorialButton />*/}
          <NbmeLabValuesButton
            onIsLabValuesVisibleChange={onIsLabValuesVisibleChange}
            isLabValuesVisible={isLabValuesVisible}
          />
          <NbmeQuestionNoteButton
            hasNote={isQuestionNotedOn}
            isNoteVisible={isQuestionNoteVisible}
            onIsNoteVisibleChange={onIsQuestionNoteVisibleChange}
          />
          <NbmeCalculatorNavbarButton
            isCalculatorVisible={isCalculatorVisible}
            onIsCalculatorVisibleChange={onIsCalculatorVisibleChange}
          />
          <NbmeSettingsNavbarButton
            isSettingsVisible={isSettingsVisible}
            onIsSettingsVisibleChange={onIsSettingsVisibleChange}
          />
        </div>
      </div>
    </NbmeNavbarBase>
  );
};

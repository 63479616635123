import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardSection from "./DashboardSection";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import AccordionItem from "./AccordionItem";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface DashboardAccordionSectionProps extends PropsWithChildren {
  title: string | JSX.Element;
  value: string;
  onToggle: () => void;
  toggled: boolean;
  className?: string;
}

const DashboardAccordionSection = (
  { children, title, toggled, onToggle, className, value }:
    DashboardAccordionSectionProps,
) => {
  return (
    <DashboardSection
      className={twMerge(
        "flex flex-col justify-start lg:items-start items-center w-full",
        className,
      )}
    >
      <div className="relative flex flex-row lg:justify-between justify-center items-center w-full dark:text-neutral-50">
        {typeof title === "string"
          ? (
            <h2 className="font-medium text-gray-900 warm:text-brown-900 dark:text-neutral-50 transition-colors">
              {title}
            </h2>
          )
          : title}
        <button
          type="button"
          className="lg:relative lg:right-none absolute right-0 flex flex-row justify-center items-center p-3 text-gray-400 no-spinners"
          onClick={onToggle}
        >
          <FontAwesomeIcon icon={toggled ? faChevronUp : faChevronDown} />
        </button>
      </div>
      <AccordionItem value={value} className="w-full">
        {children}
      </AccordionItem>
    </DashboardSection>
  );
};

export default DashboardAccordionSection;

import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import { trpc } from "@/utils/trpc";
import { useEffect, useMemo } from "react";
import useAddTopics from "./use-add-topics";
import useTopics from "./use-topics";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

type Opts = {
  enabled?: boolean;
};
const useQuestionBankTopics = (args: QuestionBankIdentifier, opts?: Opts) => {
  const { data: questionBank } = useQuestionBank(args);
  const query = trpc.topic.getQuestionBankTopics.useQuery({
    qBankId: questionBank?.id ?? "",
  }, { enabled: !!questionBank && (opts?.enabled ?? true) });
  const topics = useTopics();
  const addTopics = useAddTopics();
  const { data, isLoading } = query;
  useEffect(() => {
    if (!!data && !isLoading) {
      addTopics(data);
    }
  }, [data, isLoading]);
  const questionBankTopics = useMemo(() =>
    ArrayMapUtils.filter(
      topics,
      (t) => t.bankId === questionBank?.id,
    ), [topics, questionBank]);

  return { ...query, data: questionBankTopics };
};

export default useQuestionBankTopics;

import { useAppStore } from "@/store";

const useAuthSlice = () =>
  useAppStore((state) => ({
    signIn: state.signIn,
    signOut: state.signOut,
    sync: state.sync,
    currentUser: state.currentUser,
    reset: state.reset,
    resetQbank: state.resetQbank,
  }));
export default useAuthSlice;

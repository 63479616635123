import useQuestionBankQuestionSubject from "@/hooks/subject-hooks/use-questionbank-question-subject";
import useTestPageTest from "./use-test-page-test";
import useTestPageTestSelectedTestSlotQuestion from "./use-test-page-test-selected-test-slot-questiont";
import mergeFlags from "@/utils/common/merge-flags";

const useTestPageTestSelectedTestSlotQuestionSubject = () => {
  const test = useTestPageTest();
  const question = useTestPageTestSelectedTestSlotQuestion();
  const subject = useQuestionBankQuestionSubject({
    questionBankId: test.data?.questionBankId ?? "",
    questionId: question.data?.id ?? 0,
  });
  return {
    ...mergeFlags({ source: test, destinations: [question, subject] }),
    data: subject.data,
  };
};

export default useTestPageTestSelectedTestSlotQuestionSubject;

import useTestPageConstructedTest from "./use-test-page-constructed-test";

const useTestPageTestSlots = () => {
  const constructedTest = useTestPageConstructedTest();
  return {
    ...constructedTest,
    data: constructedTest.data?.slots,
  };
};
export default useTestPageTestSlots;

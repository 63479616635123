import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import {
  QuestionExplanationSliceCreator,
  questionExplanationSliceState,
} from ".";

const createQuestionExplanationSlice: QuestionExplanationSliceCreator = (
  set,
) => {
  return {
    ...questionExplanationSliceState,
    addQuestionExplanation: (questionExplanation) =>
      set((state) => {
        ArrayMapUtils.push(
          state.questionExplanations,
          [questionExplanation],
        );
      }),
    addQuestionExplanations: (questionExplanations) =>
      set((state) => {
        ArrayMapUtils.push(
          state.questionExplanations,
          questionExplanations,
        );
      }),
  };
};

export default createQuestionExplanationSlice;

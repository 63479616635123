import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankSubjects from "./use-question-bank-subjects";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { Test } from "@/models/test-model";
import useTest from "@/hooks/test-hooks/use-test";
import { useMemo } from "react";

const useQuestionBankTestSubjects = (
  args: QuestionBankIdentifier & { testId: Test["id"] },
) => {
  const testQuery = useTest({ testId: args.testId });
  const { data: test } = testQuery;
  const query = useQuestionBankSubjects(args);
  const { data: subjects } = query;
  const questionBankTestSubjects = useMemo(
    () => ArrayMapUtils.filter(subjects, (s) => test?.subjects.includes(s.id)),
    [subjects, test],
  );

  return {
    ...query,
    data: questionBankTestSubjects,
  };
};

export default useQuestionBankTestSubjects;

import { RichTextEditor } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import "@mantine/core/styles.css";
import "@mantine/tiptap/styles.css";
import { Toolbar } from "./components";
import { extensions } from "./extensions";
import { useEffect } from "react";
import { Setter } from "@/types";
import { useAppTheme } from "@/hooks";

export function TipTap(
  { content, onChange, editable }: {
    content: string;
    onChange: Setter<string>;
    editable: boolean;
  },
) {
  const [darkMode] = useAppTheme();
  const editor = useEditor({
    immediatelyRender: false,
    editorProps: {
      attributes: {
        class: "h-full" + darkMode
          ? "prose-neutral prose-table:bg-neutral-700 prose-table:rounded-primary dark:prose-invert"
          : "prose dark:prose-invert",
        spellcheck: "false",
      },
    },
    onUpdate: ({ editor }) => onChange(editor?.getHTML()),
    extensions,
    content,
    editable,
  });

  useEffect(() => {
    editor?.setOptions({ editable });
  }, [editable]);

  useEffect(() => {
    editor?.commands.setContent(content);
  }, [content]);

  return (
    <RichTextEditor editor={editor}>
      <Toolbar editor={editor} />
      <RichTextEditor.Content
        content={content}
        className="w-full"
      />
    </RichTextEditor>
  );
}

import { ComponentProps } from "react";
import { DeleteTestButton } from "./DeleteTest";
import { CopyQuestionIdsButton } from "./CopyQuestionIds";
import { Skeleton } from "@/components/ui/skeleton";

export const Buttons = (
  { testId, testName, qBankEndpoint, questionIds }:
    & ComponentProps<typeof DeleteTestButton>
    & ComponentProps<typeof CopyQuestionIdsButton>,
) => {
  return (
    <>
      <DeleteTestButton
        testId={testId}
        testName={testName}
        qBankEndpoint={qBankEndpoint}
      />
      <CopyQuestionIdsButton questionIds={questionIds} />
    </>
  );
};

import { PropsWithClassName } from "@/types";
import useNbmeSidePanelContext from "./hooks/use-nbme-side-panel-context";
import XMarkIcon from "@/components/icons/XMark";
import { cn } from "@/lib/utils";

type NbmeSidePanelCloseButtonProps = PropsWithClassName;
const CloseButton = ({ className }: NbmeSidePanelCloseButtonProps) => {
  const { setOpen } = useNbmeSidePanelContext();

  return (
    <button
      className={cn(
        "absolute right-5 dark:text-black text-nbme-primary-600 warm:text-brown-800 transition-colors z-[99999]",
        className,
      )}
      onClick={() => setOpen(false)}
    >
      <XMarkIcon className="w-6 h-6" />
    </button>
  );
};

export default CloseButton;

import { PropsWithChildren } from "react";
import WindowContextProvider from "./window-context-provider";

type WindowRootProps = PropsWithChildren & {
  onClose?: () => void;
  onMaximize?: () => void;
  onMinimize?: () => void;
};
const WindowRoot = ({ children, ...props }: WindowRootProps) => {
  return (
    <WindowContextProvider value={props}>
      <>
        {children}
      </>
    </WindowContextProvider>
  );
};

export default WindowRoot;

import { createFileRoute } from "@tanstack/react-router";
import { LoadingPage } from "@/components/LoadingPage";
import { NbmeLayout } from "@/components/common/nbme";
import { useNbmeLayoutStore } from "@/utils/stores";
import { trpc, trpcProxyClient } from "@/utils/trpc";
import { PropsWithChildren, useEffect } from "react";
import { useQuestionPageStore } from "./-store";
import { toast } from "sonner";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import { NotFoundPage } from "@/components/NotFoundPage";
import { ErrorPage } from "@/components/ErrorPage";
import { twMerge } from "tailwind-merge";
import { NbmeQuestion } from "@/components/common/nbme/NbmeQuestion";
import { NbmeExplanation } from "@/components/common/nbme/NbmeExplanation";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/questions/$questionId",
)({
  component: QuestionPageLayout,
});

function QuestionPageLayout() {
  const params = Route.useParams();
  const { data: questionBank } = useQuestionBank({
    questionBankEndpoint: params.qbankEndpoint,
  });
  const { loadQuestion, question, setMark, setNote, deleteNote } =
    useQuestionPageStore((
      state,
    ) => ({
      question: state.question,
      loadQuestion: state.loadQuestion,
      setMark: state.setMark,
      setNote: (args?: any) => undefined,
      deleteNote: (args?: any) => undefined,
    }));

  const {
    setSettingsVisible,
    setSelectionEnabled,
    setShortcutsEnabled,
    setPercentageAnsweredVisible,
    confirmAnswerOmissionDialogVisible,
    setConfirmAnswerOmissionDialogVisible,
    hints,
    setFeedbackVisible,
    setFontSizeFactor,
    shortcutsEnabled,
    setNotesPosition,
    selectionEnabled,
    settingsVisible,
    setTimerVisible,
    setNotesVisible,
    setCalcPosition,
    feedbackVisible,
    notesPosition,
    setCalcVisible,
    calcPosition,
    setLabVisible,
    setNightMode,
    notesVisible,
    setTheme,
    calcVisible,
    toggleHint,
    labVisbile,
    settings,
  } = useNbmeLayoutStore();

  const {
    fontSizeFactor,
    theme,
    nightMode,
    timerVisible,
    confirmAnswerOmission,
    percentageAnsweredVisible,
  } = settings;

  const isQuestionIdNumber = !isNaN(Number(params.questionId));

  const { data, error, isLoading } = trpc.question.getQuestionById.useQuery({
    questionId: Number(params.questionId),
    qBankId: questionBank?.id ?? "",
  }, { enabled: isQuestionIdNumber });

  useEffect(() => {
    if (data) {
      //@ts-ignore
      loadQuestion(data);
    }
  }, [data]);

  if (error) return <NotFoundPage />;
  if (isLoading) return <LoadingPage />;

  if (question) {
    return (
      <NbmeLayout
        onHintWindowPositionChange={() => undefined}
        onIsHintVisibleChange={(hint) =>
          toggleHint({ hintId: hint.id, value: !hint.isVisible })}
        qBankEndpoint={params.qbankEndpoint}
        questionId={question!.uWolrdId}
        isNotebookVisible={false}
        onIsNotebookVisibleChange={() =>
          toast("Your notebook is coming soon...")}
        totalChoices={question!.choices.length}
        isQuestionNoteVisible={notesVisible}
        onIsQuestionNoteVisibleChange={setNotesVisible}
        isFlashcardsVisible={false}
        onIsFlashcardsVisibleChange={() =>
          toast("Flashcards are coming soon...")}
        isSettingsVisible={settingsVisible}
        onIsSettingsVisibleChange={setSettingsVisible}
        isQuestionMarked={question.marked}
        isQuestionNotedOn={!!question.note}
        fontSizeFactor={fontSizeFactor}
        onFontSizeFactorChange={setFontSizeFactor}
        isCalculatorVisible={calcVisible}
        onIsCalculatorVisibleChange={setCalcVisible}
        isNightMode={!!nightMode.on}
        nightModeStartTime={nightMode.startTime}
        nightModeEndTime={nightMode.endTime}
        onNightModeStartTimeChange={(newStartTime) =>
          setNightMode("startTime", newStartTime)}
        onNightModeEndTimeChange={(newEndTime) =>
          setNightMode("endTime", newEndTime)}
        isFeedbackVisible={feedbackVisible}
        onIsFeedbackVisibleChange={setFeedbackVisible}
        onIsSelectionEnabledChange={setSelectionEnabled}
        onIsShortcutsEnabledChange={setShortcutsEnabled}
        isPercentageAnsweredVisible={percentageAnsweredVisible}
        onIsPercentageAnsweredVisibleChange={setPercentageAnsweredVisible}
        onIsNightModeChange={(on) => setNightMode("on", on)}
        onIsDarkModeChange={(v) => v ? setTheme("dark") : setTheme("light")}
        isDarkMode={theme === "dark"}
        onCalculatorWindowPositionChange={setCalcPosition}
        calculatorWindowPosition={calcPosition!}
        isShortcutsEnabled={shortcutsEnabled}
        isSelectionEnabled={selectionEnabled}
        onIsConfirmOmissionDialogVisibleChange={setConfirmAnswerOmissionDialogVisible}
        isConfirmOmissionDialogVisible={confirmAnswerOmissionDialogVisible}
        onQuestionMarkedChange={setMark}
        isLabValuesVisible={labVisbile}
        onIsLabValuesVisibleChange={setLabVisible}
        isCurrentSlotSubmitted={true}
        onQuestionNoteChange={(note) => setNote(note.text)}
        onQuestionNoteDeleted={() => deleteNote()}
        questionNote={{ text: question.note?.text ?? "" }}
        hints={[...question.hints, ...question.explanation.hints].map((h) => {
          const isVisible = !!hints[h.id];
          return ({
            id: h.id,
            text: h.text,
            html: h.html,
            imageId: h.imageId,
            videoId: h.videoId,
            isVisible: isVisible,
          });
        })}
        variant="question"
      >
        <QuestionPage />
      </NbmeLayout>
    );
  }
}

export default function QuestionPage() {
  const { settings, setPercentageAnsweredVisible } = useNbmeLayoutStore();
  const { percentageAnsweredVisible, fontSizeFactor } = settings;
  const { question } = useQuestionPageStore();
  if (!question) return <></>;
  return (
    <div
      className={twMerge(
        "flex flex-col justify-start items-center lg:max-w-[70%] w-full gap-6",
      )}
    >
      <NbmeQuestion
        question={question}
        variant="question"
        fontSizeFactor={fontSizeFactor}
        isPercentageAnsweredVisible={percentageAnsweredVisible}
        onIsPercentageAnsweredVisibleChange={setPercentageAnsweredVisible}
      />
      <NbmeExplanation question={question} fontSizeFactor={fontSizeFactor} />
    </div>
  );
}

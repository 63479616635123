import { CoursologyErrorPage } from "./CoursologyErrorPage";

export function NotFoundPage() {
  return (
    <CoursologyErrorPage>
      <h1 className="font-semibold text-xl dark:text-neutral-100">
        {"There's nothing here.."}
      </h1>
      <p className="text-gray-800 text-lg dark:text-neutral-500">
        {"Looks like you're trying to access a page that doesn't exist..."}
      </p>
    </CoursologyErrorPage>
  );
}

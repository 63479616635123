import { trpc } from "@/utils/trpc";
import useAddTestBlueprints from "./use-add-testblueprints";
import useTestBlueprints from "./use-testblueprints";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import { useEffect, useMemo } from "react";
import mergeFlags from "@/utils/common/merge-flags";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const useQuestionBankTestBlueprints = (args: QuestionBankIdentifier) => {
  const questionBankQuery = useQuestionBank(args);
  const { data: questionBank } = questionBankQuery;
  const testBlueprints = useTestBlueprints();
  const addTestBlueprints = useAddTestBlueprints();
  const query = trpc.testBlueprint.getQuestionBankTestBlueprints.useQuery({
    qBankId: questionBank?.id ?? "",
  }, { enabled: !!questionBank });
  const { data, isLoading } = query;
  useEffect(() => {
    if (data && !isLoading) {
      addTestBlueprints(data);
    }
  }, [data, isLoading]);
  const questionBankTestBluePrints = useMemo(() =>
    ArrayMapUtils.filter(
      testBlueprints,
      (tbp) => tbp.questionBankId === questionBank?.id,
    ), [testBlueprints, questionBank]);
  return {
    ...mergeFlags({ source: questionBankQuery, destinations: [query] }),
    data: questionBankTestBluePrints,
  };
};

export default useQuestionBankTestBlueprints;

import {
  useNbmeLayoutStore,
  useNbmeTheme,
} from "@/utils/stores/nbmeLayoutStore";
import Dialog from "../Dialog";
import { Button } from "@/components/Button";

interface NbmeHighlightWarningDialogProps {
}

export const NbmeHighlightWarningDialog = (
  {}: NbmeHighlightWarningDialogProps,
) => {
  const [theme] = useNbmeTheme();
  const { setIsVisible, isVisible, setIsWarned } = useNbmeLayoutStore((
    state,
  ) => ({
    setIsVisible: state.setIsHighlightWarningDialogVisible,
    isVisible: state.isHighlightWarningDialogVisible,
    setIsWarned: state.setIsUserWarnedAboutHighlights,
  }));

  return (
    <Dialog
      open={isVisible}
      onOpenChange={setIsVisible}
      dark={theme === "dark"}
      title="This is a beta feature"
      description="Highlighting is currently a beta feature, this means that it is still under development and unexpected behaviour may be seen while using it, if this occurs please just simply undo your last highlight from the 'Marker' menu on the top bar"
    >
      <div className="flex flex-row justify-end items-center mt-4">
        <div className="flex flex-row justify-start items-center gap-3">
          <Button
            className="p-1 px-2"
            error
            onClick={() => setIsVisible(false)}
          >
            Cancel
          </Button>
          <Button
            className="p-1 px-2"
            onClick={() => {
              setIsVisible(false);
              setIsWarned(true);
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

import { trpc } from "@/utils/trpc";
import useAddHighlightMarkers from "./use-add-highlightmarkers";
import { useEffect, useMemo } from "react";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useCurrentUser from "@/hooks/auth-hooks/use-current-user";
import { useAppStore } from "@/store";

const useHighlightMarkers = () => {
  const user = useCurrentUser();
  const highlightMarkers = useAppStore((state) => state.highlightMarkers);
  const addHighlightMarkers = useAddHighlightMarkers();
  const query = trpc.highlightMarker.getHighlightMarkers.useQuery();
  const { data, isLoading } = query;
  useEffect(() => {
    if (!!data && !isLoading) {
      addHighlightMarkers(data);
    }
  }, [data, isLoading]);
  const userHighlightMarkers = useMemo(() =>
    ArrayMapUtils.filter(
      highlightMarkers,
      (hm) => hm.userId === user.id || hm.userId === null,
    ), [highlightMarkers, user]);

  return {
    ...query,
    data: userHighlightMarkers,
  };
};

export default useHighlightMarkers;

import { WithTooltip } from "@/components/pages/dashboard";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { ComponentProps, PropsWithChildren, ReactNode } from "react";

type WindowTitlebarButtonProps =
  & PropsWithChildren
  & PropsWithClassName
  & ComponentProps<"button">
  & { tooltip?: ReactNode };
const WindowTitlebarButton = (
  { className, children, tooltip, ...props }: WindowTitlebarButtonProps,
) => {
  const Parent = tooltip ? WithTooltip : "div";
  return (
    <Parent tooltip={tooltip}>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <button
          className={cn(
            "w-5 h-5 inline-flex flex-col justify-center items-center text-white rounded-full shadow-md",
            className,
          )}
          {...props}
        >
          {children}
        </button>
      </div>
    </Parent>
  );
};

export default WindowTitlebarButton;

import { RichTextEditor } from "@mantine/tiptap";

export const ColorControls = () => {
  return (
    <>
      <RichTextEditor.ColorPicker
        colors={[
          "#25262b",
          "#868e96",
          "#fa5252",
          "#e64980",
          "#be4bdb",
          "#7950f2",
          "#4c6ef5",
          "#228be6",
          "#15aabf",
          "#12b886",
          "#40c057",
          "#82c91e",
          "#fab005",
          "#fd7e14",
        ]}
      />
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.Color color="#F03E3E" />
        <RichTextEditor.Color color="#228be6" />
        <RichTextEditor.Color color="#37B24D" />
      </RichTextEditor.ControlsGroup>
    </>
  );
};

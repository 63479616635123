import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";
import useTestPageTestSelectedTestSlot from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot";
import useTestPageTestSelectedTestSlotQuestion from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot-questiont";
import FloatingWindow from "@/components/floating-window";
import { useOnDesktop } from "@/hooks/common/useOnDesktop";
import { cn } from "@/lib/utils";
import { Dimension } from "@/types";
import { useTestStore } from "@/utils/stores";
import {
  useNbmeAlignment,
  useNbmeImageAlignment,
  useNbmeSplitView,
  useNbmeWindowsSettings,
} from "@/utils/stores/nbmeLayoutStore";
import {
  convertBlobToPng,
  copyBlobToClipboard,
  copyImageToClipboard,
} from "copy-image-clipboard";
import { Node } from "interweave";
import { useState } from "react";
import { toast } from "sonner";

const DraggableImageViewer = (
  { src, alt, dimensions }: {
    src: string;
    alt: string;
    dimensions?: Partial<Dimension>;
  },
) => {
  const onDesktop = useOnDesktop();
  const [splitView] = useNbmeSplitView();
  const [alignment] = useNbmeAlignment();
  const [imageAlignment] = useNbmeImageAlignment();
  const [settings] = useNbmeWindowsSettings();

  const { data: selected } = useTestPageTestSelectedTestSlot();
  const { data: test } = useTestPageTest();
  const mode = test?.mode;
  const submitted = test?.submitted;
  const { data: question } = useTestPageTestSelectedTestSlotQuestion();
  const shouldShowExplanation = selected?.submitted &&
    (mode?.includes("tutor") || (!mode?.includes("tutor") && submitted));

  const shouldShowSplitView = onDesktop && splitView &&
    shouldShowExplanation &&
    !question?.abstract;

  return (
    <FloatingWindow.Root
      onDownload={() => handleDownload(src)}
      isMaximizable={true}
      allowOffScreen={settings.allowOffScreen}
    >
      <div
        className={cn(
          "w-full",
          !onDesktop && "flex flex-row justify-center items-center",
          imageAlignment === "center" &&
            "lg:flex lg:justify-center lg:items-center",
          imageAlignment === "right" &&
            "lg:flex lg:justfiy-end lg:items-end",
        )}
      >
        <FloatingWindow.Toggle
          className={cn(
            "lg:max-w-lg md:max-w-md w-full my-5  hover:scale-[1.01] transition-[transform,_box-shadow] ease-in-out duration-500 hover:shadow-lg",
            alignment === "left" && imageAlignment === "right" &&
              "ms-auto",
            shouldShowSplitView && "lg:max-w-xl md:max-w-lg w-full",
          )}
        >
          <img
            src={src}
            alt={alt}
            className={"w-full rounded-lg"}
            style={{
              minWidth: dimensions?.width ?? undefined,
              minHeight: dimensions?.height ?? undefined,
            }}
          >
          </img>
        </FloatingWindow.Toggle>
      </div>

      <FloatingWindow.Body title={"Image"} className="z-[1000]">
        <FloatingWindow.Viewer>
          <img src={src} alt={alt} />
        </FloatingWindow.Viewer>
      </FloatingWindow.Body>
    </FloatingWindow.Root>
  );
};

export const imageTransformer =
  (dimensions?: { width?: number; height?: number }) =>
  (
    node: HTMLElement,
    children: Node[],
  ): React.ReactNode => {
    const isImage = node.tagName === "IMG";

    if (!isImage) return;
    const src = node.getAttribute("src") ?? "";
    const alt = node.getAttribute("alt") ?? "";

    return (
      <DraggableImageViewer
        src={src}
        alt={alt}
        key={src}
        dimensions={dimensions}
      />
    );
  };

function handleDownload(dataUri?: string, name?: string) {
  if (!dataUri) return;
  const a = document.createElement("a");
  a.href = dataUri;
  a.target = "_blank";
  a.download = name ?? "image";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { SystemSliceCreator, systemSliceState } from ".";
const createSystemSlice: SystemSliceCreator = (set) => {
  return {
    ...systemSliceState,
    addSystem: (system) =>
      set((state) => {
        ArrayMapUtils.push(
          state.systems,
          [system],
        );
      }),
    addSystems: (systems) =>
      set((state) => {
        ArrayMapUtils.push(
          state.systems,
          systems,
        );
      }),
  };
};

export default createSystemSlice;

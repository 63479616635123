import { InsertImageIcon } from "@/components/icons/hollow";
import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap";
export const InsertImage = () => {
  const { editor } = useRichTextEditorContext();
  return (
    <RichTextEditor.Control
      title="Insert Image"
      aria-label="Insert Image"
      disabled={!editor?.can().setImage({ src: "" })}
      onClick={() => {
        const src = window.prompt("Url");
        if (!src) return;

        editor?.commands.setImage({ src });
      }}
    >
      <InsertImageIcon className="w-4 h-4 text-neutral-800 dark:text-neutral-400" />
    </RichTextEditor.Control>
  );
};

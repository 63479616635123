import { HighlightMarker } from "@/models";
import { Setter } from "@/types";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type TestState = {
  selectedHighlightMarker?: HighlightMarker;
  setSelectedHighlightMarker: Setter<TestState["selectedHighlightMarker"]>;
  canSubmit: boolean;
  setCanSubmit: Setter<TestState["canSubmit"]>;
};

export const useTestStore = create(immer<TestState>((set, get) => {
  return ({
    selectedHighlightMarker: undefined,
    setSelectedHighlightMarker: (hm) =>
      set((state) => {
        state.selectedHighlightMarker = hm;
      }),
    canSubmit: true,
    setCanSubmit: (v) =>
      set((state) => {
        state.canSubmit = v;
      }),
  });
}));

export const useCanSubmit = () => {
  const { canSubmit, setCanSubmit } = useTestStore((state) => ({
    canSubmit: state.canSubmit,
    setCanSubmit: state.setCanSubmit,
  }));
  return [canSubmit, setCanSubmit] as const;
};

import { PropsWithChildren } from "react";

interface NotFoundPageProps extends PropsWithChildren {
}

export const CoursologyErrorPage = ({ children }: NotFoundPageProps) => {
  return (
    <div className="min-w-[100vw] min-h-screen flex flex-col justify-center items-center px-3 gap-7">
      <div className="w-full flex flex-col justify-center items-center">
        <img
          className="w-60"
          src={"/coursology_logo.svg"}
          alt="coursology logo"
        />
      </div>
      <div className="flex flex-col justify-center items-center text-center">
        {children}
      </div>
    </div>
  );
};

import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

type HollowSolidHollowTransitionHollowProps =
  & PropsWithClassName
  & PropsWithChildren;
const HollowSolidHollowTransitionHollow = (
  { className, children }: HollowSolidHollowTransitionHollowProps,
) => {
  return (
    <div
      className={cn(
        "absolute inline-flex flex-row justify-center items-center m-auto group-hover/hollow-solid-transition:opacity-0 opacity-100 transition-opacity",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default HollowSolidHollowTransitionHollow;

import { useState } from "react";
import useResizableContext from "./use-resizable-context";
import { ResizableContext } from "../resizable-context";
import { useGesture } from "@use-gesture/react";
import { useViewportDimensions } from "@/utils/hooks";

const useResizableGesture = ({
  dragAxes,
  placement,
  invertResizeDirection,
  events,
}: {
  dragAxes: { x?: boolean; y?: boolean };
  placement:
    | ["top" | "bottom" | "left" | "right", null]
    | ["top" | "bottom", "left" | "right"];
  invertResizeDirection?: boolean;
} & Pick<ResizableContext, "events">) => {
  const {
    dimensions,
    setDimensions,
    isResizable,
    setIsResizing,
    maxDimensions,
    minDimensions,
    bodyRef,
  } = useResizableContext();
  const [initialDimensions, setInitialDimensions] = useState(dimensions);
  const viewport = useViewportDimensions();

  return useGesture({
    onDragStart: () => {
      if (!dimensions) {
        if (bodyRef && bodyRef.current) {
          const newDimensions = {
            x: bodyRef.current.offsetWidth,
            y: bodyRef.current.offsetHeight,
          };
          setDimensions(newDimensions);
          setInitialDimensions(newDimensions);
        }
      } else {
        setInitialDimensions(dimensions);
      }
      events?.onResizeStart?.();
      setIsResizing(true);
    },
    onDrag: ({ movement }) => {
      if (!dimensions || !initialDimensions) return;
      if (!isResizable) return;
      const [x, y] = movement;
      const dragAmount = { x, y };
      const dragThreshold = { x: 10, y: 10 };
      const dragDirection = {
        x: (placement.includes("left") ? -1 : 1) *
          (invertResizeDirection ? -1 : 1),
        y: (placement.includes("top") ? -1 : 1) *
          (invertResizeDirection ? -1 : 1),
      };
      if (
        !(
          dragAmount.x > dragThreshold.x ||
          dragAmount.x < dragThreshold.x * -1 ||
          dragAmount.y > dragThreshold.y ||
          dragAmount.y < dragThreshold.y * -1
        )
      ) {
        return;
      }

      const newDimensions = {
        ...dimensions,
      };

      for (const dragAxis in dragAxes) {
        const typedDragAxis = dragAxis as "x" | "y";
        newDimensions[typedDragAxis] = initialDimensions[typedDragAxis] +
          dragAmount[typedDragAxis] * dragDirection[typedDragAxis];
      }

      const isMinimumReached = {
        x: newDimensions.x <= (minDimensions?.x ?? 0),
        y: newDimensions.y <= (minDimensions?.y ?? 0),
      };
      const isMaximumReached = {
        x: newDimensions.x >= (maxDimensions?.x ?? viewport.width),
        y: newDimensions.y >= (maxDimensions?.y ?? viewport.height),
      };

      setDimensions(newDimensions);
      events?.onResize?.({
        delta: dragAmount,
        isMaximumReached,
        isMinimumReached,
      });
    },
    onDragEnd: () => {
      setIsResizing(false);
      events?.onResizeEnd?.();
    },
  });
};

export default useResizableGesture;

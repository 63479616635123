import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { faHighlighter } from "@fortawesome/free-solid-svg-icons";
import { useNbmeLayoutStore, useTestStore } from "@/utils/stores";
import { NbmeHighlightMarkersMenu } from "./NbmeHighlightMarkersMenu";

export const NbmeHighlightMarkerButton = () => {
  const { selectedHighlightMarker } = useTestStore((state) => ({
    selectedHighlightMarker: state.selectedHighlightMarker,
  }));
  const { setHighlightMarkersVisible, highlightMarkersVisible } =
    useNbmeLayoutStore((state) => ({
      setHighlightMarkersVisible: state.setHighlightMarkersVisible,
      highlightMarkersVisible: state.highlightMarkersVisible,
    }));

  return (
    <div className="relative w-full md:w-auto">
      <NbmeIconNavbarButton
        className="w-full md:w-auto"
        icon={
          <FontAwesomeIcon
            style={{
              color: selectedHighlightMarker?.color || "white",
            }}
            icon={faHighlighter}
            className="w-8 h-8 transition-colors"
          />
        }
        label="Marker"
        labelClassName="transition-colors"
        labelStyle={{ color: selectedHighlightMarker?.color }}
        onClick={() => setHighlightMarkersVisible(!highlightMarkersVisible)}
      />
      <NbmeHighlightMarkersMenu />
    </div>
  );
};

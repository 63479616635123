"use client";
import { Context, useContext } from "react";

/** A modified version of useContext that requires its context to
 * be found, if not found it throws *errorMessage*
 * @param context - The context to be passed, just as
 * you would pass it to useContext.
 * @param errorMessage - The message that will be thrown with
 * the error in case the context was not found.
 */

const useRequiredContext = <T>(
  context: Context<T>,
  errorMessage?: string,
): NonNullable<T> => {
  const requiredContext = useContext(context);
  if (requiredContext === undefined) {
    throw Error(
      errorMessage ?? `This component can't be used outside its context`,
    );
  }

  return requiredContext!;
};

export default useRequiredContext;

import { Button } from "@/components/Button";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNotebookUiSidebar, useNotebookUiVariant } from "@/hooks/notebook";

export const OpenNotesButton = () => {
  const [variant] = useNotebookUiVariant();
  const [_, setIsSidebarOpen] = useNotebookUiSidebar();
  if (variant === "desktop") return <></>;
  return (
    <div className="flex flex-row justify-center items-center w-full z-[90]">
      <Button
        onClick={() => {
          setIsSidebarOpen(true);
        }}
        className="flex flex-row justify-center items-center gap-2"
      >
        <FontAwesomeIcon icon={faBook} />
        Open Notes
      </Button>
    </div>
  );
};

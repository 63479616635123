import { trpc } from "@/utils/trpc";
import useAddQuestionExplanations from "./use-add-questionexplanations";
import useQuestionExplanations from "./use-questionexplanations";
import { Test } from "@/models/test-model";
import useTest from "@/hooks/test-hooks/use-test";
import useTestTestSlots from "@/hooks/test-slot-hooks/use-test-test-slots";
import { useEffect, useMemo } from "react";
import mergeFlags from "@/utils/common/merge-flags";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const useTestQuestionExplanations = (
  { testId, slots }: { testId: Test["id"]; slots?: number[] },
) => {
  const addQuestionExplanations = useAddQuestionExplanations();
  const questionExplanations = useQuestionExplanations();
  const testQuery = useTest({ testId });
  const testSlotsQuery = useTestTestSlots({ testId });
  const { data: testSlots } = testSlotsQuery;
  const { data: test } = testQuery;
  const query = trpc.questionExplanation.getTestQuestionExplanations.useQuery({
    testId,
    slots,
  }, { enabled: !!test });
  const { data, isLoading } = query;

  useEffect(() => {
    if (!!data && !isLoading) {
      // @ts-ignore TYPE INSTANTIATION IS EXCESSIVELY DEEP AND POSSIBLY INFINITE
      addQuestionExplanations(data);
    }
  }, [data, isLoading]);
  const testQuestionExplanations = useMemo(() =>
    ArrayMapUtils.filter(
      questionExplanations,
      (explanation) =>
        ArrayMapUtils.includes(
          testSlots,
          (slot) => slot.questionId === explanation.questionId,
        ),
    ), [questionExplanations, testSlots]);

  return useMemo(() => (
    {
      ...mergeFlags({
        source: testQuery,
        destinations: [testSlotsQuery, query],
      }),
      data: testQuestionExplanations,
    }
  ), [
    testQuestionExplanations,
    testQuery,
    query,
  ]);
};

export default useTestQuestionExplanations;

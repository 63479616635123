import { InsertRowAfterIcon } from "@/components/icons/hollow";
import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap";

export const InsertRowAfter = () => {
  const { editor } = useRichTextEditorContext();
  return (
    <RichTextEditor.Control
      title="Insert Row After"
      aria-label="Insert Row After"
      disabled={!editor?.can().addRowAfter()}
      onClick={() => editor?.commands.addRowAfter()}
    >
      <InsertRowAfterIcon className="w-4 h-4 text-neutral-800 dark:text-neutral-400" />
    </RichTextEditor.Control>
  );
};

import { Dimension } from "@/types";
import useDocument from "@/hooks/use-document";
import { useCallback, useEffect, useState } from "react";

export const useViewportDimensions = () => {
  const doc = useDocument();

  const getCurrentDimension = useCallback(() => {
    return {
      width: doc?.body.clientWidth ?? 0,
      height: doc?.body.clientHeight ?? 0,
    };
  }, [doc]);

  const [screenSize, setScreenSize] = useState<Dimension>(
    getCurrentDimension(),
  );

  useEffect(() => {
    const updateDimension = () => {
      const newDimensions = getCurrentDimension();
      setScreenSize(newDimensions);
    };
    updateDimension();
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [setScreenSize, doc, getCurrentDimension]);
  return screenSize;
};

import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PerformanceTestPageLink } from ".";

export const PerformanceTestPageNotesButton = () => {
  return <></>;
  return (
    <PerformanceTestPageLink
      type="button"
      onClick={() => console.log("clicked")}
    >
      <div className="flex flex-row justify-center items-center gap-2">
        <FontAwesomeIcon icon={faClipboardCheck} />
        <p>Notes</p>
      </div>
    </PerformanceTestPageLink>
  );
};

import { useNotebookUi } from ".";

export const useCollapsedItems = () => {
  const { collapseItem, retractItem, collapsedItems, setCollapsedItems } =
    useNotebookUi((
      state,
    ) => ({
      collapseItem: state.collapseItem,
      retractItem: state.retractItem,
      collapsedItems: state.collapsedItems,
      setCollapsedItems: state.setCollapsedItems,
    }));
  return { collapseItem, retractItem, collapsedItems, setCollapsedItems };
};

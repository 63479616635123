import toast from "@/lib/client/toast";
import { TRPCError } from "@trpc/server";

const handleTRPCMutationError = <T>(
  { mutationDescription }: { mutationDescription: string },
  next: (error?: TRPCError) => T,
) =>
(error: any | TRPCError) => {
  const result = next(error);
  toast.error(
    `Could not ${mutationDescription}, an error occurred: ${error.message}`,
  );
  return result;
};

export default handleTRPCMutationError;

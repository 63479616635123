import { Button } from "@/components/Button";
import Dialog from "@/components/Dialog";
import { useSelectedNote, useUnsavedChangesDialog } from "@/hooks/notebook";

export const UnsavedChangesDialog = () => {
  const { isVisible, setIsVisible, onConfirm, onCancel } =
    useUnsavedChangesDialog();
  const selectedNote = useSelectedNote();
  if (!selectedNote) return <></>;
  return (
    <Dialog
      onOpenChange={setIsVisible}
      open={isVisible}
      title="Unsaved changes"
      description={`You have unsaved changes in note: "${selectedNote.title}", do you want to save them before switching to a different note?`}
    >
      <div className="flex flex-row justify-end items-center w-full mt-3">
        <div className="flex flex-row justify-start items-center gap-3">
          <Button error onClick={onCancel}>Discard</Button>
          <Button onClick={onConfirm}>Save</Button>
        </div>
      </div>
    </Dialog>
  );
};

import { useTriggerableState } from "@/components/triggerable";
import usePosition from "@/hooks/use-position";
import useViewportClampedPoint from "@/hooks/use-viewport-clamped-point";
import { ArgumentTypes } from "@/types";
import { useOnClickOutside } from "@/utils/hooks";
import React, { HTMLAttributes, useRef, useState } from "react";

type ContextMenuStateDefaults =
  & {}
  & ArgumentTypes<typeof useTriggerableState>[0];
const useContextMenuState = (defaults?: ContextMenuStateDefaults) => {
  const triggerable = useTriggerableState(defaults);

  const rootRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(rootRef, () => {
    triggerable.setIsOpen(false);
  });

  const areaRef = useRef<HTMLDivElement | null>(null);

  const [position, setPosition] = useViewportClampedPoint({ x: 0, y: 0 });

  const refreshBodyPosition = (e: React.MouseEvent) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  return {
    ...triggerable,
    rootRef,
    areaRef,
    bodyPosition: position,
    areaEvents: {
      onClick: refreshBodyPosition,
      onContextMenu: (e) => {
        e.preventDefault();
        triggerable.setIsOpen(true);
        refreshBodyPosition(e);
      },
    } as HTMLAttributes<HTMLDivElement>,
  };
};

export default useContextMenuState;

import useTestPageTestSelectedTestSlotQuestion from "./use-test-page-test-selected-test-slot-questiont";

const useTestPageTestSelectedTestSlotQuestionHighlights = () => {
  const selectedQuestion = useTestPageTestSelectedTestSlotQuestion();

  return {
    ...selectedQuestion,
    data: selectedQuestion.data?.highlights,
  };
};

export default useTestPageTestSelectedTestSlotQuestionHighlights;

import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankTests from "./use-question-bank-tests";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const useQuestionBankTestStatistics = (args: QuestionBankIdentifier) => {
  const query = useQuestionBankTests(args);
  const { data: tests } = query;
  const statistics = {
    total: 0,
    suspended: 0,
    completed: 0,
  };

  ArrayMapUtils.forEach(tests, (test) => {
    statistics.total++;
    if (test.suspended) statistics.suspended++;
    if (test.submitted) statistics.completed++;
  });

  return { ...query, data: statistics };
};

export default useQuestionBankTestStatistics;

import {
  faClipboardQuestion,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { DashboardCenterPiece } from "@/components/DashboardCenterPiece";

export const SearchQuestionsPageNoQueryCenterPiece = () => {
  return (
    <div className="absolute top-[0.5] left-[0.5] flex flex-col justify-center items-center h-full w-full">
      <DashboardCenterPiece
        icons={[faClipboardQuestion, faMagnifyingGlass]}
        label={"Please Enter a keyword to find previously used questions!"}
      />
    </div>
  );
};

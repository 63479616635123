import TriggerableBody from "./triggerable-body";
import TriggerableClose from "./triggerable-close";
import TriggerableRoot from "./triggerable-root";
import TriggerableToggle from "./triggerable-toggle";
import TriggerableTrigger from "./triggerable-trigger";

export { default as TriggerableBody } from "./triggerable-body";
export { default as TriggerableClose } from "./triggerable-close";
export { default as TriggerableRoot } from "./triggerable-root";
export { default as TriggerableToggle } from "./triggerable-toggle";
export { default as TriggerableTrigger } from "./triggerable-trigger";

export { default as useTriggerableContext } from "./hooks/use-triggerable-context";
export { default as useTriggerableState } from "./hooks/use-triggerable-state";

const Triggerable = {
  Root: TriggerableRoot,
  Body: TriggerableBody,
  Trigger: TriggerableTrigger,
  Close: TriggerableClose,
  Toggle: TriggerableToggle,
};

export default Triggerable;

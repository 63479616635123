import { SliceModifiers, UnmountedAction } from "@/store/types";
import { StateCreator } from "zustand";

//I still don't know I was able to pull this off
//Lord help you if you have to ever read this again...

export const mountActions = <
  T extends unknown,
  Y extends StateCreator<T, any, any, T>,
  X extends { [property in keyof T]: UnmountedAction<Y> },
>(
  actions: X,
  modifiers: SliceModifiers<Y>,
) => {
  const result: Partial<
    {
      [Property in keyof X]: (
        args: any,
      ) => void;
    }
  > = {};
  for (const key of Object.keys(actions) as (keyof X)[]) {
    result[key] = actions[key as keyof T]!(modifiers);
  }

  return result as Required<typeof result>;
};

import { usePerformanceReportsPageReportOptions } from "../-hooks";
import { DashboardPerformanceSelect } from "../../../-components";

export const PerformanceReportsPageSelect = () => {
  const { options, type, setType, setOptions } =
    usePerformanceReportsPageReportOptions();

  return (
    <DashboardPerformanceSelect
      withMarked={false}
      showTypeSelect={true}
      type={type}
      items={options}
      onItemsChange={setOptions}
      onTypeChange={setType}
    />
  );
};

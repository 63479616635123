import { CoursologyErrorPage } from "./CoursologyErrorPage";

export function ErrorPage({ error }: { error: string }) {
  return (
    <CoursologyErrorPage>
      <h1 className="font-semibold text-xl dark:text-neutral-100">
        {"Looks like an error occurred"}
      </h1>
      <p className="text-red-500 font-bold text-lg dark:text-neutral-500">
        {error}
      </p>
      <p className="text-gray-800 text-lg dark:text-neutral-500">
        Please report this error to us as soon as possible
      </p>
    </CoursologyErrorPage>
  );
}

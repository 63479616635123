import { RichTextEditor } from "@mantine/tiptap";

export const UndoRedoControls = () => {
  return (
    <RichTextEditor.ControlsGroup>
      <RichTextEditor.Undo />
      <RichTextEditor.Redo />
    </RichTextEditor.ControlsGroup>
  );
};

import { isInactive } from ".";

export const getStrokeClassNames = (activePercentage: number): string[] => {
  return [
    `stroke-lime-500 hover:stroke-lime-500 dark:stroke-lime-600 ${
      isInactive(activePercentage, 0)
        ? "stroke-lime-100 dark:stroke-lime-800 dark:hover:stroke-lime-500"
        : ""
    }`,
    `stroke-red-500 hover:stroke-red-500 ${
      isInactive(activePercentage, 1)
        ? "stroke-red-100 dark:stroke-red-800 dark:hover:stroke-red-500"
        : ""
    }`,
    `stroke-sky-500 hover:stroke-sky-500 ${
      isInactive(activePercentage, 2)
        ? "stroke-sky-100 dark:stroke-sky-800 dark:hover:stroke-sky-500"
        : ""
    }`,
  ];
};

import { CopyToClipboardIcon } from "@/components/icons/hollow";
import { WithTooltip } from "@/components/pages/dashboard";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";

export const CopyQuestionIdsButton = (
  { questionIds }: { questionIds: number[] },
) => {
  return (
    <WithTooltip tooltip="Copy Question Ids">
      <Button
        className="p-0 px-0 w-auto hover:text-primary-400"
        variant={"ghost"}
        size={"icon"}
        onClick={handleCopy}
      >
        <CopyToClipboardIcon />
      </Button>
    </WithTooltip>
  );

  function handleCopy() {
    navigator.clipboard.writeText(questionIds.join(","));
    toast.success(`Question Ids copied to clipboard!`);
  }
};

import React from "react";
import Dialog from "./Dialog";
import {
  useNbmeLayoutStore,
  useNbmeTheme,
} from "@/utils/stores/nbmeLayoutStore";
import { Button } from "./Button";
import useTestPageSubmitTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-submit-test";
import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";
import { useNavigate } from "@tanstack/react-router";

export const NbmeEndTestDialog = (
  { questionBankEndpoint }: { questionBankEndpoint: string },
) => {
  const [theme] = useNbmeTheme();

  const { dialogVisible, setDialogVisible } = useNbmeLayoutStore((state) => ({
    dialogVisible: state.endTestDialogVisible,
    setDialogVisible: state.setEndTestDialogVisible,
  }));
  const navigate = useNavigate();
  const submitTest = useTestPageSubmitTest();
  const { data: test } = useTestPageTest();
  const submitted = test?.submitted;
  return (
    <Dialog
      dark={theme === "dark"}
      title={submitted ? "End Review?" : "End Block?"}
      description={submitted
        ? 'You can return to it any time in the "Previous Tests" page in your dashboard.'
        : "You will not be able to change your answers after ending the test, and any unanswered questions will be omitted"}
      open={dialogVisible}
      onOpenChange={setDialogVisible}
      onCloseClicked={() => setDialogVisible(false)}
      className="dark:bg-nbme-primary-dark-900 dark:border-nbme-primary-dark-600"
    >
      <div className="flex flex-row justify-end items-center mt-4">
        <div className="flex flex-row justify-start items-center gap-3">
          <Button
            className="p-1 px-2"
            variant="dashboard"
            onClick={() => setDialogVisible(false)}
          >
            Cancel
          </Button>
          <Button
            className="p-1 px-2"
            error
            onClick={() => {
              setDialogVisible(false);
              return submitted
                ? navigate(
                  {
                    from: "/tests/$testId",

                    to:
                      `/qbanks/${questionBankEndpoint}/dashboard/previous-tests`,
                  },
                )
                : submitTest();
            }}
          >
            End
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

import { useSelectedNote } from "@/hooks/notebook";
import { Item } from "./components";

export const BreadCrumb = () => {
  const selectedNote = useSelectedNote();

  return (
    <div className="flex flex-row justify-start items-center gap-0.5">
      {selectedNote && (
        <Item
          title={selectedNote.title}
          parentId={selectedNote.parentId}
          pageId={selectedNote.pageId}
        />
      )}
    </div>
  );
};

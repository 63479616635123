import useGetSystemTopics from "./use-get-system-topics";
import { QuestionBankIdentifier } from "@/types/question-bank-types";

const useQuestionBankSystemTopics = (
  args: QuestionBankIdentifier & { systemId: string },
) => {
  const getSystemTopicsQuery = useGetSystemTopics(args);
  const { data: getSystemTopics } = getSystemTopicsQuery;
  return { ...getSystemTopicsQuery, data: getSystemTopics(args.systemId) };
};

export default useQuestionBankSystemTopics;

import { SearchInput } from "@/components/SearchInput";
import { usePerformanceReportsPageQuery } from "../-hooks";

export const PerformanceReportsPageQueryInput = () => {
  const { query, setQuery } = usePerformanceReportsPageQuery();

  return (
    <SearchInput
      value={query}
      placeholder="Search"
      onChange={(e) => setQuery(e.target.value)}
      variant="dashboard"
      className={"warm:bg-white"}
    />
  );
};

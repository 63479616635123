import { BreakPoints } from "@/types";
import { useBreakPoint } from "@/utils/hooks";
import { useEffect, useState } from "react";

export const useOnDesktop = () => {
  const breakPoint = useBreakPoint();
  const checkIfOnDesktop = () => breakPoint >= BreakPoints.Large;
  const [onDesktop, setOnDesktop] = useState<boolean>(checkIfOnDesktop());

  useEffect(() => {
    setOnDesktop(checkIfOnDesktop());
  }, [breakPoint]);
  return onDesktop;
};

import { createFileRoute } from "@tanstack/react-router";
import CreateTestQNumSection from "@/components/CreateTestQNumSection";
import CreateTestSubjectsSection from "@/components/CreateTestSubjectsSection";
import CreateTestSystemsSection from "@/components/CreateTestSystemsSection";
import useQuestionMode from "../-hooks/use-question-mode";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/create-test/_create-test/standard",
)({
  component: CreateStandardTestPage,
});

function CreateStandardTestPage() {
  useQuestionMode("standard");

  return (
    <>
      <CreateTestSubjectsSection />
      <CreateTestSystemsSection />
      <CreateTestQNumSection />
    </>
  );
}

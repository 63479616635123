import createTestBlueprintSlice from "./create-testblueprint-slice";
import { TestBlueprint } from "@/models/testblueprint-model";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";

export type TestBlueprintSliceActions = {
  addTestBlueprint: (testBlueprint: TestBlueprint) => void;
  addTestBlueprints: (testBlueprints: TestBlueprint[]) => void;
  setBlueprintUseStatus: (
    args: { blueprintId: TestBlueprint["id"]; used: boolean },
  ) => void;
  useBlueprint: (args: { blueprintId: TestBlueprint["id"] }) => void;
  unuseBlueprint: (args: { blueprintId: TestBlueprint["id"] }) => void;
};
export type TestBlueprintSliceState = {
  testBlueprints: ArrayMap<TestBlueprint>;
};

export type TestBlueprintSlice =
  & TestBlueprintSliceState
  & TestBlueprintSliceActions;

export type TestBlueprintSliceCreator = StateCreator<
  TestBlueprintSlice,
  [["zustand/immer", never]],
  [],
  TestBlueprintSlice
>;

export type TestBlueprintSliceModifiers = SliceModifiers<
  TestBlueprintSliceCreator
>;

export type TestBlueprintSliceAction = (
  ...modifiers: TestBlueprintSliceModifiers
) => void;

export const testBlueprintSliceState: TestBlueprintSliceState = {
  testBlueprints: {},
};

export default createTestBlueprintSlice;

import { KeyboardEventHandler } from "react";
import { handleNewTag } from ".";

const checkIfValueIsEmpty = (value: string) => {
  return !value;
};

const BACKSPACE = "Backspace";
const SPACE = " ";
const ENTER = "Enter";
const ARROW_UP = "ArrowUp";
const ARROW_DOWN = "ArrowDown";

export const handleKeyPressed = (
  {
    value,
    setValue,
    onTagAdd,
    onLastTagRemove,
    autocompleteValue: autoCompleteValue,
    onSelectedAutocompleteIndexChange,
    selectedAutocompleteTagIndex,
  }: {
    value: string;
    setValue: (str: string) => void;
    onTagAdd: (tag: string) => void;
    onLastTagRemove?: () => void;
    onSelectedAutocompleteIndexChange: (newIndex: number) => void;
    selectedAutocompleteTagIndex: number;
    autocompleteValue: string;
  },
): KeyboardEventHandler<HTMLInputElement> =>
(e) => {
  const { key } = e;
  const valueIsEmpty = checkIfValueIsEmpty(value);
  if (valueIsEmpty) {
    if (key === BACKSPACE) return onLastTagRemove?.();
  }
  switch (key) {
    case SPACE:
      return handleNewTag(value)({
        setValue,
        next: onTagAdd,
      });
    case ENTER:
      return handleNewTag(autoCompleteValue || value)({
        setValue,
        next: onTagAdd,
      });
    case ARROW_UP:
      return onSelectedAutocompleteIndexChange(
        selectedAutocompleteTagIndex + 1,
      );
    case ARROW_DOWN:
      return onSelectedAutocompleteIndexChange(
        selectedAutocompleteTagIndex - 1,
      );
  }
};

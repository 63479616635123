import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { SubjectSliceCreator, subjectSliceState } from ".";

const createSubjectSlice: SubjectSliceCreator = (set) => {
  return {
    ...subjectSliceState,
    addSubject: (subject) =>
      set((state) => {
        ArrayMapUtils.push(
          state.subjects,
          [subject],
        );
      }),
    addSubjects: (subjects) =>
      set((state) => {
        ArrayMapUtils.push(
          state.subjects,
          subjects,
        );
      }),
  };
};

export default createSubjectSlice;

import useTestPageTestSelectedTestSlotQuestion from "./use-test-page-test-selected-test-slot-questiont";

const useTestPageSelectedTestSlotQuestionHints = () => {
  const questionQuery = useTestPageTestSelectedTestSlotQuestion();
  const { data: question } = questionQuery;
  return {
    ...questionQuery,
    data: question?.hints,
  };
};

export default useTestPageSelectedTestSlotQuestionHints;

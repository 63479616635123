import { Note, NotebookPage } from "@/models";

export const state = {
  isUnsavedChangesDialogVisible: false,
  isConfirmDeleteDialogVisible: false,
  variant: "desktop" as "mobile" | "desktop",
  isSidebarOpen: false,
  onUnsavedChangesDialogConfirm: () => undefined as void,
  onUnsavedChangesDialogCancel: () => undefined as void,
  onConfirmDeleteDialogConfirm: () => undefined as void,
  confirmDeleteDialogTarget: undefined as Note | NotebookPage | undefined,
  onConfirmDeleteDialogCancel: () => undefined as void,
  collapsedItems: [] as string[],
  search: "" as string,
  tagInputValue: "",
};

import { sharpTransition } from "@/assets/framer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOnClickOutside } from "@/utils/hooks";
import { motion } from "framer-motion";
import { useRef } from "react";
import { twMerge } from "tailwind-merge";
import {
  NbmeSettingsSidebarAppearanceSection,
  NbmeSettingsSidebarFeaturesSection,
  NbmeSettingsSidebarNightModeSection,
} from ".";
import { NbmeSettingsSidebarAppearanceSectionProps } from "./NbmeSettingsSidebarAppearanceSection";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { NbmeSettingsSidebarNightModeSectionProps } from "./NbmeSettingsSidebarNightModeSection";
import { NbmeSettingsSidebarFeaturesSectionProps } from "./NbmeSettingsSidebarFeaturesSection";

export interface NbmeSettingsSidebarProps<T = "test" | "question">
  extends
    NbmeSettingsSidebarAppearanceSectionProps<T>,
    NbmeSettingsSidebarNightModeSectionProps,
    NbmeSettingsSidebarFeaturesSectionProps<T> {
  isVisible: boolean;
  onIsVisibleChange: (v: boolean) => void;
  variant: T;
}

export const NbmeSettingsSidebar = <T = "test" | "question">(
  { onIsVisibleChange, variant, isVisible, ...props }: NbmeSettingsSidebarProps<
    T
  >,
) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    onIsVisibleChange(false);
  });

  return (
    <motion.div
      ref={ref}
      className={twMerge(
        "fixed right-0 min-h-screen h-full p-3 py-4 bg-nbme-primary-50 dark:bg-gray-100 min-w-[20rem] max-w-xs z-[999] flex flex-col justify-start items-center gap-5",
        "border-l border-l-gray-300 transition-colors",
      )}
      initial={{ x: "100%" }}
      animate={{ x: isVisible ? 0 : "100%" }}
      transition={sharpTransition}
    >
      <div className="relative flex flex-row justify-center items-center w-full">
        <p className="w-full text-sm text-center font-bold text-gray-800">
          Settings
        </p>
        <button
          className="absolute right-0"
          onClick={() => onIsVisibleChange(false)}
        >
          <FontAwesomeIcon
            className="w-6 h-6 dark:text-black text-nbme-primary-600 transition-colors"
            icon={faXmarkCircle}
          />
        </button>
      </div>
      <div className="flex flex-col justify-start items-center gap-2 w-full">
        <NbmeSettingsSidebarAppearanceSection variant={variant} {...props} />
        <NbmeSettingsSidebarNightModeSection {...props} />
        <NbmeSettingsSidebarFeaturesSection variant={variant} {...props} />
      </div>
    </motion.div>
  );
};

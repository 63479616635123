import { Combobox as HeadlessCombobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import React, { ComponentProps, Fragment } from "react";
import { twMerge } from "tailwind-merge";

type ByComparator = ComponentProps<typeof Combobox>["by"];

interface ComboboxProps<T = any> {
  items: T[];
  value: any;
  by?: keyof T;
  onChange: (newValue: T | T[]) => void;
  onInputChange?: React.ChangeEventHandler<HTMLInputElement>;
  displayValue: ((selectedItem: T) => string) | undefined;
  itemFields: {
    key: keyof T;
    value?: keyof T;
    name: keyof T;
  };

  error?: boolean;
  placeholder?: string;
  className?: string;
}

export const Combobox = <T = any>(
  {
    placeholder,
    value,
    by,
    onChange,
    items,
    displayValue,
    itemFields,
    onInputChange,
    error,
    className,
  }: ComboboxProps<T>,
) => {
  return (
    <div
      className={twMerge(
        "relative border border-primary-500 dark:border-neutral-800 rounded-primary px-2 py-3 w-full",
        error && "border-red-500",
        className,
      )}
    >
      <HeadlessCombobox
        value={value ?? {}}
        by={by as ByComparator}
        onChange={onChange}
      >
        <div className="flex flex-row justify-between items-center w-full">
          <HeadlessCombobox.Input
            className={"w-full outline-none dark:bg-neutral-900 rounded-primary"}
            placeholder={placeholder}
            onChange={onInputChange}
            displayValue={displayValue}
          />
          <HeadlessCombobox.Button
            className={twMerge(
              "text-primary-500 dark:text-neutral-400 mr-2",
              error && "text-red-500",
            )}
          >
            <ChevronDownIcon />
          </HeadlessCombobox.Button>

          <Transition
            className={"absolute left-0 top-[105%] w-full border border-primary-500 dark:border-neutral-600 rounded-primary overflow-hidden max-h-32"}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <HeadlessCombobox.Options
              className={""}
            >
              {items.map((item) => (
                <HeadlessCombobox.Option
                  key={item[itemFields.key] as string}
                  value={itemFields.value ? item[itemFields.value] : item}
                  as={Fragment}
                >
                  {({ active, selected }) => (
                    <li
                      className={twMerge(
                        `flex flex-row justify-start items-center bg-white dark:bg-neutral-900 px-2 py-1`,
                        active && "bg-primary-500 text-white font-semibold",
                      )}
                    >
                      {item[itemFields.name] as string}
                      {selected && <CheckIcon />}
                    </li>
                  )}
                </HeadlessCombobox.Option>
              ))}
            </HeadlessCombobox.Options>
          </Transition>
        </div>
      </HeadlessCombobox>
    </div>
  );
};

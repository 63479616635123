import { useAppStore } from "@/store";
import { NoteAction } from "../../types";
import { deleteNote } from "./mutations";
import { toast } from "sonner";

export const removeNote: NoteAction = ([set]) => (id: string) => {
  const appState = useAppStore.getState();
  const { notes: oldNotes } = appState;
  const children = oldNotes.filter((n) => n.parentId === id);
  const hasChildren = !!children.length;
  const newNotes = oldNotes.filter((n) => {
    const isTargetNote = n.id === id;
    const isChildOfTargetNote = n.parentId === id;
    return !(isTargetNote || isChildOfTargetNote);
  });
  set((state) => {
    state.notes = newNotes;
  });
  deleteNote(id).catch((error) => {
    toast.error(`Could not delete note, an error occurred: ${error.message}`);
    set((state) => {
      state.notes = oldNotes;
    });
  }).then((deletedNote) => {
    toast.success(
      `Successfully deleted note: "${deletedNote!.title}" ${
        hasChildren ? `along with ${children.length} sub-note(s).` : ""
      }`,
    );
  });
};

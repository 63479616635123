import { WithTooltip } from "@/components/pages/dashboard";
import { PropsWithChildren, ReactNode } from "react";

type WindowIndicatorProps = { tooltip: ReactNode } & PropsWithChildren;
const WindowTitlebarIndicator = (
  { tooltip, children }: WindowIndicatorProps,
) => {
  return (
    <WithTooltip
      tooltip={tooltip}
      className="flex flex-row justify-center items-center"
    >
      <div className="rounded-sm hover:bg-black/10 dark:hover:bg-white/10 transition-colors w-6 h-6">
        {children}
      </div>
    </WithTooltip>
  );
};

export default WindowTitlebarIndicator;

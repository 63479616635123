import { twMerge } from "tailwind-merge";
import { XIcon } from "./components";

interface DeleteButtonProps {
  onClick: () => void;
  isVisible?: boolean;
}

export const DeleteButton = ({ onClick, isVisible }: DeleteButtonProps) => {
  return (
    <button
      className={twMerge(
        "text-transparent pointer-events-none transition-colors ml-3 text-sm",
        isVisible &&
          "text-gray-500 warm:text-brown-800 dark:text-neutral-300 pointer-events-auto",
      )}
      onClick={onClick}
    >
      <XIcon />
    </button>
  );
};

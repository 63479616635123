import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NbmeNavbarButtonWithIcon } from ".";
import { faCirclePause } from "@fortawesome/free-solid-svg-icons";

interface NbmeSuspendTestNavbarButtonProps {
  isSuspendTestDialogVisible: boolean;
  onIsSuspendTestDialogVisibleChange: (v: boolean) => void;
}

export const NbmeSuspendTestNavbarButton = (
  { isSuspendTestDialogVisible, onIsSuspendTestDialogVisibleChange }:
    NbmeSuspendTestNavbarButtonProps,
) => {
  return (
    <NbmeNavbarButtonWithIcon
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faCirclePause} className="w-6 h-6" />}
      label="Suspend"
      onClick={() =>
        onIsSuspendTestDialogVisibleChange(!isSuspendTestDialogVisible)}
    />
  );
};

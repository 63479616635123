import useQuestionBankQuestionSystem from "@/hooks/system-hooks/use-questionbank-question-system";
import useTestPageTest from "./use-test-page-test";
import useTestPageTestSelectedTestSlotQuestion from "./use-test-page-test-selected-test-slot-questiont";
import mergeFlags from "@/utils/common/merge-flags";

const useTestPageTestSelectedTestSlotQuestionSystem = () => {
  const test = useTestPageTest();
  const question = useTestPageTestSelectedTestSlotQuestion();
  const system = useQuestionBankQuestionSystem({
    questionBankId: test.data?.questionBankId ?? "",
    questionId: question.data?.id ?? 0,
  });
  return {
    ...mergeFlags({ source: test, destinations: [question, system] }),
    data: system.data,
  };
};

export default useTestPageTestSelectedTestSlotQuestionSystem;

import HollowSolidHollowTransitionHollow from "./hollow-solid-transition-hollow";
import HollowSolidTransitionRoot from "./hollow-solid-transition-root";
import HollowSolidTransitionSolid from "./hollow-solid-transition-solid";

export { default as HollowSolidHollowTransitionHollow } from "./hollow-solid-transition-hollow";
export { default as HollowSolidTransitionRoot } from "./hollow-solid-transition-root";
export { default as HollowSolidTransitionSolid } from "./hollow-solid-transition-solid";

const HollowSolidTransition = {
  Root: HollowSolidTransitionRoot,
  Hollow: HollowSolidHollowTransitionHollow,
  Solid: HollowSolidTransitionSolid,
};

export default HollowSolidTransition;

import { ToolTip as CommonToolTip } from "@/components/common/ToolTip";
import { twMerge } from "tailwind-merge";
interface ToolTipProps {
  title: string;
}
export const ToolTip = ({ title }: ToolTipProps) => {
  return (
    <CommonToolTip
      className={twMerge(
        "opacity-0 scale-90 group-hover:opacity-100 group-hover:scale-100 transition-[opacity,_transform] pointer-events-none translate-y-[-110%]",
      )}
    >
      {title}
    </CommonToolTip>
  );
};

import { DashboardTable } from "@/components/DashboardTable";
import { useReportOptions } from "../../-hooks";
import { ProgressBar } from "@/components/ProgressBar";
import { percent } from "@/utils/common/percent";
import useQuestionBankTestSubjects from "@/hooks/subject-hooks/use-question-bank-test-subjects";
import { Test } from "@/models/test-model";
import useTestQuestionHeaders from "@/hooks/question-hooks/use-test-question-headers";
import useQuestionBankSubjectPerformanceReports from "@/hooks/question-hooks/use-question-bank-subject-performance-reports";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";

export const PerformanceTestAnalysisPageSubjectsTable = (
  { testId }: {
    testId: Test["id"];
  },
) => {
  const { data: questionBank } = useDashboardQuestionBank();
  const questionBankEndpoint = questionBank?.endpoint ?? "";
  const { options } = useReportOptions();
  const { data: testQuestionHeaders } = useTestQuestionHeaders({ testId });
  const { data: subjectsArrayMap } = useQuestionBankTestSubjects({
    testId,
    questionBankEndpoint,
  });

  const { data: subjects } = useQuestionBankSubjectPerformanceReports({
    questionBankEndpoint,
  }, {
    filters: {
      subjects: subjectsArrayMap,
      questionHeaders: testQuestionHeaders,
    },
  });

  return (
    <DashboardTable
      itemsPerPage={1000000}
      currentPage={1}
      items={subjects.sort((a, b) => a.name.localeCompare(b.name))}
      columnContentMapper={(column, content) => {
        if (column.name === "Name") {
          const { name, correct, incorrect, omitted, total } = content;
          return (
            <div className="flex flex-col justify-start items-start gap-3 w-[10rem] md:w-[20rem]">
              <p>{name}</p>
              <ProgressBar
                className="bg-white md:bg-gray-200"
                percentages={[
                  options.includes("Correct") ? percent(correct, total) : 0,
                  options.includes("Incorrect") ? percent(incorrect, total) : 0,
                  options.includes("Omitted") ? percent(omitted, total) : 0,
                ]}
                percentageClassNames={[
                  "bg-lime-500",
                  "bg-red-500",
                  "bg-sky-500",
                ]}
              />
            </div>
          );
        }
        return content;
      }}
      columns={{
        collapsible: true,
        columns: [{
          name: "Name",
          visible: true,
          content: (item) => ({
            name: item.name,
            total: item.total,
            correct: item.correct,
            incorrect: item.incorrect,
            omitted: item.omitted,
          }),
        }, {
          name: "Total Q",
          visible: true,
          content: (item) => item.total,
        }, {
          name: "Correct Q",
          visible: true,
          content: (item) => item.correct,
        }, {
          name: "Incorrect Q",
          visible: true,
          content: (item) => item.incorrect,
        }, {
          name: "Omitted Q",
          visible: true,
          content: (item) => item.omitted,
        }],
        collapsedColumns: [{
          name: "Name",
          visible: true,
          content: (item) => item.name,
        }, {
          name: "Total Q",
          visible: true,
          content: (item) => item.total,
        }, {
          name: "Correct Q",
          visible: true,
          content: (item) => item.correct,
        }, {
          name: "Incorrect Q",
          visible: true,
          content: (item) => item.incorrect,
        }, {
          name: "Omitted Q",
          visible: true,
          content: (item) => item.omitted,
        }],
      }}
      collapsedItems={(item) => item.children}
    />
  );
};

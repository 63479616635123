"use client";

import { Tabs } from "@/components/Tabs";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { useEffect, useState } from "react";

interface PerformanceTestPageTabsProps {
  testId: string;
}

export const PerformanceTestPageTabs = (
  { testId }: PerformanceTestPageTabsProps,
) => {
  const navigate = useNavigate();
  const pathname = useRouterState().location.pathname;

  const getCurrentTabFromPathname = (): "Test Results" | "Test Analysis" => {
    const isAnalysis = pathname.split("/").at(-1) === "analysis";
    if (isAnalysis) return "Test Analysis";
    else return "Test Results";
  };

  const getPathnameFromCurrentTab = (): string => {
    if (currentTab === "Test Analysis") {
      return [...pathname.split("/").slice(0, -1), "analysis"].join("/");
    } else return [...pathname.split("/").slice(0, -1), "results"].join("/");
  };

  const [currentTab, setCurrentTab] = useState<
    "Test Results" | "Test Analysis"
  >(getCurrentTabFromPathname());

  useEffect(() => {
    setCurrentTab(getCurrentTabFromPathname());
  }, [pathname, setCurrentTab]);

  useEffect(() => {
    navigate({ to: getPathnameFromCurrentTab() });
  }, [currentTab]);

  return (
    <div className="flex flex-col md:flex-row justify-between items-center md:items-end w-full text-sm md:text-base lg:text-lg gap-2 md:gap-0">
      <Tabs
        className="justify-center md:justify-start"
        variant="dashboard"
        currentTab={currentTab}
        tabs={["Test Results", "Test Analysis"]}
        onCurrentTabChange={(n) => setCurrentTab(n)}
      />
      <p className="py-2 md:border-b border-gray-300 dark:border-neutral-700 lg:text-sm md:text-sm text-xs text-end px-2">
        Custom Test Id: {testId}
      </p>
    </div>
  );
};

import { ScrollArea } from "@/components/common/ScrollArea";
import { ScrollbarProps as ScrollAreaProps } from "@/components/common/ScrollArea";
import { twMerge } from "tailwind-merge";

interface DashboardScrollAreaProps extends ScrollAreaProps {
}

export const DashboardScrollArea = (
  {
    className,
    cornerClassName,
    orientation = "vertical",
    scrollbarClassName,
    thumbClassName,
    viewportClassName,
    children,
    viewportStyle,
    rootStyle,
  }: DashboardScrollAreaProps,
) => {
  return (
    <ScrollArea
      viewportStyle={viewportStyle}
      rootStyle={rootStyle}
      className={twMerge("", className)}
      viewportClassName={twMerge(
        viewportClassName,
      )}
      cornerClassName={twMerge(cornerClassName)}
      thumbClassName={twMerge(
        "z-[1000] bg-gray-400 warm:bg-brown-400 dark:bg-neutral-600",
        thumbClassName,
      )}
      scrollbarClassName={twMerge(
        "data-[orientation=vertical]:w-2.5 hover:bg-gray-300 warm:hover:bg-brown-100 dark:hover:bg-neutral-800 rounded-none bg-transparent",
        scrollbarClassName,
      )}
      orientation={orientation}
    >
      {children}
    </ScrollArea>
  );
};

import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useGetQuestionBankTopicSystem from "./use-get-question-bank-topic-system";
import { Topic } from "@/models";

const useQuestionBankTopicSystem = (
  args: QuestionBankIdentifier & { topicId: Topic["id"] },
) => {
  const query = useGetQuestionBankTopicSystem(args);
  const { data: getQuestionBankTopicSystem } = query;
  return { ...query, data: getQuestionBankTopicSystem(args.topicId) };
};

export default useQuestionBankTopicSystem;

import { findTarget } from "@/store/utils";
import { Action } from "./types";
import { changeSubscriptionMutation } from "./mutations";
import { toast } from "sonner";

type Args = Parameters<typeof changeSubscriptionMutation>[0];

export const changeSubscription: Action = ([set, get]) => (args: Args) => {
  const { userId, newSubscriptionId } = args;
  const oldUsers = get().allUsers;
  const oldFilteredUsers = get().filteredUsers;
  const targetUser = findTarget(userId, oldUsers);
  if (!targetUser) {
    toast.error("User does not exist.");
    return;
  }
  set((state) => {
    findTarget(userId, state.allUsers)!.currentSubscriptionId =
      newSubscriptionId;
    const targetInFilteredUsers = findTarget(userId, state.filteredUsers);
    if (targetInFilteredUsers) {
      targetInFilteredUsers.currentSubscriptionId = newSubscriptionId;
    }
  });
  changeSubscriptionMutation(args).catch((error) => {
    toast.error(
      `Could not change subscription, an error occurred: ${error.message}`,
    );
    set((state) => {
      state.allUsers = oldUsers;
      state.filteredUsers = oldFilteredUsers;
    });
  }).then(() => {
    toast.success(`Successfully changed user's subscription`);
  });
};

import { ContextMenuToggle } from "@/components/context-menu";
import { cn } from "@/lib/utils";
import { ComponentProps } from "react";

const NbmeContextMenuToggle = (
  { className, children, ...props }: ComponentProps<typeof ContextMenuToggle>,
) => {
  return (
    <ContextMenuToggle className={cn(className)} {...props}>
      {children}
    </ContextMenuToggle>
  );
};

export default NbmeContextMenuToggle;

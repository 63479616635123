import { ContextMenuArea } from "@/components/context-menu";
import { cn } from "@/lib/utils";
import { ComponentProps } from "react";

const NbmeContextMenuArea = (
  { children, className, ...props }: ComponentProps<typeof ContextMenuArea>,
) => {
  return (
    <ContextMenuArea className={cn(className)} as={"span"} {...props}>
      {children}
    </ContextMenuArea>
  );
};

export default NbmeContextMenuArea;

import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankQuestionHeaders from "./use-question-bank-question-headers";
import { Subject } from "@/models";
import ArrayMapUtils, { ArrayMap } from "@/types/common/ArrayConvertableMap";
import { useCallback } from "react";
import { QuestionHeader } from "@/models/question-model";

type Opts = {
  filters?: { questionHeaders?: ArrayMap<QuestionHeader> };
  enabled?: boolean;
};
const useGetSubjectQuestionHeaders = (
  args: QuestionBankIdentifier,
  opts?: Opts,
) => {
  const questionBankQuestionHeadersQuery = useQuestionBankQuestionHeaders(
    args,
    { enabled: !opts?.filters?.questionHeaders && (opts?.enabled ?? true) },
  );
  const { data: internalQuestionHeaders } = questionBankQuestionHeadersQuery;
  const externalQuestionHeaders = opts?.filters?.questionHeaders;
  const questionHeaders = externalQuestionHeaders ?? internalQuestionHeaders;

  const getSubjectQuestionHeaders = useCallback((subjectId: Subject["id"]) => {
    const subjectQuestionHeaders = ArrayMapUtils.filter(
      questionHeaders,
      (qh) => qh.subjectId === subjectId,
    );
    return subjectQuestionHeaders;
  }, [questionHeaders]);

  return {
    ...questionBankQuestionHeadersQuery,
    data: getSubjectQuestionHeaders,
  };
};

export default useGetSubjectQuestionHeaders;

import { useAppStore } from "@/store";
import { Action, Actions } from "../../types";
import { constructNewTagLocally } from "./utils";
import { postTag } from "./mutations";
import { toast } from "sonner";

export const addTag: Action =
  ([set]) => ({ title, noteId }: Parameters<Actions["addTag"]>[0]) => {
    const user = useAppStore.getState().currentUser;
    if (!user) return;
    const { tags: oldTags } = useAppStore.getState();
    const preExistingTag = oldTags.find((t) => t.title === title);
    if (preExistingTag) return;
    const newTag = constructNewTagLocally({
      title,
      creatorId: user.id,
      noteId,
    });
    set((state) => {
      state.tags = [...oldTags, newTag];
    });
    postTag({ title, noteId }).catch((error) => {
      toast.error(`Could not add tag, an error ocurred: ${error.message}`);
      set((state) => {
        state.tags = oldTags;
      });
    }).then((newTag) => {
      if (!newTag) return;
      set((state) => {
        state.tags.find((t) => t.title === newTag!.title)!.id = newTag.id;
      });
    });
  };

import React from "react";

export const DashboardFooter = () => {
  return (
    <div className="w-full flex justify-center items-center border-t border-gray-200 dark:border-neutral-800 text-xs py-2.5 bg-white warm:bg-white dark:bg-neutral-900 z-[49] h-[2.1rem] transition-colors">
      <p className="w-full text-center">
        <span className="font-semibold text-gray-700 dark:text-neutral-100 transition-colors">
          Copyright
        </span>
        <span className="font-light text-gray-600 dark:text-neutral-50 transition-colors">
          {" "}© Coursology. All rights reserved
        </span>
      </p>
    </div>
  );
};

"use client";
import { useAppTheme } from "@/hooks";
import { EyeOpenIcon, MoonIcon, SunIcon } from "@radix-ui/react-icons";
import { Switch } from "./ui/switch";
import { Button } from "./ui/button";
import { cn } from "@/lib/utils";

export const DarkModeSwitch = ({ className }: { className?: string }) => {
  const [theme, setTheme] = useAppTheme();
  return (
    <div
      className={cn(
        "flex flex-row justify-start items-center text-gray-800 warm:text-brown-800 dark:text-neutral-800",
        className,
      )}
    >
      <Button variant={"ghost"} size={"icon"} onClick={() => setTheme("light")}>
        <SunIcon />
      </Button>
      <Button variant={"ghost"} size={"icon"} onClick={() => setTheme("dark")}>
        <MoonIcon />
      </Button>
      <Button variant={"ghost"} size={"icon"} onClick={() => setTheme("warm")}>
        <EyeOpenIcon />
      </Button>
    </div>
  );
};

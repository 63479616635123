import { useNbmePadding } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSlider } from "./NbmeSettingsSlider";

export const NbmeSettingsPaddingControl = () => {
  const [padding, setPadding] = useNbmePadding();

  return (
    <NbmeSettingsSlider
      value={(padding.question + padding.explanation) / 2}
      defaultValue={(padding.question + padding.explanation) / 2}
      onValueChange={(newValue) =>
        setPadding({ question: newValue, explanation: newValue })}
      max={100}
      min={0}
    />
  );
};

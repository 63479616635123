import useCreateTest from "@/hooks/test-hooks/use-create-test";
import useCreateTestPageQuestionHeaders from "./use-create-test-page-questions";
import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import useDashboardContext from "../../-dashboard-hooks/use-dashboard-context";
import { useCreateTestPageStore } from "@/utils/stores";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import _ from "underscore";
import { useCallback } from "react";
import useQuestionBankTests from "@/hooks/test-hooks/use-question-bank-tests";
import useAccountForQuestionBankQuestionVignettes from "@/hooks/question-hooks/use-account-for-questionbank-question-vignettes";
import { useNavigate } from "@tanstack/react-router";

const useCreateTestPageCreateTest = () => {
  const navigate = useNavigate();
  const {
    selectedSystems,
    selectedSubjects,
    selectedTopics,
    questionCount,
    pool,
    mode,
    qmode,
    minutesPerQuestion,
    questionIds,
    name,
    blueprint,
  } = useCreateTestPageStore((state) => ({
    selectedTopics: state.selectedTopics,
    questionIds: state.questionIds,
    selectedSubjects: state.selectedSubjects,
    name: state.name,
    blueprint: state.selectedBlueprint,
    selectedSystems: state.selectedSystems,
    questionCount: state.questionCount,
    minutesPerQuestion: state.minutesPerQuestion,
    qmode: state.qmode,
    mode: [
      ...(state.tutor ? ["tutor"] : []),
      ...(state.timed ? ["timed"] : []),
    ] as ("tutor" | "timed")[],
    pool: [
      ...(state.unused ? ["unused"] : []),
      ...(state.correct ? ["correct"] : []),
      ...(state.incorrect ? ["incorrect"] : []),
      ...(state.omitted ? ["omitted"] : []),
      ...(state.marked ? ["marked"] : []),
    ] as ("unused" | "correct" | "incorrect" | "marked" | "unused")[],
  }));

  const createTest = useCreateTest();
  const { questionBank } = useDashboardContext();
  const accountForVignettes = useAccountForQuestionBankQuestionVignettes({
    questionBankId: questionBank.data?.id ?? "",
  });
  const { data: questionBankTests } = useQuestionBankTests({
    questionBankId: questionBank.data?.id ?? "",
  });
  const { data: questionBankQuestionHeaders } = useQuestionBankQuestionHeaders({
    questionBankId: questionBank?.data?.id ?? "",
  });
  const { data: createTestPageQuestionHeaders } =
    useCreateTestPageQuestionHeaders();
  const { data: questionPool } = useQuestionBankQuestionHeaders({
    questionBankId: questionBank.data?.id ?? "",
  }, {
    filters: {
      questionHeaders: createTestPageQuestionHeaders,
      systems: selectedSystems.map((s) => s.id),
      topics: selectedTopics.map((t) => t.id),
      subjects: selectedSubjects.map((s) => s.id),
    },
  });
  const sampledQuestionPool = _.sample(
    ArrayMapUtils.toArray(questionPool),
    Number(questionCount),
  );
  return useCallback(async () => {
    const customQuestions = questionIds.map((quwid) =>
      ArrayMapUtils.find(
        questionBankQuestionHeaders,
        (qh) => qh.uWolrdId === quwid,
      )!
    ).filter((q) => q !== undefined);

    const blueprintQuestionCount =
      questionBank.data?.maximumQuestionCountPerTest ?? 40;
    const blueprintQuestionPoolStart = (blueprint?.order ?? 0) *
      blueprintQuestionCount;
    const blueprintQuestionPoolEnd = blueprintQuestionPoolStart +
      blueprintQuestionCount;
    const blueprintQuestionPool = blueprint
      ? ArrayMapUtils.sort(questionBankQuestionHeaders, (qh) => qh.order).slice(
        blueprintQuestionPoolStart,
        blueprintQuestionPoolEnd,
      )
      : [];
    const questions = accountForVignettes({
      questionHeaders: qmode === "standard"
        ? sampledQuestionPool
        : qmode === "custom"
        ? customQuestions
        : blueprintQuestionPool,
    }, { removeExcess: true });
    const questionBankId = qmode === "standard"
      ? sampledQuestionPool[0]?.questionBankId ?? ""
      : qmode === "custom"
      ? customQuestions[0]?.questionBankId ?? ""
      : (blueprint?.questionBankId ?? "");

    const newTest = await createTest({
      test: {
        name: qmode === "ordered"
          ? `Ordered Exam ${blueprint?.order ?? ""}`
          : name
          ? name
          : String(ArrayMapUtils.getLength(questionBankTests) + 1),
        parentId: null,
        questionBankId,
        blueprintId: qmode === "ordered" ? blueprint?.id ?? null : null,
        pool,
        mode,
        minutesPerQuestion,
      },
      testSlots: questions.map((q) => ({ questionId: q.id })),
    });

    newTest &&
      navigate({
        to: "/tests/$testId",
        params: { testId: String(newTest.id) },
      });
  }, [sampledQuestionPool, minutesPerQuestion, pool, mode]);
};

export default useCreateTestPageCreateTest;

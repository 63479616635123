import { twMerge } from "tailwind-merge";
import Switch, { SwitchProps } from "./Switch";
import React from "react";

export const NbmeSettingsSwitch = ({ className, ...props }: SwitchProps) => {
  return (
    <Switch
      checkedClassName="bg-nbme-primary-600"
      className={twMerge("scale-[85%]", className)}
      {...props}
    />
  );
};

import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";
import WindowTitlebarGroup from "./window-titlebar-group";

type WindowIndicatorsProps = PropsWithClassName & PropsWithChildren;

const WindowIndicators = ({ children, className }: WindowIndicatorsProps) => {
  return (
    <WindowTitlebarGroup
      className={cn(
        "left-0 gap-1",
        className,
      )}
    >
      {children}
    </WindowTitlebarGroup>
  );
};
export default WindowIndicators;

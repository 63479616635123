import useSuspendTest from "@/hooks/test-hooks/use-suspend-test";
import useTestPageTest from "./use-test-page-test";

const useTestPageSuspendTest = () => {
  const { data: test } = useTestPageTest();
  const suspendTest = useSuspendTest();
  return () => {
    test && suspendTest({ testId: test.id });
  };
};

export default useTestPageSuspendTest;

import useTestPageConstructedTest from "./use-test-page-constructed-test";

const useTestPageTestQuestionChoices = () => {
  const constructedTest = useTestPageConstructedTest();
  return {
    ...constructedTest,
    data: constructedTest.data?.slots.flatMap((s) => s.question.choices),
  };
};
export default useTestPageTestQuestionChoices;

import mergeHomogenousFunctions from "@/lib/utils/merge-homogenous-functions";
import useWindowContext from "./hooks/use-window-context";
import WindowTitlebarButton from "./window-titlebar-button";
import { ComponentProps } from "react";
import MinimizeIcon from "@/components/icons/minimize-icon";

type WindowMinimizeProps = Pick<
  ComponentProps<typeof WindowTitlebarButton>,
  "onClick"
>;

const WindowMinimize = ({ onClick }: WindowMinimizeProps) => {
  const { onMinimize } = useWindowContext();

  const combinedOnClick = mergeHomogenousFunctions(
    onMinimize,
    onClick as () => void,
  );

  return (
    <WindowTitlebarButton
      tooltip={"Minimize This Window"}
      className="bg-lime-500"
      onClick={() => combinedOnClick()}
    >
      <MinimizeIcon className="w-2.5 h-2.5" />
    </WindowTitlebarButton>
  );
};

export default WindowMinimize;

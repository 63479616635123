import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { Store } from "./types";
import { state } from "./state";
import * as actions from "./actions";
import { mountActions } from "@/store/utils";

export const useUsersDashboard = create(
  immer<Store>((...args) => {
    return ({
      ...state,
      ...mountActions(actions, args),
    });
  }),
);

import { Page } from "../../types";
import { TreeItem, TreeItemProps } from "..";
import { useCollapsedItems, useNoteActions } from "@/hooks/notebook";

type PageTreeProps = Page & {
  isEditMode?: boolean;
  onRemove: () => void;
  onEditConfirm: (
    page: Pick<
      Page,
      | "id"
      | "title"
    >,
  ) => void;
  onEditCancel?: () => void;
};

export const PageTree = (
  {
    notes,
    title,
    id,
    onRemove,
    onEditConfirm,
    isEditMode,
    onEditCancel,
  }: PageTreeProps,
) => {
  const { add: addNote, remove: removeNote, rename: renameNote } =
    useNoteActions();
  const { collapsedItems } = useCollapsedItems();
  const recursivelyMapChildren = (
    array: typeof notes,
  ): TreeItemProps["childItems"] => {
    if (array.length === 0) return [];
    return array!.map((n) => ({
      ...n,
      onChildAdd: (newTitle) => {
        addNote({ title: newTitle, pageId: id, parentId: n.id });
      },
      onEditCancel: () => undefined,
      onEditConfirm: (newTitle) => renameNote({ id: n.id, newTitle }),
      onRemove: () => removeNote(n.id),
      childItems: recursivelyMapChildren(n.children),
      isCollapsed: collapsedItems.includes(n.id),
    }));
  };

  const childItems = recursivelyMapChildren(notes.filter((n) => !n.parentId))!;

  const handleNoteAdd = (newTitle: string) => {
    addNote({ pageId: id, title: newTitle });
  };

  return (
    <TreeItem
      level={0}
      id={id!}
      title={title!}
      childItems={childItems}
      isSelectable={false}
      collapseOnSelect={true}
      onChildAdd={handleNoteAdd}
      isCollapsed={collapsedItems.includes(id)}
      onRemove={() => onRemove()}
      onEditCancel={() => onEditCancel?.()}
      onEditConfirm={(newItem) => onEditConfirm({ id, title: newItem })}
      isEditMode={isEditMode}
    />
  );
};

import { inferProcedureInput } from "@trpc/server";
import { Action } from "../../types";
import { findTarget } from "@/store/utils";
import { trpcProxyClient } from "@/utils/trpc";
import { toast } from "sonner";
import { AppRouter } from "@/types";

export const allowReset: Action = ([set, get]) =>
(
  { userId }: inferProcedureInput<
    AppRouter["user"]["allowReset"]
  >,
) => {
  const oldUser = findTarget(userId, get().allUsers);
  if (!oldUser) return;
  set((state) => {
    const user = findTarget(userId, state.allUsers)!;
    user.lastResetAt = null;
  });
  trpcProxyClient.user.allowReset.mutate({ userId })
    .then(() => {
      toast.success(`Successfully allowed reset!`);
    }).catch((exc) => {
      toast.success(
        `Could not allow reset for user, an error occurred: ${exc.message}`,
      );
      set((state) => {
        const targetUser = findTarget(userId, state.allUsers)!;
        targetUser.lastResetAt = oldUser.lastResetAt;
      });
    });
};

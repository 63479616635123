import { PropsWithClassName } from "@/types";
import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface ToolTipProps extends PropsWithChildren, PropsWithClassName {
}

export const ToolTip = ({ children, className }: ToolTipProps) => {
  return (
    <div
      className={twMerge(
        "relative rounded-sm p-2 after:content-[ ] after:bg-gray-200 bg-gray-200 dark:bg-neutral-800 border border-transparent dark:border-neutral-700 after:dark:bg-neutral-800 after:border after:border-transparent after:dark:border-t-neutral-700 after:dark:border-l-neutral-700 transition-colors after:absolute after:rotate-45 after:h-2 after:w-2 after:top-0 after:-translate-y-[50%] after:left-[0.5] dark:text-neutral-200 ",
        className,
      )}
    >
      {children}
    </div>
  );
};

import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import { trpc } from "@/utils/trpc";
import { useEffect, useMemo } from "react";
import useAddSystems from "./use-add-systems";
import useSystems from "./use-systems";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

type Opts = { enabled?: boolean };
const useQuestionBankSystems = (args: QuestionBankIdentifier, opts?: Opts) => {
  const { data: questionBank } = useQuestionBank(args);
  const query = trpc.system.getQuestionBankSystems.useQuery({
    qBankId: questionBank?.id ?? "",
  }, { enabled: !!questionBank && (opts?.enabled ?? true) });
  const systems = useSystems();
  const addSystems = useAddSystems();
  const { data, isLoading } = query;
  useEffect(() => {
    if (!!data && !isLoading) {
      addSystems(data);
    }
  }, [data, isLoading]);

  const questionBankSystems = useMemo(() =>
    ArrayMapUtils.filter(
      systems,
      (s) => s.bankId === questionBank?.id,
    ), [systems, questionBank]);

  return { ...query, data: questionBankSystems };
};

export default useQuestionBankSystems;

import { DashboardLink, DashboardLinkProps } from "@/components/DashboardLink";
import { twMerge } from "tailwind-merge";
type PerformanceTestPageLinkProps = DashboardLinkProps;
export const PerformanceTestPageLink = (
  { className, children, ...props }: PerformanceTestPageLinkProps,
) => {
  return (
    <DashboardLink
      {...props}
      className={twMerge(
        "text-primary-500 dark:text-neutral-300 hover:text-primary-600 dark:hover:text-neutral-100 group transition-colors",
        className,
      )}
    >
      {children}
    </DashboardLink>
  );
};

import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankSystems from "./use-question-bank-systems";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { Test } from "@/models/test-model";
import useTest from "@/hooks/test-hooks/use-test";
import mergeFlags from "@/utils/common/merge-flags";
import { useMemo } from "react";

type SystemProps = { testId: Test["id"] } & QuestionBankIdentifier;
const useQuestionBankTestSystems = (args: SystemProps) => {
  const testQuery = useTest({ testId: args.testId });
  const { data: test } = testQuery;
  const questionBankQuery = useQuestionBankSystems(args);
  const { data: systems } = questionBankQuery;

  const questionBankTestSystems = useMemo(
    () => ArrayMapUtils.filter(systems, (s) => test?.systems.includes(s.id)),
    [systems, test],
  );
  return {
    ...mergeFlags({ source: testQuery, destinations: [questionBankQuery] }),
    data: questionBankTestSystems,
  };
};

export default useQuestionBankTestSystems;

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { twMerge } from "tailwind-merge";

interface CheckboxProps {
  checked: boolean;
  onCheckedChange?: (checked: boolean) => void;
  id?: string;
  disabled?: boolean;
  className?: string;
  checkedClassName?: string;
  disabledClassName?: string;
  onClick?: () => void;
}

const Checkbox = (
  {
    checked,
    onCheckedChange,
    onClick,
    disabled,
    id,
    className,
    checkedClassName,
    disabledClassName,
  }: CheckboxProps,
) => {
  return (
    <RadixCheckbox.Root
      onClick={onClick}
      className={twMerge(
        "border border-gray-300 warm:border-brown-300 dark:border-neutral-600 w-6 h-6 min-w-[1.5rem]  rounded-primary transition-colors",
        !checked &&
          "hover:border-primary-500 dark:hover:border-neutral-500 focus:border-primary-500 dark:focus:border-neutral-500",
        checked &&
          twMerge(
            "bg-primary-500 warm:bg-brown-800 dark:bg-neutral-600",
            checkedClassName,
          ),
        disabled &&
          twMerge(
            "bg-gray-50 dark:bg-neutral-800 hover:border-gray-300 dark:hover:border-neutral-800 focus:border-gray-300 dark:focus:border-neutral-800",
            disabledClassName,
          ),
        className,
      )}
      checked={checked}
      onCheckedChange={onCheckedChange}
      disabled={disabled}
      id={id}
    >
      <RadixCheckbox.Indicator
        className={twMerge(
          "text-white transition-opacity opacity-0 p-0 ",
          checked && "opacity-100",
        )}
      >
        <FontAwesomeIcon icon={faCheck} />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
};

export default Checkbox;

import { PropsWithAs } from "@/types";
import { ComponentProps, PropsWithChildren } from "react";
import useNbmeSidePanelContext from "./hooks/use-nbme-side-panel-context";

const NbmeSidePanelTrigger = (
  { children, as, ...props }:
    & PropsWithChildren
    & PropsWithAs
    & ComponentProps<"div">,
) => {
  const Parent = as ?? "div";
  const { setOpen, open } = useNbmeSidePanelContext();
  return (
    <Parent onClick={(e) => props.onClick?.(e) && setOpen(!open)} {...props}>
      {children}
    </Parent>
  );
};

export default NbmeSidePanelTrigger;

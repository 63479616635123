import { DashboardTable } from "@/components/DashboardTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronRight,
  faMinusCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { useBreakPoint } from "@/utils/hooks";
import { ArrayElement, BreakPoints, TableRow } from "@/types";
import { swap } from "@/utils/common/swap";
import { useReportOptions } from "../../-hooks";
import useTestResult from "../-hooks/use-test-result";
import { Test } from "@/models/test-model";

export const PerformanceTestResultsPageTable = (
  { testId }: { testId: Test["id"] },
) => {
  const { options } = useReportOptions();
  const breakPoint = useBreakPoint();
  const isScreenSmall = breakPoint < BreakPoints.Medium;
  const { data: questions } = useTestResult({ testId });
  let columns: TableRow<ArrayElement<typeof questions>>["columns"] = [
    {
      name: isScreenSmall ? "State" : "",
      visible: true,
      content: (question) => ({
        correct: question.used === "correct",
        omitted: question.used === "omitted",
      }),
    },
    {
      name: "ID",
      visible: true,
      content: (question) => question.uWolrdId,
    },
    {
      name: "Subject",
      visible: true,
      content: (question) => question.subject,
    },
    {
      name: "System",
      visible: true,
      content: (question) => question.system,
    },
    {
      name: "Topic",
      visible: true,
      content: (question) => question.topic,
    },
    {
      name: "% Correct Others",
      visible: true,
      content: (question) => `${question.othersCorrect}%`,
    },
    {
      name: "Time Spent",
      visible: true,
      content: (question) =>
        question.timeSpent > 60
          ? `${(question.timeSpent / 60).toFixed(1)} mins`
          : `${question.timeSpent} sec`,
    },
    { name: " ", visible: !isScreenSmall },
  ];
  columns = isScreenSmall
    ? columns.filter((column) => column.name !== " ")
    : columns;
  if (isScreenSmall) swap(0, 1, columns);

  const filteredQuestions = questions.filter((question) => {
    const isCorrent = question.used === "correct";
    const isIncorrect = question.used === "incorrect";
    const isOmitted = question.used === "omitted";
    const isMarked = question.marked;
    const isShown = (isCorrent && options.includes("Correct")) ||
      (isIncorrect && options.includes("Incorrect")) ||
      (isOmitted && options.includes("Omitted")) ||
      (isMarked && options.includes("Marked"));

    return isShown;
  });
  return (
    <DashboardTable
      itemsPerPage={10000000}
      currentPage={1}
      items={filteredQuestions}
      columns={{
        collapsible: false,
        columns: columns,
      }}
      columnContentMapper={(column, content) => {
        if (!column.name) {
          const isOmitted = content.omitted;
          const isCorrect = !isOmitted && content.correct;
          const isIncorrect = !isOmitted && !content.correct;
          return (
            <FontAwesomeIcon
              className={twMerge(
                isCorrect && "text-lime-500",
                isIncorrect && "text-red-500",
                isOmitted && "text-sky-500",
              )}
              icon={isOmitted ? faMinusCircle : isCorrect ? faCheck : faXmark}
            />
          );
        }
        if (column.name === "State") {
          const isOmitted = content.omitted;
          const isCorrect = !isOmitted && content.correct;
          const isIncorrect = !isOmitted && !content.correct;
          return (
            <div className="flex flex-row justify-start items-center gap-1">
              {isScreenSmall && (
                <p>
                  {isOmitted ? "Omitted" : isCorrect ? "Correct" : "Incorrect"}
                </p>
              )}
              <FontAwesomeIcon
                className={twMerge(
                  isCorrect && "text-lime-500",
                  isIncorrect && "text-red-500",
                  isOmitted && "text-sky-500",
                )}
                icon={isOmitted ? faMinusCircle : isCorrect ? faCheck : faXmark}
              />
            </div>
          );
        }
        if (column.name === " ") {
          return <FontAwesomeIcon icon={faChevronRight} />;
        }
        return content;
      }}
    />
  );
};

import { SearchInput } from "@/components/SearchInput";
import { usePreviousTestsPageStore } from "@/utils/stores/previousTestsPageStore";

export const PreviousTestsPageQueryInput = () => {
  const { query, setQuery } = usePreviousTestsPageStore((state) => ({
    query: state.query,
    setQuery: state.setQuery,
  }));
  return (
    <SearchInput
      variant="dashboard"
      value={query}
      onChange={(e) => setQuery(e.target.value)}
      className="warm:bg-white"
    />
  );
};

import usePerformanceReportsPageStore from "../-store";

export const usePerformanceReportsPageTabs = () =>
  usePerformanceReportsPageStore((
    state,
  ) => ({
    tabs: state.tabs,
    currentTab: state.currentTab,
    setCurrentTab: state.setCurrentTab,
  }));

import { Setter } from "@/types";
import { ScreenEdge } from "@/types/common/ScreenEdge";
import { useOnClickOutside } from "@/utils/hooks";
import { useCallback, useRef, useState } from "react";

const useNbmeSidePanelState = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [draggable, setDraggable] = useState<boolean>(true);
  const [emergeFrom, setEmeregeFrom] = useState<ScreenEdge>("right");
  const [closeOnClickOutside, setCloseOnClickOutside] = useState(true);
  const bodyRef = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(() => {
    if (!closeOnClickOutside) return;
    setOpen(false);
  }, [setOpen, closeOnClickOutside]);

  useOnClickOutside(bodyRef, onClickOutside);

  return {
    open,
    setOpen: setOpen as Setter<boolean>,
    closeOnClickOutside,
    setCloseOnClickOutside,
    bodyRef,
    draggable,
    setDraggable,
    emergeFrom,
    setEmeregeFrom,
  };
};

export default useNbmeSidePanelState;

"use client";
import React, { PropsWithChildren } from "react";
import ViewerContextProvider from "./viewer-context-provider";
import { ViewerContext } from "./viewer-context";
import { ViewerRef } from ".";

type ViewerRootProps = PropsWithChildren & ViewerContext;
const ViewerRoot = React.forwardRef<ViewerRef, ViewerRootProps>(
  ({ children, ...props }, ref) => {
    return (
      <ViewerContextProvider
        value={{
          ...props,
          //@ts-ignore
          ref,
        }}
      >
        {children}
      </ViewerContextProvider>
    );
  },
);

export default ViewerRoot;

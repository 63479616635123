import { PropsWithClassName } from "@/types";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface IconButtonProps extends PropsWithClassName, PropsWithChildren {
  onClick?: () => void;
}
export const IconButton = (
  { onClick, className, children }: IconButtonProps,
) => {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        "text-primary-500 text-xl hover:enabled:text-primary-600 transition-[color,_transform] hover:enabled:scale-110",
        className,
      )}
    >
      {children}
    </button>
  );
};

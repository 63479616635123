import { PropsWithClassName } from "@/types";
import { FilterInterface, Interweave } from "interweave";
import React from "react";

export const NbmeUrineAndBMILabValues = (
  { className, filters }: PropsWithClassName & { filters?: FilterInterface[] },
) => {
  return (
    <Interweave
      filters={filters}
      className={className}
      content={`<table id= \"UBMITable\" border= \"0\" cellpadding= \"2\" cellspacing= \"0\" width= \"100%\"><tbody><tr><td ><b>Urine</b></td><td ><b>Reference Range</b></td><td ><b>SI Reference Intervals</b></td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td >Calcium</td><td >100-300 mg/24 h</td><td >2.5-7.5 mmol/24 h</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td width= \"100%\" colspan= \"3\">Creatinine clearance</td></tr><tr><td >Male</td><td >97-137 mL/min</td><td >97-137 mL/min</td></tr><tr><td >Female</td><td >88-128 mL/min</td><td >88-128 mL/min</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td >Osmolality</td><td >50-1200 mOsmol/kg H<sub>2</sub>O</td><td >50-1200 mmol/kg</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td >Oxalate</td><td >8-40 &mu;g/mL</td><td >90-445 &mu;mol/L</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td >Proteins, total</td><td >&lt;150 mg/24 h</td><td >&lt;0.15 g/24 h</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td width= \"100%\" colspan= \"3\">17-Hydroxycorticosteroids</td></tr><tr><td >Male</td><td >3.0-10.0 mg/24 h</td><td >8.2-27.6 &mu;mol/24 h</td></tr><tr><td >Female</td><td >2.0-8.0 mg/24 h</td><td >5.5-22.0 &mu;mol/24 h</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td width= \"100%\" colspan= \"3\">17-Ketosteroids, total</td></tr><tr><td >Male</td><td >8-20 mg/24 h</td><td >28-70 &mu;mol/24 h</td></tr><tr><td >Female</td><td >6-15 mg/24 h</td><td >21-52 &mu;mol/24 h</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td ><b>Body Mass Index (BMI)</b></td><td >Adult: 19-25 kg/m<sup>2</sup></td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr></tbody></table>`}
    />
  );
};

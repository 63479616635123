import { createFileRoute, Navigate } from "@tanstack/react-router";

export const Route = createFileRoute("/qbanks/$qbankEndpoint/")({
  component: () => (
    <Navigate
      to="/qbanks/$qbankEndpoint/dashboard"
      params={(oldParams) => ({ qbankEndpoint: oldParams.qbankEndpoint! })}
      replace
    />
  ),
});

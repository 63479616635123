"use client";
import { useState } from "react";
import { countries } from "countries-list";
import { Combobox } from "./Combobox";
import { ArrayElement } from "@/types";

interface CountryComboboxProps {
  onCountrySelect?: (country: { id: string; name: string }) => void;
  error?: boolean;
  className?: string;
}

export const CountryCombobox = (
  { onCountrySelect, error, className }: CountryComboboxProps,
) => {
  const [selectedCountry, setSelectedCountry] = useState<
    typeof countryItems[0]
  >();

  const [query, setQuery] = useState("");

  const countryItems = Object.keys(countries).map((countryCode) => ({
    id: countryCode,
    name: countries[countryCode as keyof typeof countries].name,
  }));

  type Country = ArrayElement<typeof countryItems>;

  const filteredCountries = query === ""
    ? countryItems
    : countryItems.filter((country) =>
      country.name.toLowerCase().includes(query.toLowerCase())
    );

  return (
    <Combobox
      className={className}
      error={!!error}
      value={selectedCountry}
      items={filteredCountries}
      onChange={(newValue) => {
        onCountrySelect?.(newValue as Country);
        setSelectedCountry(newValue as Country);
      }}
      by={"name"}
      onInputChange={(e) => setQuery(e.target.value)}
      itemFields={{ key: "id", name: "name" }}
      displayValue={(selected) => selected.name ?? ""}
      placeholder="Egypt"
    />
  );
};

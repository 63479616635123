import { TestSlot } from "@/models/testslot-model";
import { Test } from "@/models/test-model";
import gen from "@/lib/gen";

const createTestTestSlotsManipulator = (
  { testSlots, test }: {
    testSlots: (
      & Pick<
        TestSlot,
        "questionId"
      >
      & { id?: string }
    )[];
    test: Pick<Test, "id">;
  },
): Pick<
  TestSlot,
  "id" | "lastSelected" | "questionId" | "order" | "testId"
>[] => {
  return testSlots.map((ts, index) => ({
    id: ts.id ?? gen.cuid(),
    lastSelected: index === 0 ? new Date() : null,
    questionId: ts.questionId,
    order: index,
    testId: test.id,
  }));
};
export default createTestTestSlotsManipulator;

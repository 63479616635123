import { useAppStore } from "@/store";
import infuseFunctionIntoMutation from "@/utils/common/infuse-function-into-mutation";
import { trpc } from "@/utils/trpc";

const useDeleteQuestionNote = () => {
  const utils = trpc.useUtils();
  const deleteQuestionNote = useAppStore((state) => state.deleteQuestionNote);
  const mutation = trpc.questionNote.deleteQuestionNote.useMutation({
    onMutate: () => {
      utils.questionNote.getQuestionBankQuestionNotes.invalidate();
    },
  });

  return {
    ...infuseFunctionIntoMutation({ func: deleteQuestionNote, mutation }),
  };
};
export default useDeleteQuestionNote;

import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ChangeAdmonitionType,
  CreateLink,
  InsertAdmonition,
  InsertImage,
  InsertTable,
  InsertThematicBreak,
  ListsToggle,
  Separator,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";

export const Toolbar = toolbarPlugin({
  toolbarContents: () => (
    <div className="flex flex-row justify-start items-center min-w-0 w-full overflow-scroll flex-wrap">
      <UndoRedo />
      <Separator />
      <BlockTypeSelect />
      <Separator />
      <BoldItalicUnderlineToggles />
      <Separator />
      <ListsToggle />
      <Separator />
      <InsertTable />
      <InsertThematicBreak />
      <InsertImage />
      <Separator />
      <CreateLink />
      <InsertAdmonition />
    </div>
  ),
});

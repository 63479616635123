import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

type HollowSolidTransitionSolidProps = PropsWithClassName & PropsWithChildren;
const HollowSolidTransitionSolid = (
  { className, children }: HollowSolidTransitionSolidProps,
) => {
  return (
    <div
      className={cn(
        "group-hover/hollow-solid-transition:opacity-100 transition-opacity opacity-0 inline-flex flex-row justify-center items-center",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default HollowSolidTransitionSolid;

import { NbmeNavbarButtonWithIcon } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";

interface NbmeCalculatorNavbarButtonProps {
  isCalculatorVisible: boolean;
  onIsCalculatorVisibleChange: (isCalculatorVisible: boolean) => void;
}

export const NbmeCalculatorNavbarButton = (
  { isCalculatorVisible, onIsCalculatorVisibleChange }:
    NbmeCalculatorNavbarButtonProps,
) => {
  return (
    <NbmeNavbarButtonWithIcon
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faCalculator} className="w-8 h-8" />}
      label="Calculator"
      onClick={() => {
        onIsCalculatorVisibleChange(!isCalculatorVisible);
      }}
    />
  );
};

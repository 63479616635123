import { useNbmeSettingsAutoHideSidebar } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";

export const NbmeSettingsAutoHideSidebarControl = () => {
  const [autoHideSidebar, setAutoHideSidebar] =
    useNbmeSettingsAutoHideSidebar();

  return (
    <NbmeSettingsSwitch
      checked={autoHideSidebar}
      onCheckedChange={setAutoHideSidebar}
    />
  );
};

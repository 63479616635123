import { BreakPoints } from "@/types";
import { useBreakPoint } from "@/utils/hooks";
import { useEffect, useState } from "react";

export const useOnMediumScreen = () => {
  const breakpoint = useBreakPoint();
  const checkIfOnMediumScreen = () =>
    breakpoint < BreakPoints.Large && breakpoint > BreakPoints.Small;
  const [onMediumScreen, setOnMediumScreen] = useState<boolean>(
    checkIfOnMediumScreen(),
  );
  useEffect(() => {
    setOnMediumScreen(checkIfOnMediumScreen());
  }, [breakpoint, setOnMediumScreen]);
  return onMediumScreen;
};

import createHighlightMarkerSlice from "./create-highlightmarker-slice";
import { HighlightMarker } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";
import {
  CreateHighlightMarkerInput,
  DeleteHighlightMarkerInput,
  EditHighlightMarkerInput,
} from "@/api/src/server/routers/highlightmarker-router/highlightmarker-mutations";
import { AuthSlice } from "../auth-slice";
import { SliceActionOpts } from "@/types/common/SliceActionOpts";
import { HighlightSlice } from "../highlight-slice";

export type HighlightMarkerSliceActions = {
  addHighlightMarker: (highlightMarker: HighlightMarker) => void;
  resetHighlightMarkers: (
    args: { highlightMarkerIds: HighlightMarker["id"][] },
  ) => void;
  addHighlightMarkers: (highlightMarkers: HighlightMarker[]) => void;
  deleteHighlightMarker: (
    input: DeleteHighlightMarkerInput,
    opts?: SliceActionOpts,
  ) => void;
  createHighlightMarker: (
    newHighlightMarker: CreateHighlightMarkerInput,
  ) => HighlightMarker | undefined;
  editHighlightMarker: (
    input: EditHighlightMarkerInput,
  ) => void;
};
export type HighlightMarkerSliceState = typeof highlightMarkerSliceState;

export type HighlightMarkerSlice =
  & HighlightMarkerSliceState
  & HighlightMarkerSliceActions;

export type HighlightMarkerSliceCreator = StateCreator<
  HighlightMarkerSlice & AuthSlice & HighlightSlice,
  [["zustand/immer", never]],
  [],
  HighlightMarkerSlice
>;

export type HighlightMarkerSliceModifiers = SliceModifiers<
  HighlightMarkerSliceCreator
>;

export type HighlightMarkerSliceAction = (
  ...modifiers: HighlightMarkerSliceModifiers
) => void;

export const highlightMarkerSliceState = {
  highlightMarkers: {} as ArrayMap<HighlightMarker>,
};

export default createHighlightMarkerSlice;

import { Button } from "@/components/Button";
import Dialog from "@/components/Dialog";

interface NbmeConfirmOmissionDialogProps {
  isVisible: boolean;
  onIsVisibleChange: (v: boolean) => void;
  isDarkMode: boolean;
  onSlotSubmitted: () => void;
}

export const NbmeConfirmOmissionDialog = (
  { isDarkMode, onIsVisibleChange, isVisible, onSlotSubmitted }:
    NbmeConfirmOmissionDialogProps,
) => {
  return (
    <Dialog
      dark={isDarkMode}
      title={"Omit Question?"}
      description="You have not answered this question, Do you wish to continue without answering?"
      open={isVisible}
      onOpenChange={onIsVisibleChange}
      onCloseClicked={() => onIsVisibleChange(false)}
    >
      <div className="flex flex-row justify-end items-center mt-4">
        <div className="flex flex-row justify-start items-center gap-3">
          <Button
            className="p-1 px-2"
            onClick={() => onIsVisibleChange(false)}
          >
            No
          </Button>
          <Button
            className="p-1 px-2"
            error
            onClick={() => {
              onIsVisibleChange(false);
              onSlotSubmitted();
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

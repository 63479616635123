import DragIcon from "@/components/icons/drag-icon";
import WindowTitlebarIndicator from "./window-titlebar-indicator";

const WindowDraggableIndicator = () => {
  return (
    <WindowTitlebarIndicator tooltip="This Window Can Be Dragged Around">
      <DragIcon />
    </WindowTitlebarIndicator>
  );
};

export default WindowDraggableIndicator;

import { Point } from "framer-motion";
import useClampedState from "./use-clamped-state";
import { useCallback } from "react";
import { useViewportDimensions } from "@/utils/hooks";
import { clamp } from "@/utils";

const useViewportClampedPoint = (
  initial: Point | undefined,
  opts?: { padding?: Point },
) => {
  const { width: viewportWidth, height: viewportHeight } =
    useViewportDimensions();

  const clampPointToViewport = useCallback(
    (position: Point | undefined): Point | undefined => {
      if (!position) return undefined;
      /** Prevents windows from being completely lost
       * outside the viewport */

      const pointMax = {
        x: viewportWidth - (opts?.padding?.x ?? 0),
        y: viewportHeight - (opts?.padding?.y ?? 0),
      };

      const pointMin = {
        x: 0,
        y: 0,
      };

      return {
        x: clamp({
          num: position.x,
          min: pointMin.x,
          max: pointMax.x,
        }),
        y: clamp({
          num: position.y,
          min: pointMin.y,
          max: pointMax.y,
        }),
      };
    },
    [viewportWidth, viewportHeight],
  );

  const [point, setPoint] = useClampedState<Point | undefined>(
    initial,
    clampPointToViewport,
  );

  return [point, setPoint] as const;
};
export default useViewportClampedPoint;

import WindowBody from "./window-body";
import WindowButtons from "./window-buttons";
import WindowClose from "./window-close";
import WindowDraggableIndicator from "./window-draggable-indicator";
import WindowIndicators from "./window-indicators";
import WindowMaximize from "./window-maximize";
import WindowMinimize from "./window-minimize";
import WindowResizableIndicator from "./window-resizable-indicator";
import WindowRoot from "./window-root";
import WindowTitle from "./window-title";
import WindowTitlebar from "./window-titlebar";

export { default as WindowBody } from "./window-body";
export { default as WindowButtons } from "./window-buttons";
export { default as WindowClose } from "./window-close";
export { default as WindowDraggableIndicator } from "./window-draggable-indicator";
export { default as WindowIndicators } from "./window-indicators";
export { default as WindowMaximize } from "./window-maximize";
export { default as WindowMinimize } from "./window-minimize";
export { default as WindowResizableIndicator } from "./window-resizable-indicator";
export { default as WindowRoot } from "./window-root";
export { default as WindowTitle } from "./window-title";
export { default as WindowTitlebar } from "./window-titlebar";

const Window = {
  Root: WindowRoot,
  Body: WindowBody,
  Titlebar: WindowTitlebar,
  Buttons: WindowButtons,
  Close: WindowClose,
  Maximize: WindowMaximize,
  Minimize: WindowMinimize,
  Title: WindowTitle,
  Indicators: WindowIndicators,
  DraggableIndicator: WindowDraggableIndicator,
  ResizableIndicator: WindowResizableIndicator,
};

export default Window;

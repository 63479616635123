/**
 * Converts a string to a regular expression that exactly
 * matches that string (and properly escapes special characters)
 * @param {string} str - the string to convert.
 */
export const stringToRegex = (str: string, flags?: string): RegExp => {
  const specialCharacters = [
    ".",
    "+",
    "-",
    "^",
    "$",
    "*",
    "?",
    "(",
    ")",
    "[",
    "]",
    "}",
    "{",
    "|",
    ",",
    "'",
    " ",
  ];

  let stringWithEscapedCharacters = "";

  for (const character of str) {
    if (!specialCharacters.includes(character)) {
      stringWithEscapedCharacters += character;
      continue;
    }
    stringWithEscapedCharacters += `\\${character}`;
  }

  return new RegExp(stringWithEscapedCharacters, flags);
};

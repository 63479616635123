import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";
import useTestPageTestSelectedTestSlot from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot";
import { fadeAnimation } from "@/assets/framer";
import { NbmeClickToRevealButton } from "@/components/NbmeClickToRevealButton";
import useIncrementTestTimeElapsed from "@/hooks/test-hooks/use-increment-test-time-elapsed";
import { Test } from "@/models/test-model";
import { calculateMinutesRemaining } from "@/services/test";
import { useDateEverySecond } from "@/utils/hooks";
import { useNbmeTimerVisible } from "@/utils/stores/nbmeLayoutStore";
import { AnimatePresence, motion } from "framer-motion";
import { Duration } from "luxon";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import { useStopwatch } from "react-timer-hook";
import useSubmitTest from "@/hooks/test-hooks/use-submit-test";

const TestTimer = ({ testId }: { testId: Test["id"] }) => {
  const { data: test } = useTestPageTest();
  const { timerVisible, setTimerVisible } = useNbmeTimerVisible();
  const incrementTime = useIncrementTestTimeElapsed();
  const [warned, setWarned] = useState<boolean>(false);
  const { seconds } = useStopwatch({ autoStart: true });
  const submitTest = useSubmitTest();
  useEffect(() => {
    incrementTime({ testId });
    const minutesRemaining = !!test
      ? calculateMinutesRemaining({ test })
      : undefined;
    if (minutesRemaining === undefined) return;
    if ((timed && test?.endsAt)) {
      minutesRemaining &&
        setTimeRemaining(
          Duration.fromObject({ minutes: minutesRemaining }).toFormat(
            "hh:mm:ss",
          ),
        );
    }
    if (minutesRemaining <= 0) {
      timeOut();
      return;
    }
    if (
      minutesRemaining <= 1 && !warned && !test?.submitted
    ) {
      toast.warning("You have only one minute remaining.");
      setWarned(true);
    }
  }, [seconds]);
  const formatTimeElapsed = (): string => {
    if (!test?.startedAt) return "";
    return Duration.fromMillis(test?.timeElapsed * 1000)
      .toFormat(
        "hh:mm:ss",
      );
  };

  const timed = test?.mode.includes("timed");
  const timeOut = () => timed && submitTest({ testId: test?.id ?? 0 });

  const [timeRemaining, setTimeRemaining] = useState<string>("");

  return (
    <AnimatePresence mode="wait">
      <motion.p
        className={twMerge(
          "transition-colors",
          !timerVisible && "text-nbme-primary-200",
        )}
        key={timerVisible ? "timer" : "timer-hidden"}
        {...fadeAnimation}
      >
        {timerVisible
          ? `${timed ? `Block Time Remaining:` : `Block Time Elapsed:`} ${
            timed ? timeRemaining : formatTimeElapsed()
          }`
          : (
            <NbmeClickToRevealButton
              onClick={() => setTimerVisible(true)}
              className="text-nbme-primary-300 warm:text-brown-500 dark:text-nbme-primary-dark-400"
            />
          )}
      </motion.p>
    </AnimatePresence>
  );
};

export default TestTimer;

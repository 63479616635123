import ContextMenu from "@/components/context-menu";
import HollowSolidTransition from "@/components/hollow-solid-transition";
import { DeleteIcon } from "@/components/icons";
import HollowDeleteIcon from "@/components/icons/hollow/hollow-delete-icon";
import { WithTooltip } from "@/components/pages/dashboard";
import NbmeContextMenuItem from "./nbme-context-menu-item";
import { ComponentProps } from "react";

const NbmeContextMenuDelete = (
  { className, ...props }: ComponentProps<typeof NbmeContextMenuItem>,
) => {
  return (
    <WithTooltip tooltip="Delete" className={className}>
      <NbmeContextMenuItem {...props}>
        <HollowSolidTransition.Root className="p-1 dark:bg-neutral-600 bg-nbme-primary-600 dark:hover:shadow-none hover:shadow-md hover:shadow-nbme-primary-800 rounded-primary hover:scale-110 transition-[transform,_box-shadow] active:scale-95">
          <HollowSolidTransition.Solid>
            <DeleteIcon className="w-5 h-5" />
          </HollowSolidTransition.Solid>
          <HollowSolidTransition.Hollow>
            <HollowDeleteIcon className="w-5 h-5" />
          </HollowSolidTransition.Hollow>
        </HollowSolidTransition.Root>
      </NbmeContextMenuItem>
    </WithTooltip>
  );
};

export default NbmeContextMenuDelete;

import { isInactive } from ".";

export const getStrokeClassnames = (activePercentage: number) => {
  return [
    `stroke-sky-500 hover:stroke-sky-500  ${
      isInactive(activePercentage, 0)
        ? "stroke-sky-100 dark:stroke-sky-800 dark:hover:stroke-sky-500"
        : ""
    }`,
    `stroke-gray-400 hover:stroke-gray-400 dark:stroke-neutral-500 ${
      isInactive(activePercentage, 1)
        ? "stroke-gray-100 dark:stroke-neutral-700 dark:hover:stroke-neutral-500"
        : ""
    }`,
  ];
};

import { ManipulatorOpts } from "@/types/common/ManipulatorOpts";
import { Test } from "../test-model-types";

const submitTestManipulator = (
  { test }: {
    test: Pick<
      Test,
      "submittedAt" | "finishedEvaluationAt" | "isBeingEvaluated" | "submitted"
    >;
  },
  opts?: ManipulatorOpts,
): Pick<
  Test,
  "submittedAt" | "submitted" | "isBeingEvaluated" | "finishedEvaluationAt"
> => {
  if (opts?.reverse) {
    return {
      submittedAt: null,
      finishedEvaluationAt: null,
      isBeingEvaluated: false,
      submitted: false,
    };
  }

  if (test.submitted) return test;
  return {
    submittedAt: new Date(),
    finishedEvaluationAt: new Date(),
    isBeingEvaluated: false,
    submitted: true,
  };
};

export default submitTestManipulator;

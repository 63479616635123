"use client";
/**
 * Returns true if there is currently selected text
 * on the browser, otherwise returns false.
 * (Works only in the browser)
 * @returns {boolean} Whether there is text selected at the moment or not */
const isTextSelected = (): boolean => {
  if (!window) return false;
  const windowSelection = window.getSelection();
  if (!windowSelection) return false;
  return !windowSelection.isCollapsed;
};

export default isTextSelected;

import { Button } from ".";

interface SaveButtonProps {
  isEditMode: boolean;
  onEditSave: () => void;
  onIsEditModeChange: (newValue: boolean) => void;
}

export const SaveButton = (
  { isEditMode, onIsEditModeChange, onEditSave }: SaveButtonProps,
) => {
  return (
    <Button
      className="lg:text-base w-[50%]"
      onClick={() => isEditMode ? onEditSave() : onIsEditModeChange(true)}
    >
      {isEditMode ? "Save" : "Edit"}
    </Button>
  );
};

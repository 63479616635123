"use client";
import { NbmeChoice } from "@/components/NbmeChoice";
import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import { numberToAlphabet } from "@/utils/common/numberToAlphabet";
import RadioGroup from "@/components/RadioGroup";
import { RadioGroupItemWithLabel } from "@/components/RadioGroupItemWithLabel";
import { Interweave } from "interweave";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { choiceFilter } from "@/utils/interweave/filters";
import { FontSizes } from "@/types";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { motion } from "framer-motion";
import { fadeOnConditionAnimation } from "@/assets/framer";
import { Question, QuestionChoice, TestSlot } from "@/api/types";

interface NbmeChoicesProps<T = "test" | "question"> {
  variant: T;
  onChoiceChange?: T extends "test"
    ? (args: { choiceId: string; slotId: string }) => void
    : undefined;
  onChoiceCross?: T extends "test" ? (args: {}) => void : undefined;
  currentSlot?: T extends "test"
    ? TestSlot & { chosen?: QuestionChoice; crossed: QuestionChoice[] }
    : undefined;
  question: Question & { choices: QuestionChoice[] };
  fontSizeFactor: number;
  isTestTutor: boolean;
  isPercentageAnsweredVisible: boolean;
}

export const NbmeChoices = <T = "test" | "question">(
  {
    variant,
    onChoiceChange,
    currentSlot,
    fontSizeFactor,
    question,
    isTestTutor,
    isPercentageAnsweredVisible,
    onChoiceCross,
  }: NbmeChoicesProps<T>,
) => {
  const breakPoint = useBreakPoint();
  return (
    <RadioGroup
      onValueChange={(choiceId) =>
        variant === "test" &&
        onChoiceChange?.({ choiceId, slotId: currentSlot!.id })}
      orientation="vertical"
      value={variant === "test" ? (currentSlot!.chosen?.id || "") : ""}
      ariaLabel="Question choices"
      className="py-2 flex flex-col justify-start items-end "
      disabled={variant === "question" || currentSlot?.submitted}
      style={{
        fontSize:
          scaleUnitInRem(FontSizes.Small, fontSizeFactor).resultInRemAsCss,
      }}
    >
      {question.headerHtml && (
        <div className="flex flex-row justify-end items-end w-full">
          <Interweave
            className="self-end ml-auto w-full flex flex-row justify-end items-center"
            content={question.headerHtml}
            filters={[choiceFilter(breakPoint, fontSizeFactor, false)]}
          />
        </div>
      )}
      {question.choices.map((c, index) => {
        const crossed = variant === "test"
          ? !!(currentSlot!.crossed.find((cr) => cr.id === c.id))
          : false;
        const isCorrect = question.choices.find((c) => c.correct)?.id === c.id;

        const showResult = variant === "test"
          ? currentSlot?.submitted && isTestTutor
          : true;

        const showMark = showResult &&
          (currentSlot?.chosen?.id === c.id ||
            isCorrect);
        //const chosenBy = c._count.chosenBy;
        return (
          <div
            key={`${c.id}`}
            className="relative flex flex-row w-full justify-start items-center gap-3 hover:bg-gray-200 dark:hover:text-black pl-10 pr-2 py-0.5 transition-colors"
          >
            {showMark &&
              (
                <>
                  <FontAwesomeIcon
                    icon={isCorrect ? faCheck : faXmark}
                    className={twMerge(
                      "absolute ml-3 left-0 w-5 h-5 opacity-0",
                      showResult && "opacity-100",
                      isCorrect && "text-lime-500",
                      !isCorrect && "text-red-500",
                    )}
                  />
                </>
              )}
            <RadioGroupItemWithLabel
              label={`${numberToAlphabet(index + 1).toUpperCase()}.`}
              value={c.id}
              id={`r${index}`}
            />

            <div
              className={twMerge(
                "relative flex flex-row justify-start items-center cursor-default select-none w-full gap-3",
              )}
              onClick={() =>
                variant === "test" &&
                onChoiceCross!({
                  choiceId: c.id,
                  crossed: !crossed,
                  slotId: currentSlot!.id,
                })}
            >
              <div
                className={twMerge(
                  crossed && "line-through",
                )}
              >
                {c.html
                  ? (
                    <Interweave
                      content={c.html}
                      filters={[
                        choiceFilter(breakPoint, fontSizeFactor, crossed),
                      ]}
                    />
                  )
                  : (
                    <NbmeChoice
                      text={c.text}
                      crossed={crossed}
                    />
                  )}
              </div>
              {showResult && (
                <motion.p
                  {...fadeOnConditionAnimation(isPercentageAnsweredVisible)}
                  className=""
                >
                  ({c.uWorldChosenBy}%)
                </motion.p>
              )}
            </div>
          </div>
        );
      })}
    </RadioGroup>
  );
};

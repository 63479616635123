import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../..";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

export const CreateTestButton = () => {
  return (
    <Button href="create-test">
      <FontAwesomeIcon icon={faPenToSquare} />
      <p>Create test</p>
    </Button>
  );
};

import { useAppStore } from "@/store";
import { renamePage as renamePageOnDatabase } from "./mutations";
import { PageAction } from "../../types";
import { toast } from "sonner";
import { findTargetPage } from "./utils";

export const renamePage: PageAction =
  ([set]) => ({ id, newTitle }: { id: string; newTitle: string }) => {
    const appState = useAppStore.getState();
    const { pages } = appState;
    const oldPage = findTargetPage(id, pages);
    if (!oldPage) {
      return toast.error(
        `Trying to rename a page that does not exist ${oldPage}`,
      );
    }
    set((state) => {
      findTargetPage(id, state.pages)!.title = newTitle;
    });

    renamePageOnDatabase({ id, newTitle }).catch((error) => {
      toast.error(`Could not rename page, an error occurred: ${error.message}`);
      set((state) => {
        findTargetPage(id, state.pages)!.title = oldPage.title;
      });
    }).then(() => {
      toast.success(
        `Successfully renamed page: "${oldPage.title}" to "${newTitle}"`,
      );
    });
  };

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NbmeNavbarButtonWithIcon } from ".";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "@tanstack/react-router";

export const NbmeBackButton = () => {
  const navigate = useNavigate();
  return (
    <NbmeNavbarButtonWithIcon
      icon={<FontAwesomeIcon icon={faLeftLong} className="w-6 h-6" />}
      label="Back"
      onClick={() =>
        navigate({
          from: "/qbanks/$qbankEndpoint/questions/$questionId",
          to: "/qbanks/$qbankEndpoint/dashboard/search-questions",
          params: (p) => p,
        })}
    />
  );
};

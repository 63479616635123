import LeftToRightIcon from "@/components/icons/left-to-right-icon";
import RightToLeftIcon from "@/components/icons/right-to-left-icon";
import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap";

export const DirectionControls = () => {
  const { editor } = useRichTextEditorContext();
  if (!editor) return;

  return (
    <RichTextEditor.ControlsGroup>
      <RichTextEditor.Control
        aria-label="Set Text Direction:Left to Right"
        onClick={() => {
          editor.chain().setTextDirection("ltr").setTextAlign("left").run();
        }}
      >
        <LeftToRightIcon />
      </RichTextEditor.Control>
      <RichTextEditor.Control
        aria-label="Set Text Direction:Right to Left"
        onClick={() => {
          editor.chain().setTextDirection("rtl").setTextAlign("right").run();
        }}
      >
        <RightToLeftIcon />
      </RichTextEditor.Control>
    </RichTextEditor.ControlsGroup>
  );
};

import { createNoteSlice } from "./slices";
import { PageSliceCreator } from "./types";
import { state } from "./state";
import * as actions from "./actions";
import { mountActions } from "@/store/utils";

//@ts-ignore
export const createPageSlice: PageSliceCreator = (...args) => {
  return ({
    ...state,
    ...mountActions(actions, args),
    ...createNoteSlice(...args),
  });
};

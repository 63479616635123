import { createContext } from "react";
import { TriggerableState } from "./hooks/use-triggerable-state";

const triggerableContext = createContext<TriggerableState | undefined>(
  undefined,
);

export type TriggerableContext = TriggerableState;

export default triggerableContext;

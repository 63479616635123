"use client";
import * as RadixAccordion from "@radix-ui/react-accordion";
import { PropsWithChildren } from "react";

interface AccordionProps extends PropsWithChildren {
  orientation?: "horizontal" | "vertical";
  defaultValue?: string[];
  value: string[];
  onValueChange: (value: string[]) => void;
  className?: string;
}

const Accordion = (
  { defaultValue, orientation, value, onValueChange, className, children }:
    AccordionProps,
) => {
  return (
    <RadixAccordion.Root
      className={className}
      orientation={orientation}
      type={"multiple"}
      defaultValue={defaultValue}
      value={value}
      onValueChange={onValueChange}
    >
      {children}
    </RadixAccordion.Root>
  );
};

export default Accordion;

import { nbmeHintTriggerVariants } from "./NbmeHintTrigger";
import { NbmeSettingsSegment } from "./NbmeSettingsSegment";
import { NbmeSettingsLineWidthControl } from "./NbmeSettingsLineWidthControl";
import { useNbmePadding } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsQuestionPaddingControl } from "./NbmeSettingsQuestionPaddingControl";
import { NbmeSettingsExplanationPaddingControl } from "./NbmeSettingsExplanationControl";
import { NbmeSettingsPaddingControl } from "./NbmeSettingsPaddingControl";

export const NbmeSettingsPaddingSegment = () => {
  const [padding, setPadding] = useNbmePadding();
  return (
    <NbmeSettingsSegment className="flex-col justify-start items-start gap-2">
      <div className="flex flex-row justify-between items-center w-full text-xs">
        <p>Padding</p>
        <div className="flex flex-row justify-start items-center gap-2">
          <button
            className={nbmeHintTriggerVariants({
              style: "button",
              className: "text-2xs",
            })}
            onClick={() => setPadding({ question: 0, explanation: 0 })}
          >
            Reset
          </button>
          {(padding.question === padding.explanation) && (
            <p>{padding.question}px</p>
          )}
        </div>
      </div>
      <NbmeSettingsPaddingControl />
      <div className="flex flex-row justify-between items-center w-full text-xs">
        <p>Question Padding</p>
        <div className="flex flex-row justify-start items-center gap-2">
          <button
            className={nbmeHintTriggerVariants({
              style: "button",
              className: "text-2xs",
            })}
            onClick={() =>
              setPadding({ question: 0, explanation: padding.explanation })}
          >
            Reset
          </button>
          <p>{padding.question}px</p>
        </div>
      </div>
      <NbmeSettingsQuestionPaddingControl />
      <div className="flex flex-row justify-between items-center w-full text-xs">
        <p>Explanation Padding</p>
        <div className="flex flex-row justify-start items-center gap-2">
          <button
            className={nbmeHintTriggerVariants({
              style: "button",
              className: "text-2xs",
            })}
            onClick={() =>
              setPadding({
                question: padding.question,
                explanation: 0,
              })}
          >
            Reset
          </button>
          <p>{padding.explanation}px</p>
        </div>
      </div>
      <NbmeSettingsExplanationPaddingControl />
    </NbmeSettingsSegment>
  );
};

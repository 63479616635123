"use client";
import { DashboardFrame } from "@/components/DashboardFrame";
import DashboardSection from "@/components/DashboardSection";
import { DashboardUsageStatsCircle } from "@/components/DashboardUsageStatsCircle";
import DashboardStatsSection from "@/components/DashboardStatsSection";
import { Separator } from "@/components/Separator";
import { titlize } from "@/utils/common/titlize";
import { useRequiresUser } from "@/utils/customHooks";

import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { useEffect } from "react";
import { DashboardScoreStatsCircle } from "@/components/DashboardScoreStatsCircle";
import useQuestionBankTestStatistics from "@/hooks/test-hooks/use-question-bank-test-statistics";
import useQuestionBankQuestionStatistics from "@/hooks/question-hooks/use-question-bank-question-statistics";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import useAggregateQuestionBankStatRecord from "@/hooks/statrecord-hooks/use-aggregate-questionbank-statrecord";

const OverallStatsPage = () => {
  const user = useRequiresUser()!;
  const setHeaderText = useDashboardLayoutStore((state) => state.setHeaderText);
  const { data: questionBank } = useDashboardQuestionBank();

  const {
    data: questionBankProgress,
    isLoading: isQuestionBankStatisticsLoading,
  } = useQuestionBankQuestionStatistics({
    questionBankId: questionBank?.id ?? "",
  });
  const { data: statRecord, isLoading: isStatRecordLoading } =
    useAggregateQuestionBankStatRecord({
      questionBankId: questionBank?.id ?? "",
    });

  const {
    used,
    unused,
    incorrect,
    omitted,
    total,
    correct,
  } = questionBankProgress;
  const { data: testStatistics, isLoading: isTestStatisticsLoading } =
    useQuestionBankTestStatistics({
      questionBankEndpoint: questionBank?.endpoint ?? "",
    });

  const {
    suspended: suspendedTests = 0,
    completed: completedTests = 0,
    total: totalTests = 0,
  } = testStatistics;

  useEffect(() => {
    setHeaderText(
      `Welcome ${titlize(user?.name.split(" ").slice(0, 2).join(" "))}`,
    );
    return () => setHeaderText("");
  }, [setHeaderText, user?.name]);

  return (
    <DashboardFrame className="py-12 px-6 h-full min-h-full">
      <DashboardSection>
        <div className="flex flex-col justify-start items-start w-full gap-10">
          <div className="flex flex-col justify-start items-start w-full">
            <h1 className="mb-1.5 text-lg">Statistics</h1>
            <Separator orientation="horizontal" className="bg-gray-100" />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 md:grid-rows-2 grid-flow-row w-full gap-20">
            <div className="flex flex-row justify-center items-center">
              <DashboardScoreStatsCircle
                isLoading={isQuestionBankStatisticsLoading}
                omitted={omitted ?? 0}
                incorrect={incorrect ?? 0}
                correct={correct ?? 0}
                total={used ?? 0}
              />
            </div>
            <DashboardStatsSection
              isLoading={isQuestionBankStatisticsLoading}
              title="Your Score"
              items={[{ text: "Total Correct", count: correct ?? 0 }, {
                text: "Total Incorrect",
                count: incorrect ?? 0,
              }, { text: "Total Omitted", count: omitted ?? 0 }]}
            />
            <DashboardStatsSection
              isLoading={isStatRecordLoading}
              title="Answer Changes"
              items={[{
                text: "Correct to Incorrect",
                count: statRecord?.correctToIncorrect,
              }, {
                text: "Incorrect to Correct",
                count: statRecord?.incorrectToCorrect,
              }, {
                text: "Incorrect to Incorrect",
                count: statRecord?.incorrectToIncorrect,
              }]}
            />
            <div className="flex flex-row justify-center items-center">
              <DashboardUsageStatsCircle
                used={used ?? 0}
                total={total ?? 0}
                isLoading={isQuestionBankStatisticsLoading}
              />
            </div>
            <DashboardStatsSection
              isLoading={isQuestionBankStatisticsLoading}
              title="QBank Usage"
              items={[{ text: "Used Questions", count: used ?? 0 }, {
                text: "Unused Questions",
                count: unused ?? 0,
              }, { text: "Total Questions", count: total ?? 0 }]}
            />
            <DashboardStatsSection
              isLoading={isTestStatisticsLoading}
              title="Test Count"
              items={[{ text: "Tests Created", count: totalTests ?? 0 }, {
                text: "Tests Completed",
                count: completedTests ?? 0,
              }, { text: "Suspended Tests", count: suspendedTests ?? 0 }]}
            />
          </div>
        </div>
      </DashboardSection>
    </DashboardFrame>
  );
};

export default OverallStatsPage;

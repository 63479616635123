import React from "react";
import { NbmeSettingsSidebarSwitch } from ".";

export interface NbmeSettingsSidebarPercentageAnsweredVisibleControlProps {
  isPercentageAnsweredVisible: boolean;
  onIsPercentageAnsweredVisibleChange: (v: boolean) => void;
}

export const NbmeSettingsSidebarPercentageAnsweredVisibleControl = (
  { isPercentageAnsweredVisible, onIsPercentageAnsweredVisibleChange }:
    NbmeSettingsSidebarPercentageAnsweredVisibleControlProps,
) => {
  return (
    <NbmeSettingsSidebarSwitch
      checked={!isPercentageAnsweredVisible}
      onCheckedChange={onIsPercentageAnsweredVisibleChange}
    />
  );
};

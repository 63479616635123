import { Dispatch, PropsWithChildren, SetStateAction } from "react";
import NbmeSidePanelContextProvider from "./context-provider";
import useNbmeSidePanelState from "./hooks/use-nbme-side-panel-state";
import { ScreenEdge } from "@coursology/types/common/ScreenEdge";

type NbmeSidePanelRootProps =
  & ({
    open?: undefined;
    onOpenChanged?: undefined;
    draggable?: undefined;
  } | {
    open: boolean;
    onOpenChanged: ReturnType<typeof useNbmeSidePanelState>["setOpen"];
    draggable?: boolean;
  })
  & PropsWithChildren
  & ({ closeOnClickOutside?: undefined; setCloseOnClickOutside?: undefined } | {
    closeOnClickOutside: boolean;
    setCloseOnClickOutside: Dispatch<SetStateAction<boolean>>;
  })
  & {
    emergeFrom?: ScreenEdge;
  };

const Root = (
  {
    children,
    open,
    onOpenChanged,
    draggable,
    closeOnClickOutside,
    setCloseOnClickOutside,
    emergeFrom,
  }: NbmeSidePanelRootProps,
) => {
  const state = useNbmeSidePanelState();
  return (
    <NbmeSidePanelContextProvider
      value={{
        emergeFrom: emergeFrom ?? state.emergeFrom,
        setEmeregeFrom: state.setEmeregeFrom,
        open: open ?? state.open,
        setOpen: onOpenChanged ?? state.setOpen,
        bodyRef: state.bodyRef,
        draggable: draggable ?? state.draggable,
        setDraggable: state.setDraggable,
        closeOnClickOutside: closeOnClickOutside ?? state.closeOnClickOutside,
        setCloseOnClickOutside: setCloseOnClickOutside ??
          state.setCloseOnClickOutside,
      }}
    >
      <>
        {children}
      </>
    </NbmeSidePanelContextProvider>
  );
};

export default Root;

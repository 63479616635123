import { Paginator } from "@/components/Paginator";
import { useSearchQuestionsPageStore } from "../-store";

export const SearchQuestionsPagePaginator = () => {
  const {
    questions,
    currentPage,
    setCurrentPage,
    questionsPerPage,
    setQuestionsPerPage,
  } = useSearchQuestionsPageStore((state) => ({
    questions: state.questions,
    currentPage: state.currentPage,
    setCurrentPage: state.setCurrentPage,
    questionsPerPage: state.questionsPerPage,
    setQuestionsPerPage: state.setQuestionsPerPage,
  }));

  return (
    <Paginator
      itemSingularName="question"
      currentPage={currentPage}
      onCurrentPageChange={setCurrentPage}
      onItemsPerPageChange={setQuestionsPerPage}
      itemsPerPage={questionsPerPage}
      numberOfItems={questions.length}
    />
  );
};

import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface DashboardSectionProps extends PropsWithChildren {
  className?: string;
}

const DashboardSection = ({ children, className }: DashboardSectionProps) => {
  return (
    <div
      className={twMerge(
        "p-4 px-6 border-gray-300 warm:border-brown-400 dark:border-neutral-700",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default DashboardSection;

import { RichTextEditor } from "@mantine/tiptap";

export const HeadingControls = () => {
  return (
    <RichTextEditor.ControlsGroup>
      <RichTextEditor.H1 />
      <RichTextEditor.H2 />
      <RichTextEditor.H3 />
      <RichTextEditor.H4 />
    </RichTextEditor.ControlsGroup>
  );
};

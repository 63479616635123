import { Point } from "@/types";
import { NbmeFloatingWindow } from ".";
import { titlize } from "@/utils/common/titlize";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { Interweave } from "interweave";
import { tableFilter } from "@/utils/interweave/filters";
import { trpc } from "@/utils/trpc";
import { useBreakPoint, useViewportDimensions } from "@/utils/hooks";
import { useEffect } from "react";

export interface NbmeHintWindowProps {
  hint: {
    text: string;
    html: string | null;
    imageId: string | null;
    videoId: string | null;
  };
  isVisible: boolean;
  onIsVisibleChange: (v: boolean) => void;
  position?: Point;
  onPositionChange?: (newPos: Point) => void;
  onIsSelectionEnabledChange: (v: boolean) => void;
  fontSizeFactor: number;
}

export const NbmeHintWindow = (
  {
    isVisible,
    onIsVisibleChange,
    position,
    onPositionChange,
    hint,
    onIsSelectionEnabledChange,
    fontSizeFactor,
  }: NbmeHintWindowProps,
) => {
  const breakPoint = useBreakPoint();
  const { height: vwH, width: vwW } = useViewportDimensions();

  const { data: hintImage, isLoading: isHintImageLoading } = trpc.image.getById
    .useQuery({
      id: hint.imageId!,
      size: 3,
    }, {
      enabled: !!hint.imageId && isVisible,
    });

  const { data: hintVideo, isLoading: isHintVideoLoading } = trpc.video.getById
    .useQuery({
      id: hint.videoId!,
    }, {
      enabled: !!hint.videoId && isVisible,
    });

  useEffect(() => {
    if (hintImage && !position) {
      onPositionChange?.({
        y: (vwH / 2) - (hintImage.width / 4),
        x: (vwW / 2) - (hintImage.height / 4),
      });
    }
  }, [hintImage]);

  const isLoading = (hint.imageId && isHintImageLoading) ||
    (hint.videoId && isHintVideoLoading);

  return (
    <NbmeFloatingWindow
      isVisible={isVisible}
      title={titlize(hint.text)}
      position={position}
      onPositionChanged={onPositionChange}
      onCloseClicked={() => onIsVisibleChange(!isVisible)}
      className="w-[90vw] md:h-[minmax(auto,80vh)] md:w-auto md:resize"
      viewportClassName="overflow-x-auto overflow-y-auto"
      onIsSelectionEnabledChange={onIsSelectionEnabledChange}
    >
      <div className="flex flex-col items-center justify-center">
        {isLoading && (
          <div className="flex h-32 w-32 flex-col items-center justify-center">
            <LoadingSpinner className="h-16 w-16" />
          </div>
        )}

        {hint.html && (
          <Interweave
            content={hint.html}
            filters={[tableFilter(breakPoint, fontSizeFactor)]}
          />
        )}

        {hintVideo && (
          <video width={hintVideo.width} height={hintVideo.height} controls>
            <source src={hintVideo.dataUrl} type={hintVideo.mimeType || ""} />
          </video>
        )}

        {hintImage && (
          <img
            className={"w-auto"}
            src={hintImage.dataUrl}
            alt={hint.text}
          />
        )}
      </div>
    </NbmeFloatingWindow>
  );
};

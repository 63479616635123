"use client";
import { ComponentProps, createContext } from "react";
import WindowRoot from "./window-root";

type WindowContext = Omit<
  ComponentProps<typeof WindowRoot>,
  "children" | "className"
>;

const windowContext = createContext<WindowContext | undefined>(undefined);

export default windowContext;

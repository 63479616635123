import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RightChevron = () => {
  return (
    <FontAwesomeIcon
      className="w-2.5 h-2.5 text-gray-500"
      icon={faChevronRight}
    />
  );
};

import { toast } from "sonner";
import { fetchSubscriptions } from "./queries";
import { Action } from "./types";

export const initialize: Action = ([set]) => {
  fetchSubscriptions().catch((error) => {
    toast.error(
      `Could not load subscriptions, an error occurred: ${error.message}`,
    );
    set((state) => {
      state.subscriptionsLoading = false;
    });
  }).then((subs) => {
    set((state) => {
      state.subscriptions = subs!;
      state.subscriptionsLoading = false;
    });
  });
};

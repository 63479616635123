import {
  NbmeCalculatorButton,
  NbmeCalculatorButtonProps,
} from "./NbmeCalculatorButton";
import { twMerge } from "tailwind-merge";

interface NbmeCalculatorTertiaryButtonProps extends NbmeCalculatorButtonProps {}

export const NbmeCalculatorTertiaryButton = (
  { children, className, ...props }: NbmeCalculatorTertiaryButtonProps,
) => {
  return (
    <NbmeCalculatorButton
      className={twMerge(
        "bg-sky-500 dark:bg-sky-600 transition-colors border-0 text-white",
        className,
      )}
      {...props}
    >
      {children}
    </NbmeCalculatorButton>
  );
};

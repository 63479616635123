import { PropsWithClassName } from "@/types";
import { ArrayElement } from "@/types";
import { twMerge } from "tailwind-merge";

interface TabsProps<T extends string> extends PropsWithClassName {
  variant: "dashboard";
  tabs: T[];
  currentTab: ArrayElement<this["tabs"]>;
  onCurrentTabChange: (newCurrentTab: this["currentTab"]) => void;
}

export const Tabs = <T extends string>(
  { variant = "dashboard", tabs, onCurrentTabChange, currentTab, className }:
    TabsProps<T>,
) => {
  return (
    <div
      className={twMerge(
        `flex flex-row items-center justify-start 
                  gap-3 border-b border-gray-300 dark:border-neutral-700 w-full`,
        className,
      )}
    >
      {tabs.map((tab) => {
        const isCurrent = currentTab === tab;

        return (
          <button
            key={tab}
            className={twMerge(
              `px-4 py-2 text-gray-400 warm:text-brown-500 dark:text-neutral-300 hover:enabled:text-gray-700
               dark:hover:enabled:text-neutral-100 border-b border-transparent transition-colors  
               hover:enabled:border-b-primary-500 warm:hover:enabled:border-b-brown-700 dark:hover:enabled:border-b-neutral-100 ease-smooth duration-300`,
              isCurrent &&
                "border-b-primary-500 warm:border-b-brown-700 dark:border-b-neutral-100 text-gray-700 dark:text-neutral-100 warm:text-brown-700",
            )}
            onClick={() => onCurrentTabChange(tab)}
          >
            <p>{tab}</p>
          </button>
        );
      })}
    </div>
  );
};

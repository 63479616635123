import { cn } from "@/lib/utils";
import { PropsWithClassName } from "@/types";

interface CoursologyLogoProps extends PropsWithClassName {
  variant?: "mono" | "color";
}

export const CoursologyLogo = (
  { className, variant = "color" }: CoursologyLogoProps,
) => {
  const getImageSource = (): string => {
    return variant === "mono"
      ? "/coursology_logo_admin.svg"
      : "/coursology_logo.svg";
  };

  return (
    <div className={cn(className)}>
      <img
        className="h-auto w-[1528px]"
        src={getImageSource()}
        alt="coursology logo"
      />
    </div>
  );
};

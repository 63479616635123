import { ContextMenuRoot } from "@/components/context-menu";
import { cn } from "@/lib/utils";
import { ComponentProps } from "react";

const NbmeContextMenuRoot = (
  { children, className, ...props }: ComponentProps<typeof ContextMenuRoot>,
) => {
  return (
    <ContextMenuRoot
      className={cn("flex flex-row justify-center items-center", className)}
      {...props}
    >
      {children}
    </ContextMenuRoot>
  );
};

export default NbmeContextMenuRoot;

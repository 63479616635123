import { nbmeHintTriggerVariants } from "./NbmeHintTrigger";
import { NbmeSettingsSegment } from "./NbmeSettingsSegment";
import { NbmeSettingsLineWidthControl } from "./NbmeSettingsLineWidthControl";
import { useNbmeLineWidth } from "@/utils/stores/nbmeLayoutStore";

export const NbmeSettingsLineWidthSegment = () => {
  const [lineWidth, setLineWidth] = useNbmeLineWidth();
  return (
    <NbmeSettingsSegment className="flex-col justify-start items-start gap-2">
      <div className="flex flex-row justify-between items-center w-full text-xs">
        <p>Line Width</p>
        <div className="flex flex-row justify-start items-center gap-2">
          <button
            className={nbmeHintTriggerVariants({
              style: "button",
              className: "text-2xs",
            })}
            onClick={() => setLineWidth(70)}
          >
            Reset
          </button>
          <p>{lineWidth}%</p>
        </div>
      </div>
      <NbmeSettingsLineWidthControl />
    </NbmeSettingsSegment>
  );
};

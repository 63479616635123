import { DeleteColumnIcon } from "@/components/icons/hollow";
import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap";

export const DeleteColumn = () => {
  const { editor } = useRichTextEditorContext();
  return (
    <RichTextEditor.Control
      title="Delete column"
      aria-label="Delete column"
      disabled={!editor?.can().deleteColumn()}
      onClick={() => editor?.commands.deleteColumn()}
    >
      <DeleteColumnIcon className="w-4 h-4 text-neutral-800 dark:text-neutral-400" />
    </RichTextEditor.Control>
  );
};

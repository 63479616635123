import ContextMenu from "@/components/context-menu";
import HollowSolidTransition from "@/components/hollow-solid-transition";
import AddToNotebookIcon from "@/components/icons/add-to-notebook-icon";
import HollowAddToNotebookIcon from "@/components/icons/hollow/hollow-add-to-notebook-icon";
import { WithTooltip } from "@/components/pages/dashboard";
import NbmeContextMenuItem from "./nbme-context-menu-item";

const NbmeContextMenuAddToNotebook = () => {
  return (
    <WithTooltip tooltip="Add To Notebook">
      <NbmeContextMenuItem className="hover:scale-110 transition-transform active:scale-95">
        <HollowSolidTransition.Root className="p-1 dark:bg-neutral-600 bg-nbme-primary-600 rounded-primary ">
          <HollowSolidTransition.Solid>
            <AddToNotebookIcon className="w-5 h-5" />
          </HollowSolidTransition.Solid>
          <HollowSolidTransition.Hollow>
            <HollowAddToNotebookIcon className="w-5 h-5" />
          </HollowSolidTransition.Hollow>
        </HollowSolidTransition.Root>
      </NbmeContextMenuItem>
    </WithTooltip>
  );
};

export default NbmeContextMenuAddToNotebook;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../..";
import { faHome } from "@fortawesome/free-solid-svg-icons";

export const WelcomeButton = () => {
  return (
    <Button href="welcome">
      <FontAwesomeIcon icon={faHome} />
      <p>Welcome</p>
    </Button>
  );
};

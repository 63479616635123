import { FilterInterface } from "interweave";

export const listFilter: FilterInterface = {
  node(name, node) {
    // if (name === "li") {
    //   //Fix for the bullet being above the item
    //   const children = Array.from(node.children);
    //   if (children.length === 1) {
    //     const firstChild = children[0];
    //     const ELEMENT_NODE_TYPE = 1;
    //     if (firstChild.nodeType === ELEMENT_NODE_TYPE) {
    //     }
    //   }
    // }
    if (name === "ul") {
      node.className =
        "flex flex-col justify-start items-start list-inside list-disc";
    }
    return node;
  },
};

import { useNbmeAllocateSpaceForSidebar } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";

export const NbmeSettingsAllocateSpaceForSidebarControl = () => {
  const [allocateSpaceForSidebar, setAllocateSpaceForSidebar] =
    useNbmeAllocateSpaceForSidebar();

  return (
    <NbmeSettingsSwitch
      checked={allocateSpaceForSidebar}
      onCheckedChange={setAllocateSpaceForSidebar}
    />
  );
};

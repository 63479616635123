interface CounterProps {
  name: string;
  count: number;
}

export const Counter = ({ name, count }: CounterProps) => {
  return (
    <div className="flex flex-row justify-start items-center gap-2">
      <p>{name}</p>
      <p>{count}</p>
    </div>
  );
};

import { useNavigate } from "@tanstack/react-router";
import { useUser } from ".";
import { useEffect } from "react";

export const useAdmin = () => {
  const navigate = useNavigate();
  const user = useUser();
  useEffect(() => {
    if (user?.role !== "ADMIN") navigate({ to: "/home" });
  }, [user]);
  return user;
};

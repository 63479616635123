import { AuthCard } from "./AuthCard";
import { SignupForm } from "./SignupForm";

const SignupCard = () => {
  return (
    <AuthCard>
      <SignupForm />
    </AuthCard>
  );
};

export default SignupCard;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../..";
import { faTableList } from "@fortawesome/free-solid-svg-icons";

export const TestsButton = () => {
  return (
    <Button href="previous-tests">
      <FontAwesomeIcon icon={faTableList} />
      <p>Previous tests</p>
    </Button>
  );
};

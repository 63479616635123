import { useEffect } from "react";
import { useNotebookUi, useNotebookUiVariant } from ".";

export const useNotebookUiSidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useNotebookUi((state) => ({
    isSidebarOpen: state.isSidebarOpen,
    setIsSidebarOpen: state.setIsSidebarOpen,
  }));
  const [variant] = useNotebookUiVariant();

  useEffect(() => {
    if (variant === "desktop") return setIsSidebarOpen(true);
    setIsSidebarOpen(false);
  }, [variant]);

  return [isSidebarOpen, setIsSidebarOpen] as const;
};

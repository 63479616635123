import { climbToSurface, isChildOf } from ".";

//What does this do....?
//I have absolutely no idea...

export const getCharsBefore = (
  targetNode: Node,
  parentElement?: Node,
): string => {
  let charsBeforeNode = "";
  let parent: Node | HTMLElement | null | undefined = parentElement;
  if (!parent) {
    parent = climbToSurface(targetNode);
  }
  if (!parent) {
    throw Error(
      "Could not find question-text and question-explanation nodes",
    );
  }

  for (const currentNode of Array.from(parent.childNodes)) {
    if (
      isChildOf({
        parent: currentNode,
        node: targetNode,
        onNotChild: (currentTraversedNode) => {
          const TEXT_NODE_TYPE = 3;
          if (currentTraversedNode.nodeType === TEXT_NODE_TYPE) {
            charsBeforeNode += currentTraversedNode.textContent ?? "";
          }
        },
      })
    ) {
      break;
    }
  }
  return charsBeforeNode;
};

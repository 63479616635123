import ContextMenu from "@/components/context-menu";
import HollowSolidTransition from "@/components/hollow-solid-transition";
import AnnotateIcon from "@/components/icons/annotate-icon";
import HollowAnnotateIcon from "@/components/icons/hollow/hollow-annotate-icon";
import { WithTooltip } from "@/components/pages/dashboard";
import NbmeContextMenuItem from "./nbme-context-menu-item";

const NbmeContextMenuAnnotate = () => {
  return (
    <WithTooltip tooltip="Annotate">
      <NbmeContextMenuItem className="hover:scale-110 transition-transform active:scale-95">
        <HollowSolidTransition.Root className="p-1 dark:bg-neutral-600 bg-nbme-primary-600 rounded-primary ">
          <HollowSolidTransition.Solid>
            <AnnotateIcon className="w-5 h-5" />
          </HollowSolidTransition.Solid>
          <HollowSolidTransition.Hollow>
            <HollowAnnotateIcon className="w-5 h-5" />
          </HollowSolidTransition.Hollow>
        </HollowSolidTransition.Root>
      </NbmeContextMenuItem>
    </WithTooltip>
  );
};

export default NbmeContextMenuAnnotate;

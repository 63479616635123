import mergeHomogenousFunctions from "@/lib/utils/merge-homogenous-functions";
import useWindowContext from "./hooks/use-window-context";
import WindowTitlebarButton from "./window-titlebar-button";
import MaximizeIcon from "@/components/icons/maximize-icon";
import { ComponentProps } from "react";

type WindowMaximizeProps = Pick<
  ComponentProps<typeof WindowTitlebarButton>,
  "onClick"
>;

const WindowMaximize = ({ onClick }: WindowMaximizeProps) => {
  const { onMaximize } = useWindowContext();
  const combinedOnClick = mergeHomogenousFunctions(
    onMaximize,
    onClick as () => void,
  );

  return (
    <WindowTitlebarButton
      tooltip={"Maximize This Window"}
      className="bg-lime-500"
      onClick={() => combinedOnClick()}
    >
      <MaximizeIcon className="w-2.5 h-2.5" />
    </WindowTitlebarButton>
  );
};

export default WindowMaximize;

import { useNbmeAlignment } from "@/utils/stores/nbmeLayoutStore";
import { CheckMarkIcon } from "./icons";
import AlignLeftIcon from "./icons/align-left-icon";
import AlignCenterIcon from "./icons/align-center-icon";
import AlignRightIcon from "./icons/align-right-icon";
import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "@/assets/framer";

export const NbmeSettingsAlignmentControl = () => {
  const [alignment, setAlignment] = useNbmeAlignment();

  return (
    <div className="flex flex-row justify-start items-center divide-x divide-solid divide-gray-300 dark:divide-neutral-600 [&>*]:px-1.5">
      <div className="relative">
        <button
          className="text-gray-600 dark:text-neutral-700"
          onClick={() => setAlignment("left")}
        >
          <AlignLeftIcon />
        </button>
        <CheckedCheckMark visible={alignment === "left"} />
      </div>
      <div className="relative">
        <button
          className="text-gray-600 dark:text-neutral-700"
          onClick={() => setAlignment("center")}
        >
          <AlignCenterIcon />
        </button>
        <CheckedCheckMark visible={alignment === "center"} />
      </div>
      <div className="relative">
        <button
          className="text-gray-600 dark:text-neutral-700"
          onClick={() => setAlignment("right")}
        >
          <AlignRightIcon />
        </button>
        <CheckedCheckMark visible={alignment === "right"} />
      </div>
    </div>
  );
};

const CheckedCheckMark = ({ visible }: { visible: boolean }) => {
  return (
    <AnimatePresence mode="wait">
      {visible &&
        (
          <motion.div
            {...fadeAnimation}
            className="absolute rounded-full w-3 h-3 bg-nbme-primary-600 warm:bg-brown-800 dark:bg-neutral-600 text-white inline-flex justify-center items-center -top-2 -left-1 transition-colors"
          >
            <CheckMarkIcon className="w-2 h-2" />
          </motion.div>
        )}
    </AnimatePresence>
  );
};

import { createFileRoute, Outlet } from "@tanstack/react-router";
import { AdminNavbar } from "@/components/AdminNavbar";
import { PropsWithChildren } from "react";
import { useAdmin } from "./-hooks";
import { useOnClient } from "@/hooks";

export const Route = createFileRoute("/admin")({
  component: AdminLayout,
});

export default function AdminLayout() {
  const admin = useAdmin();
  const onClient = useOnClient();
  return (
    <div className="dark min-w-screen min-h-screen h-full w-full bg-neutral-900 text-white">
      <AdminNavbar
        user={onClient
          ? admin?.name.split(" ").slice(0, 2).join(" ") ?? ""
          : ""}
      />
      <div className="w-full h-full p-10">
        <Outlet />
      </div>
    </div>
  );
}

import useSubmitTestSlot from "@/hooks/test-slot-hooks/use-submit-testslot";
import useTestPageTestSelectedTestSlot from "./use-test-page-test-selected-test-slot";

const useTestPageSubmitTestSlot = () => {
  const { data: selectedSlot } = useTestPageTestSelectedTestSlot();
  const submitTestSlot = useSubmitTestSlot();
  return () =>
    selectedSlot &&
    submitTestSlot({
      testSlotId: selectedSlot.id,
      chosenChoiceId: selectedSlot.chosenId,
    });
};
export default useTestPageSubmitTestSlot;

import { PerformanceReportsPageState } from "../-store/performanceReportsPageStore";
import { ItemPerformanceReport } from "../-types";

export const getSubjectReportPercentages = (
  itemReport: ItemPerformanceReport,
  reportOptions: PerformanceReportsPageState["reportOptions"],
) => {
  const { used, total, correct, incorrect, omitted } = itemReport;
  const { type, options } = reportOptions;

  if (type === "QBank Usage") return [0, 0, (used / total) * 100];

  return [
    options.includes("Correct") ? used ? (correct / used) * 100 : 0 : 0,
    options.includes("Incorrect") ? used ? (incorrect / used) * 100 : 0 : 0,
    options.includes("Omitted") ? used ? (omitted / used) * 100 : 0 : 0,
  ];
};

import { createFileRoute } from "@tanstack/react-router";
import DashboardLoading from "../-loading";
import OverallStatsPage from "@/components/OverallStatsPage";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/welcome",
)({
  component: DashboardWelcomePage,
  pendingComponent: DashboardLoading,
});

function DashboardWelcomePage() {
  return <OverallStatsPage />;
}

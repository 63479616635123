import { StateCreator } from "zustand";
import { Slice } from "./types";
import { state } from "./state";
import * as actions from "./actions";
import { mountActions } from "@/store/utils";

export const createSubscriptionsSlice: StateCreator<
  Slice,
  [["zustand/immer", "never"]],
  [],
  Slice
> = (...args) => ({
  ...state,
  ...mountActions(actions, args),
});

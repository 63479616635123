export class CustomEqualitySet<T extends Object> {
  private _items: T[] = [];
  private _getUniqueKey: (item: T) => string | number;

  constructor(uniqueKeySelector: (item: T) => string | number) {
    this._getUniqueKey = uniqueKeySelector;
  }

  push(...items: T[]): void {
    for (const item of items) {
      const key = this._getUniqueKey(item);
      if (
        !this._items.some((existing) => this._getUniqueKey(existing) === key)
      ) {
        this._items.push(item);
      }
    }
  }
  has(item: T): boolean {
    return this._items.some((existing) =>
      this._getUniqueKey(existing) === this._getUniqueKey(item)
    );
  }

  items(): T[] {
    return [...this._items];
  }
}

import { useAppStore } from "@/store";
import useAuthSlice from "@/hooks/auth-hooks/use-auth-slice";

export const useCurrentUserTags = () => {
  const { currentUser } = useAuthSlice();
  const tags = useAppStore((state) =>
    state.tags.filter((t) => t.creatorId === currentUser?.id)
  );
  return tags;
};

import React from "react";
import Select from "./Select";
import LabeledCheckbox from "./LabeledCheckbox";
import { SelectItem } from "./SelectItem";
import { usePreviousTestsPageStore } from "@/utils/stores/previousTestsPageStore";
import { LabeledSelect } from "./LabeledSelect";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { BreakPoints } from "@/types";

export const PreviousTestsColumnSelect = () => {
  const breakPoint = useBreakPoint();
  const { setColumnVisible, row } = usePreviousTestsPageStore((
    state,
  ) => ({
    row: state.row,
    setColumnVisible: state.setColumnVisible,
  }));

  return (
    <LabeledSelect
      value={breakPoint < BreakPoints.Medium ? "Rows" : "Columns"}
      valueClassName="text-gray-500"
      id="column-select"
      label="Show:"
      labelClassName="text-sm lg:text-base text-gray-400"
      buttonClassName="min-w-[12rem]"
    >
      {row.columns.map((c, index) => (
        <SelectItem key={index}>
          <LabeledCheckbox
            label={c.name}
            checked={c.visible}
            id={c.name + index + "select"}
            key={index}
            onCheckedChange={(checked) => setColumnVisible(c, checked)}
            labelClassName="text-xs md:text-sm lg:text-base"
            className="w-full"
          />
        </SelectItem>
      ))}
    </LabeledSelect>
  );
};

import { Dispatch, SetStateAction, useState } from "react";

const useTriggerableState = (
  defaults?: Partial<{
    isOpen: boolean;
    onOpenChanged: Dispatch<SetStateAction<boolean>>;
  }>,
) => {
  const [isOpen, setIsOpen] = useState(defaults?.isOpen ?? false);

  return {
    isOpen: defaults?.isOpen ?? isOpen,
    setIsOpen: ((value) => {
      setIsOpen(value);
      defaults?.onOpenChanged?.(value);
    }) as typeof setIsOpen,
  };
};

export type TriggerableState = ReturnType<typeof useTriggerableState>;
export default useTriggerableState;

import { smoothTransition } from "@/assets/framer";
import { motion } from "framer-motion";
import { Fragment, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface ClickableCircularProgressBarProps {
  clickable?: true;
  strokeOnClick?: (index: number) => void;
}

interface UnclickableCircularProgressBarProps {
  clickable?: false;
  strokeOnClick?: undefined;
}

type CircularProgressBar =
  & PropsWithChildren
  & (ClickableCircularProgressBarProps | UnclickableCircularProgressBarProps)
  & {
    size?: number;
    percentages: number[];
    strokeClassNames?: string[];
  };

export const CircularProgressBar = (
  {
    percentages,
    size = 200,
    strokeClassNames,
    children,
    clickable = false,
    strokeOnClick,
  }: CircularProgressBar,
) => {
  const fractions = percentages.map((p) => p / 100);
  let total = 0;
  fractions.forEach((p) => total += p);
  if (total > 1.5 || total < 0) {
    console.error(`Percentages are invalid, total: ${total}`);
  }

  return (
    <div className="relative flex flex-col justify-center items-center p-8">
      <motion.svg
        className={"absolute top-[0.5] left-[0.5]"}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
      >
        <motion.circle
          key={"background"}
          className={twMerge(
            "stroke-[9] stroke-gray-100 dark:stroke-neutral-600 fill-none transition-colors duration-[500ms]",
            clickable && "cursor-pointer",
          )}
          cy={size / 2}
          cx={size / 2}
          r={size / 2 - 20}
          style={{
            rotate: -90,
            pathOffset: 0,
            pathLength: 1,
          }}
        >
        </motion.circle>
        {fractions.map((currentFraction, index, array) => {
          let previousFractions = 0;
          for (let i = index; i > 0; i--) {
            const previousFraction = array[i - 1];
            if (previousFraction === undefined) return;
            previousFractions += previousFraction;
          }
          const key = currentFraction + " " + index;
          if (currentFraction === 0) return <Fragment key={key}></Fragment>;
          const isLast = index === array.length - 1;
          const totalFraction = previousFractions + currentFraction;

          return (
            <Fragment
              key={key}
            >
              <motion.circle
                onClick={() => strokeOnClick?.(index)}
                className={twMerge(
                  "stroke-[10] stroke-lime-500 fill-none transition-colors duration-[500ms] z-[3]",
                  currentFraction === 0 && "stroke-transparent",
                  clickable && "cursor-pointer",
                  strokeClassNames?.[index],
                )}
                cy={size / 2}
                cx={size / 2}
                r={size / 2 - 20}
                style={{ rotate: -90 }}
                initial={{ pathLength: 0, pathOffset: 0 }}
                transition={{ ...smoothTransition, duration: 1 }}
                animate={{
                  pathLength: currentFraction,
                  pathOffset: previousFractions,
                }}
              >
              </motion.circle>
            </Fragment>
          );
        })}
      </motion.svg>
      {children}
    </div>
  );
};

/** Loops through properties of object A and B, taking non-function
 * properties from A and "merging" function calls between A and B
 * for any function properties in the objects */
export default function mergeObjectFunctions<T extends Object>(
  objectA?: T,
  objectB?: T,
) {
  if (!objectA) return objectB;
  if (!objectB) return objectA;

  const result = {} as T;
  //Loop through object A and give all its properites to result
  for (const key in objectA) {
    const value = objectA[key];
    result[key] = value;
  }

  //Loop through object B and see if there are any properties
  //that have not been included when we were looping through
  //object A, if not, then take it from object B.
  for (const key in objectB) {
    if (!(key in result)) {
      result[key] = objectB[key];
      continue;
    }
    //If it has been included From A before...
    const value = result[key];

    // And it's a function...
    if (typeof value === "function") {
      //Then we "merge" the two functions, that is, returning a function
      //of the same type that just calls the two functions from the two objects.
      result[key] = ((args: any) => {
        (objectA[key] as Function)?.(args);
        (objectB[key] as Function)?.(args);
      }) as typeof value;
    }
  }

  return result;
}

import { PropsWithClassName } from "@/types";
import * as RadixSwitch from "@radix-ui/react-switch";
import { twMerge } from "tailwind-merge";

export interface SwitchProps extends PropsWithClassName {
  checked: boolean;
  checkedClassName?: string;
  onCheckedChange: (checked: boolean) => void;
  disabled?: boolean;
  id?: string;
}

const Switch = (
  { checked, checkedClassName, onCheckedChange, id, disabled, className }:
    SwitchProps,
) => (
  <RadixSwitch.Root
    className={twMerge(
      "flex flex-row justify-start px-[1px] items-center bg-gray-200 warm:bg-brown-200 rounded-full border border-gray-300 transition-colors w-14 h-6",
      checked &&
        twMerge(
          "bg-primary-500 warm:bg-brown-900 border-transparent dark:bg-zinc-800",
          checkedClassName,
        ),
      checked && disabled &&
        "bg-primary-300 warm:bg-brown-700 dark:bg-zinc-500",
      !checked && disabled &&
        "bg-gray-200 warm:bg-brown-950/10 dark:bg-zinc-300",
      className,
    )}
    checked={checked}
    disabled={disabled}
    id={id}
    onCheckedChange={onCheckedChange}
  >
    <RadixSwitch.Thumb
      className={twMerge(
        "bg-white warm:bg-brown-50 ml-0 rounded-full w-5 h-5 transition-[margin-left]",
        checked && "ml-[32px]",
      )}
    />
  </RadixSwitch.Root>
);

export default Switch;

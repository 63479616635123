import createHintSlice from "./create-hint-slice";
import { Hint } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";

export type HintSliceActions = {
  addHint: (hint: Hint) => void;
  addHints: (hints: Hint[]) => void;
};
export type HintSliceState = typeof hintSliceState;

export type HintSlice = HintSliceState & HintSliceActions;

export type HintSliceCreator = StateCreator<
  HintSlice,
  [["zustand/immer", never]],
  [],
  HintSlice
>;

export type HintSliceModifiers = SliceModifiers<HintSliceCreator>;

export type HintSliceAction = (...modifiers: HintSliceModifiers) => void;

export const hintSliceState = {
  hints: {} as ArrayMap<Hint>,
};

export default createHintSlice;

import ResizableRoot from "./resizable-root";
import ResizableBody from "./resizable-body";
import ResizableCorners from "./resizable-corners";
import ResizableBorders from "./resizable-borders";
import ResizableUpperBorder from "./resizable-upper-border";
import ResizableLowerBorder from "./resizable-lower-border";
import ResizableLeftBorder from "./resizable-left-border";
import ResizableRightBorder from "./resizable-right-border";
import ResizableUpperLeftCorner from "./resizable-upper-left-corner";
import ResizableLowerLeftCorner from "./resizable-lower-left-corner";
import ResizableUpperRightCorner from "./resizable-upper-right-corner";
import ResizableLowerRightCorner from "./resizable-lower-right-corner";
import ResizableMaximizeButton from "./resizable-maximize-button";
import ResizableMaximizeToggle from "./resizable-maximize-toggle";

export { default as ResizableRoot } from "./resizable-root";
export { default as ResizableBody } from "./resizable-body";
export { default as ResizableCorners } from "./resizable-corners";
export { default as ResizableBorders } from "./resizable-borders";
export { default as ResizableUpperBorder } from "./resizable-upper-border";
export { default as ResizableLowerBorder } from "./resizable-lower-border";
export { default as ResizableLeftBorder } from "./resizable-left-border";
export { default as ResizableRightBorder } from "./resizable-right-border";
export { default as ResizableUpperLeftCorner } from "./resizable-upper-left-corner";
export { default as ResizableLowerLeftCorner } from "./resizable-lower-left-corner";
export { default as ResizableUpperRightCorner } from "./resizable-upper-right-corner";
export { default as ResizableLowerRightCorner } from "./resizable-lower-right-corner";
export { default as ResizableMaximizeButton } from "./resizable-maximize-button";
export { default as ResizableMaximizeToggle } from "./resizable-maximize-toggle";

export { default as useResizableState } from "./hooks/use-resizable-state";
export { default as useResizableContext } from "./hooks/use-resizable-context";

const Resizable = {
  Root: ResizableRoot,
  Body: ResizableBody,
  Corners: ResizableCorners,
  Borders: ResizableBorders,
  UpperBorder: ResizableUpperBorder,
  LowerBorder: ResizableLowerBorder,
  LeftBorder: ResizableLeftBorder,
  RightBorder: ResizableRightBorder,
  UpperLeftCorner: ResizableUpperLeftCorner,
  LowerLeftCorner: ResizableLowerLeftCorner,
  UpperRightCorner: ResizableUpperRightCorner,
  LowerRightCorner: ResizableLowerRightCorner,
  MaximizeButton: ResizableMaximizeButton,
  MaximizeToggle: ResizableMaximizeToggle,
};

export default Resizable;

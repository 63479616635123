import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../..";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export const HelpButton = () => {
  return (
    <Button href="help">
      <FontAwesomeIcon icon={faQuestionCircle} />
      <p>Help</p>
    </Button>
  );
};

import useSwitchSelectedTestSlot from "@/hooks/test-hooks/use-switch-selected-testslot";
import useTestPageTest from "./use-test-page-test";
import useTestPageTestSelectedTestSlot from "./use-test-page-test-selected-test-slot";

const useTestPageSwitchSelectedSlot = () => {
  const testQuery = useTestPageTest();
  const { data: test } = testQuery;
  const switchSelectedTestSlot = useSwitchSelectedTestSlot();
  return ({ slot }: { slot: number }) =>
    test && switchSelectedTestSlot({ testId: test.id, slot });
};

export default useTestPageSwitchSelectedSlot;

export const useTestPageRelativeSwitchSelectedSlot = () => {
  const selectedSlot = useTestPageTestSelectedTestSlot();
  const switchSelectedTestSlot = useTestPageSwitchSelectedSlot();
  const mutate = ({ offset }: { offset: number }) => {
    return selectedSlot.data &&
      switchSelectedTestSlot({ slot: selectedSlot.data.order + offset });
  };
  return mutate;
};

export const useTestPageSwitchToNextSlot = () => {
  const relativeSwitch = useTestPageRelativeSwitchSelectedSlot();
  const mutate = () => {
    return relativeSwitch({ offset: 1 });
  };
  return mutate;
};

export const useTestPageSwitchToPreviousSlot = () => {
  const relativeSwitch = useTestPageRelativeSwitchSelectedSlot();
  const mutate = () => {
    return relativeSwitch({ offset: -1 });
  };
  return mutate;
};

"use client";
import { faBars, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import React from "react";
import { DarkModeSwitch } from "./DarkModeSwitch";
import { buttonVariants } from "./ui/button";
import { Link } from "@tanstack/react-router";

export const DashboardHeader = () => {
  const { toggleSidebar, headerText } = useDashboardLayoutStore((state) => ({
    toggleSidebar: state.toggleSidebar,
    headerText: state.headerText,
  }));

  return (
    <div className="w-full sticky top-0 border-b border-gray-300 dark:border-b-neutral-800 lg:relative flex justify-between items-center bg-white dark:bg-neutral-900 transition-colors z-[48] h-[3.5rem] dark:text-neutral-50">
      <div className="flex justify-start items-center px-3 py-3 gap-4">
        <button
          type="button"
          className="bg-primary-500 warm:bg-brown-800 dark:bg-neutral-700 rounded-primary px-3 py-1 text-white dark:hover:bg-neutral-600 transition-colors"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <h1 className="font-light md:text-lg text-base">{headerText}</h1>
      </div>
      <div className="flex flex-row justify-start items-center gap-2 me-3">
        <DarkModeSwitch />
        <Link
          className={buttonVariants({ variant: "ghost", className: "gap-2" })}
          to={"/home"}
        >
          <FontAwesomeIcon icon={faHome} />
          <p className="md:block hidden">Home</p>
        </Link>
      </div>
    </div>
  );
};

import NbmeContextMenuAddToNotebook from "./nbme-context-menu-add-to-notebook";
import NbmeContextMenuAnnotate from "./nbme-context-menu-annotate";
import NbmeContextMenuArea from "./nbme-context-menu-area";
import NbmeContextMenuBody from "./nbme-context-menu-body";
import NbmeContextMenuChangeHighlightColor from "./nbme-context-menu-change-highlight-color";
import NbmeContextMenuCopyToClipboard from "./nbme-context-menu-copy-to-clipboard";
import NbmeContextMenuDelete from "./nbme-context-menu-delete";
import NbmeContextMenuItem from "./nbme-context-menu-item";
import NbmeContextMenuRoot from "./nbme-context-menu-root";
import NbmeContextMenuToggle from "./nbme-context-menu-toggle";

const NbmeContextMenu = {
  Root: NbmeContextMenuRoot,
  Body: NbmeContextMenuBody,
  Area: NbmeContextMenuArea,
  Toggle: NbmeContextMenuToggle,
  Item: NbmeContextMenuItem,
  ChangeHighlightColor: NbmeContextMenuChangeHighlightColor,
  CopyToClipboard: NbmeContextMenuCopyToClipboard,
  Annotate: NbmeContextMenuAnnotate,
  AddToNotebook: NbmeContextMenuAddToNotebook,
  Delete: NbmeContextMenuDelete,
};
export default NbmeContextMenu;

import { useAppStore } from "@/store";
import { useEffect } from "react";

export const useAppTheme = () => {
  const { theme, setTheme } = useAppStore((
    state,
  ) => ({
    theme: state.theme,
    setTheme: state.setTheme,
  }));

  useEffect(() => {
    document.documentElement.classList.remove("light", "dark", "warm");
    document.documentElement.classList.add(theme);
  }, [theme]);

  return [theme, setTheme] as const;
};

import { faFont, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithClassName } from "@/types";
import { clamp } from "@/utils/common/clamp";
import { twMerge } from "tailwind-merge";

interface FontSizeControlProps extends PropsWithClassName {
  factor: number;
  step?: number;
  onChange: (newValue: number) => void;
  minimum?: number;
}

export const FontSizeControl = (
  {
    factor,
    onChange,
    minimum = 1,
    className,
    step = 0.25,
  }: FontSizeControlProps,
) => {
  const isDecreaseDisabled = factor <= minimum;
  const sharedClassName =
    "px-2 py-1 text-gray-700 hover:text-primary-400 transition-colors";

  const handleDecreaseClicked = () => {
    onChange(clamp({ max: Infinity, min: minimum, num: factor - step }));
  };

  const handleIncreaseClicked = () => {
    onChange(factor + step);
  };

  const handleResetClicked = () => {
    onChange(minimum);
  };

  return (
    <div
      className={twMerge(
        "h-full flex flex-row justify-between items-center text-sm",
        className,
      )}
    >
      <button
        className={twMerge(
          "transition-colors",
          sharedClassName,
          isDecreaseDisabled && "text-gray-200 hover:text-gray-200",
        )}
        disabled={isDecreaseDisabled}
        onClick={handleDecreaseClicked}
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <button
        className={twMerge(
          "border-x border-x-gray-300",
          sharedClassName,
          isDecreaseDisabled && "text-gray-200 hover:text-gray-200",
        )}
        disabled={isDecreaseDisabled}
        onClick={handleResetClicked}
      >
        <FontAwesomeIcon icon={faFont} />
      </button>
      <button
        className={twMerge(
          "transition-colors",
          sharedClassName,
        )}
        onClick={handleIncreaseClicked}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
};

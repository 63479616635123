import { PropsWithAs, PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";
import useTriggerableContext from "./hooks/use-triggerable-context";

type TriggerableTriggerProps =
  & PropsWithChildren
  & PropsWithClassName
  & PropsWithAs;

const TriggerableTrigger = (
  { children, className, as }: TriggerableTriggerProps,
) => {
  const { setIsOpen } = useTriggerableContext();
  const Parent = as ?? "button";
  return (
    <Parent
      className={cn("", className)}
      onClick={() => setIsOpen?.(true)}
    >
      {children}
    </Parent>
  );
};

export default TriggerableTrigger;

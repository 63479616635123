import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { PropsWithClassName } from "@/types";
import { cva, VariantProps } from "class-variance-authority";
import { PropsWithChildren, ReactNode } from "react";

type WithTooltipProps =
  & {
    tooltip: ReactNode;
  }
  & PropsWithChildren
  & PropsWithClassName
  & VariantProps<typeof tooltipVariants>;

const tooltipVariants = cva("", {
  variants: {
    intent: {
      nbme: "bg-nbme-primary-600 border border-nbme-primary-500 shadow-md",
    },
  },
});

export const WithTooltip = (
  { children, tooltip, className, intent }: WithTooltipProps,
) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={3}>
        <TooltipTrigger>
          {children}
        </TooltipTrigger>
        <TooltipContent className={tooltipVariants({ intent, className })}>
          {tooltip}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

import { PerformanceTestResultsPageProgressBar } from ".";

export const PerformanceTestResultsPageScoreSection = (
  { testId }: { testId: number },
) => {
  return (
    <div className="flex flex-col justify-start items-start gap-16 w-full md:w-[20%]">
      <p>Your Score</p>
      <PerformanceTestResultsPageProgressBar testId={testId} />
    </div>
  );
};

import {
  NbmeSettingsSidebarConfirmAnswerOmissionControl,
  NbmeSettingsSidebarSection,
  NbmeSettingsSidebarSegment,
} from ".";
import {
  NbmeSettingsSidebarPercentageAnsweredVisibleControl,
  NbmeSettingsSidebarPercentageAnsweredVisibleControlProps,
} from "./NbmeSettingsSidebarPercentageAnsweredVisibleControl";

export interface NbmeSettingsSidebarFeaturesSectionProps<
  T = "question" | "test",
> extends NbmeSettingsSidebarPercentageAnsweredVisibleControlProps {
  variant: T;
  isConfirmAnswerOmission?: T extends "test" ? boolean : undefined;
  onIsConfirmAnswerOmissionChange?: T extends "test" ? (v: boolean) => void
    : undefined;
}

export const NbmeSettingsSidebarFeaturesSection = <T = "test" | "question">(
  {
    isConfirmAnswerOmission,
    onIsConfirmAnswerOmissionChange,
    onIsPercentageAnsweredVisibleChange,
    isPercentageAnsweredVisible,
    variant,
  }: NbmeSettingsSidebarFeaturesSectionProps<T>,
) => {
  return (
    <NbmeSettingsSidebarSection
      title={"Features"}
    >
      {variant === "test" &&
        (
          <NbmeSettingsSidebarSegment>
            <p className="text-xs">Confirm Answer Omission</p>
            <NbmeSettingsSidebarConfirmAnswerOmissionControl
              isConfirmAnswerOmission={isConfirmAnswerOmission!}
              onIsConfirmAnswerOmissionChange={onIsConfirmAnswerOmissionChange!}
            />
          </NbmeSettingsSidebarSegment>
        )}
      <NbmeSettingsSidebarSegment className="border-b-0 pb-0">
        <p className="text-xs">Hide % Answered Correctly</p>
        <NbmeSettingsSidebarPercentageAnsweredVisibleControl
          onIsPercentageAnsweredVisibleChange={onIsPercentageAnsweredVisibleChange}
          isPercentageAnsweredVisible={isPercentageAnsweredVisible}
        />
      </NbmeSettingsSidebarSegment>
    </NbmeSettingsSidebarSection>
  );
};

import useTestPageTestSelectedTestSlot from "./use-test-page-test-selected-test-slot";

const useTestPageTestSelectedTestSlotQuestion = () => {
  const testSlotQuery = useTestPageTestSelectedTestSlot();
  return ({
    ...testSlotQuery,
    data: testSlotQuery.data?.question,
  });
};

export default useTestPageTestSelectedTestSlotQuestion;

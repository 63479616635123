import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
} from "react";
import { twMerge } from "tailwind-merge";

export interface NbmeNavbarButtonProps extends
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  PropsWithChildren {
}

export const NbmeNavbarButton = (
  { children, className, ...props }: NbmeNavbarButtonProps,
) => {
  return (
    <button
      className={twMerge(
        "hover:bg-nbme-primary-800 py-1 px-1 rounded-sm dark:hover:bg-nbme-primary-dark-700 transition-colors",
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
};

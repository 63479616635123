import { useAppStore } from "@/store";
import { useNoteFinder } from ".";

export const useSelectedNote = () => {
  const selectedNoteId = useAppStore((state) => (
    state.selectedNoteId
  ));

  const findNote = useNoteFinder();
  const selectedNote = findNote(selectedNoteId);
  return selectedNote;
};

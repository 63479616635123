import { DraggableRoot } from "@/components/draggable";
import { ResizableRoot } from "@/components/resizable";
import { TriggerableRoot } from "@/components/triggerable";
import { ViewerRoot } from "@/components/viewer";
import { WindowRoot } from "@/components/window";
import { PropsWithChildren } from "react";
import useFloatingWindowState from "./hooks/use-floating-window-state";
import { ArgumentTypes } from "@/types";
import FloatingWindowContextProvider from "./floating-window-context-provider";

type FloatingWindowRootProps =
  & PropsWithChildren
  & ArgumentTypes<typeof useFloatingWindowState>[0];

const FloatingWindowRoot = (
  { children, ...defaults }: FloatingWindowRootProps,
) => {
  const state = useFloatingWindowState(defaults);
  const { triggerable, draggable, resizable, viewer } = state;
  const { maximize, minimize } = resizable;

  return (
    <FloatingWindowContextProvider value={state}>
      <TriggerableRoot state={triggerable}>
        <DraggableRoot state={draggable}>
          <ResizableRoot
            state={resizable}
          >
            <WindowRoot
              onClose={() => triggerable.setIsOpen(false)}
              onMaximize={() => {
                maximize();
              }}
              onMinimize={() => minimize()}
            >
              <ViewerRoot
                ref={viewer.ref}
                contentRef={viewer.contentRef}
                panning={{ disabled: !viewer.hasOverflow }}
                onZoomStop={(ref) => {
                  viewer.setHasOverflow(
                    ref.state.positionY <= 10 ||
                      ref.state.positionX <= 10,
                  );
                }}
              >
                {children}
              </ViewerRoot>
            </WindowRoot>
          </ResizableRoot>
        </DraggableRoot>
      </TriggerableRoot>
    </FloatingWindowContextProvider>
  );
};

export default FloatingWindowRoot;

import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

type NbmeSidePanelHeaderProps = {} & PropsWithClassName & PropsWithChildren;

const Header = ({ className, children }: NbmeSidePanelHeaderProps) => {
  return (
    <div
      className={cn(
        "sticky top-0 flex flex-row justify-center items-center w-full bg-nbme-primary-50 warm:bg-brown-100 dark:bg-neutral-100 py-3 border-b border-b-gray-400 warm:border-b-brown-200 dark:border-b-neutral-400",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Header;

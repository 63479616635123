import useQuestionBankQuestionNotes from "@/hooks/question-note-hooks/use-question-bank-question-notes";
import { useState } from "react";

const useNotesPageState = (questionBankEndpoint: string) => {
  const query = useState<string>("");
  const sortBy = useState<"topicId" | "subjectId" | "systemId" | "questionId">(
    "questionId",
  );
  const currentPage = useState<number>(1);
  const notesPerPage = useState<5 | 10 | 15 | 25 | 50 | 100>(10);
  const questionNotesQuery = useQuestionBankQuestionNotes({
    questionBankEndpoint: questionBankEndpoint,
  }, { sortBy: sortBy[0], searchQuery: query[0] });

  return {
    query,
    sortBy,
    currentPage,
    notesPerPage,
    questionBankEndpoint,
    questionNotes: questionNotesQuery,
  };
};

export default useNotesPageState;

import { ProgressBar } from "@/components/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { sharpTransition, smoothTransition } from "@/assets/framer";
import { ToolTip } from "@/components/ToolTip";
import useTestScore from "@/hooks/test-hooks/use-test-score";
import useTestAverageScore from "@/hooks/test-hooks/use-test-average-score";

export const PerformanceTestResultsPageProgressBar = (
  { testId }: { testId: number },
) => {
  const { data: score } = useTestScore({ testId });
  const { data: average } = useTestAverageScore({ testId });
  return (
    <ProgressBar
      percentages={[score || 0]}
      className="h-10 rounded-3xl"
    >
      {!!score &&
        (
          <motion.div
            key={"your-score"}
            className={"absolute flex flex-col justify-start items-center text-lime-500 gap top-0 -translate-y-[35px] z-[10]"}
            initial={{ left: `${0}%` }}
            animate={{ left: `${score}%` }}
            transition={{ ...smoothTransition, duration: 0.5 }}
          >
            <div className="flex flex-row justify-center items-center absolute -translate-y-[8px]">
              {score.toFixed(1)}%
            </div>
            <FontAwesomeIcon
              icon={faSortDown}
              className="absolute translate-y-[10px]"
            />
          </motion.div>
        )}
      {!!average &&
        (
          <>
            <motion.div
              className={"absolute h-full w-[2px] bg-gray-300 dark:bg-neutral-600 text-gray-500 gap top-0 z-[10]"}
              style={{ left: `${average}%` }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ...sharpTransition, duration: 0.9 }}
            >
            </motion.div>
            <motion.div
              className="absolute flex flex-row justify-center items-center text-gray-500 -translate-x-[50%]"
              style={{ left: `${average}%` }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ...sharpTransition, duration: 0.9 }}
            >
              <ToolTip className="flex flex-row justify-center items-center translate-y-[150%] md:text-xs text-2xs">
                Avg: {average.toFixed(1)}%
              </ToolTip>
            </motion.div>
          </>
        )}
    </ProgressBar>
  );
};

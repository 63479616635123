import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankQuestionHeaders from "./use-question-bank-question-headers";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const useQuestionBankQuestionStatistics = (args: QuestionBankIdentifier) => {
  const questionHeadersQuery = useQuestionBankQuestionHeaders(args);
  const { data: questionHeaders } = questionHeadersQuery;
  let progress = {
    total: 0,
    used: 0,
    correct: 0,
    incorrect: 0,
    marked: 0,
    omitted: 0,
    unused: 0,
    correctToIncorrect: 0,
    incorrectToCorrect: 0,
    incorrectToIncorrect: 0,
  };
  ArrayMapUtils.forEach(questionHeaders!, (questionHeader) => {
    progress.total++;
    if (questionHeader.marked) {
      progress.marked++;
    }
    if (questionHeader.used) {
      progress.used++;
      switch (questionHeader.used) {
        case "omitted":
          progress.omitted++;
          break;
        case "correct":
          progress.correct++;
          break;
        case "incorrect":
          progress.incorrect++;
          break;
      }
      switch (questionHeader.change) {
        case "i2c":
          progress.incorrectToCorrect++;
          break;
        case "c2i":
          progress.correctToIncorrect++;
          break;
        case "i2i":
          progress.incorrectToIncorrect++;
          break;
      }
      return;
    }
    progress.unused++;
  });

  return { ...questionHeadersQuery, data: progress };
};

export default useQuestionBankQuestionStatistics;

import { PropsWithChildren } from "react";
import useContextMenuContext from "./hooks/use-context-menu-context";
import { PropsWithAs, PropsWithClassName } from "@/types";

type ContextMenuAreaProps =
  & PropsWithChildren
  & PropsWithClassName
  & PropsWithAs;
const ContextMenuArea = ({ children, className, as }: ContextMenuAreaProps) => {
  const { areaRef, areaEvents } = useContextMenuContext();
  const Parent = as ?? "div";

  return (
    <Parent
      {...areaEvents}
      ref={areaRef}
      className={className}
    >
      {children}
    </Parent>
  );
};

export default ContextMenuArea;

import React from "react";
import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

export const NbmeTutorialButton = () => {
  return <></>;
  return (
    <NbmeIconNavbarButton
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faCircleQuestion} className="w-8 h-8" />}
      label="Tutorial"
    />
  );
};

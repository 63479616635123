import { sharpTransition, smoothTransition } from "@/assets/framer";
import { PropsWithClassName } from "@/types";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface ProgressBarProps extends PropsWithClassName, PropsWithChildren {
  percentages: number[];
  percentageClassNames?: string[];
  backgroundClassName?: string;
}

export const ProgressBar = (
  {
    percentages,
    percentageClassNames = [],
    className,
    children,
    backgroundClassName,
  }: ProgressBarProps,
) => {
  return (
    <div
      className={twMerge(
        "relative h-2 w-full rounded-full",
        className,
      )}
    >
      {children}
      <div
        className={twMerge(
          "relative h-full w-full overflow-clip rounded-full bg-gray-200 dark:bg-neutral-800 z-[1]",
          backgroundClassName,
        )}
      >
        {percentages.map((percentage, index, array) => {
          let previousPercentages = 0;
          for (let i = index; i > 0; i--) {
            const previousPercentage = array[i - 1];
            if (previousPercentage === undefined) return;
            previousPercentages += previousPercentage;
          }

          return (
            <motion.div
              key={index}
              className={twMerge(
                "absolute h-full bg-lime-500 transition-[border-radius]",
                percentageClassNames[index],
              )}
              initial={{
                left: `${previousPercentages}%`,
                width: `${percentage}%`,
              }}
              animate={{
                left: `${previousPercentages}%`,
                width: `${percentage}%`,
              }}
              transition={sharpTransition}
            >
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

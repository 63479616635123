import { NbmeNavbarButtonWithIcon } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

interface NbmeNotebookNavbarButton {
  isNotebookVisible: boolean;
  onIsNotebookVisibleChange: (v: boolean) => void;
}

export const NbmeNotebookNavbarButton = (
  { isNotebookVisible, onIsNotebookVisibleChange }: NbmeNotebookNavbarButton,
) => {
  return (
    <NbmeNavbarButtonWithIcon
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faBook} className="w-6 h-6" />}
      label="My Notebook"
      onClick={() => onIsNotebookVisibleChange(!isNotebookVisible)}
    />
  );
};

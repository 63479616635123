import { createFileRoute } from "@tanstack/react-router";
import CreateTestOrderedSection from "@/components/CreateTestOrderedSection";
import useQuestionMode from "../-hooks/use-question-mode";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/create-test/_create-test/ordered",
)({
  component: CreateOrderedTestPage,
});

function CreateOrderedTestPage() {
  useQuestionMode("ordered");
  return (
    <>
      <CreateTestOrderedSection />
    </>
  );
}

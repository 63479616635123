import { useNbmeNightMode } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";

export const NbmeSettingsNightModeControl = () => {
  const { nightMode, setNightMode } = useNbmeNightMode();
  const { on } = nightMode;

  return (
    <NbmeSettingsSwitch
      checked={!!on}
      onCheckedChange={(newValue) => {
        setNightMode("on", newValue);
      }}
    />
  );
};

import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PreviousTestsPageTestNameProps {
  testId: number;
  testName: string;
  onRenameButtonClick: (testId: number) => void;
}

export const PreviousTestsPageTestName = (
  { testName, testId, onRenameButtonClick }: PreviousTestsPageTestNameProps,
) => {
  return (
    <div className="flex flex-row md:justify-start justify-center items-center w-[9rem] gap-2">
      <p>{testName}</p>
      <button
        onClick={() => onRenameButtonClick(testId)}
        className="flex flex-col justify-center items-center -translate-y-full text-white rounded-full w-2 h-2 md:w-4 md:h-4 md:p-3 p-2 bg-primary-500 warm:bg-brown-700 border border-transparent dark:bg-neutral-700 dark:border-neutral-600 enabled:hover:scale-105 transition-transform"
      >
        <FontAwesomeIcon className="md:w-3 md:h-3 w-2 h-2" icon={faPencil} />
      </button>
    </div>
  );
};

function splitArray<T>(arr: T[], numParts: number): T[][] {
  if (numParts <= 0) {
    throw new Error("Number of parts must be greater than 0");
  }
  const result: T[][] = new Array(numParts).fill(null).map(() => []);

  const subArrayLength = parseInt((arr.length / numParts).toFixed(0));

  for (let i = 0; i < numParts; i++) {
    const reachedEnd = i === numParts - 1;
    const beginning = i * subArrayLength;
    result.push(
      arr.slice(beginning, reachedEnd ? undefined : beginning + subArrayLength),
    );
  }

  //Take slice from current beginning to a current length
  //which equals the size of the array divided by the number
  //of parts.

  //Keep doing that until we finish the number of parts.

  return result.filter((a) => a.length !== 0);
}

export default splitArray;

"use client";
import useClampedState from "@/hooks/use-clamped-state";
import { Point, Setter } from "@/types";
import { clamp } from "@/utils/common/clamp";
import { useViewportDimensions } from "@/utils/hooks";
import { useCallback, useEffect, useRef, useState } from "react";

const useResizableState = (opts?: {
  initialDimensions?: {
    x: number;
    y: number;
  };
  maxDimensions?: {
    x: number;
    y: number;
  };
  minDimensions?: {
    x: number;
    y: number;
  };
  isResizable?: boolean;
  onIsResizableChanged?: Setter<boolean>;
  isMaximizable?: boolean;
  events?: {
    onResizeStart?: () => void;
    onResize?: (info: {
      delta: Point;
      isMinimumReached: { x: boolean; y: boolean };
      isMaximumReached: { x: boolean; y: boolean };
    }) => void;
    onResizeEnd?: () => void;
    onMaximize?: () => void;
    onMinimize?: () => void;
  };
}) => {
  const viewport = useViewportDimensions();

  const clampDimensions = useCallback(
    (dimensions: Point | undefined): Point | undefined => {
      if (!dimensions) return undefined;

      return {
        x: clamp({
          num: dimensions.x,
          min: opts?.minDimensions?.x ?? 0,
          max: opts?.maxDimensions?.x ?? viewport.width,
        }),
        y: clamp({
          num: dimensions.y,
          min: opts?.minDimensions?.y ?? 0,
          max: opts?.maxDimensions?.y ?? viewport.height,
        }),
      };
    },
    [opts?.maxDimensions, opts?.minDimensions, viewport],
  );

  const [dimensions, setDimensions] = useClampedState<Point | undefined>(
    opts?.initialDimensions ?? undefined,
    clampDimensions,
  );

  const [minimizedDimensions, setMinimizedDimensions] = useState(dimensions);

  const [isResizable, setIsResizable] = useState<boolean>(true);

  const [isMaximized, setIsMaximized] = useState<boolean>(false);

  const [isResizing, setIsResizing] = useState(false);

  const isMaximizable = opts?.isMaximizable ?? true;

  const bodyRef = useRef<HTMLDivElement>(null);

  const maximize = useCallback(() => {
    if (!isMaximizable) return;
    if (isMaximized) return;
    setIsResizable(false);
    setMinimizedDimensions(dimensions);
    setDimensions({ x: viewport.width, y: viewport.height }, false);
    setIsMaximized(true);
  }, [setIsResizable, setDimensions, setIsMaximized, opts?.events]);

  useEffect(() => {
    if (isMaximized) return opts?.events?.onMaximize?.();
    opts?.events?.onMinimize?.();
  }, [isMaximized]);

  const minimize = useCallback(() => {
    if (!isMaximizable) return;
    if (!isMaximized) return;
    setIsResizable(true);
    setDimensions(minimizedDimensions);
    setIsMaximized(false);
  }, [isMaximized, setIsMaximized, setDimensions]);

  return {
    dimensions,
    setDimensions,
    isMaximized,
    isResizing,
    isMaximizable,
    setIsResizing,
    maximize,
    minimize,
    bodyRef,
    ...opts,
    isResizable: opts?.isResizable ?? isResizable,
    setIsResizable: opts?.onIsResizableChanged ?? setIsResizable,
  };
};

export type ResizableState = ReturnType<typeof useResizableState>;

export default useResizableState;

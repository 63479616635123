import { ResizablePanelGroup } from "@/components/ui/resizable";
import { PropsWithChildren } from "react";

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="relative w-full h-full min-h-full bg-white warm:bg-brown-50 dark:bg-neutral-950 transition-colors"
    >
      {children}
    </ResizablePanelGroup>
  );
};

import { DashboardCenterPiece } from "@/components/DashboardCenterPiece";
import {
  faChartLine,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import {
  usePerformanceReportsPageQuery,
  usePerformanceReportsPageTabs,
} from "../-hooks";

export const PerformanceReportsPageNoResultsCenterPiece = () => {
  const { query } = usePerformanceReportsPageQuery();
  const { currentTab } = usePerformanceReportsPageTabs();

  return (
    <DashboardCenterPiece
      icons={[faChartLine, faMagnifyingGlass]}
      label={`No ${currentTab.slice(0, -1)} found with the name '${query}'`}
      className="w-full"
    />
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../..";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export const QuestionsButton = () => {
  return (
    <Button href="search-questions">
      <FontAwesomeIcon icon={faMagnifyingGlass} />
      <p>Search</p>
    </Button>
  );
};

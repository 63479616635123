import { clamp } from "@/utils/common/clamp";
import { useNbmeNightMode } from "@/utils/stores/nbmeLayoutStore";

interface NbmeSettingsNightModeTimeControlProps {
  controls: "startTime" | "endTime";
}

const formatUnits = (units: number): string => {
  if (units < 10) {
    return `0${units}`;
  } else return `${units}`;
};

const clampHours = (hours: number) => clamp({ min: 0, max: 12, num: hours });

const clampMinutes = (minutes: number) =>
  clamp({ min: 0, max: 59, num: minutes });

export const NbmeSettingsNightModeTimeControl = (
  { controls }: NbmeSettingsNightModeTimeControlProps,
) => {
  const { nightMode, setNightMode } = useNbmeNightMode();
  const { on } = nightMode;

  const hours = nightMode[controls].hours;
  const minutes = nightMode[controls].minutes;
  const isAm = nightMode[controls].isAm;

  const setHours = (newHours: string) =>
    setNightMode(controls, {
      ...nightMode[controls],
      hours: clampHours(Number(newHours)),
    });

  const setMinutes = (newMinutes: string) =>
    setNightMode(controls, {
      ...nightMode[controls],
      minutes: clampMinutes(Number(newMinutes)),
    });

  const setIsAm = (newIsAm: boolean) =>
    setNightMode(controls, {
      ...nightMode[controls],
      isAm: newIsAm,
    });

  const clampedHours = clampHours(hours);
  const clampedMinutes = clampMinutes(minutes);

  return (
    <div className="flex flex-row justify-center items-center text-gray-900 text-xs">
      <input
        type="number"
        value={formatUnits(clampedHours)}
        onChange={(e) => setHours(e.target.value)}
        size={2}
        max={24}
        min={0}
        disabled={!on}
        className="w-5 outline-transparent bg-transparent dark:bg-neutral-100"
      />
      <span className="font-bold">:</span>
      <input
        size={2}
        value={formatUnits(clampedMinutes)}
        onChange={(e) => setMinutes(e.target.value)}
        type="number"
        max={60}
        min={0}
        disabled={!on}
        className="w-5 outline-transparent bg-transparent dark:bg-neutral-100"
      />
      <button
        disabled={!on}
        onClick={() => setIsAm(!isAm)}
      >
        {isAm ? "AM" : "PM"}
      </button>
    </div>
  );
};

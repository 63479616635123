import { handleNewTag } from ".";

export const handleAutocompleteItemClick = (
  { setValue, onTagAdd }: {
    setValue: (v: string) => void;
    onTagAdd: (tag: string) => void;
  },
) =>
(item: { id: string; title: string }) => {
  handleNewTag(item.title)({ setValue, next: onTagAdd });
};

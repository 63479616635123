import { ComponentProps, Fragment, PropsWithChildren, useState } from "react";
import { MobileDrawer } from "./components";
import { useNotebookUiSidebar, useNotebookUiVariant } from "@/hooks/notebook";
import { twMerge } from "tailwind-merge";
import { ResizablePanel } from "@/components/ui/resizable";
import { ScrollArea } from "../../../../components";

export const Layout = ({ children }: PropsWithChildren) => {
  const [variant] = useNotebookUiVariant();
  const [isOpen, setIsOpen] = useNotebookUiSidebar();
  const onDesktop = variant === "desktop";

  const Parent = onDesktop ? ResizablePanel : MobileDrawer;
  const parentProps = onDesktop
    ? { order: 1 }
    : { open: isOpen, onOpenChange: setIsOpen };

  return (
    <Parent {...parentProps}>
      <ScrollArea className="p-0 h-full bg-gray-100 warm:bg-brown-100/50 dark:bg-neutral-900">
        <div
          className={twMerge(
            "flex flex-row justify-start items-start min-h-full h-full  w-full",
            onDesktop && "w-full",
          )}
        >
          <div className="w-full flex flex-col justify-start items-start h-full pt-12 px-6 gap-3">
            {children}
          </div>
        </div>
      </ScrollArea>
    </Parent>
  );
};

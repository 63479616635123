import SigninCard from "@/components/SigninCard";
import { useRequiresNoUser } from "@/utils/customHooks";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/auth/signin/")({
  component: SigninPage,
});

function SigninPage() {
  useRequiresNoUser();
  return <SigninCard />;
}

import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankQuestionHeaders from "./use-question-bank-question-headers";
import { Topic } from "@/models";
import ArrayMapUtils, { ArrayMap } from "@/types/common/ArrayConvertableMap";
import { useCallback } from "react";
import { QuestionHeader } from "@/models/question-model";

type Opts = {
  enabled?: boolean;
  filters?: {
    questionHeaders?: ArrayMap<QuestionHeader>;
    subjects?: string[];
  };
};
const useGetTopicQuestionHeaders = (
  args: QuestionBankIdentifier,
  opts?: Opts,
) => {
  const externalQuestionHeaders = opts?.filters?.questionHeaders;
  const questionBankQuestionHeadersQuery = useQuestionBankQuestionHeaders(
    args,
    { enabled: !externalQuestionHeaders && (opts?.enabled ?? true) },
  );
  const { data: internalQuestionHeaders } = questionBankQuestionHeadersQuery;

  const questionHeaders = externalQuestionHeaders ?? internalQuestionHeaders;

  const getTopicQuestionHeaders = useCallback((topicId: Topic["id"]) => {
    const topicQuestionHeaders = ArrayMapUtils.filter(
      questionHeaders,
      (qh) => {
        const isQuestionHeaderInTopic = qh.topicId === topicId;
        if (!opts?.filters?.subjects) {
          return isQuestionHeaderInTopic;
        }
        const isQuestionHeaderInSubjects = opts.filters.subjects.includes(
          qh.subjectId,
        );
        return isQuestionHeaderInSubjects &&
          isQuestionHeaderInTopic;
      },
    );
    return topicQuestionHeaders;
  }, [questionHeaders]);

  return {
    ...questionBankQuestionHeadersQuery,
    data: getTopicQuestionHeaders,
  };
};

export default useGetTopicQuestionHeaders;

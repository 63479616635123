import { createFileRoute, Navigate } from "@tanstack/react-router";

export const Route = createFileRoute("/qbanks/$qbankEndpoint/dashboard/")({
  component: () => (
    <Navigate
      to="/qbanks/$qbankEndpoint/dashboard/welcome"
      params={(p) => ({ qbankEndpoint: p.qbankEndpoint! })}
      replace
    />
  ),
});

import { TriggerableBody } from "@/components/triggerable";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren, useEffect, useState } from "react";
import useContextMenuContext from "./hooks/use-context-menu-context";
import { smoothTransition } from "@/assets/framer";
import { useViewportDimensions } from "@/utils/hooks";
import { useOnDesktop, useOnMediumScreen } from "@/hooks";

type ContextMenuBodyProps = PropsWithChildren & PropsWithClassName;

const ContextMenuBody = ({ children, className }: ContextMenuBodyProps) => {
  const { width: vW, height: vH } = useViewportDimensions();
  const { bodyPosition } = useContextMenuContext();
  const onMediumScreen = useOnMediumScreen();
  const onDesktop = useOnDesktop();
  const shouldDisplaceUpwards = (!onMediumScreen && !onDesktop) &&
    (bodyPosition?.y ?? 0) > vH / 2;
  const shouldDisplaceLeft = (bodyPosition?.x ?? 0) > vW / 2;

  const OFFSET = { x: 0, y: 20 };

  const newPosition = {
    left: (bodyPosition?.x ?? 0) + OFFSET.x,
    top: (bodyPosition?.y ?? 0) + OFFSET.y,
    x: shouldDisplaceLeft ? "-100%" : "0",
    y: shouldDisplaceUpwards ? "-100%" : "0%",
  };

  return (
    <TriggerableBody
      transition={{ ...smoothTransition, duration: 0.3 }}
      style={{
        position: "fixed",
      }}
      initial={newPosition}
      animate={newPosition}
      className={cn(
        className,
      )}
    >
      {children}
    </TriggerableBody>
  );
};

export default ContextMenuBody;

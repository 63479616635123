import { CoursologyLogo } from "./CoursologyLogo";

export const CoursologyLoadingIndicator = () => {
  return (
    <div className="relative flex justify-center items-center w-60 h-60">
      <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] rounded-full w-full h-full">
        <CoursologyLogo className="z-[2] absolute w-full top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] dark:hidden" />
        <CoursologyLogo
          variant="mono"
          className="z-[2] absolute w-full top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] hidden dark:block"
        />
      </div>
      <div className="flex flex-col justify-center items-center z-[1] w-2/3 h-2/3 bg-primary-500 dark:bg-neutral-100 rounded-full animate-ping">
      </div>
    </div>
  );
};

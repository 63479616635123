import { Link } from "@mantine/tiptap";
//@ts-ignore
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
//@ts-ignore
import Underline from "@tiptap/extension-underline";
//@ts-ignore
import TextAlign from "@tiptap/extension-text-align";
//@ts-ignore
import Superscript from "@tiptap/extension-superscript";
//@ts-ignore
import SubScript from "@tiptap/extension-subscript";
//@ts-ignore
import Image from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import { TaskItem } from "@tiptap/extension-task-item";
import { TaskList } from "@tiptap/extension-task-list";
import TextDirection from "tiptap-text-direction";

export const extensions = [
  StarterKit,
  Underline,
  Link,
  Image.configure({
    HTMLAttributes: {
      class: "lg:w-2/3 md:w-2/3 w-full rounded-lg shadow-md",
    },
  }),
  Superscript,
  Color,
  SubScript,
  TextStyle,
  Highlight,
  TaskItem,
  TaskList,
  TextAlign.configure({ types: ["heading", "paragraph"] }),
  Placeholder.configure({ placeholder: "Click to start editing" }),
  Table.configure({
    HTMLAttributes: {
      class:
        "rounded-primary border border-gray-300 dark:border-neutral-700 bg-white dark:bg-neutral-900 mb-4 not-prose w-full",
    },
  }),
  TableRow,
  TableCell.configure({
    HTMLAttributes: {
      class:
        "border border-gray-300 dark:border-neutral-700 border-collapse not-prose p-2 py-3",
    },
  }),
  TableHeader.configure({
    HTMLAttributes: {
      class:
        "border border-gray-300 dark:border-neutral-700 rounded-t-primary bg-gray-100 border-collapse not-prose p-2 py-3",
    },
  }),
  TextDirection.configure({ types: ["heading", "paragraph"] }),
];

import { Button } from "@/components/Button";
import Dialog from "@/components/Dialog";

interface NbmeEndTestDialogProps {
  isVisible: boolean;
  onIsVisibleChange: (v: boolean) => void;
  isDarkMode: boolean;
  isTestSubmitted: boolean;
  onTestSubmitted: () => void;
  onTestReviewEnded: () => void;
}

export const NbmeEndTestDialog = (
  {
    isDarkMode,
    isTestSubmitted,
    isVisible,
    onIsVisibleChange,
    onTestSubmitted,
    onTestReviewEnded,
  }: NbmeEndTestDialogProps,
) => {
  return (
    <Dialog
      dark={isDarkMode}
      title={isTestSubmitted ? "End Review?" : "End Block?"}
      description={isTestSubmitted
        ? 'You can return to it any time in the "Previous Tests" page in your dashboard.'
        : "You will not be able to change your answers after ending the test, and any unanswered questions will be omitted"}
      open={isVisible}
      onOpenChange={onIsVisibleChange}
      onCloseClicked={() => onIsVisibleChange(false)}
      className="dark:bg-nbme-primary-dark-900 dark:border-nbme-primary-dark-600"
    >
      <div className="flex flex-row justify-end items-center mt-4">
        <div className="flex flex-row justify-start items-center gap-3">
          <Button
            className="p-1 px-2"
            onClick={() => onIsVisibleChange(false)}
          >
            Cancel
          </Button>
          <Button
            className="p-1 px-2"
            error
            onClick={() => {
              onIsVisibleChange(false);
              return isTestSubmitted ? onTestReviewEnded() : onTestSubmitted();
            }}
          >
            End
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

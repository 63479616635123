import { PropsWithClassName } from "@/types";
import { Link } from "@tanstack/react-router";
import React, { MouseEventHandler, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface DashboardLinkTypeButtonProps {
  type?: "button";
  href?: undefined;
  onClick: MouseEventHandler<HTMLElement>;
}

interface DashboardLinkTypeLinkProps {
  type?: "link";
  href: string;
  onClick?: undefined;
}

export type DashboardLinkProps =
  & (DashboardLinkTypeLinkProps | DashboardLinkTypeButtonProps)
  & PropsWithChildren
  & PropsWithClassName;

export const DashboardLink = (
  { href, children, className, type = "link" }: DashboardLinkProps,
) => {
  return (
    <Link
      to={href || ""}
      className={twMerge(
        "text-gray-500 warm:text-brown-600 warm:enabled:hover:text-brown-800 dark:text-neutral-400 enabled:hover:text-gray-700 transition-colors dark:hover:text-neutral-300",
        className,
      )}
    >
      {children}
    </Link>
  );
};

import { Alignment, Point, Setter } from "@/types";
import { useAppTheme } from "@/hooks";
import { Draft } from "immer";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

interface NbmeLayoutStore {
  settings: {
    nightMode: {
      on?: boolean;
      startTime: {
        hours: number;
        minutes: number;
        isAm: boolean;
      };
      endTime: { hours: number; minutes: number; isAm: boolean };
    };
    highlights: {
      bold: boolean;
      shadow: boolean;
      shadowIntensity: number;
      shine: boolean;
      shineIntensity: number;
    };
    windows: {
      allowOffScreen: boolean;
    };
    theme: "light" | "dark" | "warm";
    percentageAnsweredVisible: boolean;
    timerVisible: boolean;
    fontSizeFactor: number;
    confirmAnswerOmission: boolean;
    splitView: boolean;
    unsubmittedSlotMark: boolean;
    alignment: Alignment;
    imageAlignment: Alignment;
    exhibitStyle: "link" | "button" | "plain";
    lineWidthPercent: number;
    allocateSpaceForSidebar: boolean;
    autoHideSidebar: boolean;
    autoShowSidebar: boolean;
    showSidebarOnHover: boolean;
    padding: { question: number; explanation: number };
  };

  setTheme: Setter<this["settings"]["theme"]>;
  setAlignment: Setter<Alignment>;
  setImageAlignment: Setter<Alignment>;
  setLineWidthPercent: Setter<number>;
  setAllocateSpaceForSidebar: Setter<
    this["settings"]["allocateSpaceForSidebar"]
  >;

  setExhibitStyle: Setter<"link" | "button" | "plain">;
  setSplitView: Setter<boolean>;
  setAutohideSidebar: Setter<boolean>;
  setAutoShowSidebar: Setter<boolean>;
  setShowSidebarOnHover: Setter<boolean>;
  setPadding: Setter<this["settings"]["padding"]>;

  setUnsubmittedSlotMark: Setter<boolean>;

  setNightMode: (
    key: keyof NbmeLayoutStore["settings"]["nightMode"],
    value: NbmeLayoutStore["settings"]["nightMode"][typeof key],
  ) => void;

  setHighlightSettings: Setter<NbmeLayoutStore["settings"]["highlights"]>;
  setWindowsSettings: Setter<NbmeLayoutStore["settings"]["windows"]>;

  setPercentageAnsweredVisible: Setter<boolean>;

  setTimerVisible: Setter<boolean>;

  setFontSizeFactor: Setter<number>;

  setConfirmAnswerOmission: Setter<boolean>;

  selectionEnabled: boolean;
  setSelectionEnabled: Setter<boolean>;

  calcVisible: boolean;
  setCalcVisible: Setter<boolean>;

  settingsVisible: boolean;
  setSettingsVisible: Setter<boolean>;

  endTestDialogVisible: boolean;
  setEndTestDialogVisible: Setter<boolean>;
  sidebarVisible: boolean;
  setSidebarVisible: Setter<boolean>;

  confirmAnswerOmissionDialogVisible: boolean;
  setConfirmAnswerOmissionDialogVisible: Setter<boolean>;

  suspendTestDialogVisible: boolean;
  setSuspendTestDialogVisible: Setter<boolean>;

  calcPosition?: Point;
  setCalcPosition: Setter<Point>;

  notesVisible: boolean;
  setNotesVisible: Setter<boolean>;

  isNotebookVisible: boolean;
  setIsNotebookVisible: Setter<boolean>;

  notesPosition?: Point;
  setNotesPosition: Setter<Point>;

  highlightMarkersVisible: boolean;
  setHighlightMarkersVisible: Setter<boolean>;

  shortcutsEnabled: boolean;
  setShortcutsEnabled: Setter<boolean>;
  labVisbile: boolean;
  setLabVisible: Setter<boolean>;

  feedbackVisible: boolean;
  setFeedbackVisible: (v: boolean) => void;
  sidebarWidth: number;
  setSidebarWidth: Setter<number>;

  isUserWarnedAboutHighlights: boolean;
  setIsUserWarnedAboutHighlights: Setter<this["isUserWarnedAboutHighlights"]>;

  isHighlightWarningDialogVisible: boolean;
  setIsHighlightWarningDialogVisible: Setter<
    this["isHighlightWarningDialogVisible"]
  >;

  hints: { [k: string]: boolean };
  toggleHint: (args: { hintId: string; value?: boolean }) => void;
}

const saveSettings = (settings: NbmeLayoutStore["settings"]) => {
  typeof localStorage != "undefined" &&
    localStorage.setItem("nbme.settings", JSON.stringify(settings));
};

const loadSettings = () => {
  if (typeof localStorage === "undefined") return undefined;
  const savedStringifiedSettings = localStorage.getItem("nbme.settings");

  return savedStringifiedSettings
    ? (JSON.parse(savedStringifiedSettings) as NbmeLayoutStore["settings"])
    : undefined;
};

export const useNbmeLayoutStore = create(
  persist(
    immer<NbmeLayoutStore>((set) => {
      const setValue =
        (key: keyof Draft<NbmeLayoutStore>) =>
        (newValue: NbmeLayoutStore[typeof key]) =>
          set((state) => {
            (state[key] as NbmeLayoutStore[typeof key]) = newValue;
          });

      const setSettingsValue = (
        key: keyof NbmeLayoutStore["settings"],
        value: NbmeLayoutStore["settings"][typeof key],
      ) => {
        set((state) => {
          (state.settings[key] as NbmeLayoutStore["settings"][typeof key]) =
            value;
          saveSettings(state.settings);
        });
      };

      return ({
        settings: {
          nightMode: {
            on: false,
            startTime: { hours: 6, minutes: 0, isAm: false },
            endTime: { hours: 6, minutes: 0, isAm: true },
          },
          theme: "light",
          fontSizeFactor: 1,
          timerVisible: true,
          percentageAnsweredVisible: true,
          splitView: true,
          confirmAnswerOmission: true,
          unsubmittedSlotMark: true,
          exhibitStyle: "button",
          highlights: {
            bold: true,
            shine: false,
            shadow: false,
            shadowIntensity: 30,
            shineIntensity: 50,
          },
          alignment: "left",
          imageAlignment: "left",
          lineWidthPercent: 70,
          windows: {
            allowOffScreen: true,
          },
          allocateSpaceForSidebar: true,
          autoHideSidebar: false,
          autoShowSidebar: false,
          showSidebarOnHover: true,
          padding: { question: 0, explanation: 0 },
          ...loadSettings(),
        },

        setAlignment: (newValue) => setSettingsValue("alignment", newValue),
        setPadding: (newValue) => setSettingsValue("padding", newValue),
        setImageAlignment: (newValue) =>
          setSettingsValue("imageAlignment", newValue),
        setLineWidthPercent: (newValue) =>
          setSettingsValue("lineWidthPercent", newValue),

        setTimerVisible: (newValue) =>
          setSettingsValue("timerVisible", newValue),

        setPercentageAnsweredVisible: (newValue) =>
          setSettingsValue("percentageAnsweredVisible", newValue),

        setAllocateSpaceForSidebar: (newValue) =>
          setSettingsValue("allocateSpaceForSidebar", newValue),

        setExhibitStyle: (newValue) =>
          setSettingsValue("exhibitStyle", newValue),
        setAutohideSidebar: (newValue) =>
          setSettingsValue("autoHideSidebar", newValue),

        setAutoShowSidebar: (newValue) =>
          setSettingsValue("autoShowSidebar", newValue),
        setNightMode: (key, value) =>
          set((state) => {
            (state.settings.nightMode[key] as typeof value) = value;
          }),

        setTheme: (newValue) => setSettingsValue("theme", newValue),
        setSplitView: (newValue) => setSettingsValue("splitView", newValue),

        setConfirmAnswerOmission: (newValue) =>
          setSettingsValue("confirmAnswerOmission", newValue),

        setHighlightSettings: (newValue) =>
          setSettingsValue("highlights", newValue),
        setWindowsSettings: (newValue) => setSettingsValue("windows", newValue),

        setFontSizeFactor: (newValue) =>
          setSettingsValue("fontSizeFactor", newValue),

        setUnsubmittedSlotMark: (newValue) =>
          setSettingsValue("unsubmittedSlotMark", newValue),

        setShowSidebarOnHover: (newValue) =>
          setSettingsValue("showSidebarOnHover", newValue),

        selectionEnabled: true,
        setSelectionEnabled: setValue("selectionEnabled"),

        calcVisible: false,
        setCalcVisible: setValue("calcVisible"),

        sidebarVisible: true,
        setSidebarVisible: setValue("sidebarVisible"),

        sidebarWidth: 0,
        setSidebarWidth: setValue("sidebarWidth"),

        settingsVisible: false,
        setSettingsVisible: setValue("settingsVisible"),
        isNotebookVisible: false,
        setIsNotebookVisible: setValue("isNotebookVisible"),

        confirmAnswerOmissionDialogVisible: false,
        setConfirmAnswerOmissionDialogVisible: setValue(
          "confirmAnswerOmissionDialogVisible",
        ),

        endTestDialogVisible: false,
        setEndTestDialogVisible: setValue("endTestDialogVisible"),

        calcPosition: undefined,
        setCalcPosition: setValue("calcPosition"),

        isUserWarnedAboutHighlights: false,
        setIsUserWarnedAboutHighlights: setValue("isUserWarnedAboutHighlights"),

        isHighlightWarningDialogVisible: false,
        setIsHighlightWarningDialogVisible: setValue(
          "isHighlightWarningDialogVisible",
        ),
        notesVisible: false,
        notesPosition: undefined,
        setNotesPosition: setValue("notesPosition"),
        setNotesVisible: setValue("notesVisible"),

        shortcutsEnabled: true,
        setShortcutsEnabled: setValue("shortcutsEnabled"),

        labVisbile: false,
        setLabVisible: setValue("labVisbile"),

        feedbackVisible: false,
        setFeedbackVisible: setValue("feedbackVisible"),

        suspendTestDialogVisible: false,
        setSuspendTestDialogVisible: setValue("suspendTestDialogVisible"),

        highlightMarkersVisible: false,
        setHighlightMarkersVisible: setValue("highlightMarkersVisible"),

        hints: {},
        toggleHint: (
          { hintId, value }: { hintId: string; value?: boolean },
        ) => {
          set((state) => {
            if (value !== undefined) {
              state.hints[hintId] = value;
              return;
            }

            if (state.hints[hintId]) {
              state.hints[hintId] = false;
              return;
            }
            state.hints[hintId] = true;
          });
        },
      });
    }),
    {
      storage: createJSONStorage(() => localStorage),
      name: "nbme-layout",
      partialize: (state) => ({
        isUserWarnedAboutHighlights: state.isUserWarnedAboutHighlights,
      }),
    },
  ),
);

export const useNbmeHighlightSettings = () => {
  const { highlightSettings, setHighlightSettings } = useNbmeLayoutStore(
    (state) => ({
      highlightSettings: state.settings.highlights,
      setHighlightSettings: state.setHighlightSettings,
    }),
  );
  return [highlightSettings, setHighlightSettings] as const;
};

export const useNbmeWindowsSettings = () => {
  const { windowsSettings, setWindowsSettings } = useNbmeLayoutStore((
    state,
  ) => ({
    windowsSettings: state.settings.windows,
    setWindowsSettings: state.setWindowsSettings,
  }));
  return [windowsSettings, setWindowsSettings] as const;
};

export const useNbmeFontSizeFactor = () =>
  useNbmeLayoutStore((state) => ({
    fontSizeFactor: state.settings.fontSizeFactor,
    setFontSizeFactor: state.setFontSizeFactor,
  }));

export const useNbmeExhibitStyle = () => {
  const { exhibitStyle, setExhibitStyle } = useNbmeLayoutStore((state) => ({
    exhibitStyle: state.settings.exhibitStyle,
    setExhibitStyle: state.setExhibitStyle,
  }));
  return [exhibitStyle, setExhibitStyle] as const;
};

export const useNbmeTheme = () => {
  const [appTheme, setAppTheme] = useAppTheme();
  const { setTheme } = useNbmeLayoutStore((state) => ({
    theme: state.settings.theme,
    setTheme: state.setTheme,
  }));

  return [
    appTheme,
    (theme: typeof appTheme) => {
      setAppTheme(theme);
      setTheme(theme);
    },
  ] as const;
};

export const useNbmeTimerVisible = () =>
  useNbmeLayoutStore((state) => ({
    timerVisible: state.settings.timerVisible,
    setTimerVisible: state.setTimerVisible,
  }));

export const useNbmePercentageAnsweredVisible = () =>
  useNbmeLayoutStore((state) => ({
    percentageAnsweredVisible: state.settings.percentageAnsweredVisible,
    setPercentageAnsweredVisible: state.setPercentageAnsweredVisible,
  }));

export const useNbmeNightMode = () => {
  const { nightMode, setNightMode } = useNbmeLayoutStore((state) => ({
    nightMode: state.settings.nightMode,
    setNightMode: state.setNightMode,
  }));

  return { nightMode, setNightMode };
};

export const useNbmeConfirmAnswerOmission = () =>
  useNbmeLayoutStore((state) => ({
    confirmAnswerOmission: state.settings.confirmAnswerOmission,
    setConfirmAnswerOmission: state.setConfirmAnswerOmission,
  }));

export const useNbmeShortcutsEnabled = () => {
  const { shortcutsEnabled, setShortcutsEnabled } = useNbmeLayoutStore((
    state,
  ) => ({
    shortcutsEnabled: state.shortcutsEnabled,
    setShortcutsEnabled: state.setShortcutsEnabled,
  }));
  return [shortcutsEnabled, setShortcutsEnabled] as const;
};

export const useNbmeNotebookVisible = () => {
  const { isVisible, internalSetIsVisible, setShortcutsEnabled } =
    useNbmeLayoutStore((state) => ({
      isVisible: state.isNotebookVisible,
      internalSetIsVisible: state.setIsNotebookVisible,
      setShortcutsEnabled: state.setShortcutsEnabled,
    }));

  const setIsVisible = (newValue: boolean) => {
    setShortcutsEnabled(!newValue);
    internalSetIsVisible(newValue);
  };
  return [isVisible, setIsVisible] as const;
};

export const useNbmeSplitView = () => {
  const { splitView, setSplitView } = useNbmeLayoutStore((state) => ({
    splitView: state.settings.splitView,
    setSplitView: state.setSplitView,
  }));

  return [splitView, setSplitView] as const;
};

export const useNbmeUnsubmittedSlotMark = () => {
  const { unsubmittedSlotMark, setUnsubmittedSlotMark } = useNbmeLayoutStore(
    (state) => ({
      unsubmittedSlotMark: state.settings.unsubmittedSlotMark,
      setUnsubmittedSlotMark: state.setUnsubmittedSlotMark,
    }),
  );

  return [unsubmittedSlotMark, setUnsubmittedSlotMark] as const;
};

export const useNbmeAllocateSpaceForSidebar = () => {
  const { allocateSpaceForSidebar, setAllocateSpaceForSidebar } =
    useNbmeLayoutStore((
      state,
    ) => ({
      allocateSpaceForSidebar: state.settings.allocateSpaceForSidebar,
      setAllocateSpaceForSidebar: state.setAllocateSpaceForSidebar,
    }));
  return [allocateSpaceForSidebar, setAllocateSpaceForSidebar] as const;
};

export const useNbmeAlignment = () => {
  const { alignment, setAlignment } = useNbmeLayoutStore((state) => ({
    alignment: state.settings.alignment,
    setAlignment: state.setAlignment,
  }));
  return [alignment, setAlignment] as const;
};

export const useNbmeImageAlignment = () => {
  const { imageAlignment, setImageAlignment } = useNbmeLayoutStore((state) => ({
    imageAlignment: state.settings.imageAlignment,
    setImageAlignment: state.setImageAlignment,
  }));
  return [imageAlignment, setImageAlignment] as const;
};

export const useNbmeLineWidth = () => {
  const { lineWidht, setLineWidth } = useNbmeLayoutStore((state) => ({
    lineWidht: state.settings.lineWidthPercent,
    setLineWidth: state.setLineWidthPercent,
  }));

  return [lineWidht, setLineWidth] as const;
};

export const useNbmeSettingsVisible = () => {
  const { visible, setVisible } = useNbmeLayoutStore((state) => ({
    visible: state.settingsVisible,
    setVisible: state.setSettingsVisible,
  }));
  return [visible, setVisible] as const;
};

export const useNbmeSettingsAutoHideSidebar = () => {
  const { autoHide, setAutoHide } = useNbmeLayoutStore((state) => ({
    autoHide: state.settings.autoHideSidebar,
    setAutoHide: state.setAutohideSidebar,
  }));
  return [autoHide, setAutoHide] as const;
};

export const useNbmeSettingsAutoShowSidebar = () => {
  const { autoShow, setAutoShow } = useNbmeLayoutStore((state) => ({
    autoShow: state.settings.autoShowSidebar,
    setAutoShow: state.setAutoShowSidebar,
  }));
  return [autoShow, setAutoShow] as const;
};

export const useNbmeSettingsShowSidebarOnHover = () => {
  const { showSidebarOnHover, setShowSidebarOnHover } = useNbmeLayoutStore((
    state,
  ) => ({
    showSidebarOnHover: state.settings.showSidebarOnHover,
    setShowSidebarOnHover: state.setShowSidebarOnHover,
  }));
  return [showSidebarOnHover, setShowSidebarOnHover] as const;
};

export const useNbmePadding = () => {
  const { padding, setPadding } = useNbmeLayoutStore(
    (state) => ({
      padding: state.settings.padding,
      setPadding: state.setPadding,
    }),
  );
  return [padding, setPadding] as const;
};

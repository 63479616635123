import { useEffect, useState } from "react";

export const useDateEverySecond = () => {
  const [date, setDate] = useState<Date>(new Date());
  useEffect(() => {
    const timerId = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(timerId);
  }, [date]);
  return date;
};

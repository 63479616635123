import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";

import { PropsWithChildren } from "react";
import { Link } from "@tanstack/react-router";

export const TelegramLink = (
  { children, href, withTelegramIcon = true, className }: PropsWithChildren & {
    href: string;
    withTelegramIcon?: boolean;
  } & PropsWithClassName,
) => {
  return (
    <Link
      href={href}
      target="_blank"
      className={cn(
        "relative group bg-primary-500 warm:bg-brown-800 transition-[transform,_background-color] hover:-translate-x-1 hover:-translate-y-1 duration-300 before:w-full before:h-full before:absolute before:-z-10 before:bg-primary-500/50 warm:before:bg-primary-800/50 warm:hover:before:bg-brown-800/70 warm:hover:bg-brown-900 before:rounded-md before:left-0 before:right-0 before:top-0 before:bottom-0 before:m-auto before:origin-center rounded-primary before:translate-y-1 before:translate-x-1 before:transition-transform before:hover:translate-x-2 before:hover:translate-y-2 before:duration-300 text-xl dark:before:hover:translate-y-0 dark:before:hover:translate-x-0 dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:before:bg-neutral-800 hover:z-10 dark:hover:-translate-x-0 dark:hover:-translate-y-0",
        className,
      )}
    >
      <div className="flex flex-row justify-center items-center text-white gap-3 font-semibold overflow-hidden px-2 py-1">
        {withTelegramIcon &&
          (
            <div className="relative w-6">
              <FontAwesomeIcon
                icon={faTelegram}
                className="absolute left-0 top-0 right-0 bottom-0 m-auto w-6 h-6 group-hover:translate-x-4 group-hover:-translate-y-8 transition-transform  duration-300"
              />
              <FontAwesomeIcon
                icon={faTelegram}
                className="absolute left-0 top-0 right-0 bottom-0 m-auto w-6 h-6 -translate-x-4 group-hover:translate-x-0 translate-y-8 group-hover:translate-y-0 transition-transform duration-300"
              />
            </div>
          )}
        {children}
      </div>
    </Link>
  );
};

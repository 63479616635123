import { BreakPoints, Point, Setter } from "@/types";
import { PropsWithClassName } from "@/types";
import {
  useNbmeLayoutStore,
  useNbmeWindowsSettings,
} from "@/utils/stores/nbmeLayoutStore";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import FloatingWindow from "./floating-window";
import { useViewportDimensions } from "@/utils/hooks";

interface NbmeFloatingWindowProps
  extends PropsWithClassName, PropsWithChildren {
  title: string | JSX.Element;
  onCloseClicked?: () => void;
  position?: Point;
  onPositionChanged?: (newPosition: Point) => void;
  viewportClassName?: string;
  visible?: boolean;
  maximizable?: boolean;
  resizable?: boolean;
  draggable?: boolean;
  draggableFromBody?: boolean;
  onResizableChanged?: Setter<boolean>;
  initialDimensions?: Point;
  onCopy?: Function;
  onDownload?: Function;
}

export const NbmeFloatingWindow = (
  {
    children,
    onCloseClicked,
    position: externalPosition,
    onPositionChanged,
    title,
    viewportClassName,
    visible,
    maximizable,
    resizable,
    onResizableChanged,
    draggable,
    draggableFromBody,
    initialDimensions,
    onCopy,
    onDownload,
  }: NbmeFloatingWindowProps,
) => {
  const setSelectionEnabled = useNbmeLayoutStore((state) =>
    state.setSelectionEnabled
  );
  const [zIndex, setZIndex] = useState(1000);
  const [settings] = useNbmeWindowsSettings();
  return (
    <div style={{ zIndex: zIndex }}>
      <FloatingWindow.Root
        onCopy={onCopy}
        onDownload={onDownload}
        isMaximizable={maximizable}
        events={{
          onDragStart: () => {
            setZIndex((prev) => prev + 1);
          },
        }}
        isDraggableFromBody={draggableFromBody}
        minDimensions={{ x: 200, y: 200 }}
        initialDimensions={initialDimensions}
        isDraggable={draggable}
        allowOffScreen={settings.allowOffScreen}
        isOpen={visible}
        onOpenChanged={(newValue) => {
          if (!newValue) return onCloseClicked?.();
        }}
        isResizable={resizable}
        onIsResizableChanged={onResizableChanged}
      >
        <FloatingWindow.Body
          title={title}
          className="dark:bg-neutral-900 warm:bg-brown-50 rounded-t-xl bg-gray-50"
        >
          {children}
        </FloatingWindow.Body>
      </FloatingWindow.Root>
    </div>
  );
};

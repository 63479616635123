import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

type NbmeSidePanelTitleProps = {} & PropsWithClassName & PropsWithChildren;

const Title = ({ className, children }: NbmeSidePanelTitleProps) => {
  return (
    <p
      className={cn(
        "w-full text-sm text-center font-bold text-gray-800",
        className,
      )}
    >
      {children}
    </p>
  );
};

export default Title;

/** Merges the source object with all the destination
 * objects to produce a function that has all the properties
 * of the source object, but also merged boolean values of all
 * the destination values */
const mergeFlags = <Source extends Object, Destination extends Object>(
  { source, destinations }: { source: Source; destinations: Destination[] },
) => {
  const objectWithMergedFlags: Partial<Source> = {};

  for (const key in source) {
    const sourceValue = source[key as keyof typeof source];
    // @ts-ignore
    objectWithMergedFlags[key] = sourceValue;
    for (const destination of destinations) {
      if (key in destination) {
        const destinationValue = destination[key as keyof typeof destination];
        if (typeof sourceValue === "boolean") {
          //@ts-ignore
          objectWithMergedFlags[key] = sourceValue ||
            destinationValue;
        }
      }
    }
  }
  return objectWithMergedFlags as Source;
};

export default mergeFlags;

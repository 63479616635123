import { Skeleton } from "@/components/ui/skeleton";
import _ from "underscore";

const NbmeQuestionSkeleton = () => {
  return (
    <div className="flex flex-col justify-start items-start gap-2 w-full">
      {[...new Array(12).keys()].map((i) => {
        const randomWidth = _.random(3, 8) * 10;
        return (
          <Skeleton
            key={i}
            className="rounded-full h-6"
            style={{ width: `${randomWidth}%` }}
          />
        );
      })}
    </div>
  );
};
export default NbmeQuestionSkeleton;

import { FilterInterface } from "interweave";

export const vignetteFilter: FilterInterface = {
  node(name, node) {
    let vignetteDescriptor: HTMLElement | null;
    if (name === "table") {
      vignetteDescriptor = node.querySelector("#vignettedescriptor");
      if (
        !vignetteDescriptor &&
        !node.textContent?.includes(
          "applies to the next",
        )
      ) return node;
      node.setAttribute(
        "class",
        "rounded-md bg-gray-300 hover:bg-gray-200 p-2 mb-4 dark:bg-neutral-700 dark:hover:bg-neutral-600 transition-colors",
      );
      vignetteDescriptor = node.querySelector("td");
      vignetteDescriptor?.setAttribute("class", "p-2");
    }
    return node;
  },
};

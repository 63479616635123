import { createFileRoute, Link } from "@tanstack/react-router";
import { useRequiresUser } from "@/utils/customHooks";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { calcExpirationDate } from "@/utils/subscription";
import { cn } from "@/lib/utils";
import { WantMore } from "./-components/want-more";
import HomeNavbar from "@/components/HomeNavbar";
import { LoadingPage } from "@/components/LoadingPage";
import useSubscriptionQuestionBanks from "@/hooks/question-bank-hooks/use-subscription-question-banks";
import QuestionBankButton from "./-components/question-bank-button";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

export const Route = createFileRoute("/home")({
  component: HomeLayout,
});

function HomeLayout() {
  useRequiresUser();

  return (
    <div className="flex flex-col justify-start items-center min-h-screen w-full overflow-hidden">
      <HomeNavbar />
      <HomePage />
    </div>
  );
}

export default function HomePage() {
  const [isClient, setIsClient] = useState(false);
  const user = useRequiresUser();
  const { data: banks, isLoading } = useSubscriptionQuestionBanks();

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (isLoading && isClient) return <LoadingPage />;

  const expirationDate = user && calcExpirationDate({
    lastRenewal: DateTime.fromJSDate(
      new Date(user.lastRenewal!),
    ),
    subscriptionType: user.currentSubscription.type,
    duration: user.currentSubscription.duration,
    extraMonths: user.extraMonths ?? 0,
  });

  const expired = expirationDate && expirationDate < DateTime.now();

  const expiresAfter = expirationDate && !expired &&
    expirationDate.diff(DateTime.now()).toFormat("d");

  const onFreeTrial = user?.currentSubscription.basic;

  return (
    <div
      className={cn(
        "grid grid-rows-2 lg:grid-rows-1 grid-cols-1 lg:grid-cols-2 w-full max-w-[90%] h-full p-5 py-12 gap-10",
      )}
    >
      <div className="flex flex-col justify-start items-start w-full gap-3">
        <div className="flex flex-col justify-center items-center md:items-start w-full gap-3 md:gap-0">
          {isClient && user &&
            (
              <>
                <div className="flex flex-col justify-center w-full items-center gap-1 text-gray-800 dark:text-neutral-100">
                  <h1 className="font-semibold text-3xl text-center md:text-start text-gray-800 dark:text-neutral-50">
                    {`Welcome `}
                    <br className="md:hidden" />
                    {`${
                      (user.name as string).split(" ").slice(0, 2).join(" ")
                    }`}
                  </h1>

                  <p className="text-sm font-light">
                    You are currently on subscription ({user
                      ?.currentSubscription
                      .name})
                  </p>
                  <p className="font-medium text-lg text-center">
                    {expired
                      ? (
                        <>
                          <span className="text-red-500">
                            Your subscription has expired
                          </span>
                        </>
                      )
                      : (
                        <>
                          <span>Your subscription expires in:</span>
                          <span className="font-semibold">
                            {expiresAfter} Days
                          </span>
                        </>
                      )}
                  </p>
                </div>
              </>
            )}
        </div>
        <div className="flex flex-col justify-start items-center w-full gap-6 md:gap-3">
          {isClient && (
            <h2 className="font-semibold text-2xl mb-2 text-gray-800 dark:text-neutral-50">
              Available QBanks
            </h2>
          )}
          {expired && isClient
            ? (
              <>
                <p className="font-semibold text-red-500 text-2xl">
                  Renew Your subscription to see your available banks...<br />
                  Contact{" "}
                  <span>
                    <Link href={"https://t.me/coursologyy"}>Coursology</Link>
                  </span>{" "}
                  on telegram to renew
                </p>
              </>
            )
            : (
              <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-3 w-full md:max-w-xl">
                {banks &&
                  ArrayMapUtils.toArray(banks).map((b, index) => (
                    <QuestionBankButton key={b.id} {...b} />
                  ))}
              </div>
            )}
        </div>
      </div>
      <WantMore onFreeTrial={onFreeTrial ?? false} />
    </div>
  );
}

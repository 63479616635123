import { cn } from "@/lib/utils";
import { ComponentProps } from "react";

type SpanProps = ComponentProps<"span">;
const Span = (props: SpanProps) => {
  return (
    <span
      className={cn(
        "text-gray-700 warm:text-brown-800 dark:text-neutral-300",
        props.className,
      )}
      {...props}
    />
  );
};

export default Span;

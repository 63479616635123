import { buttonVariants } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";

interface PerformanceTestPageReviewTestButtonProps {
  testId: string;
}

export const PerformanceTestPageReviewTestButton = (
  { testId }: PerformanceTestPageReviewTestButtonProps,
) => {
  return (
    <Link
      type="button"
      className={buttonVariants({ variant: "dashboard" })}
      to={"/tests/$testId"}
      params={{ testId }}
    >
      Review Test
    </Link>
  );
};

import { trpc } from "@/utils/trpc";
import { useEffect } from "react";
import useAddQuestionBanks from "./use-add-question-banks";
import useQuestionBanks from "./use-question-banks";

const useSubscriptionQuestionBanks = () => {
  const query = trpc.questionBank.getSubscriptionQuestionBanks.useQuery();
  const questionBanks = useQuestionBanks();
  const addQuestionBanks = useAddQuestionBanks();
  const { data, isSuccess } = query;
  useEffect(() => {
    if (!!data && isSuccess) {
      addQuestionBanks(data);
    }
  }, [data, isSuccess]);

  return { ...query, data: questionBanks };
};

export default useSubscriptionQuestionBanks;

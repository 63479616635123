import { useNbmeSettingsAutoShowSidebar } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";

export const NbmeSettingsAutoShowSidebarControl = () => {
  const [autoShowSidebar, setAutoShowSidebar] =
    useNbmeSettingsAutoShowSidebar();

  return (
    <NbmeSettingsSwitch
      checked={autoShowSidebar}
      onCheckedChange={setAutoShowSidebar}
    />
  );
};

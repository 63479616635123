import useTestPageConstructedTest from "./use-test-page-constructed-test";

const useTestPageQuestionNotes = () => {
  const constructedTest = useTestPageConstructedTest();
  return {
    ...constructedTest,
    data: constructedTest.data?.slots.filter((s) => !!s.question.note).map((
      s,
    ) => s.question.note!),
  };
};
export default useTestPageQuestionNotes;

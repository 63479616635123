import React, { useEffect, useMemo, useState } from "react";
import { NbmeFloatingWindow } from "./NbmeFloatingWindow";
import { Button } from "./Button";
import { useNbmeLayoutStore } from "@/utils/stores/nbmeLayoutStore";
import useDeleteQuestionNote from "@/hooks/question-note-hooks/use-delete-question-note";
import useEditQuestionNote from "@/hooks/question-note-hooks/use-edit-question-note";
import useCreateQuestionNote from "@/hooks/question-note-hooks/use-create-question-note";
import useTestPageTestSelectedTestSlot from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot";
import useTestPageTestSelectedTestSlotQuestion from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot-questiont";
import useTestPageSelectedTestSlotQuestionNote from "@/routes/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot-question-note";

export const NbmeQuestionNoteWindow = () => {
  const [draft, setDraft] = useState<string>("");
  const {
    notesVisible,
    notesPosition,
    setNotesVisible,
    setNotesPosition,
    setShortcutsEnabled,
  } = useNbmeLayoutStore((state) => ({
    notesPosition: state.notesPosition,
    notesVisible: state.notesVisible,
    setNotesVisible: state.setNotesVisible,
    setNotesPosition: state.setNotesPosition,
    setShortcutsEnabled: state.setShortcutsEnabled,
  }));

  const { data: selectedSlot } = useTestPageTestSelectedTestSlot();
  const { data: question } = useTestPageTestSelectedTestSlotQuestion();

  const questionId = question?.id;
  const { data: questionNote } = useTestPageSelectedTestSlotQuestionNote();
  const { mutate: deleteQuestionNote } = useDeleteQuestionNote();
  const { mutate: editQuestionNote, isPending: isEditLoading } =
    useEditQuestionNote();
  const { mutate: createQuestionNote, isPending: isCreationLoading } =
    useCreateQuestionNote();

  useEffect(() => {
    if (questionNote) {
      setDraft(questionNote.text);
    } else {
      setDraft("");
    }
  }, [questionNote]);

  return (
    <NbmeFloatingWindow
      visible={notesVisible}
      resizable={false}
      maximizable={false}
      draggableFromBody={false}
      position={notesPosition}
      onPositionChanged={(p) => setNotesPosition(p)}
      initialDimensions={{ x: 500, y: 280 }}
      title={`${questionNote ? "Edit" : "Save"} Question Notes`}
      className="lg:min-w-[30rem] md:min-w-[25rem] min-w-[20rem]"
      onCloseClicked={() => {
        setNotesVisible(false);
        setDraft("");
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!draft) {
            setNotesVisible(false);
            return;
          }
          if (!questionId) return;
          if (questionNote) {
            editQuestionNote({ text: draft, questionNoteId: questionNote.id });
          } else {
            createQuestionNote({ text: draft, questionId });
          }
          setNotesVisible(false);
        }}
        className="flex flex-col justify-start items-center w-full p-3 gap-3"
      >
        <textarea
          value={draft}
          onChange={(e) => setDraft(e.target.value)}
          onFocus={() => setShortcutsEnabled(false)}
          onBlur={() => setShortcutsEnabled(true)}
          placeholder="Click to edit notes."
          className={`w-full border border-gray-300 rounded-primary px-2 py-1 min-h-[10rem] bg-white warm:bg-white warm:placeholder:text-brown-200 dark:bg-nbme-primary-dark-600
                      dark:text-white dark:placeholder:text-nbme-primary-dark-300 dark:border-nbme-primary-dark-600 dark:outline-nbme-primary-dark-600`}
        >
        </textarea>
        <div className="flex flex-row justify-between items-center w-full gap-5">
          <Button
            disabled={isCreationLoading || isEditLoading}
            type="submit"
            className="p-1 text-sm md:text-sm lg:text-sm bg-nbme-primary-600 warm:bg-brown-800 warm:hover:bg-brown-900 warm:focus:bg-brown-900 dark:bg-nbme-primary-dark-700 dark:hover:bg-nbme-primary-dark-600 hover:bg-nbme-primary-700 focus:bg-nbme-primary-700"
          >
            {questionNote ? "Edit" : "Save"} and Close
          </Button>
          <Button
            onClick={() => {
              if (!questionNote) return;
              deleteQuestionNote({ questionNoteId: questionNote.id ?? "" });
              setDraft("");
            }}
            className="p-1 text-sm md:text-sm lg:text-sm bg-nbme-primary-600 dark:bg-nbme-primary-dark-700 dark:hover:bg-nbme-primary-dark-600 hover:bg-nbme-primary-700 focus:bg-nbme-primary-700 warm:bg-brown-800 warm:hover:bg-brown-900 warm:focus:bg-brown-900"
          >
            Delete Notes
          </Button>
        </div>
      </form>
    </NbmeFloatingWindow>
  );
};

import { Dimension } from "@/types";
import { useEffect, useState } from "react";

export const useDimensions = (ref: React.RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState<Dimension>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!ref || !ref.current) return;
    setDimensions({
      height: ref.current.offsetHeight,
      width: ref.current.offsetWidth,
    });
  }, [ref, ref.current]);

  return dimensions;
};

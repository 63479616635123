import QuestionCountBadge from "./QuestionCountBadge";
import LabeledCheckbox from "./LabeledCheckbox";
import { Topic } from "@/api/types";
import { useCreateTestPageStore } from "@/utils/stores";
import useQuestionBankTopicSystem from "@/hooks/system-hooks/use-question-bank-topic-system";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import { useEffect } from "react";
import useQuestionBankSystemTopics from "@/hooks/topic-hooks/use-question-bank-system-topics";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

interface SubjectCheckBoxProps {
  topic: Topic;
  onCheckedChange: (checked: boolean) => void;
  className?: string;
  labelClassName?: string;
  isLoading: boolean;
  questionCount: number;
}

const TopicCheckbox = (
  {
    topic,
    onCheckedChange,
    className,
    labelClassName,
    isLoading,
    questionCount,
  }: SubjectCheckBoxProps,
) => {
  const disabled = questionCount === 0;
  const { data: questionBank } = useDashboardQuestionBank();
  const questionBankEndpoint = questionBank?.endpoint ?? "";
  const { data: system } = useQuestionBankTopicSystem({
    questionBankEndpoint,
    topicId: topic.id,
  });
  const { data: topics } = useQuestionBankSystemTopics({
    questionBankEndpoint,
    systemId: system?.id ?? "",
  });
  const { selectedTopics, selectedSystems, toggleSelection } =
    useCreateTestPageStore((
      state,
    ) => ({
      selectedTopics: state.selectedTopics,
      selectedSystems: state.selectedSystems,
      toggleSelection: state.toggleSelection,
    }));
  const checked = selectedTopics.includes(topic);
  //
  // useEffect(() => {
  //   const isSystemChecked = system && selectedSystems.includes(system);
  //   if (isSystemChecked && !checked) {
  //     toggleSelection("selectedTopics", true, topic);
  //   }
  //
  //   if (!isSystemChecked) {
  //     for (const topic of ArrayMapUtils.toArray(topics)) {
  //       if (!selectedTopics.find((t) => t.id === topic.id)) {
  //         return;
  //       }
  //     }
  //     toggleSelection("selectedTopics", false, topic);
  //   }
  // }, [selectedSystems]);

  return (
    <div className="flex justify-between items-center gap-2 w-full">
      <LabeledCheckbox
        className={className}
        id={`${topic.name}-subject-checkbox`}
        checked={checked && questionCount !== 0}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
        label={topic.name.at(0)!.toUpperCase() + topic.name.slice(1)}
        labelClassName={labelClassName}
        checkBoxClassName={"min-w-max"}
      />
      <QuestionCountBadge count={questionCount} loading={isLoading} />
    </div>
  );
};

export default TopicCheckbox;

import { QuestionChoice, Test, TestSlot } from "@/api/types";
export default function calculateTestScore(
  { testSlots, questionChoices }: {
    testSlots: Pick<TestSlot, "testId" | "chosenId">[];
    questionChoices: Pick<QuestionChoice, "correct" | "id">[];
  },
) {
  let correctSlots = 0;
  const totalSlots = testSlots.length;
  for (const slot of testSlots) {
    const chosenChoice = questionChoices.find(
      (qc) => qc.id === slot.chosenId,
    );
    if (chosenChoice && chosenChoice.correct) {
      correctSlots += 1;
    }
  }

  const score = (correctSlots / totalSlots) * 100;
  return Number(score.toFixed(2));
}

import { Button as CommonButton } from "@/components/Button";
import { ComponentProps } from "react";

export const Button = (props: ComponentProps<typeof CommonButton>) => {
  return (
    <CommonButton className="text-sm md:text-sm lg:text-sm" {...props}>
      {props.children}
    </CommonButton>
  );
};

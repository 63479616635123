import { useNbmeSettingsShowSidebarOnHover } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";

export const NbmeSettingsShowSidebarOnHoverControl = () => {
  const [showSidebarOnHover, setShowSidebarOnHover] =
    useNbmeSettingsShowSidebarOnHover();

  return (
    <NbmeSettingsSwitch
      checked={showSidebarOnHover}
      onCheckedChange={setShowSidebarOnHover}
    />
  );
};

import { useDateEverySecond } from "@/utils/hooks";
import { useNbmeLayoutStore } from "@/utils/stores";
import { DateTime } from "luxon";
import { useEffect } from "react";

const useTestPageNightMode = () => {
  const { theme, setTheme, nightMode } = useNbmeLayoutStore((state) => ({
    setTheme: state.setTheme,
    theme: state.settings.theme,
    nightMode: state.settings.nightMode,
  }));

  const now = useDateEverySecond();
  useEffect(() => {
    if (nightMode.on) {
      const nowAsLuxonDate = DateTime.fromJSDate(now);
      const { startTime, endTime } = nightMode;
      const startHours = startTime.hours + (startTime.isAm ? 0 : 12);
      const startDate = nowAsLuxonDate.set({
        hour: startHours,
        minute: startTime.minutes,
      });
      const endHours = endTime.hours + (endTime.isAm ? 0 : 12);

      let endDate = nowAsLuxonDate.set({
        day: nowAsLuxonDate.day,
        hour: endHours,
        minute: endTime.minutes,
      });

      if (endDate < startDate) {
        endDate = endDate.set({ day: endDate.day + 1 });
      }

      if (nowAsLuxonDate >= startDate && nowAsLuxonDate <= endDate) {
        setTheme("dark");
      } else setTheme("light");
    }
  }, [now, nightMode]);
};

export default useTestPageNightMode;

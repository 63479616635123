import type { QuestionHeader } from "@/models";
import { useCallback } from "react";
import useQuestionBankQuestionHeaders from "./use-question-bank-question-headers";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const useUnravelQuestionVignette = (args: QuestionBankIdentifier) => {
  const { data: questionBankQuestionHeaders } = useQuestionBankQuestionHeaders(
    args,
  );
  const unravelVignette = useCallback((
    question: QuestionHeader,
  ): QuestionHeader[] => {
    const result: QuestionHeader[] = [];
    let parent: QuestionHeader | null = null;
    if (question.parentId) {
      parent = question?.parentId
        ? ArrayMapUtils.find(
          questionBankQuestionHeaders,
          question.parentId,
        ) ?? null
        : null;
    }

    while (parent) {
      result.unshift(parent);
      if (parent.parentId) {
        parent =
          ArrayMapUtils.find(questionBankQuestionHeaders, parent.parentId) ??
            null;
      } else parent = null;
    }

    result.push(question);

    let child: QuestionHeader | null = null;
    if (question.childId) {
      child =
        ArrayMapUtils.find(questionBankQuestionHeaders, question.childId) ??
          null;
    }

    while (child) {
      result.push(child);
      child = ArrayMapUtils.find(questionBankQuestionHeaders, (qh) =>
        qh.parentId === child!.id) ?? null;
    }

    return result;
  }, [questionBankQuestionHeaders]);
  return unravelVignette;
};
export default useUnravelQuestionVignette;

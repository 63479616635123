// YES this is a duplicate this was not a mistake, I am absolutely
// aware that this is trash but I don't have time to properly code this
// sorry.... future you...

import { useEffect, useMemo, useState } from "react";
import Mark from "mark.js";
import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useTestPageTestSelectedTestSlotQuestionExplanationHighlights from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-testslot-questionexplanation-highlights";

export const useHighlightMarker = (
  explanationId: string,
  explanationHtml: string,
) => {
  const [highlightedHtml, setHighlightedHtml] = useState<string>(
    explanationHtml,
  );
  const { data: highlights } =
    useTestPageTestSelectedTestSlotQuestionExplanationHighlights();
  const { data: highlightMarkersArrayMap } = useHighlightMarkers();
  const highlightMarkers = useMemo(
    () => ArrayMapUtils.toArray(highlightMarkersArrayMap),
    [highlightMarkersArrayMap],
  );

  useEffect(() => {
    if (!explanationHtml) return;
    const domParser = new DOMParser();
    const parsedExplanationDocument =
      domParser.parseFromString(explanationHtml, "text/html").documentElement;
    parsedExplanationDocument.querySelectorAll("a").forEach((hint) => {
      hint.setAttribute("data-id", hint.getAttribute("href") ?? "");
    });

    const markInstance = new Mark(parsedExplanationDocument);

    markInstance.unmark({
      done: () => {
        //Fake mark so that mark.js can make it's magic
        //Don't touch this... ever... without it highlights
        //don't land on the right spot for some reason
        markInstance.markRanges([{ start: 0, length: 1 }], {
          each(elem) {
            elem.classList.add("bg-transparent", "dark:text-neutral-50");
          },
        });
        highlights?.forEach((highlight) => {
          markInstance.markRanges([{
            start: highlight.start,
            length: highlight.end - highlight.start,
          }], {
            className:
              "rounded-sm cursor-pointer font-semibold dark:text-neutral-800",
            each: (node) => {
              node.setAttribute("data-id", highlight.id);
              node.setAttribute(
                "data-explanation-id",
                explanationId.toString(),
              );
              node.setAttribute("data-marker-id", highlight.markerId);
              node.setAttribute(
                "style",
                `background-color: ${
                  highlightMarkers.find((hm) => hm.id === highlight.markerId)
                    ?.color
                }`,
              );
            },
          });
        });
        setHighlightedHtml(
          parsedExplanationDocument.querySelector("body")!.innerHTML,
        );
      },
    });
  }, [
    highlights,
    explanationId,
    explanationHtml,
  ]);

  return highlightedHtml;
};

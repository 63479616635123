import useTestPageConstructedTest from "./use-test-page-constructed-test";

const useTestPageTestQuestions = () => {
  const constructedTest = useTestPageConstructedTest();
  return {
    ...constructedTest,
    data: constructedTest.data?.slots.map((s) => s.question),
  };
};
export default useTestPageTestQuestions;

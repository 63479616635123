import { fadeAnimation } from "@/assets/framer";
import { PropsWithClassName } from "@/types";
import { AnimatePresence, motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface PopoverProps extends PropsWithChildren, PropsWithClassName {
  isVisible: boolean;
}

export const Popover = ({ isVisible, children, className }: PopoverProps) => {
  return (
    <AnimatePresence mode="wait">
      {isVisible &&
        (
          <motion.div
            {...fadeAnimation}
            className={twMerge(
              "absolute rounded-primary bg-white dark:bg-neutral-700 border border-gray-300 dark:border-neutral-600 min-w-[15rem] p-3",
              className,
            )}
          >
            {children}
          </motion.div>
        )}
    </AnimatePresence>
  );
};

import { trpc } from "@/utils/trpc";
import { useEffect, useMemo } from "react";
import useAddQuestionHeaders from "./use-add-question-headers";
import useQuestionHeaders from "./use-question-headers";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import ArrayMapUtils, { ArrayMap } from "@/types/common/ArrayConvertableMap";
import type { QuestionHeader } from "@/models/question-model";

type Args = QuestionBankIdentifier;
type Opts = {
  filters?: {
    questionHeaders?: ArrayMap<QuestionHeader>;
    subjects?: string[];
    systems?: string[];
    topics?: string[];
    stats?: ("unused" | "incorrect" | "correct" | "marked" | "omitted")[];
  };
  enabled?: boolean;
};

export default function useQuestionBankQuestionHeaders(
  args: Args,
  opts?: Opts,
) {
  const { data: questionBank } = useQuestionBank(args);
  const questionBankId = questionBank?.id ?? "";
  const query = trpc.question.getUserAccessibleQuestionHeadersByQuestionbank
    .useQuery({ questionBankId }, {
      enabled: !!questionBankId && (!opts?.filters?.questionHeaders) &&
        (opts?.enabled ?? true),
    });
  const addQuestionHeaders = useAddQuestionHeaders();
  const questionHeaders = useQuestionHeaders();
  const { data, isSuccess } = query;
  useEffect(() => {
    if (data && isSuccess) {
      addQuestionHeaders(data);
    }
  }, [data, isSuccess]);
  const questionBankQuestionHeaders = useMemo(
    () =>
      (opts?.enabled ?? true)
        ? ArrayMapUtils.filter(
          opts?.filters?.questionHeaders ?? questionHeaders,
          (qh) => {
            const questionIsInQuestioBank =
              qh.questionBankId === questionBankId;
            if (!opts?.filters) return questionIsInQuestioBank;
            const questionInCorrect = opts.filters.stats
              ? opts.filters.stats.includes("correct") && qh.used === "correct"
              : false;
            const questionInInCorrect = opts.filters.stats
              ? opts.filters.stats.includes("incorrect") &&
                qh.used === "incorrect"
              : false;
            const questionInUnused = opts.filters.stats
              ? opts.filters.stats.includes("unused") && !qh.used
              : false;
            const questionInMarked = opts.filters.stats
              ? opts.filters.stats.includes("marked") && qh.marked
              : false;
            const questionInOmitted = opts.filters.stats
              ? opts.filters.stats.includes("omitted") && qh.used === "omitted"
              : false;

            const questionInSubjects =
              opts.filters.subjects && opts.filters.subjects.length > 0
                ? opts.filters.subjects.includes(qh.subjectId)
                : true;
            const questionInSystems =
              opts.filters.systems && opts.filters.systems.length > 0
                ? opts.filters.systems.includes(qh.systemId)
                : true;
            const questionInTopics =
              opts.filters.topics && opts.filters.topics.length > 0
                ? opts.filters.topics.includes(qh.topicId)
                : true;

            if (!opts.filters.stats) {
              return questionIsInQuestioBank &&
                (questionInSubjects && questionInSystems && questionInTopics);
            }

            return (questionIsInQuestioBank &&
              (questionInCorrect || questionInInCorrect || questionInUnused ||
                questionInMarked || questionInOmitted)) &&
              (questionInSubjects && questionInSystems && questionInTopics);
          },
        )
        : {},
    [questionHeaders, questionBankId, opts],
  );
  return {
    ...query,
    data: questionBankQuestionHeaders,
  };
}

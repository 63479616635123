import { DraggableBody, DraggableHandle } from "@/components/draggable";
import Resizable from "@/components/resizable";
import { TriggerableBody } from "@/components/triggerable";
import { WindowBody } from "@/components/window";
import { PropsWithChildren, ReactNode, useEffect } from "react";
import useFloatingWindowContext from "./hooks/use-floating-window-context";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { ViewerToolbar } from "@/components/viewer";
import FloatingWindowTitlebar from "./floating-window-titlebar";

type FloatingWindowBodyProps = PropsWithChildren & PropsWithClassName & {
  title?: ReactNode;
};
const FloatingWindowBody = (
  { children, className, title }: FloatingWindowBodyProps,
) => {
  const {
    draggable,
    initialPositionOnGesture,
    resizable,
    viewer,
    isDraggableFromBody,
  } = useFloatingWindowContext();
  const { position, setPosition, recenter, isDragging, neverDraggedBefore } =
    draggable;
  const { isResizing } = resizable;
  useEffect(() => {
    if (isDragging || isResizing) return;
    if (!neverDraggedBefore) return;
    recenter();
  }, [
    viewer.contentRef.current?.offsetHeight,
    viewer.contentRef.current?.offsetWidth,
    viewer.isMounted,
  ]);
  return (
    <TriggerableBody>
      <DraggableBody
        tabIndex={0}
        className={cn(
          "outline-none z-[998] focus:z-[1000] focus:ring focus:ring-nbme-primary-500 warm:focus:ring-brown-700 dark:focus:ring-neutral-400 duration-300 transition-[box-shadow] rounded-t-xl rounded-b-xl",
          className,
        )}
      >
        <Resizable.Body>
          <WindowBody
            className={cn("w-full h-full justify-between")}
          >
            {title &&
              (
                <FloatingWindowTitlebar>
                  {title}
                </FloatingWindowTitlebar>
              )}
            <DraggableHandle
              className="w-full min-h-0 flex-grow cursor-auto"
              isDraggable={(!viewer.hasOverflow) && isDraggableFromBody}
            >
              {children}
            </DraggableHandle>
            {viewer.isMounted &&
              (
                <DraggableHandle className="mt-auto w-full">
                  <ViewerToolbar
                    onCopy={viewer.onCopy}
                    onDownload={viewer.onDownload}
                  />
                </DraggableHandle>
              )}
          </WindowBody>
          <Resizable.UpperBorder
            events={{
              onResize: (
                { delta, isMinimumReached, isMaximumReached },
              ) => {
                if (!position) return;
                if (!initialPositionOnGesture) return;
                setPosition({
                  y: isMinimumReached.y || isMaximumReached.y
                    ? position.y
                    : initialPositionOnGesture.y + delta.y,
                  x: isMinimumReached.x || isMaximumReached.x
                    ? position.x
                    : initialPositionOnGesture.x,
                });
              },
            }}
          />
          <Resizable.LowerBorder />
          <Resizable.RightBorder />
          <Resizable.LeftBorder
            events={{
              onResize: (
                { delta, isMinimumReached, isMaximumReached },
              ) => {
                if (!position) return;
                if (!initialPositionOnGesture) return;
                setPosition({
                  y: isMinimumReached.y || isMaximumReached.y
                    ? position.y
                    : initialPositionOnGesture.y,
                  x: isMinimumReached.x || isMaximumReached.x
                    ? position.x
                    : initialPositionOnGesture.x + delta.x,
                });
              },
            }}
          />
          <Resizable.UpperLeftCorner
            events={{
              onResize: (
                { delta, isMinimumReached, isMaximumReached },
              ) => {
                if (!position) return;
                if (!initialPositionOnGesture) return;
                setPosition({
                  y: isMinimumReached.y || isMaximumReached.y
                    ? position.y
                    : initialPositionOnGesture.y + delta.y,
                  x: isMinimumReached.x || isMaximumReached.x
                    ? position.x
                    : initialPositionOnGesture.x + delta.x,
                });
              },
            }}
          />
          <Resizable.LowerLeftCorner
            events={{
              onResize: (
                { delta, isMinimumReached, isMaximumReached },
              ) => {
                if (!position) return;
                if (!initialPositionOnGesture) return;
                setPosition({
                  y: isMinimumReached.y || isMaximumReached.y
                    ? position.y
                    : initialPositionOnGesture.y,
                  x: isMinimumReached.x || isMaximumReached.x
                    ? position.x
                    : initialPositionOnGesture.x + delta.x,
                });
              },
            }}
          />
          <Resizable.UpperRightCorner
            events={{
              onResize: (
                { delta, isMinimumReached, isMaximumReached },
              ) => {
                if (!position) return;
                if (!initialPositionOnGesture) return;
                setPosition({
                  y: isMinimumReached.y || isMaximumReached.y
                    ? position.y
                    : initialPositionOnGesture.y + delta.y,
                  x: isMinimumReached.x || isMaximumReached.x
                    ? position.x
                    : initialPositionOnGesture.x,
                });
              },
            }}
          />
          <Resizable.LowerRightCorner />
        </Resizable.Body>
      </DraggableBody>
    </TriggerableBody>
  );
};

export default FloatingWindowBody;

import { twMerge } from "tailwind-merge";
import Checkbox from "./Checkbox";
import { Label } from "./Label";
import { cn } from "@/lib/utils";

interface SubjectCheckBoxProps {
  checked: boolean;
  onCheckedChange?: (checked: boolean) => void;
  disabled?: boolean;
  id: string;
  label: string;
  className?: string;
  labelClassName?: string;
  checkBoxClassName?: string;
  onClick?: () => void;
}

const LabeledCheckbox = (
  {
    checked,
    onCheckedChange,
    id,
    disabled,
    label,
    className,
    labelClassName,
    checkBoxClassName,
    onClick,
  }: SubjectCheckBoxProps,
) => {
  return (
    <div
      className={twMerge("flex justify-start items-center gap-2", className)}
    >
      <Checkbox
        id={id}
        checked={checked}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
        className={cn("", checkBoxClassName)}
        onClick={onClick}
      />
      <Label
        className={twMerge(
          "cursor-pointer text-gray-400 dark:text-neutral-200 warm:text-brown-500 warm:hover:text-brown-800 transition-colors",
          checked && "text-gray-500 warm:text-brown-800",
          labelClassName,
          disabled && "cursor-default text-gray-300",
        )}
        htmlFor={id}
      >
        {label}
      </Label>
    </div>
  );
};

export default LabeledCheckbox;

import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

type WindowTitleProps = PropsWithClassName & PropsWithChildren;

const WindowTitle = ({ children, className }: WindowTitleProps) => {
  return (
    <div
      className={cn(
        "flex flex-row justify-center items-center font-semibold dark:text-neutral-100",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default WindowTitle;

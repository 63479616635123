import { Drawer, DrawerContent } from "@/components/ui/drawer";
import { ComponentProps, PropsWithChildren } from "react";

export const PeepingDrawer = (
  { children, ...props }:
    & ComponentProps<typeof Drawer>
    & PropsWithChildren,
) => {
  return (
    <Drawer
      {...props}
      shouldScaleBackground
    >
      <DrawerContent>
        <div className="pt-10 h-full">
          {children}
        </div>
      </DrawerContent>
    </Drawer>
  );
};

import { createFileRoute } from "@tanstack/react-router";
import { DashboardFrame } from "@/components/DashboardFrame";
import { PreviousTestsColumnSelect } from "@/components/PreviousTestsColumnSelect";
import PreviousTestsMobile from "./-components/previous-tests-mobile-table";
import PreviousTestsTable from "./-components/previous-tests-table";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { useEffect, useMemo, useState } from "react";
import { PreviousTestsPagePaginator } from "./-components/PreviousTestsPagePaginator";
import { PreviousTestsPageTestRenameDialog } from "./-components/PreviousTestsPageTestRenameDialog";
import { PreviousTestsPageQueryInput } from "./-components/PreviousTestsPageQueryInput";
import useQuestionBankTests from "@/hooks/test-hooks/use-question-bank-tests";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useRenameTest from "@/hooks/test-hooks/use-rename-test";
import {
  usePreviousTestsPageCurrentPage,
  usePreviousTestsPageTestsPerPage,
} from "@/utils/stores/previousTestsPageStore";
import { useOnMobile } from "@/hooks";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/previous-tests",
)({
  component: TestsPage,
});

function TestsPage() {
  const params = Route.useParams();
  const [renameTestDialogVisible, setRenameTestDialogVisible] = useState<
    boolean
  >(false);

  const { setHeaderText } = useDashboardLayoutStore();

  const { mutate: renameTest } = useRenameTest();

  const { data: testsArrayMap, isLoading } = useQuestionBankTests({
    questionBankEndpoint: params.qbankEndpoint,
  });
  const isOnMobile = useOnMobile();

  const [currentPage, setCurrentPage] = usePreviousTestsPageCurrentPage();
  const [testsPerPage, setTestsPerPage] = usePreviousTestsPageTestsPerPage();
  const tests = useMemo(
    () => ArrayMapUtils.sort(testsArrayMap, (t) => t.startedAt).reverse(),
    [testsArrayMap],
  );

  useEffect(() => {
    setHeaderText("Previous Tests");
    //
    //   if (error) {
    //     toast.error(
    //       `Could not retrieve previous tests, an error occured: ${error.message}`,
    //     );
    //   }
    //
    //   setTestsLoading(isLoading);
    return () => setHeaderText("");
  }, [
    // data, isLoading, error, loadTests,
    setHeaderText,
  ]);

  const [testToRenameId, setTestToRenameId] = useState<number | null>(null);
  useEffect(() => {
    if (testToRenameId) return setRenameTestDialogVisible(true);
  }, [testToRenameId, setRenameTestDialogVisible]);

  const handleTestRename = (newName: string) => {
    if (!testToRenameId) return;
    renameTest({ testId: testToRenameId, name: newName });
    setRenameTestDialogVisible(false);
    setTestToRenameId(null);
  };

  return (
    <DashboardFrame className="min-h-full pb-5 h-full">
      <PreviousTestsPageTestRenameDialog
        open={renameTestDialogVisible}
        onOpenChange={setRenameTestDialogVisible}
        onTestNameChange={handleTestRename}
        testName={!!testToRenameId
          ? (tests.find((t) => t.id === testToRenameId)?.name ?? "")
          : ""}
      />
      <div className="hidden md:flex flex-row justify-between items-center w-full p-3">
        <PreviousTestsColumnSelect />
        <PreviousTestsPageQueryInput />
      </div>
      <div className="md:hidden flex flex-col justify-start items-center w-full p-3 gap-3">
        <div className="self-start">
          <PreviousTestsColumnSelect />
        </div>
        <div className="self-end">
          <PreviousTestsPageQueryInput />
        </div>
      </div>
      {!isOnMobile &&
        (
          <div className="w-full hidden md:block">
            <PreviousTestsTable
              tests={tests}
              isLoading={isLoading}
              currentPage={currentPage}
              testsPerPage={testsPerPage}
              onTestRenameClick={(testId) => setTestToRenameId(testId)}
              qBankEndpoint={params.qbankEndpoint}
            />
          </div>
        )}
      {isOnMobile &&
        (
          <div className="w-full block md:hidden">
            <PreviousTestsMobile
              tests={tests}
              isLoading={isLoading}
              currentPage={currentPage}
              testsPerPage={testsPerPage}
              onTestRenameClick={(testId) => setTestToRenameId(testId)}
              qBankEndpoint={params.qbankEndpoint}
            />
          </div>
        )}
      <div className="w-full flex flex-row justify-center md:justify-end items-center px-6 mt-5 text-xs md:text-sm lg:text-base">
        <PreviousTestsPagePaginator
          currentPage={currentPage}
          onItemsPerPageChange={setTestsPerPage}
          onCurrentPageChange={setCurrentPage}
          numberOfItems={tests.length}
          itemsPerPage={testsPerPage}
        />
      </div>
    </DashboardFrame>
  );
}

import { InsertColumnBeforeIcon } from "@/components/icons/hollow";
import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap";

export const InsertColumnBefore = () => {
  const { editor } = useRichTextEditorContext();
  return (
    <RichTextEditor.Control
      title="Insert Column Before"
      aria-label="Insert Column Before"
      disabled={!editor?.can().addColumnBefore()}
      onClick={() => editor?.commands.addColumnBefore()}
    >
      <InsertColumnBeforeIcon className="w-4 h-4 text-neutral-800 dark:text-neutral-400" />
    </RichTextEditor.Control>
  );
};

import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { NbmeNavbarButton } from ".";
import { twMerge } from "tailwind-merge";

interface NbmeNavbarButtonWithIconsProps extends
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon: JSX.Element;
  label: string;
  labelClassName?: string;
}

export const NbmeNavbarButtonWithIcon = (
  { icon, label, labelClassName, ...props }: NbmeNavbarButtonWithIconsProps,
) => {
  return (
    <NbmeNavbarButton {...props}>
      <div className="flex flex-col justify-between items-center text-white min-h-full px-1">
        {icon}
        <p className={twMerge("text-xs mt-0.5", labelClassName)}>{label}</p>
      </div>
    </NbmeNavbarButton>
  );
};

"use client";
import { ContextType, createContext } from "react";
import useResizableState from "./hooks/use-resizable-state";

const throwOutsideContextError = () => {
  throw Error("This component can't be used outside its Root");
};

const resizableContext = createContext<
  ReturnType<typeof useResizableState> | undefined
>(undefined);

export type ResizableContext = NonNullable<
  ContextType<typeof resizableContext>
>;

export default resizableContext;

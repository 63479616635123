import { useState } from "react";
import {
  NbmeBloodLabValues,
  NbmeCerebrospinalLabValues,
  NbmeFloatingWindow,
  NbmeSerumLabValues,
  NbmeUrineAndBMILabValues,
} from ".";
import { FilterInterface } from "interweave";
import { twMerge } from "tailwind-merge";

interface NbmeLabValuesWindowProps {
  isVisible: boolean;
  onIsVisibleChange: (v: boolean) => void;
  onIsSelectionEnabledChange: (v: boolean) => void;
}

export const NbmeLabValuesWindow = (
  { isVisible, onIsVisibleChange, onIsSelectionEnabledChange }:
    NbmeLabValuesWindowProps,
) => {
  const tabs = ["Serum", "Cerebrospinal", "Blood", "Urine and BMI"];
  const [activeTab, setActiveTab] = useState<string>("serum");

  const labValueFilter: FilterInterface = {
    node(name, node) {
      if (name === "table") {
        node.setAttribute("style", "");
        node.className = "min-w-0 w-auto table-auto";
      }
      return node;
    },
  };
  return (
    <NbmeFloatingWindow
      isVisible={isVisible}
      title={"Lab Values"}
      className="min-w-0 overflow-x-auto"
      onCloseClicked={() => onIsVisibleChange(false)}
      onIsSelectionEnabledChange={onIsSelectionEnabledChange}
    >
      <div className="flex flex-col justify-start items-center w-full">
        <div className="flex flex-row justify-between items-end bg-nbme-primary-600 dark:bg-nbme-primary-dark-900 gap-3 w-full p-3 px-7 pb-0 transition-colors">
          {tabs.map((tab) => {
            const tabName = tab.toLowerCase().replace(/\ /g, "");
            const active = activeTab === tabName;
            return (
              <button
                type="button"
                key={tab}
                className={twMerge(
                  "text-white rounded-primary p-1 font-bold bg-nbme-primary-600 dark:bg-nbme-primary-dark-900 rounded-b-none",
                  active &&
                    "text-nbme-primary-600 bg-white dark:bg-nbme-primary-dark-700 dark:text-white",
                  "transition-colors",
                )}
                onClick={() => setActiveTab(tabName)}
              >
                {tab.replace("and", "&")}
              </button>
            );
          })}
        </div>

        <div className="relative max-h-[400px] overflow-auto p-4 text-sm md:text-base w-full dark:text-white transition-colors">
          <NbmeSerumLabValues
            filters={[labValueFilter]}
            className={twMerge("hidden", activeTab === "serum" && "block")}
          />
          <NbmeCerebrospinalLabValues
            filters={[labValueFilter]}
            className={twMerge(
              "hidden",
              activeTab === "cerebrospinal" && "block",
            )}
          />
          <NbmeBloodLabValues
            filters={[labValueFilter]}
            className={twMerge(
              "hidden",
              activeTab === "blood" && "block",
            )}
          />
          <NbmeUrineAndBMILabValues
            filters={[labValueFilter]}
            className={twMerge(
              "hidden",
              activeTab === "urineandbmi" && "block",
            )}
          />
        </div>
      </div>
    </NbmeFloatingWindow>
  );
};

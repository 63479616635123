import { ProgressBar } from "@/components/ProgressBar";
import React from "react";
import { getSubjectReportPercentages } from "../-utils";
import { ItemPerformanceReport, ReportOptions } from "../-types";

interface PerformanceReportsPageProgressBarProps {
  itemReport: ItemPerformanceReport;
  reportOptions: ReportOptions;
}

export const PerformanceReportsPageProgressBar = (
  { itemReport, reportOptions }: PerformanceReportsPageProgressBarProps,
) => {
  const percentages = getSubjectReportPercentages(itemReport, reportOptions);

  return (
    <ProgressBar
      className="w-[100%] h-2 bg-white md:bg-gray-200"
      percentages={percentages}
      percentageClassNames={[
        "bg-lime-500",
        "bg-red-500",
        "bg-sky-500",
      ]}
    />
  );
};

import { twMerge } from "tailwind-merge";
import { SideIcon } from "./components";
import { ChangeEventHandler, FormEventHandler } from "react";
import { useSearch } from "@/hooks/notebook";

type TitleProps = {
  title: string;
  isCollapsable: boolean;
  isCollapsed: boolean;
  onIsCollapsedChange: (newIsCollapsed: boolean) => void;
  isEditMode: boolean;
  onTitleChange?: (newTitle: string) => void;
  onEditSubmit: (newTitle: string) => void;
  onTitleClick: () => void;
  onSideIconClick: () => void;
  isSelected: boolean;
};

export const Title = (
  {
    title,
    isSelected,
    isCollapsable,
    isCollapsed,
    onIsCollapsedChange,
    isEditMode,
    onEditSubmit,
    onTitleChange,
    onTitleClick,
    onSideIconClick,
  }: TitleProps,
) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onTitleChange?.(e.target.value);
  };
  const [search] = useSearch();
  return (
    <div
      className={twMerge(
        "flex flex-row justify-start items-center gap-3 w-full cursor-pointer",
        "text-gray-700  warm:text-brown-900 dark:text-neutral-300 font-semibold transition-colors",
        isSelected &&
          "text-primary-500 warm:text-brown-950 dark:text-neutral-50",
      )}
      onClick={() => !isEditMode && onTitleClick()}
    >
      <SideIcon
        onClick={() => {
          if (
            isCollapsable
          ) {
            onIsCollapsedChange(!isCollapsed);
          }
          onSideIconClick();
        }}
        isChevron={isCollapsable}
        isRotatedToRight={isCollapsed}
      />
      {isEditMode
        ? (
          <form
            className="w-full"
            onSubmit={(e) => {
              e.preventDefault();
              onEditSubmit(title);
            }}
          >
            <input
              className="w-[70%] rounded-primary outline-none dark:bg-neutral-700 dark:text-neutral-300"
              value={title}
              onChange={handleChange}
            />
          </form>
        )
        : (
          <p className="text-ellipsis whitespace-nowrap overflow-hidden px-1 group-hover:w-[40%]">
            {Array.from(title).map((char, index) => (
              <span
                key={char + index}
                className={twMerge(
                  search.includes(char.toLowerCase()) &&
                    "bg-[rgb(255,255,0)]",
                )}
              >
                {char}
              </span>
            ))}
          </p>
        )}
    </div>
  );
};

import {
  Hint,
  Question,
  QuestionChoice,
  QuestionExplanation,
  Subject,
  System,
  Topic,
} from "@/api/types";
import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import { numberToAlphabet } from "@/utils/common/numberToAlphabet";
import { trpc } from "@/utils/trpc";
import { Interweave } from "interweave";
import React from "react";
import { Separator } from "@/components/Separator";
import {
  imageFilter,
  linkFilter,
  listFilter,
  paragraphFilter,
  tableFilter,
} from "@/utils/interweave/filters";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { BreakPoints, FontSizes, LineHeights } from "@/types";
import { hintTransformer } from "@/utils/interweave/transformers/hintTransformer";

interface NbmeExplanationProps {
  fontSizeFactor: number;
  question: Question & {
    explanation: QuestionExplanation & { hints: Hint[] };
    system: Pick<System, "name">;
    subject: Pick<Subject, "name">;
    topic: Pick<Topic, "name">;
  };
}

export const NbmeExplanation = (
  { fontSizeFactor, question }: NbmeExplanationProps,
) => {
  const breakPoint = useBreakPoint();
  const {
    data: explanationImage,
    isSuccess: isExplanationImageSuccess,
    isError: isExplanationImageError,
    error: explanationImageError,
    isLoading: isExplanationImageLoading,
  } = trpc.image.getById.useQuery({
    id: question?.explanation.imageId!,
    size: 2,
  }, {
    enabled: !!question.explanation.imageId,
  });

  if (!question) return <></>;

  const isScreenLarge = breakPoint > BreakPoints.Medium;
  const isScreenMedium = breakPoint > BreakPoints.Small;

  return (
    <div className="flex flex-col justify-start items-center w-full gap-7">
      <div className="flex flex-row justify-between items-center w-full">
        <p className="font-medium border-t border-r border-l border-gray-300 p-3 rounded-t-md">
          Explanation:
        </p>
        <div className="flex-grow w-full h-full border-b border-gray-300 self-end">
        </div>
      </div>
      <div
        className="flex flex-col justify-start items-center lg:items-start gap-4"
        style={{
          fontSize: scaleUnitInRem(
            isScreenLarge ? FontSizes.Small : FontSizes.Base,
            fontSizeFactor,
          ).resultInRemAsCss,
          lineHeight: scaleUnitInRem(
            isScreenLarge ? LineHeights.Small : LineHeights.Base,
            fontSizeFactor,
          ).resultInRemAsCss,
        }}
      >
        {question.explanation.html
          ? (
            <Interweave
              className=""
              filters={[
                linkFilter,
                paragraphFilter(fontSizeFactor),
                imageFilter(explanationImage?.dataUrl ?? ""),
                tableFilter(breakPoint, fontSizeFactor),
                listFilter,
              ]}
              transform={hintTransformer({
                questionSource: question.source ?? "",
              })}
              content={question.explanation.html}
            />
          )
          : (
            <>
              <p className="whitespace-pre-line">{question.explanation.text}</p>
              {question.explanation.objective &&
                (
                  <div className="flex flex-col justify-start items-start gap-1">
                    <h2 className="font-semibold">Educational objective:</h2>
                    <p>{question.explanation.objective}</p>
                  </div>
                )}
              {question.explanation.references &&
                (question.explanation.references as Array<any>).length > 0 &&
                (
                  <div className="flex flex-col justify-start items-start gap-1">
                    <h2 className="font-semibold">References:</h2>
                    <ul className="list-disc inset-10 underline text-nbme-primary-500 ml-8">
                      {(question.explanation.references as Array<any>).map((
                        exp,
                        index,
                      ) => {
                        const typedExp = exp as { link: string; text: string };
                        return (
                          <li key={index}>
                            <a href={typedExp.link} target="_blank">
                              {typedExp.text}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
            </>
          )}
        <Separator orientation="horizontal" className="bg-gray-300" />
        <div className="flex flex-wrap md:flex-nowrap flex-row justify-between md:justify-start items-center gap-8">
          <div className="flex flex-col justify-center items-start">
            <p
              className={"font-medium"}
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Base : FontSizes.Small,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              {question.subject.name}
            </p>
            <p
              className="font-light text-gray-500"
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              Subject
            </p>
          </div>
          <div className="flex flex-col justify-center items-start">
            <p
              className={"font-medium"}
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Base : FontSizes.Small,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              {question.system.name}
            </p>
            <p
              className="font-light text-gray-500"
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              System
            </p>
          </div>
          <div className="flex flex-col justify-center items-start">
            <p
              className={"font-medium"}
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Base : FontSizes.Small,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              {question.topic.name}
            </p>
            <p
              className="font-light text-gray-500"
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              Topic
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

import { PropsWithChildren } from "react";
import useResizableContext from "./hooks/use-resizable-context";
import { PropsWithClassName } from "@/types";

type ResizableMaximizeToggleProps = PropsWithChildren & PropsWithClassName;
const ResizableMaximizeToggle = (
  { children, className }: ResizableMaximizeToggleProps,
) => {
  const { maximize, isMaximized, minimize } = useResizableContext();
  return (
    <button
      className={className}
      onClick={isMaximized ? minimize : maximize}
    >
      {children}
    </button>
  );
};

export default ResizableMaximizeToggle;

import { DashboardFrame } from "@/components/DashboardFrame";
import DashboardSection from "@/components/DashboardSection";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { useEffect } from "react";
import { useSearchQuestionsPageStore } from "./-store";
import { trpc } from "@/utils/trpc";
import { toast } from "sonner";

import {
  SearchQuestionsPageNoQueryCenterPiece,
  SearchQuestionsPagePaginator,
  SearchQuestionsPageQueryInput,
  SearchQuestionsPageTable,
} from "./-components";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";

import { createFileRoute } from "@tanstack/react-router";
import DashboardLoading from "../-loading";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/search-questions",
)({
  component: SearchQuestionsPage,
  pendingComponent: DashboardLoading,
});

function SearchQuestionsPage() {
  const params = Route.useParams();

  const { data: questionBank } = useQuestionBank({
    questionBankEndpoint: params.qbankEndpoint,
  });
  const { setHeaderText } = useDashboardLayoutStore();
  const qBankId = questionBank?.id ?? "";

  const {
    questions,
    loadQuestions,
    setIsQuestionsLoading,
    query,
  } = useSearchQuestionsPageStore();
  const isQueryEnabled = !!qBankId;
  const { data, isLoading, error } = trpc.question.findQuestions.useQuery({
    query,
    qBankId,
  }, { enabled: isQueryEnabled });

  useEffect(() => {
    setHeaderText("Search Questions");
    return () => setHeaderText("");
  }, [setHeaderText]);

  useEffect(() => {
    setIsQuestionsLoading(isLoading);

    if (error) {
      toast.error(
        `Could not load questions, an error occured: ${error.message}`,
      );
    }
    if (data) {
      loadQuestions(data);
    }
  }, [isLoading, data, error]);

  return (
    <div className="flex flex-col justify-start items-center gap-7 w-full">
      <DashboardFrame className="w-full">
        <DashboardSection className="py-8">
          <div className="flex flex-row justify-between items-center gap-2 w-full max-w-md">
            <SearchQuestionsPageQueryInput />
          </div>
        </DashboardSection>
      </DashboardFrame>
      <DashboardFrame className="relative w-full min-h-[70vh]">
        {!query && <SearchQuestionsPageNoQueryCenterPiece />}
        {query &&
          (
            <>
              <div className="p-4 px-6">
                <p className="w-full">
                  Total results found: {questions.length}
                </p>
              </div>
              <div>
                <SearchQuestionsPageTable />
              </div>
              <div className="w-full flex flex-row justify-center md:justify-end items-center px-6 mt-5 text-xs md:text-sm lg:text-base">
                <SearchQuestionsPagePaginator />
              </div>
            </>
          )}
      </DashboardFrame>
    </div>
  );
}

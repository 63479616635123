import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useGetQuestionBankTopicPerofrmanceReports from "./use-get-question-bank-topic-performance-reports";

const useQuestionBankTopicPerformanceReports = (
  args: QuestionBankIdentifier,
) => {
  const getQuestionBankTopicPerformanceReportsQuery =
    useGetQuestionBankTopicPerofrmanceReports(args);
  const { data: getQuestionBankTopicPerformanceReports } =
    getQuestionBankTopicPerformanceReportsQuery;
  const performanceReports = getQuestionBankTopicPerformanceReports();

  return {
    ...getQuestionBankTopicPerformanceReportsQuery,
    data: performanceReports,
  };
};

export default useQuestionBankTopicPerformanceReports;

import { FocusEventHandler, useEffect, useRef, useState } from "react";
import { Autocompletor, Tag } from "./components";
import {
  handleAutocompleteItemClick,
  handleChange,
  handleKeyPressed,
} from "./handlers";
import { useCurrentUserTags } from "./hooks";
import { clamp } from "@/utils/common/clamp";
import { useTagInput } from "@/hooks/notebook";

interface InputProps {
  disabled?: boolean;
  tags: { title: string; id: string }[];
  onTagAdd: (tag: string) => void;
  onTagRemove: (tag: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  selectedNote?: { id: string };
}

export const Input = (
  {
    tags: selectedNoteTags,
    onTagAdd,
    onTagRemove,
    onFocus,
    disabled,
    selectedNote,
  }: InputProps,
) => {
  const userTags = useCurrentUserTags();
  const [value, setValue] = useTagInput();
  const [selectedAutocompleteTagIndex, setSelectedAutocompleteTagIndex] =
    useState<
      number
    >(0);

  const matchesInputValue = (t: { title: string; id: string }) =>
    t.title.toLowerCase().includes(value.toLowerCase());

  const notAlreadyAddedToSelectedNote = (t: { title: string; id: string }) =>
    !selectedNoteTags.find((selectedNoteTag) => selectedNoteTag.id === t.id);

  const matchingTags = userTags.filter((t) =>
    matchesInputValue(t) && notAlreadyAddedToSelectedNote(t)
  );

  const selectedMatchingTag = matchingTags[selectedAutocompleteTagIndex];
  const lastTag = selectedNoteTags.at(-1);

  return (
    <div className="flex flex-row flex-wrap justify-start items-center gap-1 flex-grow overflow-visible">
      {selectedNoteTags.map((t) => {
        return (
          <Tag
            key={t.id}
            id={t.id}
            onRemove={() => onTagRemove(t.title)}
            title={t.title}
          />
        );
      })}
      <div className="relative flex flex-row justify-start items-center flex-grow">
        <Autocompletor
          onMatchClick={handleAutocompleteItemClick({ onTagAdd, setValue })}
          matches={matchingTags}
          selectedMatch={selectedMatchingTag}
          value={value}
        />
        <input
          disabled={disabled}
          value={value}
          onKeyDownCapture={handleKeyPressed({
            value,
            setValue,
            onTagAdd,
            onLastTagRemove: lastTag
              ? () => onTagRemove(lastTag?.title)
              : undefined,
            autocompleteValue: selectedMatchingTag?.title ?? "",
            selectedAutocompleteTagIndex: selectedAutocompleteTagIndex,
            onSelectedAutocompleteIndexChange: (newIndex) =>
              setSelectedAutocompleteTagIndex(
                clamp({ num: newIndex, min: 0, max: 2 }),
              ),
          })}
          placeholder="Click to add tags"
          onChange={handleChange({ setValue, onTagAdd })}
          className="outline-none border-0 bg-white warm:bg-brown-50 warm:placeholder:text-brown-950/30 dark:bg-neutral-800 text-sm disabled:placeholder:text-gray-200 dark:disabled:placeholder:text-neutral-700 transition-colors min-w-0 w-full dark:text-neutral-100"
          onFocus={onFocus}
        />
      </div>
    </div>
  );
};

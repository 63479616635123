import { PropsWithClassName } from "@/types";
import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface BadgeProps extends PropsWithChildren, PropsWithClassName {
}

export const Badge = ({ className, children }: BadgeProps) => {
  return (
    <div
      className={twMerge(
        "bg-gray-100 warm:bg-brown-700/80 warm:text-brown-50 border border-transparent warm:border-brown-700/90 dark:border-neutral-600 dark:bg-neutral-700 dark:text-neutral-100 rounded-full p-3 flex justify-center items-center w-min",
        className,
      )}
    >
      {children}
    </div>
  );
};

import { Button } from "@/components/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import useQuestionBankTests from "@/hooks/test-hooks/use-question-bank-tests";
import { useCreateTestPageStore } from "@/utils/stores";
import useDashboardQuestionBank from "../../-dashboard-hooks/use-dashboard-question-bank";
import useCreateTestPageCreateTest from "../-hooks/use-create-test-page-create-test";
import { useState } from "react";

const CreateTestButton = ({ isLoading }: { isLoading: boolean }) => {
  const [disabled, setDisabled] = useState(false);
  const [isCreatingTest, setIsCreatingTest] = useState<boolean>(false);
  const { data: questionBank } = useDashboardQuestionBank();
  const {
    questionCount,
    questionIds,
    selectedBlueprint,
    qmode,
  } = useCreateTestPageStore((state) => ({
    toggledSections: state.toggledSections,
    selectedSubjects: state.selectedSubjects,
    selectedTopics: state.selectedTopics,
    selectedSystems: state.selectedSystems,
    selectedBlueprint: state.selectedBlueprint,
    name: state.name,
    minutesPerQuestion: state.minutesPerQuestion,
    setMinutesPerQuestion: state.setMinutesPerQuestion,
    qPool: {
      unused: state.unused,
      marked: state.marked,
      incorrect: state.incorrect,
      correct: state.correct,
      omitted: state.omitted,
    },
    qmode: state.qmode,
    questionCount: state.questionCount,
    mode: {
      tutor: state.tutor,
      timed: state.timed,
    },
    unused: state.unused,
    incorrect: state.incorrect,
    marked: state.marked,
    omitted: state.omitted,
    correct: state.correct,

    questionIds: state.questionIds,
    reset: state.reset,
  }));
  const createTest = useCreateTestPageCreateTest();

  const questionBankTests = useQuestionBankTests({
    questionBankId: questionBank?.id ?? "",
  });
  const handleSubmit = () => {
    setIsCreatingTest(true);
    setDisabled(true);
    createTest().then(() => {
      //This will reset everytime this component is dismounted so no worries.
      setIsCreatingTest(false);
      setDisabled(false);
    }).catch(() => {
      setDisabled(false);
      setIsCreatingTest(false);
    });
  };
  const isButtonDisabled = () => {
    let isDisabled = disabled;
    if (qmode === "custom") {
      return questionIds.length === 0;
    }
    if (qmode === "ordered") {
      return !selectedBlueprint;
    }

    return isDisabled ||
      Number(questionCount) === 0 || questionBankTests.isLoading;
  };
  return (
    <div className="flex flex-row justify-start items-center gap-2">
      <Button
        disabled={isButtonDisabled() || isLoading || isCreatingTest}
        className="text-xs md:text-sm lg:text-sm px-4 w-fit h-10"
        onClick={handleSubmit}
      >
        Generate Test
      </Button>
      {(isLoading || isCreatingTest) && (
        <div className="w-10 h-10">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default CreateTestButton;

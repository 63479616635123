import { useNbmeSplitView } from "@/utils/stores/nbmeLayoutStore";
import React from "react";
import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";

export const NbmeSettingsSplitViewControl = () => {
  const [splitView, setSplitView] = useNbmeSplitView();
  return (
    <NbmeSettingsSwitch checked={splitView} onCheckedChange={setSplitView} />
  );
};

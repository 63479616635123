import { useEffect } from "react";

const useOnScroll = (onScroll: (e: MouseEvent) => void) => {
  useEffect(() => {
    const listener = (e: MouseEvent) => onScroll(e);
    window.addEventListener("wheel", listener);
    return () => window.removeEventListener("wheel", listener);
  }, []);
};

export default useOnScroll;

import createSystemSlice from "./create-system-slice";
import { System } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";

export type SystemSliceActions = {
  addSystem: (system: System) => void;
  addSystems: (systems: System[]) => void;
};
export type SystemSliceState = typeof systemSliceState;

export type SystemSlice = SystemSliceState & SystemSliceActions;

export type SystemSliceCreator = StateCreator<
  SystemSlice,
  [["zustand/immer", never]],
  [],
  SystemSlice
>;

export type SystemSliceModifiers = SliceModifiers<SystemSliceCreator>;

export type SystemSliceAction = (...modifiers: SystemSliceModifiers) => void;

export const systemSliceState = {
  systems: {} as ArrayMap<System>,
};

export default createSystemSlice;

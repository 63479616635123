import { useAppStore } from "@/store";
import infuseFunctionIntoMutation from "@/utils/common/infuse-function-into-mutation";
import { trpc } from "@/utils/trpc";

const useEditQuestionNote = () => {
  const editQuestionNote = useAppStore((state) => state.editQuestionNote);
  const mutation = trpc.questionNote.editQuestionNote.useMutation();

  return {
    ...infuseFunctionIntoMutation({ func: editQuestionNote, mutation }),
  };
};

export default useEditQuestionNote;

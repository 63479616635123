import useTestPageConstructedTest from "./use-test-page-constructed-test";

const useTestPageTestSelectedTestSlot = () => {
  const constructedTest = useTestPageConstructedTest();
  return ({
    ...constructedTest,
    data: constructedTest.data?.slots.find((s) =>
      constructedTest.data?.selectedSlot === s.order
    ),
  });
};

export default useTestPageTestSelectedTestSlot;

import { TestWithArticles } from "@/models/test-model/test-model-types";
import { TableRow } from "@/types";

const row: TableRow<TestWithArticles> = {
  collapsible: false,
  columns: [
    {
      name: "Score",
      visible: true,
      content: (test) => ({
        score: test.score,
        submitted: test.submitted,
        testId: test.id,
        isBeingEvaluated: test.isBeingEvaluated,
      }),
    },
    {
      name: "Name",
      visible: true,
      content: (test) => ({ name: test.name, id: test.id }),
    },
    { name: "Date", visible: true, content: (test) => test.startedAt },
    { name: "Mode", visible: true, content: (test) => test.mode },
    { name: "Q. Pool", visible: false, content: (test) => test.pool },
    {
      name: "Subjects",
      visible: true,
      content: (test) => test,
    },
    {
      name: "Systems",
      visible: true,
      content: (test) => {
        return test;
      },
    },
    { name: "# Qs", visible: true, content: (test) => test.slots.length },
    {
      name: "Actions",
      visible: true,
      content: (test) => ({
        id: test.id,
        submitted: test.submitted,
        name: test.name,
        questions: test.questions,
      }),
    },
  ],
};

export default row;

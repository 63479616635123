import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankSystems from "./use-question-bank-systems";
import { Question } from "@/models/question-model";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import mergeFlags from "@/utils/common/merge-flags";
import { useMemo } from "react";

const useQuestionBankQuestionSystem = (
  args: QuestionBankIdentifier & { questionId: Question["id"] },
) => {
  const questionBankQuestionHeadersQuery = useQuestionBankQuestionHeaders(args);
  const { data: questionBankQuestionHeaders } =
    questionBankQuestionHeadersQuery;
  const questionBankSystemsQuery = useQuestionBankSystems(args);
  const { data: questionBankSystems } = questionBankSystemsQuery;
  const question = useMemo(() =>
    ArrayMapUtils.find(
      questionBankQuestionHeaders,
      args.questionId,
    ), [questionBankQuestionHeaders, args.questionId]);
  const questionSystem = useMemo(
    () =>
      question
        ? ArrayMapUtils.find(questionBankSystems, question.systemId)
        : undefined,
    [questionBankSystems, question?.systemId],
  );

  return {
    ...mergeFlags({
      source: questionBankQuestionHeadersQuery,
      destinations: [questionBankSystemsQuery],
    }),
    data: questionSystem,
  };
};
export default useQuestionBankQuestionSystem;

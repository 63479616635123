import { useNbmeLineWidth } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSlider } from "./NbmeSettingsSlider";

export const NbmeSettingsLineWidthControl = () => {
  const [lineWidth, setLineWidth] = useNbmeLineWidth();

  return (
    <NbmeSettingsSlider
      value={lineWidth}
      onValueChange={setLineWidth}
      max={100}
      min={0}
    />
  );
};

import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import { UnsavedChangesDialog } from "./UnsavedChangesDialog";

export const Dialogs = () => {
  return (
    <>
      <ConfirmDeleteDialog />
      <UnsavedChangesDialog />
    </>
  );
};

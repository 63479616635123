import { QuestionBankIdentifier } from "@/types/question-bank-types";
import { Skeleton } from "@/components/ui/skeleton";
import useQuestionBankTestSubjects from "@/hooks/subject-hooks/use-question-bank-test-subjects";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { useMemo } from "react";

type SubjectProps = { testId: number } & QuestionBankIdentifier;
const TestSubjects = (args: SubjectProps) => {
  const { data: subjectsArrayMap, isLoading } = useQuestionBankTestSubjects(
    args,
  );
  const subjects = useMemo(() => ArrayMapUtils.toArray(subjectsArrayMap), [
    subjectsArrayMap,
  ]);
  return (
    <>
      {isLoading && <Skeleton className="w-20 h-5 rounded-full" />}
      {!isLoading &&
        (
          <span>
            {subjects.length > 1
              ? "Multiple"
              : subjects[0]?.name ?? "Not Found"}
          </span>
        )}
    </>
  );
};

export default TestSubjects;

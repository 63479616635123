import { FilterInterface } from "interweave";

export const audioFilter = (audioDataUrl?: string): FilterInterface => ({
  node(name, node) {
    if (name === "source") {
      node.setAttribute("class", "w-[60%] my-5");
      audioDataUrl && node.setAttribute("src", audioDataUrl);
    }
    return !!audioDataUrl ? node : null;
  },
});

import React from "react";
import { CoursologyLoadingIndicator } from "./CoursologyLoadingIndicator";

export const LoadingPage = (props: {}) => {
  return (
    <div className="flex justify-center items-center min-h-screen min-w-[100vw] overflow-hidden bg-white warm:bg-egg-sour-100 dark:bg-neutral-950">
      <CoursologyLoadingIndicator />
    </div>
  );
};

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { useDashboardLayoutStore } from "@/utils/stores";
import useCurrentUser from "@/hooks/auth-hooks/use-current-user";

export const ResetAccountButton = () => {
  const user = useCurrentUser();
  const setDialogVisible = useDashboardLayoutStore((state) =>
    state.setIsResetAccountDialogVisible
  );
  if (!user) return <></>;
  const hasResettedBefore = user.lastResetAt !== null;

  const isVisible = !hasResettedBefore;

  return (
    <button
      className={twMerge(
        "w-full font-medium rounded-primary outline-none",
        "bg-white warm:bg-brown-50 dark:bg-neutral-700 text-primary-600 warm:text-brown-800 dark:text-white hover:bg-gray-100 dark:hover:bg-neutral-600 focus:bg-gray-100 dark:focus:bg-neutral-600 group transition-[opacity,_background-color]",
        "flex flex-row justify-start items-center gap-2 w-full py-3.5 pr-14 pl-8  relative  opacity-0 pointer-events-none",
        isVisible && "opacity-100 pointer-events-auto",
      )}
      onClick={() => setDialogVisible(true)}
    >
      <FontAwesomeIcon
        icon={faRotate}
        className="transition-transform group-hover:rotate-180 duration-300 ease-in-out"
      />
      <p>Reset Account</p>
    </button>
  );
};

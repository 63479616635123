import useDocument from "./use-document";

const useCopyToClipboard = () => {
  const doc = useDocument();
  return async (text: string) => {
    if (!doc) return;
    await navigator.clipboard.writeText(text);
  };
};

export default useCopyToClipboard;

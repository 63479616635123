import { createFileRoute } from "@tanstack/react-router";
import {
  PerformanceTestResultsPageScoreSection,
  PerformanceTestResultsPageSelect,
  PerformanceTestResultsPageSettingsSection,
  PerformanceTestResultsPageTable,
} from "./-components";
import useTestResult from "./-hooks/use-test-result";
import DashboardLoading from "../../../../-loading";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/performance/test/$testId/results",
)({
  component: PerfromanceTestResultsPage,
});

export default function PerfromanceTestResultsPage() {
  const params = Route.useParams();
  const testId = Number(params.testId);
  const { isLoading } = useTestResult({ testId });
  if (isLoading) return <DashboardLoading />;
  return (
    <div className="flex flex-col justify-start items-center gap-5 w-full">
      <div className="flex flex-col md:flex-row justify-center items-center gap-14 w-full px-10">
        <PerformanceTestResultsPageScoreSection
          testId={testId}
        />
        <PerformanceTestResultsPageSettingsSection testId={testId} />
      </div>
      <div className="flex flex-col justify-center items-center gap-2 w-full">
        <div className="self-end px-10">
          <PerformanceTestResultsPageSelect />
        </div>
        <PerformanceTestResultsPageTable testId={testId} />
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";

const useDocument = () => {
  const [doc, setDoc] = useState<Document | undefined>(
    typeof document === "undefined" ? undefined : document,
  );

  //useEffect can only run on browsers...
  useEffect(() => {
    setDoc(document);
  }, []);

  return doc;
};

export default useDocument;

import { useAppStore } from "@/store";
import { useConfirmDeleteDialog } from ".";

export const usePageActions = () => {
  const { setIsVisible, setTarget } = useConfirmDeleteDialog();
  const { addPage, renamePage, internalRemovePage, notes, pages } = useAppStore(
    (state) => ({
      addPage: state.addPage,
      renamePage: state.renamePage,
      notes: state.notes,
      pages: state.pages,
      internalRemovePage: state.removePage,
    })
  );

  const removePage = (id: string) => {
    const children = notes.filter((n) => n.pageId === id);
    if (children.length > 0) {
      setIsVisible(true);
      setTarget(pages.find((p) => p.id === id));
      return;
    }
    internalRemovePage(id);
  };

  return { add: addPage, remove: removePage, rename: renamePage };
};

import { mountActions } from "@/store/utils";
import { state } from "./state";
import { NoteSliceCreator } from "./types";
import * as actions from "./actions";

//@ts-ignore
export const createNoteSlice: NoteSliceCreator = (...args) => ({
  ...state,
  ...mountActions(actions, args),
});

import React, { PropsWithChildren } from "react";
import { NbmeTopNavbar } from "./NbmeTopNavbar";
import { NbmeBottomNavbar } from "./NbmeBottomNavbar";

interface NbmeTopNavbarProps extends PropsWithChildren {
  onSidebarToggle: () => void;
}

export const NbmeNavbar = (
  { children, onSidebarToggle }: NbmeTopNavbarProps,
) => {
  return (
    <>
      <NbmeTopNavbar onSidebarToggle={onSidebarToggle} />
      {children}
      <NbmeBottomNavbar />
    </>
  );
};

import { paginateArray } from "@/utils/common/paginateArray";
import _ from "underscore";

type Identifiable = { id: string | number };
export type ArrayMap<T extends Identifiable> = {
  [k: Identifiable["id"]]: T;
};

export default abstract class ArrayMapUtils {
  static push<T extends Identifiable>(
    arrayMap: ArrayMap<T>,
    items: T[],
  ) {
    for (const item of items) {
      const isAlreadyInArrayMap = !!arrayMap[item.id];
      if (isAlreadyInArrayMap) continue;
      arrayMap[item.id] = item;
    }
  }

  static find<T extends Identifiable>(
    arrayMap: ArrayMap<T>,
    predicate: T["id"] | ((item: T) => boolean),
  ) {
    if (typeof predicate !== "function") {
      return arrayMap[predicate];
    }
    for (const key in arrayMap) {
      const item = arrayMap[key]!;
      if (predicate(item)) return item;
    }
  }

  static toArray<T extends Identifiable>(
    arrayMap: ArrayMap<T>,
  ) {
    const newArray: T[] = [];
    for (const key in arrayMap) {
      newArray.push(arrayMap[key]!);
    }
    return newArray;
  }

  static getLength<T extends Identifiable>(
    arrayMap: ArrayMap<T>,
  ) {
    let length = 0;
    for (const _ in arrayMap) {
      length += 1;
    }
    return length;
  }

  static filter<T extends Identifiable>(
    arrayMap: ArrayMap<T>,
    predicate: (item: T) => boolean | null | undefined,
  ): ArrayMap<T> {
    const filteredArrayMap: ArrayMap<T> = {};
    for (const key in arrayMap) {
      const item = arrayMap[key]!;
      if (predicate(item)) {
        filteredArrayMap[item.id] = item;
      }
    }
    return filteredArrayMap;
  }

  static sort<T extends Identifiable>(
    arrayMap: ArrayMap<T>,
    sorter: (item: T) => T[keyof T],
  ) {
    return _.sortBy(
      ArrayMapUtils.toArray(arrayMap),
      sorter,
    );
  }

  static paginate<T extends Identifiable>(
    arrayMap: ArrayMap<T>,
    { pageNumber, pageSize }: { pageNumber: number; pageSize: number },
  ) {
    return paginateArray({
      array: ArrayMapUtils.toArray(arrayMap),
      pageSize,
      pageNumber,
    });
  }

  static includes<T extends Identifiable>(
    arrayMap: ArrayMap<T>,
    predicate: T["id"] | T | ((item: T) => boolean),
  ) {
    if (typeof predicate !== "function") {
      if (typeof predicate === "string") {
        return !!arrayMap[predicate];
      }
      if (typeof predicate === "object") {
        return !!arrayMap[predicate.id];
      }
    } else {
      for (const key in arrayMap) {
        const condition = predicate(arrayMap[key]!);
        if (condition) return true;
      }
    }

    return false;
  }

  static map<
    T extends Identifiable,
    Y extends Identifiable,
  >(
    arrayMap: ArrayMap<T>,
    map: (item: T) => Y,
  ): ArrayMap<Y> {
    const newArrayMap: ArrayMap<Y> = {};
    for (const key in arrayMap) {
      const item = arrayMap[key]!;
      newArrayMap[item.id] = map(item);
    }
    return newArrayMap;
  }

  static update<T extends Identifiable>(
    arrayMap: ArrayMap<T>,
    { updater, ids }: {
      updater: (item: T) => T;
      ids?: T["id"][];
    },
  ) {
    if (!ids) return;
    for (const id of ids) {
      const item = arrayMap[id];
      if (!item) continue;
      //we set id after calling the updater to
      //preserve the item id even if the one returned
      //from the updater is different
      arrayMap[id] = { ...updater(item), id };
    }
  }

  static delete<T extends Identifiable>(arrayMap: ArrayMap<T>, id: T["id"]) {
    const item = arrayMap[id];
    if (!item) return;
    delete arrayMap[id];
    return item;
  }

  static create<T extends Identifiable>(arrayMap: ArrayMap<T>, item: T) {
    arrayMap[item.id] = item;
  }

  static forEach<T extends Identifiable>(
    arrayMap: ArrayMap<T>,
    fn: (item: T) => any,
  ) {
    for (const key in arrayMap) {
      fn(arrayMap[key]!);
    }
  }
}

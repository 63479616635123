import { faChevronDown, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";
interface SideIconProps {
  isChevron: boolean;
  isRotatedToRight: boolean;
  onClick: () => void;
}

export const SideIcon = (
  { isChevron, isRotatedToRight, onClick }: SideIconProps,
) => {
  return (
    <button
      className="flex flex-row justify-center items-center"
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={isChevron ? faChevronDown : faCircle}
        className={twMerge(
          "text-sm transition-transform text-gray-600 dark:text-neutral-500",
          isRotatedToRight && isChevron && "-rotate-90",
          !isChevron && "w-2 h-2",
        )}
      />
    </button>
  );
};

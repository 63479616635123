import { useNoteFinder, usePage, useSetSelectedNoteId } from "@/hooks/notebook";
import { RightChevron } from "./components";
import { twMerge } from "tailwind-merge";

type ItemProps =
  & {
    title: string;
    parentId?: string | null;
    pageId?: string;
  }
  & ({
    isClickable?: true;
    onClick: () => void;
  } | {
    isClickable?: false;
    onClick?: undefined;
  });

export const Item = (
  { onClick, title, parentId, pageId, isClickable = true }: ItemProps,
) => {
  const findNote = useNoteFinder();
  const page = usePage(pageId ?? "");
  const parent = parentId ? findNote(parentId) : undefined;
  const setSelectedNote = useSetSelectedNoteId();

  return (
    <>
      {parent
        ? (
          <Item
            parentId={parent.parentId}
            title={parent.title}
            pageId={pageId}
            isClickable
            onClick={() => setSelectedNote(parent.id)}
          />
        )
        : page
        ? <Item title={page.title} isClickable={false} />
        : <></>}
      <div className="flex flex-row justify-start items-center gap-0.5">
        {(parent || page) && <RightChevron />}
        <button
          type="button"
          onClick={onClick}
          disabled={!isClickable}
          className={twMerge(
            "text-xs text-gray-500 dark:text-neutral-400 hover:enabled:no-underline",
            isClickable && "hover:enabled:underline",
          )}
        >
          {title}
        </button>
      </div>
    </>
  );
};

import { fadeAnimation } from "@/assets/framer";
import { CircularProgressBar } from "@/components/CircularProgressBar";
import { percent } from "@/utils/common/percent";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { getCenterText, getStrokeClassNames } from "./utils";
import { LoadingSpinner } from "@/components/LoadingSpinner";

interface DashboardScoreStatsCircleProps {
  total: number;
  correct: number;
  incorrect: number;
  omitted: number;
  isLoading?: boolean;
}

export const DashboardScoreStatsCircle = (
  { total, correct, incorrect, omitted, isLoading }:
    DashboardScoreStatsCircleProps,
) => {
  const [activePercentage, setActivePercentage] = useState<number>(0);
  const percentages = [
    percent(correct, total),
    percent(incorrect, total),
    percent(omitted, total),
  ];

  return (
    <CircularProgressBar
      clickable
      percentages={percentages}
      strokeClassNames={getStrokeClassNames(activePercentage)}
      strokeOnClick={setActivePercentage}
    >
      <motion.div
        key={isLoading ? "loading" : getCenterText(activePercentage)}
        {...fadeAnimation}
        transition={{ ease: "backOut", duration: 0.9 }}
        className="flex flex-col justify-center items-center select-none"
      >
        {isLoading ? <LoadingSpinner className="w-12 h-12" /> : (
          <>
            <p className="text-xl text-gray-700 dark:text-neutral-200">
              {percentages[activePercentage]!.toFixed(1)}%
            </p>
            <p className="text-gray-500 font-light dark:text-neutral-300">
              {getCenterText(activePercentage)}
            </p>
          </>
        )}
      </motion.div>
    </CircularProgressBar>
  );
};

import { BreakPoints, FontSizes } from "@/types";
import { scaleUnitInRem } from "@/utils/common";
import { FilterInterface } from "interweave";

export const tableFilter = (
  breakPoint: BreakPoints,
  fontSizeFactor: number,
): FilterInterface => ({
  node(name, node) {
    if (name === "table") {
      node.setAttribute("style", "");
      node.setAttribute("width", "");
      node.className =
        "border border-black bg-white dark:bg-nbme-primary-dark-800 dark:hover:bg-nbme-primary-dark-700 hover:bg-gray-100 mb-4";
    }
    const isScreenMedium = breakPoint > BreakPoints.Small;
    const isScreenLarge = breakPoint > BreakPoints.Medium;
    const baseFontSize = isScreenLarge
      ? FontSizes.Base
      : (isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall);

    const fontSize = scaleUnitInRem(baseFontSize, fontSizeFactor);

    node.style.fontSize =
      scaleUnitInRem(FontSizes.Base, fontSizeFactor).resultInRemAsCss;

    if (name === "td") {
      node.className =
        "border border-black dark:border-nbme-primary-dark-600 border-collapse p-2 py-3";
    }
    return node;
  },
});

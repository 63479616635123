import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { QuestionChoiceSliceCreator, questionChoiceSliceState } from ".";

const createQuestionChoiceSlice: QuestionChoiceSliceCreator = (set, get) => {
  return {
    ...questionChoiceSliceState,
    addQuestionChoice: (questionChoice) =>
      set((state) => {
        ArrayMapUtils.push(
          state.questionChoices,
          [questionChoice],
        );
      }),
    addQuestionChoices: (questionChoices) => {
      const state = get();
      for (const questionChoice of questionChoices) {
        state.addQuestionChoice(questionChoice);
      }
    },
  };
};

export default createQuestionChoiceSlice;

import { DecodedUserToken } from "@/api/types";
import { jwt } from "@/lib/client";
import { AuthSliceCreator } from ".";
import { trpcProxyClient } from "@/utils/trpc";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import handleTRPCMutationError from "@/utils/trpc/handle-trpc-mutation-error";
import handleTRPCMutationSuccess from "@/utils/trpc/handle-trpc-mutation-success";

const createAuthSlice: AuthSliceCreator = (set, get) => {
  return {
    currentUser: undefined,
    signIn: (userToken: string) =>
      set((state) => {
        const user: DecodedUserToken = jwt.decode(userToken);
        const currentUser = { ...user, token: userToken };
        state.currentUser = currentUser;
      }),
    signOut: () => {
      set((state) => {
        state.currentUser = undefined;
      });
    },
    sync: (userToken: string) =>
      set((state) => {
        const user: DecodedUserToken = jwt.decode(userToken);
        state.currentUser = { ...user, token: userToken };
      }),

    reset: (password: string) => {
      const oldState = get();
      const oldUser = oldState.currentUser;
      if (!oldUser) return;
      oldState.resetQuestionHeaders(
        ArrayMapUtils.toArray(oldState.questionHeaders).map((q) => q.id),
      );
      oldState.resetTests({
        testIds: ArrayMapUtils.toArray(oldState.tests).map((t) => t.id),
      });
      oldState.resetHighlights({
        highlightIds: ArrayMapUtils.toArray(oldState.highlights).map((h) =>
          h.id
        ),
      });
      oldState.resetHighlightMarkers({
        highlightMarkerIds: ArrayMapUtils.toArray(oldState.highlightMarkers)
          .map((
            h,
          ) => h.id),
      });
      oldState.resetQuestionNotes({
        questionNoteIds: ArrayMapUtils.toArray(oldState.questionNotes).map((
          qn,
        ) => qn.id),
      });
      oldState.resetStatRecords();
      set((state) => {
        if (!state.currentUser) return;
        state.currentUser.lastResetAt = new Date();
      });
      trpcProxyClient.auth.reset
        .mutate(password).then(
          handleTRPCMutationSuccess(
            { mutationPastPrinciple: "reset account" },
            (newUserToken) => {
              set((state) => {
                const newUser: DecodedUserToken = jwt.decode(newUserToken);
                state.currentUser = { ...newUser, token: newUserToken };
              });
            },
          ),
        )
        .catch(
          handleTRPCMutationError(
            { mutationDescription: "reset account" },
            () => {
              set(() => {
                return oldState;
              });
            },
          ),
        );
    },
    resetQbank: ({ password, qbankId }) => {
      const initialState = get();
      const oldUser = initialState.currentUser;
      if (!oldUser) return;
      initialState.resetQuestionHeaders(
        ArrayMapUtils.toArray(
          ArrayMapUtils.filter(
            initialState.questionHeaders,
            (qh) => qh.questionBankId === qbankId,
          ),
        ).map((q) => q.id),
      );
      initialState.resetTests({
        testIds: ArrayMapUtils.toArray(
          ArrayMapUtils.filter(
            initialState.tests,
            (t) => t.userId === oldUser.id && t.questionBankId === qbankId,
          ),
        ).map((t) => t.id),
      });
      initialState.resetHighlights({
        highlightIds: ArrayMapUtils.toArray(initialState.highlights).map((h) =>
          h.id
        ),
      });
      initialState.resetHighlightMarkers({
        highlightMarkerIds: ArrayMapUtils.toArray(initialState.highlightMarkers)
          .map((
            h,
          ) => h.id),
      });
      initialState.resetQuestionNotes({
        questionNoteIds: ArrayMapUtils.toArray(initialState.questionNotes).map((
          qn,
        ) => qn.id),
      });
      initialState.resetStatRecords();
      trpcProxyClient.auth.resetQbank
        .mutate({ password, qbankId })
        .then(
          handleTRPCMutationSuccess(
            { mutationPastPrinciple: "reset Qbank" },
            (newUserToken) => {
              set((state) => {
                const newUser: DecodedUserToken = jwt.decode(newUserToken);
                state.currentUser = { ...newUser, token: newUserToken };
              });
            },
          ),
        )
        .catch(
          handleTRPCMutationError(
            { mutationDescription: "Reset Qbank" },
            () => {
              set(() => {
                return initialState;
              });
            },
          ),
        );
    },
  };
};

export default createAuthSlice;

import XIcon from "@/components/icons/x-icon";
import WindowTitlebarButton from "./window-titlebar-button";
import useWindowContext from "./hooks/use-window-context";
import mergeHomogenousFunctions from "@/lib/utils/merge-homogenous-functions";
import { ComponentProps } from "react";

type WindowCloseProps = Pick<
  ComponentProps<typeof WindowTitlebarButton>,
  "onClick"
>;

const WindowClose = ({ onClick }: WindowCloseProps) => {
  const { onClose } = useWindowContext();
  const combinedOnClick = mergeHomogenousFunctions(
    onClose,
    onClick as () => void,
  );

  return (
    <WindowTitlebarButton
      className="bg-red-500"
      onClick={() => combinedOnClick()}
    >
      <XIcon className="w-3.5 h-3.5" />
    </WindowTitlebarButton>
  );
};

export default WindowClose;

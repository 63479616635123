import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { QuestionBankSliceCreator, questionBankSliceState } from ".";

const createQuestionBankSlice: QuestionBankSliceCreator = (set, get) => {
  return {
    ...questionBankSliceState,
    addQuestionBank: (questionBank) =>
      set((state) => {
        ArrayMapUtils.push(
          state.questionBanks,
          [questionBank],
        );
      }),
    addQuestionBanks: (questionBanks) =>
      set((state) => {
        ArrayMapUtils.push(
          state.questionBanks,
          questionBanks,
        );
      }),
  };
};

export default createQuestionBankSlice;

import { FontSizeControl } from "@/components/FontSizeControl";
import {
  NbmeSettingsSidebarSection,
  NbmeSettingsSidebarSegment,
  NbmeSettingsSidebarThemeControl,
  NbmeSettingsSidebarTimerVisibileControl,
} from ".";

export interface NbmeSettingsSidebarAppearanceSectionProps<
  T = "test" | "question",
> {
  variant: T;
  isNightMode: boolean;
  onIsDarkModeChange: (v: boolean) => void;
  fontSizeFactor: number;
  onFontSizeFactorChange: (v: number) => void;
  isTimerVisible?: T extends "test" ? boolean : undefined;
  onIsTimerVisibleChange?: T extends "test" ? (v: boolean) => void : undefined;
}

export const NbmeSettingsSidebarAppearanceSection = <T = "test" | "question">(
  {
    fontSizeFactor,
    onFontSizeFactorChange,
    isNightMode,
    onIsDarkModeChange,
    isTimerVisible,
    onIsTimerVisibleChange,
    variant,
  }: NbmeSettingsSidebarAppearanceSectionProps<T>,
) => {
  return (
    <NbmeSettingsSidebarSection title={"Appearance"}>
      <NbmeSettingsSidebarSegment>
        <p className="text-xs">Font Size</p>
        <FontSizeControl
          step={0.05}
          factor={fontSizeFactor}
          onChange={onFontSizeFactorChange}
        />
      </NbmeSettingsSidebarSegment>
      <NbmeSettingsSidebarSegment disabled={isNightMode}>
        <p className="text-xs">Color Theme</p>
        <NbmeSettingsSidebarThemeControl
          isNightMode={isNightMode}
          onIsDarkModeChange={onIsDarkModeChange}
        />
      </NbmeSettingsSidebarSegment>
      {variant === "test" &&
        (
          <NbmeSettingsSidebarSegment className="border-b-0 pb-0">
            <p className="text-xs">Show Timer</p>
            <NbmeSettingsSidebarTimerVisibileControl
              isTimerVisible={isTimerVisible!}
              onIsTimerVisibleChange={onIsTimerVisibleChange!}
            />
          </NbmeSettingsSidebarSegment>
        )}
    </NbmeSettingsSidebarSection>
  );
};

import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import { useTestStore } from "@/utils/stores/testStore";
import { trpc } from "@/utils/trpc";
import { Interweave } from "interweave";
import React, { useEffect, useMemo, useRef } from "react";
import { Separator } from "../Separator";
import {
  imageFilter,
  linkFilter,
  listFilter,
  paragraphFilter,
  tableFilter,
  uWorldImageFilter,
} from "@/utils/interweave/filters";
import {
  useNbmeAlignment,
  useNbmeFontSizeFactor,
} from "@/utils/stores/nbmeLayoutStore";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { BreakPoints, FontSizes, LineHeights } from "@/types";
import { analyzeSelection } from "@/utils/highlights/analyzeSelection";
import { Highlight } from "@/models";
import Mark from "mark.js";
import {
  breaklineTransformer,
  highlightTransformer,
  imageTransformer,
} from "@/utils/interweave/transformers";
import { useHighlightMarker } from "./hooks";
import { cn } from "@/lib/utils";
import combineInterweaveTransformers from "@/lib/utils/combine-interweave-transformers";
import useTestPageTestSelectedTestSlotQuestion from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot-questiont";
import useTestPageTestSelectedTestSlotQuestionExplanation from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-testslot-questionexplanation";
import useQuestionBankQuestionSubject from "@/hooks/subject-hooks/use-questionbank-question-subject";
import useTestPageTestSelectedTestSlotQuestionSubject from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-testslot-question-subject";
import useTestPageTestSelectedTestSlotQuestionSystem from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-testslot-question-system";
import useTestPageTestSelectedTestSlotQuestionTopic from "@/routes/tests/$testId/-test-page-hooks/use-test-page-selected-testslot-question-topic";
import NbmeQuestionSkeleton from "@/components/NbmeQuestion/nbme-question-skeleton";
import useCreateHighlight from "@/hooks/highlight-hooks/use-create-highlight";
import useTestPageSelectedTestSlotQuestionExplanationHints from "@/routes/tests/$testId/-test-page-hooks/use-test-page-selected-testslot-questionexplanation-hints";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { hintTransformer } from "@/utils/interweave/transformers/hintTransformer";

export const NbmeExplanation = () => {
  const { fontSizeFactor } = useNbmeFontSizeFactor();
  const breakPoint = useBreakPoint();
  const { data: question } = useTestPageTestSelectedTestSlotQuestion();
  const { data: explanation, isLoading: isExplanationLoading } =
    useTestPageTestSelectedTestSlotQuestionExplanation();
  const { data: subject } = useTestPageTestSelectedTestSlotQuestionSubject();
  const { data: system } = useTestPageTestSelectedTestSlotQuestionSystem();
  const { data: topic } = useTestPageTestSelectedTestSlotQuestionTopic();

  const questionExplanationRef = useRef<HTMLDivElement>(null);
  const highlightedHtml = useHighlightMarker(
    question?.explanationId ?? "",
    explanation?.newHtml ?? "",
  );

  const [alignment] = useNbmeAlignment();
  const memoizedParagraphFilter = useMemo(
    () => paragraphFilter(fontSizeFactor, alignment),
    [fontSizeFactor, alignment],
  );
  const memoizedTableFilter = useMemo(
    () => tableFilter(breakPoint, fontSizeFactor),
    [breakPoint, fontSizeFactor],
  );
  const memoizedCombinedTransformer = useMemo(
    () =>
      combineInterweaveTransformers(
        highlightTransformer,
        imageTransformer(),
        hintTransformer({ questionSource: question?.source ?? "" }),
      ),
    [highlightTransformer, imageTransformer, hintTransformer, question?.source],
  );
  const memoizedInterweaveFilters = useMemo(() => [
    listFilter,
    linkFilter,
    memoizedParagraphFilter,
    memoizedTableFilter,
  ], [
    listFilter,
    linkFilter,
    memoizedParagraphFilter,
    memoizedTableFilter,
  ]);
  const { data: explanationHints } =
    useTestPageSelectedTestSlotQuestionExplanationHints();

  const memoizedBlockList = useMemo(() => ["br"], []);

  const selectedMarker = useTestStore((state) => state.selectedHighlightMarker);
  const createHighlight = useCreateHighlight();
  const isScreenLarge = breakPoint > BreakPoints.Medium;
  const isScreenMedium = breakPoint > BreakPoints.Small;

  if (!question || (!explanation && isExplanationLoading)) {
    return <NbmeQuestionSkeleton />;
  }

  return (
    <div className="flex flex-col justify-start items-center w-full gap-7 lg:p-3 p-4">
      <div className="flex flex-row justify-between items-center w-full">
        <p className="font-medium border-t border-r border-l border-gray-300 p-3 rounded-t-md">
          Explanation:
        </p>
        <div className="flex-grow w-full h-full border-b border-gray-300 self-end">
        </div>
      </div>
      <div
        className={cn(
          "flex flex-col justify-start items-center lg:items-start gap-4",
          alignment === "center" && "lg:items-center",
          alignment === "right" && "lg:items-end",
        )}
        style={{
          fontSize: scaleUnitInRem(
            FontSizes.Base,
            fontSizeFactor,
          ).resultInRemAsCss,
          lineHeight: scaleUnitInRem(
            LineHeights.Base,
            fontSizeFactor,
          ).resultInRemAsCss,
        }}
      >
        {explanation?.newHtml
          ? (
            <div
              id="question-explanation"
              key={`question-${question.id}-explanation`}
              ref={questionExplanationRef}
              onMouseUp={handleHighlight}
              onTouchEnd={handleHighlight}
            >
              <Interweave
                className=""
                blockList={memoizedBlockList}
                transform={memoizedCombinedTransformer}
                filters={memoizedInterweaveFilters}
                content={highlightedHtml}
              />
            </div>
          )
          : (
            <>
              <p className="whitespace-pre-line">{explanation?.text}</p>
              {explanation?.objective &&
                (
                  <div className="flex flex-col justify-start items-start gap-1">
                    <h2 className="font-semibold">Educational objective:</h2>
                    <p>{explanation?.objective}</p>
                  </div>
                )}
              {explanation?.references &&
                (explanation?.references as Array<any>).length > 0 &&
                (
                  <div className="flex flex-col justify-start items-start gap-1">
                    <h2 className="font-semibold">References:</h2>
                    <ul className="list-disc inset-10 underline text-nbme-primary-500 ml-8">
                      {(explanation?.references as Array<any>).map((
                        exp,
                        index,
                      ) => {
                        const typedExp = exp as { link: string; text: string };
                        return (
                          <li key={index}>
                            <a href={typedExp.link} target="_blank">
                              {typedExp.text}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
            </>
          )}
        <Separator orientation="horizontal" className="bg-gray-300" />
        <div className="flex flex-wrap md:flex-nowrap flex-row justify-between md:justify-start items-center gap-8">
          <div className="flex flex-col justify-center items-start">
            <p
              className={"font-medium"}
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Base : FontSizes.Small,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              {subject?.name}
            </p>
            <p
              className="font-light text-gray-500"
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              Subject
            </p>
          </div>
          <div className="flex flex-col justify-center items-start">
            <p
              className={"font-medium"}
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Base : FontSizes.Small,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              {system?.name}
            </p>
            <p
              className="font-light text-gray-500"
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              System
            </p>
          </div>
          <div className="flex flex-col justify-center items-start">
            <p
              className={"font-medium"}
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Base : FontSizes.Small,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              {topic?.name}
            </p>
            <p
              className="font-light text-gray-500"
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              Topic
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  function handleHighlight() {
    const windowSelection = window.getSelection();
    const analysis = analyzeSelection(windowSelection);
    if (!analysis) return;
    if (!analysis.selectionText) return;
    if (!selectedMarker) return;
    if (
      analysis.selectionText === " " || analysis.selectionText.length === 0
    ) {
      return;
    }

    createHighlight(
      {
        highlight: {
          text: analysis.selectionText!,
          ocurrence: analysis.ocurrence,
          questionId: null,
          questionExplanationId: question?.explanationId ?? null,
          start: analysis.startIndex,
          end: analysis.endIndex,
          markerId: selectedMarker.id,
        },
      },
    );
    windowSelection?.removeAllRanges();
  }
};

import { createFileRoute, Navigate } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/performance/test/$testId/",
)({
  component: () => (
    <Navigate
      from="/qbanks/$qbankEndpoint/dashboard/performance/test/$testId"
      to="./results"
    />
  ),
});

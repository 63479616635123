/** Gets the number of pages given the number of items
 * and the number of items per page
 * @param {number} itemCount - the number of items
 * @param {number} itemsPerPage - the number of items per page
 * @returns {number} the number of pages */

export const getPageCount = (itemCount: number, itemsPerPage: number) => {
  if (itemCount === 0) return 1;
  return Math.ceil(itemCount / itemsPerPage);
};

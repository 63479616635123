import { CSSProperties, useEffect, useState } from "react";
import { PropsWithClassName } from "@/types";
import { Body, Layout } from "./components";
import { useDraft } from "./hooks";

export interface TreeItemProps extends PropsWithClassName {
  id: string;
  title: string;
  childItems?: TreeItemProps[];
  onClick?: () => void;
  style?: CSSProperties;
  level?: number;
  onChildAdd: (newChild: string) => void;
  onRemove: () => void;
  onEditConfirm: (newValue: string) => void;
  onEditCancel: () => void;
  isEditMode?: boolean;
  isCollapsed?: boolean;
  collapseOnSelect?: boolean;
  isSelectable?: boolean;
}

export const TreeItem = (
  {
    title,
    id,
    childItems = [],
    onClick,
    className,
    style,
    onRemove,
    onChildAdd,
    level = 0,
    onEditConfirm,
    collapseOnSelect,
    onEditCancel,
    isEditMode: externalIsEditMode,
    isCollapsed: externalIsCollapsed,
    isSelectable = true,
  }: TreeItemProps,
) => {
  const [draft, setDraft, reset] = useDraft(title);
  const [isAddingNewChild, setIsAddingNewChild] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    externalIsCollapsed ?? false,
  );
  const [isEditMode, setIsEditMode] = useState<boolean>(
    externalIsEditMode ?? false,
  );
  const isCollapsable = childItems.length > 0;

  const handleIsCollapsedChange = (newIsCollapsed: boolean) => {
    setIsCollapsed(newIsCollapsed);
  };

  const handleEditConfirm = (newValue: string) => {
    setIsEditMode(false);
    onEditConfirm(newValue);
  };

  const handleEditCancel = () => {
    setIsEditMode(false);
    onEditCancel();
  };

  const handleSelect = () => {
    if (collapseOnSelect) handleIsCollapsedChange(!isCollapsed);
  };
  useEffect(() => {
    if (externalIsCollapsed === undefined) return;
    setIsCollapsed(externalIsCollapsed);
  }, [externalIsCollapsed]);

  return (
    <Layout className={className} style={style}>
      <Body
        id={id}
        title={draft}
        onTitleChange={setDraft}
        isCollapsable={!!childItems.length}
        onIsCollapsedChange={handleIsCollapsedChange}
        isCollapsed={isCollapsed}
        showButtons={!!id}
        onTrashClicked={onRemove}
        onSelect={handleSelect}
        isSelectable={isSelectable}
        onPlusClicked={() => setIsAddingNewChild(true)}
        onEditSubmit={handleEditConfirm}
        isEditMode={isEditMode}
        onCheckClicked={() => {
          onEditConfirm(draft);
          setIsEditMode(false);
        }}
        onPenClicked={() => setIsEditMode((prev) => !prev)}
        onXClicked={() => {
          reset();
          handleEditCancel();
        }}
      />
      {isAddingNewChild && (() => {
        const paddingMultiplier = 1;
        const newLevel = level + 1;

        return (
          <TreeItem
            isEditMode={true}
            id=""
            title=""
            onEditConfirm={(newTitle) => {
              onChildAdd(newTitle);
              setIsAddingNewChild(false);
              handleIsCollapsedChange(true);
            }}
            onChildAdd={() => undefined}
            onRemove={() => undefined}
            isCollapsed={false}
            onEditCancel={() => setIsAddingNewChild(false)}
            level={newLevel}
            style={{
              paddingLeft: `${newLevel * paddingMultiplier}rem`,
            }}
          />
        );
      })()}
      {isCollapsable && isCollapsed &&
        childItems.map((child, index) => {
          const paddingMultiplier = 0.5;
          const newLevel = level + 1;
          return (
            <TreeItem
              style={{
                paddingLeft: `${newLevel * paddingMultiplier}rem`,
              }}
              key={child.id ?? index}
              {...child}
              level={newLevel}
            />
          );
        })}
    </Layout>
  );
};

import React, { useState } from "react";
import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { NbmeCalculator } from "./NbmeCalculator";
import { useNbmeLayoutStore } from "@/utils/stores/nbmeLayoutStore";

export const NbmeCalculatorShowButton = (props: {}) => {
  const { toggleCalc, calcVisible } = useNbmeLayoutStore((state) => ({
    toggleCalc: state.setCalcVisible,
    calcVisible: state.calcVisible,
  }));
  const [calculatorVisible, setCalculatorVisible] = useState<boolean>(true);

  return (
    <NbmeIconNavbarButton
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faCalculator} className="w-8 h-8" />}
      label="Calculator"
      onClick={() => toggleCalc(!calcVisible)}
    />
  );
};

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PerformanceTestPageLink } from ".";

interface PerformanceTestPageBackLinkProps {
  qBankEndpoint: string;
}

export const PerformanceTestPageBackLink = (
  { qBankEndpoint }: PerformanceTestPageBackLinkProps,
) => {
  return (
    <PerformanceTestPageLink
      type="link"
      href={`/qbanks/${qBankEndpoint}/dashboard/previous-tests`}
    >
      <div className="flex flex-row justify-center items-center gap-2">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="group-hover:-translate-x-[50%] md:text-lg text-base transition-transform duration-300 ease-smooth"
        />
        <p>Back</p>
      </div>
    </PerformanceTestPageLink>
  );
};

import { NbmeHighlight } from "@/components/NbmeHighlight";
import { Highlight } from "@/models";
import { Node } from "interweave";

export const highlightTransformer = (
  node: HTMLElement,
  children: Node[],
): React.ReactNode => {
  const isHighlight = node.getAttribute("data-markjs") === "true";
  const id = node.getAttribute("data-id");
  const questionId = node.getAttribute("data-question-id");
  const markerId = node.getAttribute("data-marker-id");
  const explanationId = node.getAttribute("data-explanation-id");

  if (!id) return;
  if (!isHighlight) return;

  return (
    <NbmeHighlight
      id={id}
      markerId={markerId!}
      color={node.style.backgroundColor}
      text={node.textContent || ""}
      questionId={Number(questionId)}
      explanationId={explanationId ?? undefined}
    />
  );
};

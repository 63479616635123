import { PropsWithChildren } from "react";
import useDraggableGesture from "./hooks/use-draggable-gesture";
import { PropsWithClassName } from "@/types";
import useDraggableContext from "./hooks/use-draggable-context";
import { cn } from "@/lib/utils";

type DraggableHandleProps = PropsWithChildren & PropsWithClassName & {
  isDraggable?: boolean;
};
const DraggableHandle = (
  { children, className, isDraggable: externalIsDraggable }:
    DraggableHandleProps,
) => {
  const bind = useDraggableGesture({ isDraggable: externalIsDraggable });
  const { isDraggable: contextIsDraggable, isDragging } = useDraggableContext();

  return (
    <div
      {...bind()}
      style={{ touchAction: "none" }}
      className={cn(
        (externalIsDraggable ?? contextIsDraggable) && "cursor-grab",
        isDragging && "cursor-grabbing",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default DraggableHandle;

import { useAppStore } from "@/store";
import { NoteAction } from "../../types";
import { findTarget } from "@/store/utils";
import { toast } from "sonner";

import { editNote as editNoteOnServer } from "./mutations";

export const editNote: NoteAction =
  ([set]) => ({ id, newContent }: { id: string; newContent: string }) => {
    const notes = useAppStore.getState().notes;
    const oldNote = findTarget(id, notes);
    if (!oldNote) {
      toast.error("Note has been deleted or does not exist.");
      return;
    }

    set((state) => {
      const targetNote = findTarget(id, state.notes)!;
      targetNote.html = newContent;
      targetNote.markdown = null;
    });

    editNoteOnServer({ id, newContent: newContent }).catch((error) => {
      toast.error(`Could not edit note: ${error.message}`);
      set((state) => {
        findTarget(id, state.notes)!.html = oldNote.html;
      });
    }).then(() => {
      toast.success("Note has been edited successfully.");
    });
  };

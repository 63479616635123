import { SearchInputWithActivity } from "@/components/SearchInputWithActivity";
import useNotesPageQuery from "../-notes-page-hooks/use-notes-page-query";

const NotesPageQueryInput = () => {
  const [query, setQuery] = useNotesPageQuery();

  return (
    <div className="flex flex-col justify-center items-start gap-1">
      <SearchInputWithActivity
        className="warm:bg-white"
        isLoading={false}
        variant="dashboard"
        value={query}
        placeholder="Search notes"
        onChange={() => undefined} //It has an internal state so onChange is fine like this.
        onChangeEnd={setQuery}
      />
      <p className="text-xs md:text-sm font-light text-gray-700 warm:text-brown-700 dark:text-neutral-300 w-[15rem] md:w-[22rem]">
        You can search by the content of the note, Question ID, Subject, System,
        or even Topic, e.g. 52-year-old woman
      </p>
    </div>
  );
};

export default NotesPageQueryInput;

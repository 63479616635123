import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import DashboardAccordionSection from "./DashboardAccordionSection";
import { InputGroup } from "./InputGroup";
import { Switch } from "./ui/switch";
import { Label } from "./Label";

const CreateTestModeSection = () => {
  const {
    toggledSections,
    toggleSection,
    tutor,
    timed,
    toggleField,
  } = useCreateTestPageStore();

  return (
    <DashboardAccordionSection
      title="Test mode"
      value="mode"
      className="border-b"
      onToggle={() => toggleSection("mode")}
      toggled={toggledSections.includes("mode")}
    >
      <div className="flex flex-row justify-start items-center gap-5 mt-3">
        <InputGroup className="flex flex-row justify-center items-center gap-2">
          <Switch
            id="tutor-switch"
            checked={tutor}
            onCheckedChange={(checked) => toggleField("tutor", checked)}
          />
          <Label
            htmlFor="tutor-switch"
            className="text-gray-500 dark:text-neutral-300"
          >
            Tutor
          </Label>
        </InputGroup>
        <InputGroup className="flex flex-row justify-center items-center gap-2">
          <Switch
            id="timed-switch"
            checked={timed}
            onCheckedChange={(checked) => toggleField("timed", checked)}
          />
          <Label
            htmlFor="timed-switch"
            className="text-gray-500 dark:text-neutral-300"
          >
            Timed
          </Label>
        </InputGroup>
      </div>
    </DashboardAccordionSection>
  );
};

export default CreateTestModeSection;

"use client";
import { cva, VariantProps } from "class-variance-authority";
import { PropsWithClassName } from "@/types";
import useBorderGesture from "./hooks/use-border-gesture";
import useResizableContext from "./hooks/use-resizable-context";
import { ResizableContext } from "./resizable-context";
import mergeObjectFunctions from "@/lib/utils/merge-object-functions";

const resizableBorderVariants = cva(
  "absolute bg-transparent",
  {
    variants: {
      direction: {
        horizontal: "w-full h-3",
        vertical: "h-full w-3",
      },
      placement: {
        top: "-top-1 group-data-[is-resizable=true]/resizable:cursor-n-resize",
        bottom:
          "-bottom-1 group-data-[is-resizable=true]/resizable:cursor-s-resize",
        left:
          "top-0 -left-1 group-data-[is-resizable=true]/resizable:cursor-w-resize",
        right:
          "top-0 -right-1 group-data-[is-resizable=true]/resizable:cursor-e-resize",
      },
    },
  },
);

type ResizableBorderProps =
  & { invertResizeDirection?: boolean }
  & VariantProps<
    typeof resizableBorderVariants
  >
  & PropsWithClassName
  & Pick<ResizableContext, "events">;

const ResizableBorder = (
  { direction, placement, className, invertResizeDirection, events }:
    ResizableBorderProps,
) => {
  const context = useResizableContext();
  const bind = useBorderGesture({
    dragAxis: direction === "horizontal" ? "y" : "x",
    placement: placement ?? "top",
    invertResizeDirection,
    events: mergeObjectFunctions(events, context.events),
  });

  return (
    <div
      {...bind()}
      className={resizableBorderVariants({
        direction,
        placement,
        className,
      })}
      style={{ touchAction: "none" }}
    >
    </div>
  );
};

export default ResizableBorder;

import {
  NbmeSettingsSidebarNightModeControl,
  NbmeSettingsSidebarNightModeTimeControl,
  NbmeSettingsSidebarSection,
  NbmeSettingsSidebarSegment,
} from ".";
import { NbmeSettingsSidebarNightModeControlProps } from "./NbmeSettingsSidebarNightModeControl";

export interface NbmeSettingsSidebarNightModeSectionProps
  extends NbmeSettingsSidebarNightModeControlProps {
  nightModeStartTime: { hours: number; minutes: number; isAm: boolean };
  onNightModeStartTimeChange: (time: this["nightModeStartTime"]) => void;
  nightModeEndTime: { hours: number; minutes: number; isAm: boolean };
  onNightModeEndTimeChange: (time: this["nightModeEndTime"]) => void;
}

export const NbmeSettingsSidebarNightModeSection = (
  {
    isNightMode,
    onIsNightModeChange,
    nightModeStartTime,
    nightModeEndTime,
    onNightModeEndTimeChange,
    onNightModeStartTimeChange,
  }: NbmeSettingsSidebarNightModeSectionProps,
) => {
  return (
    <NbmeSettingsSidebarSection
      title={"Night Mode"}
      note={"If on, auto changes to dark mode automatically based on scheduled time"}
    >
      <NbmeSettingsSidebarSegment>
        <p className="text-xs">Automatic</p>
        <NbmeSettingsSidebarNightModeControl
          isNightMode={isNightMode}
          onIsNightModeChange={onIsNightModeChange}
        />
      </NbmeSettingsSidebarSegment>
      <NbmeSettingsSidebarSegment disabled={!isNightMode}>
        <p className="text-xs">Start</p>
        <NbmeSettingsSidebarNightModeTimeControl
          time={nightModeStartTime}
          onTimeChange={onNightModeStartTimeChange}
          isNightMode={isNightMode}
        />
      </NbmeSettingsSidebarSegment>
      <NbmeSettingsSidebarSegment
        disabled={!isNightMode}
        className="border-b-0 pb-0"
      >
        <p className="text-xs">End</p>
        <NbmeSettingsSidebarNightModeTimeControl
          time={nightModeEndTime}
          onTimeChange={onNightModeEndTimeChange}
          isNightMode={isNightMode}
        />
      </NbmeSettingsSidebarSegment>
    </NbmeSettingsSidebarSection>
  );
};

"use client";

import { useMemo } from "react";
import { NbmeChoice } from "./NbmeChoice";
import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import { numberToAlphabet } from "@/utils/common/numberToAlphabet";
import RadioGroup from "./RadioGroup";
import { RadioGroupItemWithLabel } from "./RadioGroupItemWithLabel";
import { Interweave } from "interweave";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { choiceFilter } from "@/utils/interweave/filters";
import {
  useNbmeFontSizeFactor,
  useNbmePercentageAnsweredVisible,
} from "@/utils/stores/nbmeLayoutStore";
import { FontSizes } from "@/types";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { motion } from "framer-motion";
import { fadeOnConditionAnimation } from "@/assets/framer";
import isTextSelected from "@/utils/client/is-text-selected";
import useTestPageTestSelectedTestSlot from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot";
import useTestPageTestSelectedTestSlotQuestion from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot-questiont";
import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";
import useTestPageChooseTestSlotQuestionChoice from "@/routes/tests/$testId/-test-page-hooks/use-test-page-choose-testslot-questionchoice";
import useTestPageCrossTestSlotQuestionChoice from "@/routes/tests/$testId/-test-page-hooks/use-test-page-cross-testslot-questionchoice";
import useTestPageSelectedTestSlotQuestionChoices from "@/routes/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot-questionchoices";
import _ from "underscore";
import { cn } from "@/lib/utils";

export const NbmeChoices = () => {
  const breakPoint = useBreakPoint();

  const { percentageAnsweredVisible } = useNbmePercentageAnsweredVisible();

  const { fontSizeFactor } = useNbmeFontSizeFactor();

  const { data: test } = useTestPageTest();
  const mode = test?.mode;
  const { data: slot } = useTestPageTestSelectedTestSlot();
  const { data: question } = useTestPageTestSelectedTestSlotQuestion();
  const { data: questionChoices, isLoading } =
    useTestPageSelectedTestSlotQuestionChoices();
  const chooseChoice = useTestPageChooseTestSlotQuestionChoice();
  const crossChoice = useTestPageCrossTestSlotQuestionChoice();
  const memoizedChoiceFilter = useMemo(
    () => [choiceFilter(breakPoint, fontSizeFactor, false)],
    [choiceFilter, breakPoint, fontSizeFactor],
  );
  if ((isLoading && questionChoices?.length === 0) || !question) return <></>;

  return (
    <RadioGroup
      onValueChange={(choiceId) => chooseChoice({ questionChoiceId: choiceId })}
      orientation="vertical"
      value={slot?.chosenId || ""}
      ariaLabel="Question choices"
      className="py-2 flex flex-col justify-start items-end "
      disabled={slot?.submitted}
      style={{
        fontSize:
          scaleUnitInRem(FontSizes.Small, fontSizeFactor).resultInRemAsCss,
      }}
    >
      {question.headerHtml && (
        <div className="flex flex-row justify-end items-end w-full">
          <Interweave
            className="self-end ml-auto w-full flex flex-row justify-end items-center"
            content={question.headerHtml}
            filters={memoizedChoiceFilter}
          />
        </div>
      )}
      {questionChoices &&
        _.sortBy(questionChoices, (qc) => qc.order).map((c, index) => {
          const crossed = !!(slot?.crossed.find((cr) => cr === c.id));
          const isCorrect = c.correct;
          const showResult = (slot?.submitted &&
            mode?.includes("tutor")) || test?.submitted;
          const showMark = showResult &&
            (slot?.chosenId === c.id ||
              isCorrect);
          //const chosenBy = c._count.chosenBy;
          return (
            <div
              key={`${c.id}`}
              className="relative flex flex-row w-full justify-start items-center gap-3 hover:bg-gray-200 warm:hover:bg-brown-950/10 dark:hover:text-black pl-10 pr-2 py-0.5 transition-colors"
            >
              {showMark &&
                (
                  <>
                    <FontAwesomeIcon
                      icon={isCorrect ? faCheck : faXmark}
                      className={twMerge(
                        "absolute ml-3 left-0 w-5 h-5 opacity-0",
                        showResult && "opacity-100",
                        isCorrect && "text-lime-500",
                        !isCorrect && "text-red-500",
                      )}
                    />
                  </>
                )}
              <RadioGroupItemWithLabel
                label={`${numberToAlphabet(index + 1).toUpperCase()}.`}
                labelClassName={cn(crossed && "line-through")}
                value={c.id}
                id={`r${index}`}
              />

              <div
                className={twMerge(
                  "relative flex flex-row justify-start items-center cursor-default w-full gap-3",
                )}
                onClick={() => {
                  if (isTextSelected()) return;
                  crossChoice({
                    questionChoiceId: c.id,
                  });
                }}
              >
                <div
                  className={twMerge(
                    crossed && "line-through",
                  )}
                >
                  {c.html
                    ? (
                      <Interweave
                        content={c.html}
                        filters={[
                          choiceFilter(breakPoint, fontSizeFactor, crossed),
                          {
                            node(name, node) {
                              if (
                                name === "img" && question?.source === "amboss"
                              ) {
                                return null;
                              } else return node;
                            },
                          },
                        ]}
                      />
                    )
                    : (
                      <NbmeChoice
                        text={c.text}
                        crossed={!!(slot?.crossed.find((id) => id === c.id))}
                      />
                    )}
                </div>
                {showResult && (
                  <motion.p
                    {...fadeOnConditionAnimation(percentageAnsweredVisible)}
                    className=""
                  >
                    ({c.uWorldChosenBy}%)
                  </motion.p>
                )}
              </div>
            </div>
          );
        })}
    </RadioGroup>
  );
};

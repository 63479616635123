import { Test } from "@/models/test-model";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useTestQuestionChoices from "@/hooks/question-choice-hooks/use-test-question-choices";

const useTestAverageScore = ({ testId }: { testId: Test["id"] }) => {
  const testQuestionChoicesQuery = useTestQuestionChoices({ testId });
  const { data: questionChoices } = testQuestionChoicesQuery;
  let count = 0;
  let average = 0;
  ArrayMapUtils.forEach(questionChoices, (qc) => {
    if (qc.correct) {
      count += 1;
      if (!qc.uWorldChosenBy) return;
      average += qc.uWorldChosenBy;
    }
  });

  return { ...testQuestionChoicesQuery, data: average / count };
};

export default useTestAverageScore;

import { Input, Layout, TagIcon } from "./components";
import { useNoteTags } from "./hooks";
import { sortTagsAlphabetically } from "./utils";

interface TagInputProps {
  selectedNote?: { id: string };
}

export const TagInput = ({ selectedNote }: TagInputProps) => {
  const { tags, addTag, removeTag } = useNoteTags(selectedNote?.id ?? "");
  const disabled = !selectedNote;

  return (
    <Layout>
      <TagIcon disabled={disabled} />
      <Input
        selectedNote={selectedNote}
        disabled={disabled}
        tags={sortTagsAlphabetically(tags)}
        onTagAdd={addTag}
        onTagRemove={removeTag}
      />
    </Layout>
  );
};

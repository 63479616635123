import useTestPageTestSelectedTestSlotQuestionExplanation from "./use-test-page-test-selected-testslot-questionexplanation";

const useTestPageSelectedTestSlotQuestionExplanationHints = () => {
  const questionExplanationQuery =
    useTestPageTestSelectedTestSlotQuestionExplanation();
  return {
    ...questionExplanationQuery,
    data: questionExplanationQuery.data?.hints,
  };
};

export default useTestPageSelectedTestSlotQuestionExplanationHints;

import { PropsWithClassName } from "@/types";
import { NbmeNavbarButton, NbmeNavbarButtonProps } from "./NbmeNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler } from "react";
import { faCheck, faFlag } from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";

interface NbmeMarkQuestionButtonProps
  extends PropsWithClassName, NbmeNavbarButtonProps {
  isMarked: boolean;
  onMarkedChange: (newMarked: this["isMarked"]) => void;
}

export const NbmeMarkQuestionButton = (
  { className, onClick, isMarked, onMarkedChange }: NbmeMarkQuestionButtonProps,
) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    onClick?.(e);
    onMarkedChange(!isMarked);
  };

  return (
    <NbmeNavbarButton
      onClick={handleClick}
      className={className}
    >
      <div className="flex flex-col md:flex-row text-white justify-center items-center">
        <div className="relative ml-2">
          <FontAwesomeIcon icon={faFlag} className="w-8 h-8" />
          {isMarked &&
            (
              <FontAwesomeIcon
                icon={faCheck}
                className={twMerge(
                  "w-2.5 h-2.5 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-90%] text-white bg-nbme-primary-700 dark:bg-nbme-primary-dark-900 p-0.5 rounded-full",
                )}
              />
            )}
        </div>
        <p className="text-xs md:text-sm font-semibold ml-2">
          Mark
        </p>
      </div>
    </NbmeNavbarButton>
  );
};

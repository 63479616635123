import useTestPageTestSelectedTestSlot from "./use-test-page-test-selected-test-slot";

const useTestPageTestSelectedTestSlotQuestionExplanation = () => {
  const selectedSlotQuery = useTestPageTestSelectedTestSlot();
  const { data: selectedSlot } = selectedSlotQuery;

  return {
    ...selectedSlotQuery,
    data: selectedSlot?.question.explanation,
  };
};

export default useTestPageTestSelectedTestSlotQuestionExplanation;

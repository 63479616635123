import React from "react";
import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { faBoltLightning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "sonner";

export const NbmeFlashcardsButton = (props: {}) => {
  return (
    <NbmeIconNavbarButton
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faBoltLightning} className="w-6 h-6" />}
      label="Flashcards"
      onClick={() => toast("Flashcards are coming soon...")}
    />
  );
};

import { CoursologyTokenHeader } from "@/api/types";
import { AppRouter } from "@/types";
import { QueryClient } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import getCurrentUser from "../auth-utils/get-current-user";

export const trpc = createTRPCReact<AppRouter>();
export const queryClient = new QueryClient();
export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: import.meta.env.VITE_API_ENDPOINT,
      headers() {
        const coursologyTokenHeader: CoursologyTokenHeader =
          "x-coursology-token";
        return {
          [coursologyTokenHeader]: getCurrentUser()
            ?.token,
        };
      },
    }),
  ],
});

import { cn } from "@/lib/utils";
import { TelegramLink } from "./telegram-link";

export const WantMore = ({ onFreeTrial }: { onFreeTrial: boolean }) => {
  return (
    <div
      className={cn(
        "flex flex-col justify-start items-center w-full h-full",
        !onFreeTrial && "justify-center",
      )}
    >
      {onFreeTrial &&
        (
          <>
            <h1 className="w-full text-center text-gray-800 dark:text-neutral-50 text-3xl mb-2 font-semibold">
              Want more QBanks?
            </h1>
            <p className="w-full text-center text-gray-700 dark:text-neutral-100 mb-3">
              Get USMLE World self assessments and NBMEs when you subscribe for
              either STEP 1 or STEP 2, subscribe now to get access to a growing
              library of more than eight{" "}
              <span className="italic">thousand</span> questions!
            </p>
            <p className="w-full text-center text-gray-800 dark:text-neutral-50 font-semibold mb-2">
              Contact us on telegram now to subscribe
            </p>
            <TelegramLink
              href="https://t.me/coursologyy"
              className="mb-6 text-2xl"
              withTelegramIcon={false}
            >
              Subscribe now!
            </TelegramLink>
          </>
        )}

      <p className="w-full text-center text-gray-800 dark:text-neutral-100 font-semibold mb-2">
        You can <span>{onFreeTrial ? "also" : ""}</span>{" "}
        join our thriving community group on telegram for all the latest updates
        and discussions!
      </p>
      <div className="flex lg:flex-row flex-col justify-start items-center gap-3 ">
        <TelegramLink
          href="https://t.me/+KKylrQDAD8E2NzJk"
          className="mb-3"
        >
          Join our group
        </TelegramLink>
        <TelegramLink
          href="https://t.me/coursologyqbank"
          className="mb-3"
        >
          Join our channel
        </TelegramLink>
      </div>
    </div>
  );
};

import { MotionProps } from "framer-motion";
import { smoothTransition } from "..";

export const fadeAnimation: MotionProps = {
  initial: {
    opacity: 0,
    pointerEvents: "none",
  },
  animate: {
    opacity: 100,
    pointerEvents: "inherit",
  },
  exit: {
    opacity: 0,
    pointerEvents: "none",
  },
  transition: smoothTransition,
};

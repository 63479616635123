import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from ".";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { ComponentProps } from "react";
import { cn } from "@/lib/utils";

export const PenButton = (
  { onClick, className }: ComponentProps<typeof IconButton>,
) => {
  return (
    <IconButton
      className={cn(
        "text-gray-400 dark:text-neutral-400 hover:enabled:text-gray-500 dark:hover:enabled:text-neutral-300 text-base",
        className,
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faPenToSquare} />
    </IconButton>
  );
};

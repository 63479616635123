import { TransformCallback } from "interweave";

type InterweaveTransformer = TransformCallback;
const combineInterweaveTransformers = (
  ...transformers: InterweaveTransformer[]
) => {
  const combinedTransformer: InterweaveTransformer = (
    node,
    children,
    config,
  ) => {
    for (const transformer of transformers) {
      const result = transformer(node, children, config);
      if (result === undefined) continue;
      return result;
    }
  };
  return combinedTransformer;
};

export default combineInterweaveTransformers;

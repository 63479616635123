import { Body, Dialogs, Layout, Sidebar } from "./components";

export const Notebook = () => {
  return (
    <Layout>
      <Sidebar />
      <Body />
      <Dialogs />
    </Layout>
  );
};

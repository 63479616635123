import DashboardAccordionSection from "@/components/DashboardAccordionSection";
import { useCreateTestPageStore } from "@/utils/stores";
import OrderedTestButton from "./ordered-test-button";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import useQuestionBankTestBlueprints from "@/hooks/testblueprint-hooks/use-questionbank-testblueprints";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { useMemo } from "react";

const CreateTestOrderedSection = () => {
  const { data: questionBank } = useDashboardQuestionBank();
  const { data: orderedTestBlueprintsArrayMap } = useQuestionBankTestBlueprints(
    {
      questionBankId: questionBank?.id ?? "",
    },
  );
  const orderedTestBlueprints = useMemo(() =>
    ArrayMapUtils.toArray(
      orderedTestBlueprintsArrayMap,
    ), [orderedTestBlueprintsArrayMap]);

  const {
    toggleSection,
    toggledSections,
    selectedBlueprint,
    setSelectedBlueprint,
  } = useCreateTestPageStore((
    state,
  ) => ({
    toggledSections: state.toggledSections,
    toggleSection: state.toggleSection,
    selectedBlueprint: state.selectedBlueprint,
    setSelectedBlueprint: state.setSelectedBlueprint,
  }));
  if (!questionBank?.supportsOrderedTests) {
    return (
      <div className="flex flex-col justify-center items-center flex-1 py-24">
        <p className="lg:text-2xl text-xl text-gray-500 text-center dark:text-neutral-600 font-light">
          This Question Bank does not support ordered mode.
        </p>
      </div>
    );
  }
  return (
    <div className="flex flex-col justify-start items-center w-full">
      <DashboardAccordionSection
        className="border-b dark:border-b-neutral-600 border-b-gray-300"
        title={"What is ordered mode?"}
        toggled={toggledSections.includes("qmode")}
        onToggle={() => toggleSection("qmode")}
        value="qmode"
      >
        <p className="dark:text-neutral-200 lg:max-w-[70%] text-center lg:text-start text-gray-700">
          Ordered mode is available in certain Question Banks where the order of
          the Questions matter (e.g. in NBMEs and UWSAs) it allows you to create
          a limited number of tests where questions will always remain in a
          specific order, you can create the same test as many times as you
          like.
        </p>
      </DashboardAccordionSection>
      <DashboardAccordionSection
        title={
          <div className="flex flex-col lg:flex-row justify-center lg:justify-start items-center gap-1 mb-3">
            <p>Tests</p>

            <Button
              variant={"ghost"}
              onClick={() => setSelectedBlueprint(undefined)}
              className={cn(
                "transition-[background-color,_color,_opacity] opacity-0 pointer-events-none text-gray-600 dark:text-neutral-400",
                !!selectedBlueprint && "opacity-100 pointer-events-auto",
              )}
            >
              Clear Selection
            </Button>
          </div>
        }
        toggled={toggledSections.includes("ordered-tests")}
        onToggle={() => toggleSection("ordered-tests")}
        value="ordered-tests"
      >
        <div className="flex flex-col lg:flex-row justify-center lg:justify-start items-center w-full gap-2.5 flex-wrap">
          {orderedTestBlueprints.map((otb) => {
            const questionCount = otb.end === null || otb.start === null
              ? questionBank?.maximumQuestionCountPerTest
              : otb.end - otb.start;
            const used = otb.used;
            const active = selectedBlueprint
              ? selectedBlueprint.id === otb.id
              : false;
            return (
              <OrderedTestButton
                key={otb.id}
                onClick={() => setSelectedBlueprint(otb)}
                name={otb.name}
                active={active}
                questionCount={questionCount}
                used={used}
              />
            );
          })}
        </div>
      </DashboardAccordionSection>
    </div>
  );
};

export default CreateTestOrderedSection;

import createTopicSlice from "./create-topic-slice";
import { Topic } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";

export type TopicSliceActions = {
  addTopic: (topic: Topic) => void;
  addTopics: (topics: Topic[]) => void;
};
export type TopicSliceState = typeof topicSliceState;

export type TopicSlice = TopicSliceState & TopicSliceActions;

export type TopicSliceCreator = StateCreator<
  TopicSlice,
  [["zustand/immer", never]],
  [],
  TopicSlice
>;

export type TopicSliceModifiers = SliceModifiers<TopicSliceCreator>;

export type TopicSliceAction = (...modifiers: TopicSliceModifiers) => void;

export const topicSliceState = {
  topics: {} as ArrayMap<Topic>,
};

export default createTopicSlice;

import { CoursologyLoadingIndicator } from "@/components/CoursologyLoadingIndicator";

const DashboardLoading = () => {
  return (
    <div className="h-full w-full flex min-h-[85vh] justify-center items-center">
      <div className="">
        <CoursologyLoadingIndicator />
      </div>
    </div>
  );
};
export default DashboardLoading;

import { InsertRowBeforeIcon } from "@/components/icons/hollow";
import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap";

export const InsertRowBefore = () => {
  const { editor } = useRichTextEditorContext();
  return (
    <RichTextEditor.Control
      title="Insert Row Before"
      aria-label="Insert Row Before"
      disabled={!editor?.can().addRowBefore()}
      onClick={() => editor?.commands.addRowBefore()}
    >
      <InsertRowBeforeIcon className="w-4 h-4 text-neutral-800 dark:text-neutral-400" />
    </RichTextEditor.Control>
  );
};

import { PropsWithAs, PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { ComponentProps, ComponentType, PropsWithChildren } from "react";
import useTriggerableContext from "./hooks/use-triggerable-context";

type TriggerableToggleProps =
  & PropsWithAs
  & PropsWithChildren
  & PropsWithClassName
  & ComponentProps<"button">;

const TriggerableToggle = (
  { children, className, as, ...props }: TriggerableToggleProps,
) => {
  const { setIsOpen } = useTriggerableContext();
  const Parent = (as ?? "button") as "button";
  return (
    <Parent
      {...props}
      className={cn("", className)}
      onClick={(e) => {
        setIsOpen?.((prev) => !prev);
        props.onClick?.(e);
      }}
    >
      {children}
    </Parent>
  );
};

export default TriggerableToggle;

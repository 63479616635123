import { PropsWithAs, PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";
import useTriggerableContext from "./hooks/use-triggerable-context";

type TriggerableCloseProps =
  & PropsWithChildren
  & PropsWithClassName
  & PropsWithAs;

const TriggerableClose = (
  { children, className, as }: TriggerableCloseProps,
) => {
  const { setIsOpen } = useTriggerableContext();
  const Parent = as ?? "button";

  return (
    <Parent
      className={cn("", className)}
      onClick={() => setIsOpen?.(false)}
    >
      {children}
    </Parent>
  );
};

export default TriggerableClose;

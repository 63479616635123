import { fixDate } from ".";

export const fixDates = <
  T extends {
    lastRenewal: string | null;
    createdAt: string | null;
    lastResetAt: string | null;
  },
>(
  array: T[],
) => {
  return array.map((item) => ({
    ...item,
    lastRenewal: fixDate(item.lastRenewal),
    createdAt: fixDate(item.createdAt),
    lastResetAt: fixDate(item.lastResetAt),
  }));
};

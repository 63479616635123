import { useNavigate } from "@tanstack/react-router";
import useAuthSlice from "./use-auth-slice";
import { useEffect } from "react";

const useCurrentUser = () => {
  const { currentUser } = useAuthSlice();
  const navigate = useNavigate();
  useEffect(() => {
    if (!currentUser) {
      navigate({ to: "/auth/signin" });
    }
  }, [currentUser]);
  return currentUser!;
};
export default useCurrentUser;

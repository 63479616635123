import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useGetQuestionBankSubjectPerofrmanceReports from "./use-get-question-bank-subject-performance-reports";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";
import { QuestionHeader } from "@/models/question-model";
import { Subject } from "@/models/Subject";

type Opts = {
  filters?: {
    questionHeaders?: ArrayMap<QuestionHeader>;
    subjects?: ArrayMap<Subject>;
  };
};

const useQuestionBankSubjectPerformanceReports = (
  args: QuestionBankIdentifier,
  opts?: Opts,
) => {
  const getQuestionBankSubjectPerformanceReportsQuery =
    useGetQuestionBankSubjectPerofrmanceReports(args, opts);
  const { data: getQuestionBankSubjectPerformanceReports } =
    getQuestionBankSubjectPerformanceReportsQuery;

  const performanceReports = getQuestionBankSubjectPerformanceReports();

  return {
    ...getQuestionBankSubjectPerformanceReportsQuery,
    data: performanceReports,
  };
};

export default useQuestionBankSubjectPerformanceReports;

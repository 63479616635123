import { Button } from "@/components/Button";
import { useRenew } from "./hooks";

interface RenewButtonProps {
  userId: string;
}

export const RenewButton = ({ userId }: RenewButtonProps) => {
  const renewSubscription = useRenew({ userId });

  return (
    <Button
      onClick={renewSubscription}
      className="w-min text-sm md:text-sm lg:text-sm"
    >
      <p>Renew</p>
    </Button>
  );
};

import { useNotebookUi } from ".";
import { useEffect } from "react";
import { useOnDesktop } from "@/hooks";

export const useNotebookUiVariant = () => {
  const onDesktop = useOnDesktop();
  const { variant, setVariant } = useNotebookUi((state) => ({
    variant: state.variant,
    setVariant: state.setVariant,
  }));

  useEffect(() => {
    if (!onDesktop) return setVariant("mobile");
    return setVariant("desktop");
  }, [onDesktop]);
  return [variant, setVariant];
};

import ContextMenu from "@/components/context-menu";
import HollowSolidTransition from "@/components/hollow-solid-transition";
import { CopyToClipboardIcon as SolidCopyToClipboardIcon } from "@/components/icons";
import { CopyToClipboardIcon as HollowCopyToClipboardIcon } from "@/components/icons/hollow";

import { WithTooltip } from "@/components/pages/dashboard";
import NbmeContextMenuItem from "./nbme-context-menu-item";
import useCopyToClipboard from "@/hooks/use-copy-to-clipboard";
import { ComponentProps } from "react";

const NbmeContextMenuCopyToClipboard = (
  { onClick, textToCopy, ...props }:
    & ComponentProps<typeof NbmeContextMenuItem>
    & { textToCopy?: string },
) => {
  const copyToClipboard = useCopyToClipboard();
  return (
    <WithTooltip tooltip="Copy To Clipboard">
      <NbmeContextMenuItem
        onClick={(e) => {
          textToCopy && copyToClipboard(textToCopy);
          onClick?.(e);
        }}
        className="hover:scale-110 transition-transform active:scale-95"
        {...props}
      >
        <HollowSolidTransition.Root className="p-1 dark:bg-neutral-600 bg-nbme-primary-600 rounded-primary ">
          <HollowSolidTransition.Solid>
            <SolidCopyToClipboardIcon className="w-5 h-5" />
          </HollowSolidTransition.Solid>

          <HollowSolidTransition.Hollow>
            <HollowCopyToClipboardIcon className="w-5 h-5" />
          </HollowSolidTransition.Hollow>
        </HollowSolidTransition.Root>
      </NbmeContextMenuItem>
    </WithTooltip>
  );
};

export default NbmeContextMenuCopyToClipboard;

import { GetTestHighlightsInput } from "@/api/routers/highlight-router";
import useAddHighlights from "./use-add-highlights";
import useHighlights from "./use-highlights";
import { trpc } from "@/utils/trpc";
import { useEffect, useMemo } from "react";
import useTestQuestionExplanations from "@/hooks/questionexplanation-hooks/use-test-questionexplanations";
import mergeFlags from "@/utils/common/merge-flags";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const useTestHighlights = ({ testId, slots }: GetTestHighlightsInput) => {
  const questionExplanationsQuery = useTestQuestionExplanations({
    testId,
    slots,
  });
  const { data: questionExplanations } = questionExplanationsQuery;

  const highlights = useHighlights();
  const addHighlights = useAddHighlights();
  const query = trpc.highlight.getTestHighlights.useQuery({ testId, slots });
  const { data, isLoading } = query;

  useEffect(() => {
    if (!!data && !isLoading) {
      addHighlights(data);
    }
  }, [data, isLoading]);

  const testHighlights = useMemo(
    () =>
      ArrayMapUtils.filter(highlights, (highlight) => {
        return ArrayMapUtils.includes(
          questionExplanations,
          (qe) =>
            qe.questionId === highlight.questionId ||
            qe.id === highlight.questionExplanationId,
        );
      }),
    [highlights, questionExplanations],
  );

  return useMemo(() => ({
    ...mergeFlags({
      source: query,
      destinations: [questionExplanationsQuery],
    }),
    data: testHighlights,
  }), [
    query,
    questionExplanationsQuery,
    testHighlights,
  ]);
};

export default useTestHighlights;

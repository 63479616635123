import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../..";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";

export const NotesButton = () => {
  return (
    <Button href="notes">
      <FontAwesomeIcon icon={faFileLines} />
      <p>Notes</p>
    </Button>
  );
};

export const scaleUnitInRem = (
  unitInRem: number,
  scalar: number,
) => {
  const scaledSizeInRem = unitInRem * scalar;

  return {
    resultInRemAsNumber: scaledSizeInRem,
    resultInRemAsCss: `${scaledSizeInRem}rem`,
  };
};

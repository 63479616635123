import React from "react";
import PreviousTestMobile from "./previous-test-mobile";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { twMerge } from "tailwind-merge";
import _ from "underscore";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { TestWithArticles } from "@/models/test-model/test-model-types";
import { paginateArray } from "@/utils/common/paginateArray";

interface PreviousTestsMobileProps {
  onTestRenameClick: (testId: number) => void;
  qBankEndpoint: string;
  tests: Array<TestWithArticles>;
  isLoading: boolean;
  currentPage: number;
  testsPerPage: number;
}

const PreviousTestsMobileTable = (
  {
    onTestRenameClick,
    qBankEndpoint,
    tests,
    isLoading,
    currentPage,
    testsPerPage,
  }: PreviousTestsMobileProps,
) => {
  const paginatedTests = paginateArray({
    array: tests,
    pageNumber: currentPage,
    pageSize: testsPerPage,
  });

  return (
    <div
      className={twMerge(
        "w-full flex flex-col justify-start items-center px-5 gap-5",
      )}
    >
      {isLoading && (
        <div className="w-20 h-20 absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%]">
          <LoadingSpinner />
        </div>
      )}
      {paginatedTests.map((test) => (
        <PreviousTestMobile
          test={test}
          testName={test.name}
          qBankEndpoint={qBankEndpoint}
          onTestRenameClick={onTestRenameClick}
          key={test.id}
          testId={test.id}
          questionIds={[123, 123]}
        />
      ))}
    </div>
  );
};

export default PreviousTestsMobileTable;

import { faHighlighter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HighlightMarker } from "@/models";
import { useNbmeLayoutStore, useTestStore } from "@/utils/stores";
import { NbmeHighlightMarkerMenuButton } from "./NbmeHighlightMarkersMenuButton";
import { PropsWithClassName } from "@coursology/types";
import { cn } from "@/lib/utils";
import { Button } from "./ui/button";
import { EditIcon } from "./icons";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { HighlightMarkerForm } from "@/ncomponents/highlights/highlight-marker-form";
import { useEffect, useState } from "react";
import useOnScroll from "@/hooks/common/use-on-scroll";

interface NbmeHighlightMarker extends HighlightMarker, PropsWithClassName {
  onClick?: () => void;
}

export const NbmeHighlightMarker = (
  { onClick, className, ...highlightMarker }: NbmeHighlightMarker,
) => {
  const setSelectedMarker = useTestStore((state) =>
    state.setSelectedHighlightMarker
  );
  const { isUserWarned, showWarningDialog, setShortcutsEnabled } =
    useNbmeLayoutStore((state) => ({
      isUserWarned: state.isUserWarnedAboutHighlights,
      showWarningDialog: () => state.setIsHighlightWarningDialogVisible(true),
      setShortcutsEnabled: state.setShortcutsEnabled,
    }));

  const [isHighlightMarkerFormOpen, setIsHighlightMarkerFormOpen] = useState<
    boolean
  >(false);

  const { name, color, id, userId } = highlightMarker;

  const handleClick = () => {
    onClick?.();
    if (!isUserWarned) {
      showWarningDialog();
      return;
    }
    if (!id) {
      setSelectedMarker(undefined);
      return;
    }
    setSelectedMarker(highlightMarker);
  };
  useEffect(() => {
    setShortcutsEnabled(!isHighlightMarkerFormOpen);
  }, [isHighlightMarkerFormOpen]);

  useOnScroll(() => {
    setIsHighlightMarkerFormOpen(false);
  });

  return (
    <div
      className={cn(
        "flex flex-row justify-start items-center bg-nbme-primary-700 w-full pe-6 dark:bg-neutral-800 warm:bg-brown-700",
        className,
      )}
    >
      <NbmeHighlightMarkerMenuButton
        onClick={handleClick}
        className="bg-transparent w-full"
      >
        <div className="flex flex-row justify-start items-center gap-2">
          <div
            className={cn(
              "inline-flex justify-center items-center bg-nbme-primary-400 warm:bg-brown-600 dark:bg-nbme-primary-dark-500 p-1 group-hover:bg-nbme-primary-500 warm:group-hover:bg-brown-700 group-hover:dark:bg-nbme-primary-dark-300  transition-colors overflow-x-hidden rounded-sm",
            )}
          >
            <FontAwesomeIcon icon={faHighlighter} style={{ color }} />
          </div>
          <p className="capitalize text-start text-sm">
            {name}
          </p>
        </div>
      </NbmeHighlightMarkerMenuButton>
      {id && userId &&
        (
          <Popover
            open={isHighlightMarkerFormOpen}
            onOpenChange={setIsHighlightMarkerFormOpen}
          >
            <PopoverTrigger>
              <Button
                variant={"ghost"}
                size={"icon"}
                className="w-5 h-5 hover:bg-nbme-primary-800 text-nbme-primary-200"
              >
                <EditIcon />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-full z-[999] bg-transparent shadow-none border-0 dark:bg-transparent warm:bg-transparent">
              <HighlightMarkerForm
                id={id}
                onEdit={() => setIsHighlightMarkerFormOpen(false)}
                onCancel={() => setIsHighlightMarkerFormOpen(false)}
              />
            </PopoverContent>
          </Popover>
        )}
    </div>
  );
};

import { useEffect, useState } from "react";
import { BreakPoints } from "@/types";

export const useBreakPoint = () => {
  const check = () => {
    if (typeof window === "undefined") return BreakPoints.Null;
    const width = window.innerWidth;
    if (width > BreakPoints.XXLarge) return BreakPoints.XXLarge;
    if (width > BreakPoints.XLarge) return BreakPoints.XLarge;
    if (width > BreakPoints.Large) return BreakPoints.Large;
    if (width > BreakPoints.Medium) return BreakPoints.Medium;
    return BreakPoints.Small;
  };

  const [breakPoint, setBreakPoint] = useState<BreakPoints>(check()!);

  useEffect(() => {
    const handleResize = () => {
      const breakPoint = check();
      setBreakPoint(breakPoint);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakPoint]);
  return breakPoint;
};

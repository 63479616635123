import { EditableTitle, Layout } from "./components";

interface HeaderProps {
}

export const Header = (
  {}: HeaderProps,
) => {
  return (
    <Layout>
      <EditableTitle />
    </Layout>
  );
};

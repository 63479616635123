import { createTRPCClient, httpBatchLink } from "@trpc/client";
import type { AppRouter } from "@/types";
import { CoursologyTokenHeader } from "@/api/types";
import getCurrentUser from "../auth-utils/get-current-user";
export const trpcProxyClient = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      /**
       * If you want to use SSR, you need to use the server's full URL
       * @link https://trpc.io/docs/ssr
       */
      url: import.meta.env.VITE_API_ENDPOINT,

      // You can pass any HTTP headers you wish here
      async headers() {
        const coursologyTokenHeader: CoursologyTokenHeader =
          "x-coursology-token";
        return {
          [coursologyTokenHeader]: getCurrentUser()
            ?.token,
        };
      },
    }),
  ],
});

import { useCallback, useState } from "react";

const useClampedState = <T>(initialState: T, clamper: (state: T) => T) => {
  const [state, setState] = useState(clamper(initialState));

  const clampedSetState = useCallback(
    (value: T | ((v: T) => T), clamp = true) => {
      if (typeof value === "function") {
        //@ts-ignore
        return setState(value(clamp ? clamper(state) : state));
      }

      return setState(clamp ? clamper(value) : value);
    },
    [clamper],
  );

  return [state, clampedSetState] as const;
};

export default useClampedState;

import useSubmitTest from "@/hooks/test-hooks/use-submit-test";
import useTestPageTest from "./use-test-page-test";

const useTestPageSubmitTest = () => {
  const { data: test } = useTestPageTest();
  const submitTest = useSubmitTest();

  return () => {
    test && submitTest({ testId: test.id });
  };
};

export default useTestPageSubmitTest;

import OverallStatsPage from "@/components/OverallStatsPage";
import { useDashboardLayoutStore } from "@/utils/stores";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/performance/overall",
)({
  component: PerformancePage,
});

function PerformancePage() {
  const { setHeaderText } = useDashboardLayoutStore();
  useEffect(() => {
    setHeaderText("Performance");
    return () => setHeaderText("");
  }, [setHeaderText]);
  return <OverallStatsPage />;
}

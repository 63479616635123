import { Button } from "../..";
import { faBoltLightning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FlashcardsButton = () => {
  return (
    <Button href="flashcards">
      <FontAwesomeIcon icon={faBoltLightning} />
      <p>Flashcards</p>
    </Button>
  );
};

import { ArgumentTypes } from "@/types";
import useResizableGesture from "./use-resizable-gesture";

type HandleGestureArguments = ArgumentTypes<
  ReturnType<typeof useResizableGesture>
>[0];

const useBorderGesture = (
  { placement, dragAxis, events, invertResizeDirection }:
    & Omit<HandleGestureArguments, "placement" | "dragAxes">
    & {
      placement: "top" | "bottom" | "left" | "right";
      dragAxis: "x" | "y";
    },
) => {
  return useResizableGesture({
    placement: [placement, null],
    dragAxes: { [dragAxis]: true },
    events,
    invertResizeDirection,
  });
};

export default useBorderGesture;

import { SubscriptionType } from "@/api/types";
import { DateTime } from "luxon";

export const calcExpirationDate = (
  { lastRenewal, subscriptionType, duration, extraMonths }: {
    lastRenewal: DateTime;
    subscriptionType: SubscriptionType;
    duration: number;
    extraMonths: number;
  },
) => {
  switch (subscriptionType) {
    case "DAILY":
      return lastRenewal.plus({ days: duration, months: extraMonths });
    case "WEEKLY":
      return lastRenewal.plus({ weeks: duration, months: extraMonths });
    case "MONTHLY":
      return lastRenewal.plus({ months: duration + extraMonths });
    case "YEARLY":
      return lastRenewal.plus({ years: duration, months: extraMonths });
  }
};

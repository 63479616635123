import useGetSubjectQuestionHeaders from "./use-get-subject-question-headers";
import useQuestionBankSubjects from "@/hooks/subject-hooks/use-question-bank-subjects";
import { PerformanceReport } from "@/types/statistics/performance-report";
import ArrayMapUtils, { ArrayMap } from "@/types/common/ArrayConvertableMap";
import mergeFlags from "@/utils/common/merge-flags";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import { useCallback } from "react";
import { QuestionHeader } from "@/models/question-model";
import useGetQuestionBankSystemPerofrmanceReports from "./use-get-question-bank-system-performance-reports";
import { Subject } from "@/models";

type Opts = {
  filters?: {
    questionHeaders?: ArrayMap<QuestionHeader>;
    subjects?: ArrayMap<Subject>;
  };
};

const useGetQuestionBankSubjectPerofrmanceReports = (
  args: QuestionBankIdentifier,
  opts?: Opts,
) => {
  const subjectsQuery = useQuestionBankSubjects(args, {
    enabled: !opts?.filters?.subjects,
  });
  const { data: subjectsData } = subjectsQuery;
  const internalSubjects = opts?.filters?.subjects ?? subjectsData;
  const getSubjectsQuestionHeadersQuery = useGetSubjectQuestionHeaders(args, {
    filters: { questionHeaders: opts?.filters?.questionHeaders },
  });
  const getSystemPerformanceReportsQuery =
    useGetQuestionBankSystemPerofrmanceReports(args, opts);
  const { data: getSystemPerformanceReports } =
    getSystemPerformanceReportsQuery;
  const {
    data: getSubjectQuestionHeaders,
    isLoading: isGetSubjectsQuestionHeadersLoading,
  } = getSubjectsQuestionHeadersQuery;

  const getQuestionBankSubjectPerformanceReports = useCallback(
    (opts?: {
      filters?: {
        questionHeaders?: ArrayMap<QuestionHeader>;
        subjects?: ArrayMap<Subject>;
      };
    }) => {
      const performanceReports: PerformanceReport[] = [];
      const subjects = opts?.filters?.subjects ?? internalSubjects;
      if (!!getSubjectQuestionHeaders && !isGetSubjectsQuestionHeadersLoading) {
        ArrayMapUtils.forEach(subjects, (subject) => {
          let total = 0;
          let correct = 0;
          let incorrect = 0;
          let omitted = 0;
          let used = 0;
          const subjectQuestionHeaders = opts?.filters?.questionHeaders
            ? ArrayMapUtils.filter(
              opts.filters.questionHeaders,
              (qh) => qh.subjectId === subject.id,
            )
            : getSubjectQuestionHeaders(subject.id);
          ArrayMapUtils.forEach(subjectQuestionHeaders, (questionHeader) => {
            total++;
            if (questionHeader.used) {
              used++;
              switch (questionHeader.used) {
                case "omitted":
                  omitted++;
                  break;
                case "correct":
                  correct++;
                  break;
                case "incorrect":
                  incorrect++;
                  break;
              }
            }
          });
          const performanceReport = {
            id: subject.id,
            name: subject.name,
            total,
            incorrect,
            correct,
            omitted,
            used,
            children: getSystemPerformanceReports({
              filters: { questionHeaders: subjectQuestionHeaders },
            }),
          };
          if (performanceReport.total === 0) return;
          performanceReports.push(performanceReport);
        });
      }
      return performanceReports;
    },
    [
      getSubjectQuestionHeaders,
      isGetSubjectsQuestionHeadersLoading,
      internalSubjects,
    ],
  );

  return {
    ...mergeFlags({
      source: subjectsQuery,
      destinations: [
        getSubjectsQuestionHeadersQuery,
        getSystemPerformanceReportsQuery,
      ],
    }),
    data: getQuestionBankSubjectPerformanceReports,
  };
};

export default useGetQuestionBankSubjectPerofrmanceReports;

"use client";
import { PropsWithChildren } from "react";
import useResizableState, { ResizableState } from "./hooks/use-resizable-state";
import ResizableContextProvider from "./resizable-context-provider";
import { ResizableContext } from "./resizable-context";
import { ArgumentTypes } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithClassName } from "@/types";

type ResizableRootProps =
  & Partial<Pick<ResizableContext, "events">>
  & ArgumentTypes<typeof useResizableState>[0]
  & PropsWithChildren
  & PropsWithClassName
  & { state?: ResizableState };

const ResizableRoot = (
  { children, className, state: externalState, ...props }: ResizableRootProps,
) => {
  const internalState = useResizableState({
    ...props,
  });

  const state = externalState ?? internalState;

  return (
    <ResizableContextProvider value={state}>
      <div
        className={cn("group/resizable relative", className)}
        data-is-maximized={state.isMaximized}
        data-is-resizable={state.isResizable}
        data-is-resizing={state.isResizing}
      >
        {children}
      </div>
    </ResizableContextProvider>
  );
};

export default ResizableRoot;

import { ContextMenuBody } from "@/components/context-menu";
import { cn } from "@/lib/utils";
import { ComponentProps } from "react";

const NbmeContextMenuBody = (
  { children, className, ...props }: ComponentProps<typeof ContextMenuBody>,
) => {
  return (
    <ContextMenuBody
      className={cn(
        "dark:bg-neutral-800 bg-nbme-primary-700 font-semibold px-2 py-2 md:py-1 rounded-primary flex flex-col md:flex-row justify-center items-center gap-3.5",
        className,
      )}
      {...props}
    >
      {children}
    </ContextMenuBody>
  );
};

export default NbmeContextMenuBody;

import { DeleteButton } from "./components";
import { tagIsSavedOnServer } from "./utils";

interface TagProps {
  title: string;
  id: string;
  onRemove: () => void;
}
export const Tag = ({ title, onRemove, id }: TagProps) => {
  if (!title) return <></>;
  return (
    <div className="relative flex flex-row justify-center items-center rounded-full px-2 py-1 bg-gray-100 warm:bg-brown-800/10 dark:bg-neutral-700 dark:hover:bg-neutral-600 hover:bg-gray-200 transition-colors text-gray-600 warm:text-brown-800 dark:0ext-neutral-200 border border-transparent dark:border-neutral-600">
      {title}
      <DeleteButton isVisible={tagIsSavedOnServer(id)} onClick={onRemove} />
    </div>
  );
};

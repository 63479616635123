import React from "react";
import { NbmeSettingsSidebarSwitch } from ".";

export interface NbmeSettingsSidebarConfirmAnswerOmissionControlProps {
  isConfirmAnswerOmission: boolean;
  onIsConfirmAnswerOmissionChange: (v: boolean) => void;
}

export const NbmeSettingsSidebarConfirmAnswerOmissionControl = (
  { isConfirmAnswerOmission, onIsConfirmAnswerOmissionChange }:
    NbmeSettingsSidebarConfirmAnswerOmissionControlProps,
) => {
  return (
    <NbmeSettingsSidebarSwitch
      checked={isConfirmAnswerOmission}
      onCheckedChange={onIsConfirmAnswerOmissionChange}
    />
  );
};

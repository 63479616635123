import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Input } from "@/components/Input";
import { Skeleton } from "@/components/ui/skeleton";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import useAuthSlice from "@/hooks/auth-hooks/use-auth-slice";

export const ResetQuestionBankButton = () => {
  const { data: questionBank, isLoading } = useDashboardQuestionBank();
  const { currentUser, resetQbank } = useAuthSlice();

  const [password, setPassword] = useState("");

  if (!currentUser) return <></>;

  if (!questionBank && isLoading) {
    return <Skeleton className="w-full h-12 mt-2" />;
  }
  if (
    (currentUser.resetQuestionBanks &&
      currentUser.resetQuestionBanks.map((qb) => qb.id).includes(
        questionBank?.id ?? "",
      ))
  ) {
    return <></>;
  }
  return (
    <AlertDialog
      onOpenChange={(newOpen) => {
        if (!newOpen) {
          setPassword("");
        }
      }}
    >
      <AlertDialogTrigger className="w-full">
        <button
          className={twMerge(
            "w-full font-medium rounded-primary outline-none",
            "bg-white warm:bg-brown-50 dark:bg-neutral-700 text-primary-600 warm:text-brown-800 dark:text-white hover:bg-gray-100 dark:hover:bg-neutral-600 focus:bg-gray-100 dark:focus:bg-neutral-600 group transition-[opacity,_background-color]",
            "flex flex-row justify-start items-center gap-2 w-full py-3.5 pr-14 pl-8  relative mt-3",
          )}
        >
          <FontAwesomeIcon
            icon={faRotate}
            className="transition-transform group-hover:rotate-180 duration-300 ease-in-out"
          />
          <p>Reset QBank</p>
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Reset {questionBank?.name}?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This will reset everything in this question bank
            (<span className="font-bold">{questionBank?.name}</span>), including
            question status (correct, incorrect, omitted, etc..), highlights,
            notes, notebook and all your progress, in this Qbank{" "}
            <span className="font-bold">only</span>{" "}
            <span className="text-red-500 font-bold">
              No one can reverse this.., not even us.
            </span>,Are you sure you want to continue?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="flex flex-col justify-start items-start gap-1">
          <label
            htmlFor="reset-qbank-input"
            className="text-gray-700 dark:text-neutral-400"
          >
            Enter your password to confirm:
          </label>

          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex flex-row justify-start items-center gap-2">
              <Input
                className="h-8 py-2"
                id="reset-qbank-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex flex-row justify-start items-center gap-2">
              <AlertDialogCancel>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                disabled={!password}
                onClick={() =>
                  questionBank &&
                  resetQbank({ password, qbankId: questionBank.id })}
                className="warm:bg-brown-700 warm:hover:bg-brown-800"
              >
                Confirm
              </AlertDialogAction>
            </div>
          </div>
        </div>
      </AlertDialogContent>
      <AlertDialogOverlay className="opacity-10" />
    </AlertDialog>
  );
};

import { trpc } from "@/utils/trpc";
import { useEffect, useMemo } from "react";
import useAddQuestionNotes from "./use-add-question-notes";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestionNotes from "./use-question-notes";
import useTestTestSlots from "@/hooks/test-slot-hooks/use-test-test-slots";
import mergeFlags from "@/utils/common/merge-flags";

const useTestQuestionNotes = ({ testId }: { testId: number }) => {
  const questionNotes = useQuestionNotes();
  const addQuestionNotes = useAddQuestionNotes();
  const testSlotsQuery = useTestTestSlots({ testId });
  const { data: testSlots } = testSlotsQuery;
  const query = trpc.questionNote.getTestQuestionNotes.useQuery({ testId });
  const { data, isLoading } = query;
  useEffect(() => {
    if (data && !isLoading) {
      addQuestionNotes(data);
    }
  }, [data, isLoading]);

  return useMemo(() => (
    {
      ...mergeFlags({ source: query, destinations: [testSlotsQuery] }),
      data: ArrayMapUtils.filter(
        questionNotes,
        (qn) =>
          ArrayMapUtils.includes(
            testSlots,
            (s) => s.questionId === qn.questionId,
          ),
      ),
    }
  ), [
    query,
    testSlotsQuery,
    questionNotes,
    testSlots,
  ]);
};

export default useTestQuestionNotes;

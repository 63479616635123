import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankTopics from "./use-question-bank-topics";
import { Question } from "@/models/question-model";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import mergeFlags from "@/utils/common/merge-flags";
import { useMemo } from "react";

const useQuestionBankQuestionTopic = (
  args: QuestionBankIdentifier & { questionId: Question["id"] },
) => {
  const questionBankQuestionHeadersQuery = useQuestionBankQuestionHeaders(args);
  const { data: questionBankQuestionHeaders } =
    questionBankQuestionHeadersQuery;
  const questionBankTopicsQuery = useQuestionBankTopics(args);
  const { data: questionBankTopics } = questionBankTopicsQuery;
  const question = useMemo(() =>
    ArrayMapUtils.find(
      questionBankQuestionHeaders,
      args.questionId,
    ), [questionBankQuestionHeaders, args.questionId]);

  const questionTopic = useMemo(
    () =>
      question
        ? ArrayMapUtils.find(questionBankTopics, question.topicId)
        : undefined,
    [questionBankTopics, question?.topicId],
  );

  return {
    ...mergeFlags({
      source: questionBankQuestionHeadersQuery,
      destinations: [questionBankTopicsQuery],
    }),
    data: questionTopic,
  };
};
export default useQuestionBankQuestionTopic;

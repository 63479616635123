import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface CardProps extends PropsWithChildren {
  className?: string;
}

export const Card = ({ className, children }: CardProps) => {
  return (
    <div
      className={twMerge(
        "bg-white rounded-primary shadow-lg px-2 py-4 lg:px-3 lg:py-5 border border-primary-500",
        className,
      )}
    >
      {children}
    </div>
  );
};

import { CheckMarkIcon } from "@/components/icons";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";

type GreenCheckMarkProps = PropsWithClassName;
const GreenCheckMark = ({ className }: GreenCheckMarkProps) => {
  return (
    <div
      className={cn(
        "inline-flex flex-row justify-center items-center bg-green-500 text-white w-4 h-4 rounded-full",
        className,
      )}
    >
      <CheckMarkIcon className="w-3 h-3" />
    </div>
  );
};
export default GreenCheckMark;

import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankQuestionHeaders from "./use-question-bank-question-headers";
import { System } from "@/models";
import ArrayMapUtils, { ArrayMap } from "@/types/common/ArrayConvertableMap";
import { useCallback } from "react";
import { QuestionHeader } from "@/models/question-model";

type Opts = {
  enabled?: boolean;
  filters?: {
    questionHeaders?: ArrayMap<QuestionHeader>;
    subjects?: string[];
  };
};
const useGetSystemQuestionHeaders = (
  args: QuestionBankIdentifier,
  opts?: Opts,
) => {
  const questionBankQuestionHeadersQuery = useQuestionBankQuestionHeaders(
    args,
    { enabled: !opts?.filters?.questionHeaders && (opts?.enabled ?? true) },
  );
  const { data: internalQuestionHeaders } = questionBankQuestionHeadersQuery;
  const externalQuestionHeaders = opts?.filters?.questionHeaders;
  const questionHeaders = externalQuestionHeaders ?? internalQuestionHeaders;

  const getSystemQuestionHeaders = useCallback((systemId: System["id"]) => {
    const systemQuestionHeaders = ArrayMapUtils.filter(
      questionHeaders,
      (qh) => {
        const isQuestionHeaderInSystem = qh.systemId === systemId;
        if (!opts?.filters?.subjects) {
          return isQuestionHeaderInSystem;
        }
        const isQuestionHeaderInSubjects = opts.filters.subjects.includes(
          qh.subjectId,
        );
        return isQuestionHeaderInSystem && isQuestionHeaderInSubjects;
      },
    );
    return systemQuestionHeaders;
  }, [questionHeaders]);

  return {
    ...questionBankQuestionHeadersQuery,
    data: getSystemQuestionHeaders,
  };
};

export default useGetSystemQuestionHeaders;

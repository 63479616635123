import { Tag } from "@/components/Tag";
import { titlize } from "@/utils/common/titlize";
import useTestSettings from "@/hooks/test-hooks/use-test-settings";
import { Test } from "@/models/test-model";
import React from "react";

export const PerformanceTestResultsPageSettingsSection = (
  { testId }: { testId: Test["id"] },
) => {
  const { data } = useTestSettings({ testId });
  const { mode, pool } = data ?? { mode: [], pool: [] };
  return (
    <div className="flex flex-col justify-start items-start gap-6 w-full md:w-[20%]">
      <p>Test Settings</p>
      <div className="flex flex-col justify-start items-start w-full">
        <div className="flex flex-row justify-between items-center border-b border-gray-300 dark:border-neutral-700 pb-3 w-full">
          <p className="text-start text-gray-500 dark:text-neutral-300">Mode</p>
          <div className="flex flex-row justify-end items-end gap-2">
            {mode.map((m) => (
              <React.Fragment key={m}>
                {m &&
                  (
                    <Tag
                      key={m}
                      className={"md:text-sm text-xs"}
                      hideRemoveButton
                    >
                      {titlize(m)}
                    </Tag>
                  )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-3 w-full">
          <p className="text-start text-gray-500 dark:text-neutral-300">
            Question Pool
          </p>
          <div className="flex flex-row justify-end items-end gap-2">
            {pool.map((m) => (
              <Tag key={m} className={"md:text-sm text-xs"} hideRemoveButton>
                {titlize(m)}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

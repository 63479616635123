import React, { useState } from "react";
import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { useNbmeLayoutStore } from "@/utils/stores/nbmeLayoutStore";

export const NbmeSettingsButton = () => {
  const { setSettingsVisible, settingsVisible } = useNbmeLayoutStore((
    state,
  ) => ({
    setSettingsVisible: state.setSettingsVisible,
    settingsVisible: state.settingsVisible,
  }));

  return (
    <NbmeIconNavbarButton
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faGear} className="w-8 h-8" />}
      label="Settings"
      onClick={() => setSettingsVisible(!settingsVisible)}
    />
  );
};

import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import useTestQuestionNotes from "@/hooks/question-note-hooks/use-test-questionnotes";
import { createContext } from "react";
import useConstructedTest from "./-test-page-hooks/use-constructed-test";

type ConstructedTest = ReturnType<typeof useConstructedTest>;

type TestPageContext = {
  constructedTest: ConstructedTest;
  highlightMarkers: ReturnType<typeof useHighlightMarkers>;
};

const testPageContext = createContext<TestPageContext | undefined>(undefined);
export default testPageContext;

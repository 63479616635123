import { Badge } from "./Badge";

type TestScoreBadgeProps = { score: number | null; isTestSubmitted: boolean };
const TestScoreBadge = ({ score, isTestSubmitted }: TestScoreBadgeProps) => {
  if (!isTestSubmitted) return <></>;
  if (score === null || score === undefined) return <></>;
  return (
    <Badge className="lg:w-12 lg:h-12 md:w-10 md:h-10 p-0">
      {score.toFixed(0)}%
    </Badge>
  );
};

export default TestScoreBadge;

import { PropsWithChildren, useEffect } from "react";
import { twMerge } from "tailwind-merge";
import {
  NbmeCalculatorWindow,
  NbmeConfirmOmissionDialog,
  NbmeEndTestDialog,
  NbmeFeedbackWindow,
  NbmeHintWindow,
  NbmeLabValuesWindow,
  NbmeNavbars,
  NbmeQuestionNoteWindow,
  NbmeSettingsSidebar,
  NbmeSidebar,
  NbmeSuspendTestDialog,
} from ".";
import { NbmeNavbarsProps } from "./NbmeNavbars";
import { alphabetToNumber } from "@/utils/common/alphabetToNumber";
import { clamp } from "@/utils/common/clamp";
import { NbmeSidebarProps } from "./NbmeSidebar";
import { NbmeSettingsSidebarProps } from "./NbmeSettingsSidebar";
import { ArrayElement, Point } from "@/types";
import { DateTime } from "luxon";
import { useDateEverySecond } from "@/utils/hooks";

interface CommonNbmeLayoutProps<T = "test" | "question">
  extends PropsWithChildren, NbmeNavbarsProps<T>, NbmeSettingsSidebarProps<T> {
  onIsSettingsVisibleChange: (v: boolean) => void;
  isConfirmOmissionDialogVisible: boolean;
  onIsConfirmOmissionDialogVisibleChange: (v: boolean) => void;
  isSelectionEnabled: boolean;
  isDarkMode: boolean;
  isShortcutsEnabled: boolean;
  onIsShortcutsEnabledChange: (v: boolean) => void;
  isCurrentSlotSubmitted: boolean;
  onIsSelectionEnabledChange: (v: boolean) => void;
  onFeedbackSubmitted?: T extends "test" ? (
      feedback: { text: string; technical: boolean },
    ) => void
    : undefined;
  onQuestionNoteChange: (note: { text: string }) => void;
  onQuestionNoteDeleted: (note: { text: string }) => void;
  qBankEndpoint: string;
  questionNote: { text: string };
  hints: {
    isVisible: boolean;
    text: string;
    imageId: string | null;
    videoId: string | null;
    html: string | null;
    id: string;
    position?: Point;
  }[];
  onIsHintVisibleChange: (
    hint: ArrayElement<CommonNbmeLayoutProps["hints"]>,
    newIsHintVisible: boolean,
  ) => void;
  onHintWindowPositionChange: (
    hint: ArrayElement<CommonNbmeLayoutProps["hints"]>,
    newHintWindowPosition: Point,
  ) => void;
  calculatorWindowPosition: Point;
  onCalculatorWindowPositionChange: (p: Point) => void;
  totalChoices: number;
  onChoiceChange?: this["variant"] extends "test" ? (order: number) => void
    : undefined;
  onTestSubmitted?: this["variant"] extends "test" ? () => void
    : undefined;
  onTestReviewEnded?: this["variant"] extends "test" ? () => void
    : undefined;

  onSlotSubmitted?: this["variant"] extends "test" ? () => void
    : undefined;

  onTestSuspended?: this["variant"] extends "test" ? () => void
    : undefined;
}

type NbmeLayoutProps<T = "question" | "test"> =
  & CommonNbmeLayoutProps<T>
  & (T extends "test" ? NbmeSidebarProps : {});

export const NbmeLayout = <T = "test" | "question">(
  props: Omit<NbmeLayoutProps<T>, "onIsVisibleChange" | "isVisible">,
) => {
  const now = useDateEverySecond();
  const {
    hints,
    fontSizeFactor,
    onIsHintVisibleChange,
    onHintWindowPositionChange,
    children,
    onTestSubmitted,
    onTestReviewEnded,
    isSelectionEnabled,
    totalChoices,
    onChoiceChange,
    onIsSelectionEnabledChange,
    isDarkMode,
    isShortcutsEnabled,
    onIsShortcutsEnabledChange,
    isSidebarVisible,
    onIsSidebarVisibleChange,
    totalSlots,
    isTestSubmitted,
    calculatorWindowPosition,
    onCalculatorWindowPositionChange,
    currentSlot,
    onIsSettingsVisibleChange,
    variant,
    onCurrentSlotChange,
    isCurrentSlotSubmitted,
    isEndTestDialogVisible,
    onIsEndTestDialogVisibleChange,
    isQuestionMarked,
    onQuestionMarkedChange,
    isQuestionNoteVisible,
    onQuestionNoteChange,
    onQuestionNoteDeleted,
    questionNote,
    isSuspendTestDialogVisible,
    onIsSuspendTestDialogVisibleChange,
    onIsQuestionNoteVisibleChange,
    onIsFeedbackVisibleChange,
    isLabValuesVisible,
    onIsLabValuesVisibleChange,
    isFeedbackVisible,
    onFeedbackSubmitted,
    isCalculatorVisible,
    onIsCalculatorVisibleChange,
    onSlotSubmitted,
    isConfirmOmissionDialogVisible,
    onIsConfirmOmissionDialogVisibleChange,
    onTestSuspended,
    isTestTutor,
    onIsTimerVisibleChange,
    isTimerVisible,
    isTestTimed,
    isQuestionNotedOn,
    testTimeRemaining,
    testTimeElapsed,
    onIsFlashcardsVisibleChange,
    isFlashcardsVisible,
    onIsNotebookVisibleChange,
    isNotebookVisible,
    testId,
    questionId,
    onNightModeStartTimeChange,
    onNightModeEndTimeChange,
    nightModeEndTime,
    nightModeStartTime,
    isNightMode,
    onFontSizeFactorChange,
    isSettingsVisible,
    isPercentageAnsweredVisible,
    isConfirmAnswerOmission,
    onIsPercentageAnsweredVisibleChange,
    onIsConfirmAnswerOmissionChange,
    onIsNightModeChange,
    onIsDarkModeChange,
    qBankEndpoint,
  } = props;

  useEffect(() => {
    if (isNightMode) {
      const nowAsLuxonDate = DateTime.fromJSDate(now);
      const startHours = nightModeStartTime.hours +
        (nightModeStartTime.isAm ? 0 : 12);
      const startDate = nowAsLuxonDate.set({
        hour: startHours,
        minute: nightModeStartTime.minutes,
      });
      const endHours = nightModeEndTime.hours +
        (nightModeEndTime.isAm ? 0 : 12);

      let endDate = nowAsLuxonDate.set({
        day: nowAsLuxonDate.day,
        hour: endHours,
        minute: nightModeEndTime.minutes,
      });

      if (endDate < startDate) {
        endDate = endDate.set({ day: endDate.day + 1 });
      }

      if (nowAsLuxonDate >= startDate && nowAsLuxonDate <= endDate) {
        onIsDarkModeChange(true);
      } else onIsDarkModeChange(false);
    }
  }, [now, isNightMode]);

  return (
    <div
      className={twMerge(
        "flex flex-row min-w-full justify-between items-start selection:bg-nbme-primary-700 outline-none",
        !isSelectionEnabled && "select-none",
        isDarkMode && "dark",
      )}
      onKeyDown={isShortcutsEnabled
        ? (e) => {
          if (e.code === "ArrowLeft") {
            e.preventDefault();
            if (variant === "question") return;
            onCurrentSlotChange?.(
              clamp({ min: 1, max: totalSlots!, num: currentSlot! - 1 }),
            );
          }
          if (e.code === "ArrowRight") {
            e.preventDefault();
            if (variant === "question") return;
            onCurrentSlotChange?.(
              clamp({ min: 1, max: totalSlots!, num: currentSlot! + 1 }),
            );
          }

          if (/Key[A-Z]/.test(e.code)) {
            if (!e.altKey) {
              if (isCurrentSlotSubmitted) return;
              const choiceOrder = alphabetToNumber(e.key);
              if (choiceOrder > totalChoices - 1 || choiceOrder < 0) return;

              e.preventDefault();
              onChoiceChange?.(choiceOrder);
            }
          }

          if (!e.altKey) return;

          if (e.code === "KeyM") {
            e.preventDefault();
            onQuestionMarkedChange(!isQuestionMarked);
          }

          if (e.code === "KeyP") {
            e.preventDefault();
            onCurrentSlotChange?.(
              clamp({ min: 1, max: totalSlots!, num: currentSlot! - 1 }),
            );
          }

          if (e.code === "KeyN") {
            e.preventDefault();
            onCurrentSlotChange?.(
              clamp({ min: 1, max: totalSlots!, num: currentSlot! + 1 }),
            );
          }

          if (e.code === "KeyO") {
            e.preventDefault();
            onIsQuestionNoteVisibleChange(!isQuestionNoteVisible);
          }

          if (e.code === "KeyU") {
            e.preventDefault();
            onIsCalculatorVisibleChange(!isCalculatorVisible);
          }

          if (e.code === "KeyF") {
            e.preventDefault();
            onIsFeedbackVisibleChange(!isFeedbackVisible);
          }

          if (e.code === "KeyL") {
            e.preventDefault();
            onIsLabValuesVisibleChange(!isLabValuesVisible);
          }
        }
        : undefined}
      tabIndex={0}
    >
      <NbmeLabValuesWindow
        isVisible={isLabValuesVisible}
        onIsVisibleChange={onIsLabValuesVisibleChange}
        onIsSelectionEnabledChange={onIsSelectionEnabledChange}
      />
      {variant === "test" &&
        (
          <>
            <NbmeFeedbackWindow
              isVisible={isFeedbackVisible}
              onIsVisibleChange={onIsFeedbackVisibleChange}
              onIsSelectionEnabledChange={onIsSelectionEnabledChange}
              onIsShortcutsEnabledChange={onIsShortcutsEnabledChange}
              onFeedbackSubmitted={onFeedbackSubmitted ?? (() => undefined)}
            />
            <NbmeSuspendTestDialog
              isVisible={isSuspendTestDialogVisible!}
              onIsVisibleChange={onIsSuspendTestDialogVisibleChange!}
              onTestSuspended={onTestSuspended!}
              isDarkMode={isDarkMode}
            />
            <NbmeConfirmOmissionDialog
              isVisible={isConfirmOmissionDialogVisible}
              onIsVisibleChange={onIsConfirmOmissionDialogVisibleChange}
              onSlotSubmitted={onSlotSubmitted!}
              isDarkMode={isDarkMode}
            />
            <NbmeEndTestDialog
              isVisible={isEndTestDialogVisible!}
              onIsVisibleChange={onIsEndTestDialogVisibleChange!}
              isTestSubmitted={isTestSubmitted!}
              isDarkMode={isDarkMode}
              onTestSubmitted={onTestSubmitted!}
              onTestReviewEnded={onTestReviewEnded!}
            />
          </>
        )}
      <NbmeCalculatorWindow
        isVisible={isCalculatorVisible}
        onIsVisibleChange={onIsCalculatorVisibleChange}
        onIsSelectionEnabledChange={onIsSelectionEnabledChange}
        position={calculatorWindowPosition}
        onPositionChange={onCalculatorWindowPositionChange}
      />
      <NbmeQuestionNoteWindow
        isVisible={isQuestionNoteVisible}
        onIsVisibleChange={onIsQuestionNoteVisibleChange}
        onNoteChange={onQuestionNoteChange}
        onNoteDeleted={onQuestionNoteDeleted}
        note={questionNote}
        onIsSelectionEnabledChange={onIsSelectionEnabledChange}
        onIsShortcutsEnabledChange={onIsShortcutsEnabledChange}
      />
      <NbmeSettingsSidebar
        variant={variant}
        onNightModeStartTimeChange={onNightModeStartTimeChange}
        onIsDarkModeChange={onIsDarkModeChange}
        onIsNightModeChange={onIsNightModeChange}
        onIsConfirmAnswerOmissionChange={onIsConfirmAnswerOmissionChange}
        onIsPercentageAnsweredVisibleChange={onIsPercentageAnsweredVisibleChange}
        isConfirmAnswerOmission={isConfirmAnswerOmission}
        isPercentageAnsweredVisible={isPercentageAnsweredVisible}
        onFontSizeFactorChange={onFontSizeFactorChange}
        isNightMode={isNightMode}
        nightModeStartTime={nightModeStartTime}
        nightModeEndTime={nightModeEndTime}
        onNightModeEndTimeChange={onNightModeEndTimeChange}
        fontSizeFactor={fontSizeFactor}
        onIsTimerVisibleChange={onIsTimerVisibleChange}
        isTimerVisible={isTimerVisible}
        isVisible={isSettingsVisible}
        onIsVisibleChange={onIsSettingsVisibleChange}
      />
      {hints.map((h) => {
        return (
          <NbmeHintWindow
            hint={h}
            isVisible={h.isVisible}
            onIsVisibleChange={(newIsVisible) =>
              onIsHintVisibleChange(h, newIsVisible)}
            key={h.id}
            position={h.position}
            onPositionChange={(newPos) => onHintWindowPositionChange(h, newPos)}
            fontSizeFactor={fontSizeFactor}
            onIsSelectionEnabledChange={onIsSelectionEnabledChange}
          />
        );
      })}
      {variant === "test" &&
        (
          <NbmeSidebar
            isVisible={isSidebarVisible!}
            onIsVisibleChange={onIsSidebarVisibleChange!}
            isTestSubmitted={isTestSubmitted!}
            onCurrentSlotChange={onCurrentSlotChange!}
            isTestTutor={isTestTutor!}
            slots={[]}
            currentSlot={currentSlot!}
          />
        )}
      <NbmeNavbars
        isTestTutor={isTestTutor}
        currentSlot={currentSlot}
        onCurrentSlotChange={onCurrentSlotChange}
        isTestSubmitted={isTestSubmitted}
        isSidebarVisible={isSidebarVisible}
        onIsSidebarVisibleChange={onIsSidebarVisibleChange}
        onIsLabValuesVisibleChange={onIsLabValuesVisibleChange}
        isLabValuesVisible={isLabValuesVisible}
        onIsSuspendTestDialogVisibleChange={onIsSuspendTestDialogVisibleChange}
        isSuspendTestDialogVisible={isSuspendTestDialogVisible}
        onIsCalculatorVisibleChange={onIsCalculatorVisibleChange}
        isCalculatorVisible={isCalculatorVisible}
        onIsEndTestDialogVisibleChange={onIsEndTestDialogVisibleChange}
        isEndTestDialogVisible={isEndTestDialogVisible}
        fontSizeFactor={fontSizeFactor}
        onIsSettingsVisibleChange={onIsSettingsVisibleChange}
        isSettingsVisible={isSettingsVisible}
        onIsQuestionNoteVisibleChange={onIsQuestionNoteVisibleChange}
        isQuestionNoteVisible={isQuestionNoteVisible}
        onIsTimerVisibleChange={onIsTimerVisibleChange}
        isTimerVisible={isTimerVisible}
        onIsFeedbackVisibleChange={onIsFeedbackVisibleChange}
        isFeedbackVisible={isFeedbackVisible}
        isTestTimed={isTestTimed}
        isQuestionNotedOn={isQuestionNotedOn}
        isQuestionMarked={isQuestionMarked}
        totalSlots={totalSlots}
        testTimeRemaining={testTimeRemaining}
        testTimeElapsed={testTimeElapsed}
        onQuestionMarkedChange={onQuestionMarkedChange}
        onIsFlashcardsVisibleChange={onIsFlashcardsVisibleChange}
        isFlashcardsVisible={isFlashcardsVisible}
        onIsNotebookVisibleChange={onIsNotebookVisibleChange}
        isNotebookVisible={isNotebookVisible}
        testId={testId}
        variant={variant}
        questionId={questionId}
        qBankEndpoint={qBankEndpoint}
      >
        <div
          className={twMerge(
            "flex-grow min-h-full bg-nbme-primary-50 dark:bg-nbme-primary-dark-900 dark:text-white p-6",
            !isSelectionEnabled && "pointer-events-none",
          )}
        >
          {children}
        </div>
      </NbmeNavbars>
    </div>
  );
};

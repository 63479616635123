import { NbmeSettingsSidebarSwitch } from ".";
interface NbmeSettingsSidebarTimerVisibileControlProps {
  isTimerVisible: boolean;
  onIsTimerVisibleChange: (v: boolean) => void;
}

export const NbmeSettingsSidebarTimerVisibileControl = (
  { isTimerVisible, onIsTimerVisibleChange }:
    NbmeSettingsSidebarTimerVisibileControlProps,
) => {
  return (
    <NbmeSettingsSidebarSwitch
      checked={isTimerVisible}
      onCheckedChange={onIsTimerVisibleChange}
    />
  );
};

import { trpc } from "@/utils/trpc";
import useAddTestSlots from "./use-add-test-slots";
import { useEffect, useMemo } from "react";
import useTestSlots from "./use-test-slots";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const useTestTestSlots = (
  { testId, slots }: { testId: number; slots?: number[] },
) => {
  const testSlots = useTestSlots();
  const addTestSlots = useAddTestSlots();
  const query = trpc.testSlot.getTestTestSlots.useQuery({ testId, slots });
  const { data, isLoading } = query;
  useEffect(() => {
    if (!!data && !isLoading) {
      //Again, as usual, very dangerous ts-ignore but
      //I don't have any other choice as trpc
      //renders the type as 'string' instead of 'Date'
      //for dates on the client side
      // @ts-ignore
      addTestSlots(data);
    }
  }, [data, isLoading]);

  const testTestSlots = useMemo(() => {
    return ArrayMapUtils.filter(
      testSlots,
      (testSlot) => testSlot.testId === testId,
    );
  }, [testSlots, testId]);

  return useMemo(() => (
    {
      ...query,
      data: testTestSlots,
    }
  ), [query, testTestSlots]);
};

export default useTestTestSlots;

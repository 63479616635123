import { useAppStore } from "@/store";
import { useNotebookUi } from ".";
import { useNoteActions, usePageActions } from "..";
import { Note } from "@/models";
import { useEffect } from "react";

export const useConfirmDeleteDialog = () => {
  const removePage = useAppStore((state) => state.removePage);
  const removeNote = useAppStore((state) => state.removeNote);
  const {
    onCancel: internalOnCancel,
    onConfirm: internalOnConfirm,
    isVisible,
    setIsVisible,
    target,
    setTarget,
  } = useNotebookUi((state) => ({
    onCancel: state.onConfirmDeleteDialogCancel,
    onConfirm: state.onConfirmDeleteDialogConfirm,
    isVisible: state.isConfirmDeleteDialogVisible,
    setIsVisible: state.setIsConfirmDeleteDialogVisible,
    target: state.confirmDeleteDialogTarget,
    setTarget: state.setConfirmDeleteDialogTarget,
  }));
  const isThereATarget = !!target;
  const isTargetAPage = !(target as Note)?.pageId;
  const doNothing = () => {};
  const remove = isThereATarget
    ? isTargetAPage ? removePage : removeNote
    : doNothing;

  const onConfirm = () => {
    setIsVisible(false);
    if (!target) return;
    remove(target.id);
    internalOnConfirm();
  };

  const onCancel = () => {
    setIsVisible(false);
    return;
  };

  useEffect(() => {
    if (!isVisible) setTarget(undefined);
  }, [isVisible, setTarget]);

  return {
    onConfirm,
    onCancel,
    isVisible,
    setIsVisible,
    target,
    setTarget,
  };
};

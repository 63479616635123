import { ContextMenuItem } from "@/components/context-menu";
import useContextMenuContext from "@/components/context-menu/hooks/use-context-menu-context";
import { cn } from "@/lib/utils";
import { ComponentProps } from "react";

const NbmeContextMenuItem = (
  { className, children, ...props }: ComponentProps<
    typeof ContextMenuItem
  >,
) => {
  return (
    <ContextMenuItem
      className={cn("relative rounded-primary w-7 h-7", className)}
      {...props}
    >
      {children}
    </ContextMenuItem>
  );
};

export default NbmeContextMenuItem;

import { Button } from "../..";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NotebookButton = () => {
  return (
    <Button href="notebook">
      <FontAwesomeIcon icon={faBook} />
      <p>My Notebook</p>
    </Button>
  );
};

import { useGesture } from "@use-gesture/react";
import useNbmeSidebarVisible from "./use-nbme-sidebar-visible";
import useNbmeSidebarAutoHide from "./use-nbme-sidebar-autohide";

const useNbmeSidebarGestures = () => {
  const timer = useNbmeSidebarAutoHide();
  const [_, setVisible] = useNbmeSidebarVisible();
  const bind = useGesture({
    onMouseLeave: () => {
      timer.start();
    },
    onTouchEnd: () => {
      timer.start();
    },
    onTouchStart: () => {
      timer.pause();
    },
    onMouseEnter: () => {
      timer.pause();
    },
    onScroll: () => {
      setVisible(true);
    },
  });
  return bind;
};
export default useNbmeSidebarGestures;

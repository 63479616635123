import { ContextType, createContext, RefObject } from "react";
import { ReactZoomPanPinchProps } from "react-zoom-pan-pinch";
import { ViewerRef } from ".";

const viewerContext = createContext<
  | (Omit<
      {
        onCopy?: Function;
        onDownload?: Function;
        withToolbar?: boolean;
      } & ReactZoomPanPinchProps,
      "ref"
    > & { ref: RefObject<ViewerRef>; contentRef?: RefObject<HTMLDivElement> })
  | undefined
>(undefined);

export type ViewerContext = NonNullable<ContextType<typeof viewerContext>>;

export default viewerContext;

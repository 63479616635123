import { ArgumentTypes } from "@/types";

const mergeHomogenousFunctions = <T extends (args: any) => any>(
  ...functions: (T | undefined)[]
): ((...args: ArgumentTypes<T>) => ReturnType<T>[]) => {
  const result = (...args: ArgumentTypes<T>) => {
    const functionResults: Array<ReturnType<T>> = [];
    for (let i = 0; i < functions.length; i++) {
      const func = functions[i];
      functionResults.push(func?.(args) ?? undefined);
    }
    return functionResults;
  };

  return result;
};

export default mergeHomogenousFunctions;

import { DraggableHandle } from "@/components/draggable";
import Window from "@/components/window";
import { PropsWithChildren } from "react";
import useFloatingWindowContext from "./hooks/use-floating-window-context";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";

type FloatingWindowTitlebarProps = PropsWithChildren & PropsWithClassName;
const FloatingWindowTitlebar = (
  { children, className }: FloatingWindowTitlebarProps,
) => {
  const { draggable, resizable } = useFloatingWindowContext();
  return (
    <DraggableHandle className={cn("w-full", className)}>
      <Window.Titlebar>
        <Window.Indicators>
          {draggable.isDraggable &&
            <Window.DraggableIndicator />}
          {resizable.isResizable &&
            <Window.ResizableIndicator />}
        </Window.Indicators>
        <Window.Title>
          {children}
        </Window.Title>
        <Window.Buttons>
          {resizable.isMaximizable &&
            (
              <>
                {resizable.isMaximized
                  ? <Window.Minimize />
                  : <Window.Maximize />}
              </>
            )}

          <Window.Close />
        </Window.Buttons>
      </Window.Titlebar>
    </DraggableHandle>
  );
};

export default FloatingWindowTitlebar;

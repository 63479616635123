import { ClickableSelectItem } from "@/components/ClickableSelectItem";
import { LabeledSelect } from "@/components/LabeledSelect";
import { ArrayElement, Setter } from "@/types";
import { twMerge } from "tailwind-merge";
import { formatPerformanceSelectItems } from "../-utils";
import Select from "@/components/Select";
import { SelectItem } from "@/components/SelectItem";
import LabeledCheckbox from "@/components/LabeledCheckbox";

export type DashboardPerformanceSelectItemTypeWithoutMarked =
  | "Correct"
  | "Incorrect"
  | "Omitted";

export type DashboardPerformanceSelectItemTypeWithMarked =
  | "Correct"
  | "Incorrect"
  | "Omitted"
  | "Marked";

interface DashboardPerformanceSelectItemsWithMarked {
  withMarked?: true;
  items: DashboardPerformanceSelectItemTypeWithMarked[];
  onItemsChange: Setter<this["items"]>;
}

interface DashboardPerformanceSelectItemsWithoutMarked {
  withMarked?: false;
  items: DashboardPerformanceSelectItemTypeWithoutMarked[];
  onItemsChange: Setter<this["items"]>;
}

type DashboardPerformanceSelectPropsWithTypeSelect =
  & (
    | DashboardPerformanceSelectItemsWithoutMarked
    | DashboardPerformanceSelectItemsWithMarked
  )
  & {
    showTypeSelect: true;
    onTypeChange: Setter<"Score" | "QBank Usage">;
  };
type DashboardPerformanceSelectPropsWithoutTypeSelect =
  & (
    | DashboardPerformanceSelectItemsWithoutMarked
    | DashboardPerformanceSelectItemsWithMarked
  )
  & {
    showTypeSelect: false;
    onTypeChange?: undefined;
  };

type DashboardPerformanceSelectProps =
  & (
    | DashboardPerformanceSelectPropsWithTypeSelect
    | DashboardPerformanceSelectPropsWithoutTypeSelect
  )
  & {
    type: "Score" | "QBank Usage";
  };

export const DashboardPerformanceSelect = (
  {
    type,
    onTypeChange,
    showTypeSelect = true,
    items,
    onItemsChange,
    withMarked = false,
  }: DashboardPerformanceSelectProps,
) => {
  const isItemChecked = (item: ArrayElement<typeof items>) =>
    //@ts-ignore
    !!items.includes(item);

  const handleItemClicked = (
    item: ArrayElement<typeof items>,
  ) => {
    //@ts-ignore
    if (items.includes(item)) {
      //@ts-ignore
      onItemsChange(items.filter((i) => i !== item));
    } else {
      //@ts-ignore
      onItemsChange([...items, item]);
    }
  };

  return (
    <div
      className={twMerge(
        "flex flex-col md:flex-row justify-start items-start md:items-center gap-5 w-full ",
      )}
    >
      {showTypeSelect &&
        (
          <LabeledSelect
            containerClassName="min-w-[8rem] z-[80]"
            valueClassName="min-w-[8rem]"
            buttonClassName="text-start"
            className="z-[99]"
            id="reports-show-select"
            label="Show:"
            value={type}
            hideItemsOnClick
          >
            <ClickableSelectItem onClick={() => onTypeChange?.("Score")}>
              Score
            </ClickableSelectItem>
            <ClickableSelectItem onClick={() => onTypeChange?.("QBank Usage")}>
              QBank Usage
            </ClickableSelectItem>
          </LabeledSelect>
        )}
      {type === "Score" &&
        (
          <div className="flex flex-row justify-start items-center gap-5">
            {!showTypeSelect && <p className="text-gray-500">Show:</p>}
            <Select
              value={formatPerformanceSelectItems(items)}
              containerClassName="min-w-[9rem]"
              valueClassName="min-w-[9rem]"
              className="self-end"
            >
              <SelectItem>
                <LabeledCheckbox
                  label="Correct"
                  checked={isItemChecked("Correct")}
                  id="reports-show-correct"
                  onClick={() => handleItemClicked("Correct")}
                />
              </SelectItem>
              <SelectItem>
                <LabeledCheckbox
                  label="Incorrect"
                  checked={isItemChecked("Incorrect")}
                  id="reports-show-incorrect"
                  onClick={() => handleItemClicked("Incorrect")}
                />
              </SelectItem>
              <SelectItem>
                <LabeledCheckbox
                  label="Omitted"
                  checked={isItemChecked("Omitted")}
                  id="reports-show-omitted"
                  onClick={() => handleItemClicked("Omitted")}
                />
              </SelectItem>
              {withMarked &&
                (
                  <SelectItem>
                    <LabeledCheckbox
                      label="Marked"
                      checked={isItemChecked("Marked")}
                      id="reports-show-marked"
                      onClick={() => handleItemClicked("Marked")}
                    />
                  </SelectItem>
                )}
            </Select>
          </div>
        )}
    </div>
  );
};

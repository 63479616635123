import { PropsWithChildren } from "react";
import { cn } from "@/lib/utils";
import useDraggableContext from "./hooks/use-draggable-context";
import { motion } from "framer-motion";
import { PropsWithClassName } from "@/types";
import { smoothTransition } from "@/assets/framer";
import { smoothSpring } from "@/assets/framer/springs";

type DraggableBodyProps = PropsWithChildren & PropsWithClassName & {
  tabIndex?: number;
};
const DraggableBody = (
  { children, className, tabIndex }: DraggableBodyProps,
) => {
  const { position, isDragging, bodyRef } = useDraggableContext();

  return (
    <motion.div
      ref={bodyRef}
      className={cn(
        "fixed z-[99999]",
        isDragging && "select-none",
      )}
      style={{
        ...position
          ? { left: position.x, top: position.y }
          : { left: "50%", top: "50%", x: "-50%", y: "-50%" },
      }}
    >
      <motion.div
        animate={{
          scale: isDragging ? 1.03 : 1,
        }}
        className={cn("", className)}
        tabIndex={tabIndex}
        transition={{ ...smoothSpring, duration: 0.65, bounce: 0.3 }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default DraggableBody;

import createSubjectSlice from "./create-subject-slice";
import { Subject } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";

export type SubjectSliceActions = {
  addSubject: (subject: Subject) => void;
  addSubjects: (subjects: Subject[]) => void;
};
export type SubjectSliceState = typeof subjectSliceState;

export type SubjectSlice = SubjectSliceState & SubjectSliceActions;

export type SubjectSliceCreator = StateCreator<
  SubjectSlice,
  [["zustand/immer", never]],
  [],
  SubjectSlice
>;

export type SubjectSliceModifiers = SliceModifiers<SubjectSliceCreator>;

export type SubjectSliceAction = (...modifiers: SubjectSliceModifiers) => void;

export const subjectSliceState = {
  subjects: {} as ArrayMap<Subject>,
};

export default createSubjectSlice;

import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useGetQuestionBankSystemPerofrmanceReports from "./use-get-question-bank-system-performance-reports";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";
import { QuestionHeader } from "@/models/question-model";
import { System } from "@/models";

type Opts = {
  filters?: {
    questionHeaders?: ArrayMap<QuestionHeader>;
    systems?: ArrayMap<System>;
  };
};
const useQuestionBankSystemPerformanceReports = (
  args: QuestionBankIdentifier,
  opts?: Opts,
) => {
  const getQuestionBankSystemPerformanceReportsQuery =
    useGetQuestionBankSystemPerofrmanceReports(args, opts);
  const { data: getQuestionBankSystemPerformanceReports } =
    getQuestionBankSystemPerformanceReportsQuery;
  const performanceReports = getQuestionBankSystemPerformanceReports();

  return {
    ...getQuestionBankSystemPerformanceReportsQuery,
    data: performanceReports,
  };
};

export default useQuestionBankSystemPerformanceReports;

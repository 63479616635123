import { SearchInput as CommonSearchInput } from "@/components/SearchInput";
import { useSearch } from "@/hooks/notebook/ui/useSearch";
export const SearchInput = () => {
  const [search, setSearch] = useSearch();
  return (
    <CommonSearchInput
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      className="w-full bg-gray-100"
      variant="dashboard"
    />
  );
};

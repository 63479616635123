import useGetSystemQuestionHeaders from "./use-get-system-question-headers";
import useQuestionBankSystems from "@/hooks/system-hooks/use-question-bank-systems";
import { PerformanceReport } from "@/types/statistics/performance-report";
import ArrayMapUtils, { ArrayMap } from "@/types/common/ArrayConvertableMap";
import mergeFlags from "@/utils/common/merge-flags";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import { useCallback } from "react";
import { QuestionHeader } from "@/models/question-model";
import useGetQuestionBankSubjectChildlessPerofrmanceReports from "./use-get-question-bank-subject-childless-performance-reports";
import { Subject, System } from "@/models";

type Opts = {
  filters?: {
    questionHeaders?: ArrayMap<QuestionHeader>;
    systems?: ArrayMap<System>;
    subjects?: ArrayMap<Subject>;
  };
};
const useGetQuestionBankSystemPerofrmanceReports = (
  args: QuestionBankIdentifier,
  opts?: Opts,
) => {
  const systemsQuery = useQuestionBankSystems(args, {
    enabled: !opts?.filters?.systems,
  });
  const { data: systemsData } = systemsQuery;
  const internalSystems = opts?.filters?.systems ?? systemsData;
  const getSystemsQuestionHeadersQuery = useGetSystemQuestionHeaders(args, {
    filters: { questionHeaders: opts?.filters?.questionHeaders },
  });
  const getSubjectPerformanceReportsQuery =
    useGetQuestionBankSubjectChildlessPerofrmanceReports(args, opts);
  const { data: getSubjectPerformanceReports } =
    getSubjectPerformanceReportsQuery;
  const {
    data: getSystemQuestionHeaders,
    isLoading: isGetSystemsQuestionHeadersLoading,
  } = getSystemsQuestionHeadersQuery;

  const getQuestionBankSystemPerformanceReports = useCallback(
    (opts?: Opts) => {
      const systems = opts?.filters?.systems ?? internalSystems;
      const performanceReports: PerformanceReport[] = [];
      if (!!getSystemQuestionHeaders && !isGetSystemsQuestionHeadersLoading) {
        ArrayMapUtils.forEach(systems, (system) => {
          let total = 0;
          let correct = 0;
          let incorrect = 0;
          let omitted = 0;
          let used = 0;
          const systemQuestionHeaders = opts?.filters?.questionHeaders
            ? ArrayMapUtils.filter(
              opts?.filters?.questionHeaders,
              (qh) => qh.systemId === system.id,
            )
            : getSystemQuestionHeaders(system.id);
          ArrayMapUtils.forEach(systemQuestionHeaders, (questionHeader) => {
            total++;
            if (questionHeader.used) {
              used++;
              switch (questionHeader.used) {
                case "omitted":
                  omitted++;
                  break;
                case "correct":
                  correct++;
                  break;
                case "incorrect":
                  incorrect++;
                  break;
              }
            }
          });
          const performanceReport = {
            id: system.id,
            name: system.name,
            total,
            incorrect,
            correct,
            omitted,
            used,
            children: getSubjectPerformanceReports({
              filters: {
                questionHeaders: systemQuestionHeaders,
                systems: { [system.id]: system },
              },
            }).sort((a, b) => a.name.localeCompare(b.name)),
          };
          if (performanceReport.total === 0) return;
          performanceReports.push(performanceReport);
        });
      }
      return performanceReports.sort((a, b) => a.name.localeCompare(b.name));
    },
    [
      getSystemQuestionHeaders,
      isGetSystemsQuestionHeadersLoading,
      internalSystems,
    ],
  );

  return {
    ...mergeFlags({
      source: systemsQuery,
      destinations: [
        getSystemsQuestionHeadersQuery,
        getSubjectPerformanceReportsQuery,
      ],
    }),
    data: getQuestionBankSystemPerformanceReports,
  };
};

export default useGetQuestionBankSystemPerofrmanceReports;

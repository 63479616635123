import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";
import { useNbmeUnsubmittedSlotMark } from "@/utils/stores/nbmeLayoutStore";

export const NbmeSettingsUnsubmittedMarkControl = () => {
  const [unsubmittedSlotMark, setUnsubmittedSlotMark] =
    useNbmeUnsubmittedSlotMark();
  return (
    <NbmeSettingsSwitch
      checked={unsubmittedSlotMark}
      onCheckedChange={setUnsubmittedSlotMark}
    />
  );
};

import { MotionProps } from "framer-motion";
import { smoothTransition } from "..";

export const fadeOnConditionAnimation = (visible: boolean): MotionProps => ({
  initial: {
    opacity: visible ? 100 : 0,
    pointerEvents: visible ? "inherit" : "none",
  },
  animate: {
    opacity: visible ? 100 : 0,
    pointerEvents: visible ? "inherit" : "none",
  },
  exit: {
    opacity: 0,
    pointerEvents: "none",
  },
  transition: smoothTransition,
});
